import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import SortArrows from 'components/SortArrows/SortArrows';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { productTypes } from 'config/constants/productTypes';

const ActionHeaderItem = memo((props) => {
  const { header, onSort, hasSortButtons } = props;

  const { t } = useTranslation();

  const handleSort = useCallback(() => onSort(null, header), [header, onSort]);

  return (
    <>
      {header === 'name' && (
        <div className="col-title prod-name-title">
          <div className={classNames('label-wrap', { 'pointer-events__none': !hasSortButtons })} onClick={handleSort}>
            <span className="catalog-grid-l__title">{t('products.nomination')}</span>
            <div className="btn-square">{hasSortButtons && <SortArrows name={header} />}</div>
          </div>
        </div>
      )}

      {header === 'article' && (
        <div className="col-title vendor-code-title">
          <div className={classNames('label-wrap', { 'pointer-events__none': !hasSortButtons })} onClick={handleSort}>
            <span className="catalog-grid-l__title">{t('products.article')}</span>
            <div className="btn-square">{hasSortButtons && <SortArrows name={header} />}</div>
          </div>
        </div>
      )}

      {header === 'manufacturer' && (
        <div className="col-title brand-title">
          <div className={classNames('label-wrap', { 'pointer-events__none': !hasSortButtons })} onClick={handleSort}>
            <span className="catalog-grid-l__title">{t('products.brand')}</span>
            <div className="btn-square">{hasSortButtons && <SortArrows name={header} />}</div>
          </div>
        </div>
      )}

      {header === 'price' && (
        <div className="col-title prod-price-title">
          <div className={classNames('label-wrap', { 'pointer-events__none': !hasSortButtons })} onClick={handleSort}>
            <span className="catalog-grid-l__title">{t('products.price')}</span>
            <div className="btn-square">{hasSortButtons && <SortArrows name={header} />}</div>
          </div>
        </div>
      )}
    </>
  );
});

function ListHeader({ productType, onSort, sortOptions, hasSortButtons }) {
  const windowDimension = useWindowDimensions();
  const { t } = useTranslation();

  return (
    <>
      {productType === productTypes['catalogue'] && (
        <div className="cat-grid-l-fil cat-grid-l-fil_titles-row">
          <div className="col-title prod-img-title" />
          <ActionHeaderItem header={sortOptions.article} onSort={onSort} hasSortButtons={hasSortButtons} />
          <ActionHeaderItem header={sortOptions.manufacturer} onSort={onSort} hasSortButtons={hasSortButtons} />
          {windowDimension.width > 1600 && <ActionHeaderItem header={sortOptions.name} onSort={onSort} hasSortButtons={hasSortButtons} />}
          {windowDimension.width > 1600 && (
            <div className="col-title prod-info-title">
              <span className="catalog-grid-l__title" />
            </div>
          )}
          {windowDimension.width > 1600 && (
            <div className="col-title num-prod-title">
              <span className="catalog-grid-l__title">{t('products.number')}</span>
            </div>
          )}
          <ActionHeaderItem header={sortOptions.price} onSort={onSort} hasSortButtons={hasSortButtons} />
          {windowDimension.width > 1600 && (
            <>
              <div className="col-title favorites-title" />
              <div className="col-title buy-title">
                <span className="catalog-grid-l__title">{t('products.buy')}</span>
              </div>
            </>
          )}
        </div>
      )}

      {productType === productTypes['common'] && (
        <div className="catalog-grid-l">
          <div className="col-title prod-img-title" />
          <ActionHeaderItem header={sortOptions?.article} onSort={onSort} hasSortButtons={hasSortButtons} />
          <ActionHeaderItem header={sortOptions?.manufacturer} onSort={onSort} hasSortButtons={hasSortButtons} />
          <ActionHeaderItem header={sortOptions?.name} onSort={onSort} hasSortButtons={hasSortButtons} />
          <div className="col-title prod-info-title">
            <span className="catalog-grid-l__title" />
          </div>
          <div className="col-title num-prod-title">
            <span className="catalog-grid-l__title">{t('products.number')}</span>
          </div>
          <ActionHeaderItem header={sortOptions?.price} onSort={onSort} hasSortButtons={hasSortButtons} />
          <div className="col-title favorites-title" />
          <div className="col-title buy-title">
            <span className="catalog-grid-l__title">{t('products.buy')}</span>
          </div>
        </div>
      )}
    </>
  );
}

export default ListHeader;
