export const formatPrice = (price) => {
  if (!price && price !== 0) return '';
  const number = Number(price).toFixed(2);

  let floatingPointSymbol;
  if (number.includes('.')) floatingPointSymbol = '.';
  if (number.includes(',')) floatingPointSymbol = ',';

  const parts = number.split(floatingPointSymbol);
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return parts.join(floatingPointSymbol);
};
