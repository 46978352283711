import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';

import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import withAuth from 'HOC/withAuth/withAuth';
import useWindowDimensions from 'hooks/useWindowDimensions';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import { GlobalPreloader } from 'components/Preloader/Preloader';
import { useGetAboutUs } from 'hooks/api/useAboutUs';
import { useCatalogueTypes } from 'hooks/api/useProducts';

function AboutUs() {
  const { data: catalogueTypes = [] } = useCatalogueTypes(true);

  const params = useParams();
  const storage = params.storage;

  const windowDimension = useWindowDimensions();

  const { t } = useTranslation();

  const { data, isFetching } = useGetAboutUs(storage);

  return (
    <Fragment>
      {isFetching && <GlobalPreloader />}

      <div className="nav-bottom">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="title">
              <h3>{t('page_title.about_us')}</h3>
            </div>
          </div>

          {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>
      </div>

      {data && (
        <div className="wrapper">
          <div className="news-wrap news-wrap-v2">
            <div className="container">
              <div className="news-wrap__header">
                <div className="wrap">
                  <div className="title">
                    <h1>{data.label}</h1>
                  </div>
                </div>
              </div>
              <section className="news-detail">
                <div className="map-wrap" dangerouslySetInnerHTML={{ __html: data.iframe }} />
                <div className="news-detail__news-cnt news-cnt" dangerouslySetInnerHTML={{ __html: data.description }} />
              </section>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default compose(withPageLayout(), withAuth())(AboutUs);
