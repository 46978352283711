import React, { memo } from 'react';

const HintCommon = memo(({ label }) => (
  <div className="hidden-inf">
    <div className="hidden-inf__header-text">
      <p>{label}</p>
    </div>
  </div>
));

export default HintCommon;
