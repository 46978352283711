import React, { memo, useState, useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useWindowDimensions from 'hooks/useWindowDimensions';
import useOutsideClick from 'hooks/useOutsideClick';
import history from 'services/history';

const dropdownTypes = {
  characteristics: 'characteristics',
  appliedTo: 'appliedTo',
  originalNums: 'originalNums',
  description: 'description',
  noData: 'noData',
};

const DropdownItem = memo(({ type, data, onClick }) => {
  const { t } = useTranslation();

  switch (type) {
    case dropdownTypes.noData: {
      return (
        <li className="no-cnt">
          <p className="list__title">{t('label.no_information_provided')}</p>
        </li>
      );
    }
    case dropdownTypes.characteristics: {
      const [label, value, units] = data;

      return (
        <li>
          <p className="list__title">{label}</p>
          <p className="list__value">{`${value} ${units || ''}`}</p>
        </li>
      );
    }
    case dropdownTypes.appliedTo: {
      return (
        <li>
          <p className="list__title">{data.label}</p>
          <div className="list__value">
            <button type="button" onClick={() => onClick(data.articleId, data.mfrId)}>
              {t('action.show_models')}
            </button>
          </div>
        </li>
      );
    }
    case dropdownTypes.originalNums: {
      return (
        <li>
          <p className="list__title">{data.label}</p>
          <div className="list__value">
            {data.value.map((el) => (
              <button
                key={el.articleNumber}
                type="button"
                onClick={() => history.searchProductsResultCode(el.articleNumber, el.mfrId, data.label)}
              >
                {el.articleNumber}
              </button>
            ))}
          </div>
        </li>
      );
    }
    default:
      return null;
  }
});

const CharacteristicsDropdown = memo(({ data, type, onClick }) => {
  const [shownMore, setShownMore] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  const ref = useRef();
  const contentRef = useRef();
  const listRef = useRef();

  const title = useMemo(() => {
    if (type === dropdownTypes.characteristics) return t('label.characteristics');
    if (type === dropdownTypes.appliedTo) return t('label.applicability');
    if (type === dropdownTypes.originalNums) return t('label.original_numbers');
    if (type === dropdownTypes.description) return t('label.description');
  }, [t, type]);

  const toggleShowMore = () => setShownMore(!shownMore);

  useOutsideClick(ref, () => {
    if (shownMore) toggleShowMore();
  });

  useEffect(() => {
    const wrapHeight = contentRef?.current?.clientHeight + 60;
    const listHeight = listRef?.current?.clientHeight;

    if (listHeight > wrapHeight) setHasMore(true);
    if (listHeight <= wrapHeight) setHasMore(false);
  }, [width]);

  return (
    <div
      ref={ref}
      className={classNames('about-product', {
        active: shownMore,
        characteristics: type === dropdownTypes.characteristics,
        applied: type === dropdownTypes.appliedTo,
        'original-numbers': type === dropdownTypes.originalNums,
        description: type === dropdownTypes.description,
      })}
    >
      <div className="about-product__title title">
        <h4>{title}</h4>
      </div>

      <div
        ref={contentRef}
        className={classNames('cnt-wrap', {
          eclipse: hasMore && !shownMore && type === dropdownTypes.description,
        })}
      >
        {type === dropdownTypes.description && (
          <div className="about-product__desc" ref={listRef}>
            {data && data.length ? <p dangerouslySetInnerHTML={{ __html: data }} /> : <p>{t('label.no_description')}</p>}
          </div>
        )}
        {type !== dropdownTypes.description && (
          <ul ref={listRef} className="list">
            {(!data || data.length === 0) && <DropdownItem type={dropdownTypes.noData} />}
            {data && data.map((item, index) => <DropdownItem key={index} type={type} data={item} onClick={onClick} />)}
          </ul>
        )}

        {hasMore && (
          <button type="button" className="btn-review" onClick={toggleShowMore}>
            {shownMore ? t('action.hide') : t('action.in_more_detail')}
          </button>
        )}
      </div>
    </div>
  );
});

export { dropdownTypes };
export default CharacteristicsDropdown;
