import { useEffect } from 'react';

const useConstantScroll = () => {
  useEffect(() => {
    document.body.setAttribute('style', 'overflow-y: scroll;');

    return () => document.body.removeAttribute('style');
  }, []);
};

export { useConstantScroll };
