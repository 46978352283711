import React, { useRef, useContext, useCallback, useMemo, memo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import FilterGroup from './FilterGroup';
import SubCategories from './Subcategories';
import DuplicateFilterGroup from './DuplicateFilterGroup';
import Icon, { ICON_NAMES } from 'components/Icon/index';
import { ViewContext } from 'HOC/withPageLayout/withPageLayout';
import useBodyScrollLock from 'hooks/useBodyScrollLock';

const Filter = memo((props) => {
  const { filterGroups, productsTotal, smallScreen, largeScreen, isFilterOpen, onApply, catalogueTypes } = props;

  const { t } = useTranslation();

  const { setIsFilterOpen } = useContext(ViewContext);

  const filterRef = useRef();
  useBodyScrollLock(filterRef, isFilterOpen && smallScreen);

  const { catalogueType } = useParams();

  const filterLabelTextInitial = catalogueType === 'tools';

  const subCategories = catalogueTypes?.find((i) => i.name === catalogueType)?.categories;

  // Fetch products by click button on small screens
  const onApplyFetch = useCallback(() => {
    onApply();
    setIsFilterOpen(false);
  }, [onApply, setIsFilterOpen]);

  const shownProductsCount = useMemo(() => {
    if (!productsTotal) return '';
    const total = Number(productsTotal);
    let context;

    if (total === 0) context = 'none';
    if (total === 1) context = 'one';
    if (total > 1 && total < 5) context = 'not_many';
    if (total > 4) context = 'many';

    return t('filter.products_total', { total, context });
  }, [productsTotal, t]);

  switch (true) {
    case smallScreen:
      return (
        <>
          <div className="overlay-backdrop" onClick={() => setIsFilterOpen(false)} />
          <div className="filter filter_small-screen" ref={filterRef}>
            <div className="filter__top">
              <span>{t('filter.label')}</span>
              <button className="close-btn_small-screen" onClick={() => setIsFilterOpen(false)}>
                <Icon name={ICON_NAMES.close} fill={'000'} />
              </button>
            </div>

            <DuplicateFilterGroup />

            {Boolean(subCategories) && <SubCategories categories={subCategories} catalogueName={catalogueType} />}

            <ul className="filter-list">
              {filterGroups.map((group, index) => (
                <FilterGroup
                  key={group.name}
                  defaultOpen={index === 0}
                  name={group.name}
                  title={group.label}
                  options={group.data}
                  labelTextInitial={filterLabelTextInitial}
                />
              ))}
            </ul>

            <div className="filter__apply-btn_wrap">
              <button type="button" onClick={onApplyFetch}>
                {t('filter.apply_filter')}
              </button>
            </div>
          </div>
        </>
      );

    case largeScreen:
    default:
      return (
        <div className="filter">
          <div className="filter__top">
            <span>{t('filter.label')}</span>
            <div className="filter__selected-items">
              <p>{shownProductsCount}</p>
            </div>
          </div>

          <DuplicateFilterGroup />

          {Boolean(subCategories) && <SubCategories categories={subCategories} catalogueName={catalogueType} />}

          <ul className="filter-list">
            {filterGroups.map((group, index) => (
              <FilterGroup
                key={group.name}
                defaultOpen={index === 0}
                name={group.name}
                title={group.label}
                options={group.data}
                labelTextInitial={filterLabelTextInitial}
              />
            ))}
          </ul>
        </div>
      );
  }
});

export default Filter;
