import React, { useState, memo, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

import Icon, { ICON_NAMES } from 'components/Icon/index';
import { useScrollTo } from 'hooks/useScrollTo';
import { tecdocIconsByID } from 'config/constants/tecdocIcons';

const Node = memo((props) => {
  const { node, isMainNode, stairs, activeNode, openWithSearch, onFavoritesChange, onNodeClick } = props;

  const isActive = stairs.find((item) => item.nodeID === node.assemblyGroupNodeId);

  const [open, setOpen] = useState(isActive);

  const iconClassName = isMainNode && tecdocIconsByID[node.assemblyGroupNodeId]?.class;

  const nodeRef = useRef();

  useEffect(() => {
    if (activeNode === node.assemblyGroupNodeId && open) {
      setTimeout(() => {
        nodeRef.current && nodeRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }, 250);
    }
  }, [open, activeNode, node.assemblyGroupNodeId]);

  useEffect(() => {
    if (openWithSearch || isActive) setOpen(true);
    else setOpen(false);
  }, [openWithSearch, isActive]);

  return (
    <ul
      className={classNames('', {
        'tab-list': isMainNode,
        'tab-list__sublist': !isMainNode,
      })}
    >
      <li ref={nodeRef}>
        <div
          className={classNames('tab-list__desc', {
            'main-desc': isMainNode,
            active: activeNode === node.assemblyGroupNodeId,
            'tab-list__desc_hover-disabled': node.children.length === 0,
          })}
          onClick={() => setOpen(!open)}
        >
          <div className={classNames('tab-list__indicator', { active: open })}>
            {node.children.length > 0 && <Icon name={ICON_NAMES.listArrowRight} />}
          </div>
          <div className={classNames('tab-list__tab-img', { [iconClassName]: isMainNode })} />
          {node.children.length === 0 ? (
            <button type="button" className="node-btn" onClick={(event) => onNodeClick(event, node.assemblyGroupNodeId)}>
              {node.assemblyGroupName}
            </button>
          ) : (
            node.assemblyGroupName
          )}

          {node.hasFavoriteButton && (
            <button
              className="btn-square btn-square_favorites"
              onClick={(event) => onFavoritesChange(event, node.isFavorite, node.assemblyGroupNodeId)}
            >
              {node.isFavorite === true ? (
                <Icon name={ICON_NAMES.favoritesChecked} fill="#0a2352" />
              ) : (
                <Icon name={ICON_NAMES.favoritesUnchecked} fill="#0a2352" />
              )}
            </button>
          )}
        </div>

        <SlideDown className="dropdown-slidedown">
          {node.children.length > 0 &&
            open &&
            node.children.map((children) => (
              <Node
                key={children.assemblyGroupNodeId}
                node={children}
                activeNode={activeNode}
                openWithSearch={children.isOpened}
                stairs={stairs}
                onFavoritesChange={onFavoritesChange}
                onNodeClick={onNodeClick}
              />
            ))}
        </SlideDown>
      </li>
    </ul>
  );
});

const Nodes = memo((props) => {
  const { activeNode, vehicle, nodeStairs, nodes, search, removeFromFavorites, addToFavorites, onNodeClick, onChange } = props;

  const { t } = useTranslation();

  const onScrollTo = useScrollTo(null);

  const toggleFavorites = (event, isFavorite, productId) => {
    event.stopPropagation();
    if (isFavorite) removeFromFavorites({ id: productId });
    if (!isFavorite) addToFavorites({ id: productId });
  };

  useEffect(() => {
    if (vehicle && !activeNode) onScrollTo();
  }, [vehicle, activeNode, onScrollTo]);

  return !nodes ? null : (
    <div className="category-tabs">
      <div className="input-wrap">
        <input type="search" value={search} onChange={onChange} placeholder={t('label.filter')} />
      </div>

      {nodes.length > 0 &&
        nodes.map((node) => (
          <Node
            key={node.assemblyGroupNodeId}
            isMainNode
            stairs={nodeStairs}
            activeNode={activeNode}
            openWithSearch={node.isOpened}
            node={node}
            onFavoritesChange={toggleFavorites}
            onNodeClick={onNodeClick}
          />
        ))}

      {search && nodes.length === 0 && <div className="no-node-results">{t('label.no_search_results')}</div>}
    </div>
  );
});

export default Nodes;
