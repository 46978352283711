import React, { memo, useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import ProductName from 'components/ProductName/ProductName';
import VendorCode from 'components/VendorCode/VendorCode';
import ProductImage from 'components/ProductImage/ProductImage';
import BrandImage from 'components/BrandImage/BrandImage';
import FavoriteButton from 'components/FavoriteButton/FavoriteButton';

import { useAddToFavorites, useDeleteFromFavorites } from 'hooks/api/useProducts';
import useToastNotification from 'hooks/useToastNotification';
import { pageTypes } from 'config/constants/pageTypes';

const ProductItem = memo((props) => {
  const {
    queryKey,
    images,
    isMultiImages,
    logo,
    name,
    productViewType,
    article,
    manufacturer,
    mfrId,
    description,
    isFavorite,
    pageType,
    autoliderID,
    warehouses,
    isTecDoc,
    tecdocActive,
    handleSelectProduct,
    isFlipped,
    disable,
    selected,
    handleActiveProduct,
  } = props;
  const isFavoritesPage = pageType === pageTypes['favorites'];

  const { notify, notifyStatus } = useToastNotification();
  const { t } = useTranslation();

  const storageTotal = warehouses
    ?.filter((i) => i.value !== 'income')
    ?.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0);
  const incomeTotal = warehouses.find((i) => i.value === 'income').count;
  const totalCountIsZero = Number(storageTotal) === 0 && Number(incomeTotal) === 0;

  const { mutate: addToFavorites } = useAddToFavorites(queryKey);
  const { mutate: deleteFromFavorites } = useDeleteFromFavorites(queryKey, isFavoritesPage);

  const favoritesButtonClickHandler = useCallback(() => {
    if (isFavorite) {
      deleteFromFavorites({ item_id: autoliderID, mfrId, article });
      notify(t('info.deleted_favorites'), notifyStatus.success);
    } else {
      addToFavorites({ article, item_id: autoliderID, name, manufacturer, description, logo, images, mfrId });
      notify(t('info.added_favorites'), notifyStatus.success);
    }
  }, [
    article,
    autoliderID,
    name,
    manufacturer,
    images,
    mfrId,
    isFavorite,
    logo,
    description,
    notifyStatus,
    t,
    notify,
    addToFavorites,
    deleteFromFavorites,
  ]);

  return (
    <div
      className={classNames('catalog-grid-c flip-block', {
        flip: isFlipped,
        active: tecdocActive,
        transparent: disable,
      })}
    >
      {totalCountIsZero && <div className="overlay overlay_white" />}
      <div className="front">
        <FavoriteButton isColumn isFavorite={isFavorite} onClick={favoritesButtonClickHandler} />
        <ProductImage
          id={autoliderID}
          images={images}
          isMultiImages={isMultiImages}
          width={100}
          height={100}
          disabledLink={!autoliderID}
          isTecDoc={isTecDoc}
          deal={selected ? { text: t('label.chosen'), color: '#0A2352' } : undefined}
        />
        <div className="catalog-grid-c__wrap">
          <BrandImage isColumn manufacturer={manufacturer} logo={logo} />
          <ProductName id={autoliderID} name={name} disabledLink={!autoliderID} />
        </div>
        <VendorCode
          id={autoliderID}
          link={`/product-details/${autoliderID}`}
          productViewType={productViewType}
          article={article}
          showLabel
          disabledLink={!autoliderID}
          isColumn
        />
        <div className="product-select">
          <div className="product-select__wrapper">
            {!selected && (
              <button disabled={disable} onClick={() => handleActiveProduct(autoliderID)} type="button" className="btn select-product-btn">
                {t('label.choose')}
              </button>
            )}
            {selected && (
              <button
                type="button"
                onClick={() => notify(t('info.change_the_gift_contact_your_manager'), notifyStatus.success, { autoClose: 6000 })}
                className="btn btn--transparent btn-w-auto"
              >
                {t('label.choose_another_gift')}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="back message-block">
        <p>{t('label.are_you_sure_choose_this_gift')}</p>
        <div className="product-select no-grow">
          <div className="product-select__wrapper">
            <button onClick={() => handleActiveProduct(null)} type="button" className="btn btn--transparent">
              {t('label.no')}
            </button>
            <button
              onClick={() => {
                handleActiveProduct(null);
                handleSelectProduct(autoliderID);
              }}
              type="button"
              className="btn"
            >
              {t('label.yes')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ProductItem;
