import React, { useRef, useContext, useCallback, memo, useMemo } from 'react';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Icon, { ICON_NAMES } from 'components/Icon/index';
import useOutsideClick from 'hooks/useOutsideClick';
import useBodyScrollLock from 'hooks/useBodyScrollLock';
import history from 'services/history';
import { ViewContext } from 'HOC/withPageLayout/withPageLayout';

const Option = memo((props) => {
  const { smallScreen, largeScreen, selectSortOptionHandler, option, sortOption } = props;

  const onSelect = useCallback(() => selectSortOptionHandler(option), [option, selectSortOptionHandler]);

  switch (true) {
    case smallScreen:
      return (
        <li onClick={onSelect}>
          <span>{sortOption.label === option.label && <Icon name={ICON_NAMES.selected} fill={'#0a2352'} />}</span>
          <span>{option.label}</span>
        </li>
      );
    case largeScreen:
    default:
      return (
        <li className={classNames('', { active: sortOption.label === option.label })} onClick={onSelect}>
          {option.label}
        </li>
      );
  }
});

const Sort = memo(({ largeScreen, smallScreen, sortOptions, onSort }) => {
  const { isSortMenuOpen, setIsSortMenuOpen, sortOption, setSortOption } = useContext(ViewContext);

  const sortRef = useRef();
  useBodyScrollLock(sortRef, isSortMenuOpen && smallScreen);

  const ref = useRef();
  useOutsideClick(ref, () => {
    if (isSortMenuOpen) setIsSortMenuOpen(false);
  });

  const { t } = useTranslation();

  const selectSortOptionHandler = useCallback(
    (option) => {
      onSort({
        sort_by: option.sort_by,
        direction: option.direction,
      });
      setSortOption(option);
      setIsSortMenuOpen(false);
    },
    [onSort, setSortOption, setIsSortMenuOpen]
  );

  const sortLabel = useMemo(() => {
    const query = queryString.parse(history.location.search, {
      arrayFormat: 'separator',
      arrayFormatSeparator: '|',
    });
    const direction = query.direction;
    const sort_by = query.sort_by;
    const labelObject = sortOptions.find((item) => item.direction === direction && item.sort_by === sort_by);

    if (labelObject && labelObject.label) return labelObject.label;
    return t('select.products_sort.sort');
  }, [sortOptions, t]);

  switch (true) {
    case smallScreen:
      return (
        <>
          <div className="select-wrap__small-screen" ref={sortRef}>
            <div className="select-wrap__small-screen-header">
              <span className="select-wrap__small-screen-title">{t('select.products_sort.sort')}</span>
              <button className="select-wrap__small-screen-close" onClick={() => setIsSortMenuOpen(false)}>
                <Icon name={ICON_NAMES.close} fill={'#0a2352'} />
              </button>
            </div>

            <ul className="select-wrap__small-screen-list">
              {sortOptions.map((option, index) => (
                <Option key={index} smallScreen sortOption={sortOption} option={option} selectSortOptionHandler={selectSortOptionHandler} />
              ))}
            </ul>
          </div>
          <div className="overlay-backdrop" onClick={() => setIsSortMenuOpen(false)} />
        </>
      );
    case largeScreen:
    default:
      return (
        <div ref={ref} className="nav-bottom__select-wrap select-wrap" onClick={() => setIsSortMenuOpen(!isSortMenuOpen)}>
          <span className="select-wrap__val">{sortLabel}</span>
          <ul className="select-wrap__list shadow">
            {isSortMenuOpen &&
              sortOptions.map((option, index) => (
                <Option key={index} largeScreen option={option} sortOption={sortOption} selectSortOptionHandler={selectSortOptionHandler} />
              ))}
          </ul>
        </div>
      );
  }
});

export default Sort;
