import React from 'react';
import tecdocOffline from 'assets/images/other/tecdocOffline.svg';

const TecdocStatus = ({ offline }) => {
  switch (true) {
    case offline: {
      return (
        <div className="tecdoc-icon">
          <img src={tecdocOffline} width="60" height="60" alt="" />
        </div>
      );
    }
    default:
      return null;
  }
};

export default TecdocStatus;
