import React, { memo } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

const NewPost = memo(() => {
  const { t } = useTranslation();
  return (
    <>
      <Field name="city" initialValue="" validate={(value) => (value ? null : t('errors.required_field'))}>
        {({ input, meta }) => (
          <div className="field-wrap">
            <p>{t('basket.delivery_list.city')}</p>
            <div className="input-wrap input-wrap_small-field">
              <input {...input} type="text" placeholder={t('basket.delivery_list.city_placeholder')} />
              {meta.error && meta.touched && <span className="form-field_error">{meta.error}</span>}
            </div>
          </div>
        )}
      </Field>

      <Field name="department" initialValue="" validate={(value) => (value ? null : t('errors.required_field'))}>
        {({ input, meta }) => (
          <div className="field-wrap">
            <p>{t('basket.delivery_list.delivery_department')}</p>
            <div className="input-wrap input-wrap_small-field">
              <input {...input} type="text" placeholder={t('basket.delivery_list.new_post_delivery_department_placeholder')} />
              {meta.error && meta.touched && <span className="form-field_error">{meta.error}</span>}
            </div>
          </div>
        )}
      </Field>
    </>
  );
});

export default NewPost;
