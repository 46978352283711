import { useMemo, useCallback } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import sortProducts from 'utils/sortProducts';
import history from 'services/history';

const sortOptions = {
  article: 'article',
  manufacturer: 'manufacturer',
  name: 'name',
  price: 'price',
  sum: 'sum', // basket page
  data: 'data', // report page
  nomber: 'nomber', // report page
  kred: 'kred', // report page
};

const useSorting = (products) => {
  const { search, pathname } = useLocation();

  const query = queryString.parse(search, {
    arrayFormat: 'separator',
    arrayFormatSeparator: '|',
  });

  const direction = query.direction;
  const sortBy = query.sort_by;
  const descending = direction === 'desc';
  const ascending = direction === 'asc';

  const list = useMemo(() => {
    if (!products) return null;
    if (!sortBy || !direction) return products;
    return Array.from(sortProducts(sortBy, direction, products));
  }, [sortBy, direction, products]);

  const sort = useCallback(
    (sortObj, sortStr) => {
      const queryObj = { ...query };

      if (sortObj) {
        queryObj.sort_by = sortObj.sort_by;
        queryObj.direction = sortObj.direction;
      }
      if (!sortObj) {
        if (sortBy === sortStr && descending) queryObj.direction = 'asc';
        if (sortBy === sortStr && ascending) queryObj.direction = 'desc';
        if (sortBy !== sortStr || !sortBy) {
          queryObj.sort_by = sortStr;
          queryObj.direction = 'asc';
        }
      }

      history.pushSearch(queryObj, pathname);
    },
    [pathname, query, ascending, descending, sortBy]
  );

  return { sort, list, sortOptions };
};

export default useSorting;
