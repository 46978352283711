import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';

import logo from '../../assets/images/login/logo.svg';
import background from '../../assets/images/login/bg.png';
import { useResetPassword } from 'hooks/api/useAuth';
import withNoAuth from 'HOC/withNoAuth/withNoAuth';

const initialValues = {
  newPassword: '',
  repeatedPassword: '',
};

function CreateNewPassword() {
  const location = useLocation();

  const { t } = useTranslation();

  const { isSuccess, mutate: resetPassword } = useResetPassword();

  const onSubmit = (event) => {
    const token = location.pathname?.split('/').find((param) => param !== 'create-new-password' && param !== '');

    resetPassword({
      password: event.newPassword,
      token,
    });
  };

  if (isSuccess) return <Redirect to="/login" />;

  return (
    <section className="login" style={{ backgroundImage: `url(${background})` }}>
      <div className="login__container-m container-m">
        <div className="login__img-wrap">
          <img src={logo} width="366" height="100" alt="logo" />
          <span>{t('label.give_peoples_the_best')}</span>
        </div>

        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={(values) => {
            const errors = {};
            if (!values.newPassword) errors.newPassword = t('errors.required_field');
            if (!values.repeatedPassword) errors.repeatedPassword = t('errors.required_field');
            if (values.newPassword !== values.repeatedPassword) errors.repeatedPassword = t('errors.passwords_must_match');
            return errors;
          }}
          render={({ handleSubmit }) => {
            return (
              <form className="form" onSubmit={handleSubmit}>
                <div className="form__title title">
                  <h3>
                    {t('title.create_new_password')}
                    <br />
                    {t('label.b2b_avtolider')}
                  </h3>
                </div>

                <div className="list-fields">
                  <Field name="newPassword">
                    {({ input, meta }) => (
                      <div className="item">
                        <span className="item__title">{t('label.enter_new_password')}</span>
                        <div className="item__input-wrap">
                          <input {...input} type="password" placeholder={t('label.enter_password')} />

                          {meta.error && meta.touched && <span className="form-field_error">{meta.error}</span>}
                        </div>
                      </div>
                    )}
                  </Field>

                  <Field name="repeatedPassword">
                    {({ input, meta }) => (
                      <div className="item">
                        <span className="item__title">{t('label.repeat_new_password')}</span>
                        <div className="item__input-wrap">
                          <input {...input} type="password" placeholder={t('label.enter_password')} />

                          {meta.error && meta.touched && <span className="form-field_error">{meta.error}</span>}
                        </div>
                      </div>
                    )}
                  </Field>
                </div>

                <div className="form__btn-wrap">
                  <button type="submit" className="btn">
                    {t('action.send')}
                  </button>
                </div>
              </form>
            );
          }}
        />
      </div>
    </section>
  );
}

export default withNoAuth()(CreateNewPassword);
