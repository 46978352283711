import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { warehouseToLable, carrierIdToCarrierLable } from 'utils/deliveryDataUtils';
import { formatPrice } from 'utils/formatPrice';
import Icon, { ICON_NAMES } from 'components/Icon/index';

const OrderItem = (props) => {
  const { id, date, sum, deliveryDate, warehouse, carrierId, isProcessed } = props;

  const { t } = useTranslation();

  const processingStatus = isProcessed ? t('label.processed') : t('label.in_processing');

  const hasDeliveryTime = deliveryDate && (moment.utc(deliveryDate).minute() !== 0 || moment.utc(deliveryDate).hour() !== 0);

  const dispatchDate = hasDeliveryTime
    ? moment.utc(deliveryDate).format('(DD.MM.YYYY HH:mm)')
    : moment.utc(deliveryDate).format('(DD.MM.YYYY)');

  const toAboutUsPage = warehouse === 'expr' ? null : `${process.env.REACT_APP_API_URL}/about-us/${warehouse}`;

  return (
    <Link to={`/order/${id}`} className="cnt-row">
      <div className="column status">{processingStatus}</div>
      <div className="column warehouse">
        {warehouseToLable(warehouse)}
        {toAboutUsPage && (
          <button
            type="button"
            className="btn-inf"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              window.open(toAboutUsPage, '_blank').focus();
            }}
          >
            <Icon name={ICON_NAMES.info} onLeave />
          </button>
        )}
      </div>
      <div className="column number">
        <div>{id}</div>
      </div>
      <div className="column date">{date && moment.utc(date).format('DD MMMM YYYY')}</div>
      <div className="column total-amount">
        <strong className="amount">{formatPrice(sum)}</strong>
        <span className="currency">{t('label.currency')}</span>
      </div>
      <div className="column delivery-information">
        {carrierIdToCarrierLable(carrierId)}
        <br />
        {deliveryDate && dispatchDate}
      </div>
    </Link>
  );
};

export default OrderItem;
