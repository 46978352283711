import React, { Fragment, memo } from 'react';
import { NavLink } from 'react-router-dom';

const formatNum = (num) => {
  if (!num && num !== 0) return '';
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const DiscountLabel = memo(({ deal }) => {
  const { text, color, slug } = deal;

  const isNumberValue = Number(text) >= 0;

  return (
    <Fragment>
      {slug && (
        <NavLink to={`/sales/${slug}`} exact className="discount" style={{ backgroundColor: color }}>
          {isNumberValue ? formatNum(text) : text}
        </NavLink>
      )}
      {!slug && (
        <span className="discount" style={{ backgroundColor: color, cursor: 'auto' }}>
          {isNumberValue ? formatNum(text) : text}
        </span>
      )}
    </Fragment>
  );
});

export default DiscountLabel;
