import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useApi } from 'services/api';
import { articleKeys } from 'services/queryClient/queryKeys';
import logger from 'services/logger';
import i18next from 'i18next';
import useToastNotification from 'hooks/useToastNotification';

export const useGetArticles = (params) => {
  const { articles } = useApi();

  return useQuery(articleKeys.articles(params), () => articles.getArticles(params), {
    select: (res) => res?.data?.articles,
    onError: (error) => logger(error),
    keepPreviousData: false,
    staleTime: 500,
    cacheTime: 500,
  });
};

export const useGetArticle = (params) => {
  const { articles } = useApi();
  const { slug } = params;

  return useQuery(articleKeys.article(slug), () => articles.getArticle(params), {
    select: (res) => res?.data?.data,
    onError: (error) => logger(error),
  });
};

export const useGetUnreadArticlesCount = (type) => {
  const { articles } = useApi();

  return useQuery(articleKeys.unreadArticle(type), () => articles.getUnreadArticles(type), {
    select: (res) => res?.data?.count,
    onError: (error) => logger(error),
  });
};

export const useGetArticleProducts = (id) => {
  const { articles } = useApi();

  return useQuery(articleKeys.articleProducts(id), () => articles.getArticlesProducts(id), {
    enabled: false,
    select: (res) => res.data.products,
    onError: (error) => logger(error),
    keepPreviousData: false,
    cacheTime: 0,
    staleTime: 0,
  });
};

export const useReadAllArticles = () => {
  const { articles } = useApi();

  const queryClient = useQueryClient();

  return useMutation(articles.readAll, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['articles']);
    },
    onError: (error) => logger(error),
  });
};

export const useRegisterProductGift = (slug) => {
  const { articles } = useApi();
  const queryClient = useQueryClient();
  const { notify, notifyStatus } = useToastNotification();

  return useMutation(articles.selectProductGift, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(articleKeys.article(slug));
      notify(i18next.t('label.product_is_selected'), notifyStatus.success);
    },
    onError: (error) => logger(error),
  });
};
