import React, { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Icon, { ICON_NAMES } from 'components/Icon/index';
import UserProfile from './UserProfile';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { getArrearsData, getBalanceData } from 'utils/getArrearsData';
import { useApi } from 'services/api';
import { formatPrice } from 'utils/formatPrice';

const ClientInfo = memo((props) => {
  const { userName, userEmail, runningLineVisible, userCode, userBalanceFullInfo, userDebt, basketTotal, managerID } = props;

  const [isUserProfileInfoOpen, setIsUserProfileInfoOpen] = useState(false);

  const windowDimension = useWindowDimensions();

  const { logout } = useApi();

  const { t } = useTranslation();

  const arrears = getArrearsData(userDebt);

  const balanceCash = getBalanceData(userBalanceFullInfo.cash);
  const balanceCashless = getBalanceData(userBalanceFullInfo.cashless);

  return (
    <div className="inf-client">
      <div className="inf-client__arrears-wrap">
        <Link to="/debts" className="arrears balance-item inf-client__item">
          <span className="balance-label">
            {(balanceCash.isDebt || balanceCashless.isDebt) && t('label.debt')}
            {!balanceCash.isDebt && !balanceCashless.isDebt && t('label.no_debt')}
          </span>
          <span className="balance-info">
            {balanceCash.balanceShow && (
              <span className="balance-info__row">
                <span className="balance-info__title">
                  <span className="balance-info__title-icon">
                    <Icon name={ICON_NAMES.cash} fill="#fff" />
                  </span>
                  <span className="balance-info__title-label">{t('label.cash')}</span>
                </span>
                <span className="balance-info__value">
                  <span>{balanceCash.balance}</span> <span className="currency">{t('label.currency')}</span>
                </span>
              </span>
            )}
            {balanceCashless.balanceShow && (
              <span className="balance-info__row">
                <span className="balance-info__title">
                  <span className="balance-info__title-icon">
                    <Icon name={ICON_NAMES.cashless} fill="#fff" />
                  </span>
                  <span className="balance-info__title-label">{t('label.non_cash')}</span>
                </span>
                <span className="balance-info__value">
                  <span>{balanceCashless.balance}</span> <span className="currency">{t('label.currency')}</span>
                </span>
              </span>
            )}
            {!balanceCash.balanceShow && !balanceCashless.balanceShow && (
              <span className="balance-info__row" style={{ justifyContent: 'center' }}>
                <span className="balance-info__value">
                  <span>{formatPrice(0)}</span> <span className="currency">{t('label.currency')}</span>
                </span>
              </span>
            )}
          </span>
        </Link>

        <Link to="/debts" className="overdue inf-client__item">
          {windowDimension.width < 961 ? (
            <span className="overdue__text-h">{arrears?.overdueStatusShort}</span>
          ) : (
            <p className="overdue__text">
              {arrears?.overdueStatus} <span className="overdue__date">{arrears?.debtDateLabel}</span>
            </p>
          )}

          <div className={`overdue__wrap ${arrears?.overdueClassName}`}>
            <span className="overdue__sum">{arrears?.debt}</span> <span className="currency">{t('label.currency')}</span>
          </div>
        </Link>
      </div>

      <div
        className="user inf-client__item"
        style={{
          top: windowDimension.width > 1920 && runningLineVisible ? 30 : 0,
        }}
      >
        {windowDimension.width < 761 && (
          <Link to="/basket" className="user__basket btn-square">
            <Icon name={ICON_NAMES.basket} fill={'#fff'} />
            {basketTotal > 0 && <span className="count">{basketTotal}</span>}
          </Link>
        )}

        {windowDimension.width < 1681 && (
          <div
            className="user__profile btn-square btn-square_bg-col-sapphire"
            onClick={() => setIsUserProfileInfoOpen(!isUserProfileInfoOpen)}
          >
            <Icon name={ICON_NAMES.user} />
          </div>
        )}

        {windowDimension.width > 1680 && (
          <div className="user__wrap" onClick={() => setIsUserProfileInfoOpen(!isUserProfileInfoOpen)}>
            <p className="user__name">{userName}</p>
            <div className="btn-square user__btn-square">
              <Icon name={ICON_NAMES.dotsSquare} />
            </div>
          </div>
        )}

        {isUserProfileInfoOpen && (
          <UserProfile
            setIsUserProfileInfoOpen={setIsUserProfileInfoOpen}
            isUserProfileInfoOpen={isUserProfileInfoOpen}
            logoutHandler={logout}
            userName={userName}
            userEmail={userEmail}
            userCode={userCode}
            arrears={arrears}
            balanceCash={balanceCash}
            balanceCashless={balanceCashless}
            managerID={managerID}
          />
        )}

        {windowDimension.width > 760 && (
          <div className="user__log-out btn-square" onClick={logout}>
            <Icon name={ICON_NAMES.logOut} />
          </div>
        )}
      </div>
    </div>
  );
});

export default ClientInfo;
