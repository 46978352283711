import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useApi } from 'services/api';
import i18n from 'services/i18n/i18n';
import { authKeys } from 'services/queryClient/queryKeys';
import logger from 'services/logger';
import history from 'services/history';

export const useToken = () => {
  return useQuery(authKeys.token(), () => {
    return localStorage.getItem('token');
  });
};

export const useLogin = () => {
  const { auth, setToken, setContentLanguage } = useApi();

  const queryClient = useQueryClient();

  return useMutation(auth.login, {
    retry: false,
    onSuccess: ({ data }) => {
      const { token, settings } = data;
      const locale = settings?.locale;
      setToken(token);
      setContentLanguage(locale);
      i18n.changeLanguage(locale);
      queryClient.setQueryData(authKeys.token(), token);
    },
    onError: (error) => logger(error),
  });
};

export const useResetPassword = () => {
  const { auth } = useApi();

  return useMutation(auth.resetPassword, {
    retry: false,
    onError: (error) => logger(error),
  });
};

export const useRequestPasswordChange = () => {
  const { auth } = useApi();

  return useMutation(auth.requestPasswordChange, {
    retry: false,
    onSuccess: () => history.push({ pathname: '/login', state: 'success' }),
    onError: (error) => logger(error),
  });
};

export const useUpdatePassword = () => {
  const { auth } = useApi();

  return useMutation(auth.updatePassword, {
    retry: false,
    onError: (error) => logger(error),
  });
};
