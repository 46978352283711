const googleAnalytics = {};

googleAnalytics.extractCategoryFromUrl = (pathname) => {
  let category = null;
  if (pathname === '/') category = 'dashboard';
  if (pathname.includes('/tecdoc-search')) category = 'tecdoc-search';
  if (pathname.includes('/search/result/name')) category = 'search-name';
  if (pathname.includes('/search/result/code')) category = 'search-code';
  if (pathname.includes('/catalogue')) category = 'catalog';
  return category;
};

googleAnalytics.onOrderProcessing = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    window.gtag('event', 'checkout_progress');
  }
};

googleAnalytics.onOrderPurchase = (order, list) => {
  if (process.env.REACT_APP_ENV === 'production') {
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        currencyCode: 'UAH',
        purchase: {
          actionField: {
            id: order.id,
            revenue: order.summ,
            shipping: 0,
            tax: 'null',
          },
          products: list,
        },
      },
    });
    window.gtag('event', 'purchase', {
      transaction_id: order.id,
      affiliation: 'online.avtolider-ua.com',
      value: order.summ,
      currency: 'UAH',
      tax: 'null',
      shipping: 0,
      items: list,
    });
  }
};

googleAnalytics.onBasketEnter = (list) => {
  if (process.env.REACT_APP_ENV === 'production') {
    window.dataLayer.push({
      event: 'checkout',
      ecommerce: {
        currencyCode: 'UAH',
        checkout: {
          actionField: { step: 1 },
          products: list,
        },
      },
    });

    window.gtag('event', 'begin_checkout', {
      items: list,
    });
  }
};

googleAnalytics.onAddToBasket = (name, id, price, manufacturer, count) => {
  if (process.env.REACT_APP_ENV === 'production') {
    window.dataLayer.push({
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'UAH',
        add: {
          products: [
            {
              name: name,
              id: id,
              price: price,
              brand: manufacturer,
              category: 'Auto Spare Parts',
              quantity: count,
            },
          ],
        },
      },
    });
    window.gtag('event', 'add_to_cart', {
      items: [
        {
          name: name,
          id: id,
          price: price,
          brand: manufacturer,
          category: 'Auto Spare Parts',
          quantity: count,
        },
      ],
    });
  }
};

googleAnalytics.onAddToBasketFromPage = (name, id, price, manufacturer, count, category) => {
  if (process.env.REACT_APP_ENV === 'production') {
    window.gtag('event', 'select_item', {
      item_list_id: category,
      item_list_name: category,
      items: [
        {
          item_id: id,
          item_name: name,
          item_brand: manufacturer,
          item_category: category,
          price: price,
          quantity: count,
        },
      ],
    });

    window.gtag('event', `select_item_${category}`, {
      item_list_id: category,
      item_list_name: category,
      items: [
        {
          item_id: id,
          item_name: name,
          item_brand: manufacturer,
          item_category: category,
          price: price,
          quantity: count,
        },
      ],
    });
  }
};

googleAnalytics.onRemoveFromBasket = (name, id, price, manufacturer, count) => {
  if (process.env.REACT_APP_ENV === 'production') {
    window.gtag('event', 'remove_from_cart', {
      items: [
        {
          name: name,
          id: id,
          price: price,
          brand: manufacturer,
          category: 'Auto Spare Parts',
          quantity: count,
        },
      ],
    });
  }
};

googleAnalytics.onSearchPageView = (url) => {
  if (process.env.REACT_APP_ENV === 'production') {
    window.gtag('set', 'page', url);
    window.gtag('send', 'pageview');
  }
};

googleAnalytics.onSearchByCodeOrName = (url, pathname) => {
  if (process.env.REACT_APP_ENV === 'production') {
    window.gtag('event', 'page_view', {
      page_location: url,
      send_to: 'UA-5256301-68',
      page_path: pathname,
    });
    window.gtag('event', {
      page_location: url,
      send_to: 'UA-5256301-68',
      page_path: pathname,
    });

    window.gtag('set', 'page', url);
    window.gtag('send', 'Pageview');
  }
};

Object.freeze(googleAnalytics);

export default googleAnalytics;
