import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

const OrderItemsHeader = memo(() => {
  const { t } = useTranslation();

  return (
    <div className="titles-row">
      <div className="col-title numbering-title">#</div>
      <div className="col-title product-img-title"></div>
      <div className="col-title vendor-code-title">{t('label.article')}</div>
      <div className="col-title product-name-title">{t('label.name')}</div>
      <div className="col-title num-prod-title">{t('label.count')}</div>
      <div className="col-title product-price-title">{t('label.price')}</div>
      <div className="col-title total-amount-title">{t('label.sum')}</div>
    </div>
  );
});

export default OrderItemsHeader;
