import React, { useState, useCallback, memo } from 'react';
import AliceCarousel from 'react-alice-carousel';
import ImageViewer from 'react-simple-image-viewer';
import classNames from 'classnames';

import DiscountLabel from 'components/DiscountLabel/DiscountLabel';
import Icon, { ICON_NAMES } from 'components/Icon/index';
import { handleImageUrlDetail } from 'utils/handleImageUrl';
import LoyaltyLabel from 'components/LoyaltyLabel';

const Toolbar = memo(({ arrowLeft, arrowRight, close }) => (
  <div
    className={classNames('viewer-tool', {
      'viewer-tool__arrow': arrowRight || arrowLeft,
      'viewer-tool__close': close,
    })}
  >
    {arrowLeft && '<'}
    {arrowRight && '>'}
    {close && <Icon name={ICON_NAMES.close} />}
  </div>
));

const SliderItem = memo(({ image, index, onOpen }) => (
  <div className="image-slider__item" onClick={() => onOpen(index)}>
    <img src={image} width="" height="" alt="" />
  </div>
));

const ImageSlider = memo(({ images, deal, isMultiImages, loyalty }) => {
  const isButtonsDisabled = images?.length <= 1 ? true : false;

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = useCallback(() => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  }, []);

  return (
    <div className="image-slider">
      {deal && <DiscountLabel deal={deal} />}
      {loyalty && <LoyaltyLabel loyalty={loyalty} />}

      <AliceCarousel
        infinite={images?.length > 1 ? true : false}
        disableButtonsControls={isButtonsDisabled}
        autoPlay={false}
        touchTracking={false}
      >
        {images?.length !== 0 ? (
          images?.map((item, i) => {
            return <SliderItem key={i} image={handleImageUrlDetail(item)} index={i} onOpen={openImageViewer} />;
          })
        ) : (
          <img src={`${process.env.REACT_APP_API_URL}/assets/Nophoto.svg`} width="" height="" alt="" />
        )}
      </AliceCarousel>

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={true}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.6)',
            zIndex: 99999,
          }}
          leftArrowComponent={<Toolbar arrowLeft />}
          rightArrowComponent={<Toolbar arrowRight />}
          closeComponent={<Toolbar close />}
          closeOnClickOutside={true}
        />
      )}

      {isMultiImages && (
        <div className="small-squares">
          <span className="small-squares__item" />
          <span className="small-squares__item" />
          <span className="small-squares__item" />
        </div>
      )}
    </div>
  );
});

export default ImageSlider;
