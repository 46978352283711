import React, { memo } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

const SelfPickup = memo(() => {
  const { t } = useTranslation();

  return (
    <Field name="note">
      {({ input, meta }) => (
        <div className="field-wrap textarea">
          <p>{t('basket.delivery_list.note')}</p>
          <textarea
            {...input}
            placeholder={t('basket.label.default_placeholder')}
          />
        </div>
      )}
    </Field>
  );
});

export default SelfPickup;
