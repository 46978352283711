import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BasketProduct from '../BasketProduct';
import Icon, { ICON_NAMES } from 'components/Icon/index';

const OrderApproved = memo(({ order, defaultWarehouse, onToBasket }) => {
  const { t } = useTranslation();

  const data = order?.data?.products;
  const orderId = order?.data?.order?.id;

  return (
    <div className="wrapper">
      <section className="basket-wrap">
        <div className="order-result">
          <div className="order-result__container container">
            <div className="order-result__top">
              <Icon name={ICON_NAMES.success} />
              <p>
                {t('basket.label.your_order')} №<span className="order-result__number">{orderId}</span> {t('basket.label.decorated')}
              </p>
            </div>
            <div className="order-result__desc-wrap">
              <p>{t('basket.label.order_success_message1')}</p>
              <p>{t('basket.label.order_success_message2')}</p>
            </div>
            <div className="order-result__btns-wrap">
              <Link to="/" className="btn btn_bg-col-gray">
                {t('action.to_main')}
              </Link>

              <button className="btn" type="button" onClick={onToBasket}>
                {t('action.to_basket')}
              </button>
            </div>
          </div>
        </div>

        {data && (
          <div className="basket remaining-goods">
            {data?.map((item) => (
              <BasketProduct
                isOrderApproved
                key={item.item_id}
                article={item.article}
                autoliderID={item.item_id}
                images={item.images}
                manufacturer={item.manufacturer}
                name={item.name}
                logo={item.logo}
                price={item.price}
                totalPrice={Number((item.price * item.shopping_count).toFixed(2))}
                deal={item.deal}
                description={item.description}
                warehouses={item.warehouses ?? []}
                selected={item?.selected}
                onUnselected={() => {}}
                count={item.shopping_count}
                defaultWarehouse={defaultWarehouse}
              />
            ))}
          </div>
        )}
      </section>
    </div>
  );
});

export default OrderApproved;
