import { useMutation, useQueryClient } from 'react-query';
import { useApi } from 'services/api';
import logger from 'services/logger';

export const useAddToWishlist = (queryKey) => {
  const { wishlist } = useApi();
  const queryClient = useQueryClient();

  return useMutation(wishlist.addToWishlist, {
    retry: false,
    onMutate: async (product) => {
      await queryClient.cancelQueries(queryKey);
      const { mfrId, article } = product;
      const previous = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (data) => {
        const products = data.data.products;
        const productSingle = data.data.product;

        const mutate = (array) => {
          return array.map((item) => {
            if (item.mfrId === mfrId && item.article === article) return { ...item, is_in_wishlist: true };
            return item;
          });
        };

        if (Boolean(productSingle)) {
          data.data.product.is_in_wishlist = false;
          return data;
        }

        if (Array.isArray(products)) {
          data.data.products = mutate(products);
          return data;
        }

        if (!Array.isArray(products)) {
          const objKeys = Object.keys(products);

          if (objKeys.includes('data')) {
            data.data.products.data = mutate(products.data);
            return data;
          }

          if (objKeys.includes('main') && objKeys.includes('other_analogs') && objKeys.includes('td_analogs')) {
            data.data.products.main = mutate(products.main);
            data.data.products.other_analogs = mutate(products.other_analogs);
            data.data.products.td_analogs = mutate(products.td_analogs);
            return data;
          }
        }
      });
      return { previous };
    },
    onError: (error) => {
      logger(error);
      queryClient.invalidateQueries(queryKey);
    },
  });
};

export const useRemoveFromWishlist = (queryKey) => {
  const { wishlist } = useApi();
  const queryClient = useQueryClient();

  return useMutation(wishlist.removeFromWishlist, {
    retry: false,
    onMutate: async (product) => {
      await queryClient.cancelQueries(queryKey);
      const { mfrId, article } = product;
      const previous = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (data) => {
        const products = data.data.products;
        const productSingle = data.data.product;

        const mutate = (array) => {
          return array.map((item) => {
            if (item.mfrId === mfrId && item.article === article) return { ...item, is_in_wishlist: false };
            return item;
          });
        };

        if (Boolean(productSingle)) {
          data.data.product.is_in_wishlist = false;
          return data;
        }

        if (Array.isArray(products)) {
          data.data.products = mutate(products);
          return data;
        }

        if (!Array.isArray(products)) {
          const objKeys = Object.keys(products);

          if (objKeys.includes('data')) {
            data.data.products.data = mutate(products.data);
            return data;
          }

          if (objKeys.includes('main') && objKeys.includes('other_analogs') && objKeys.includes('td_analogs')) {
            data.data.products.main = mutate(products.main);
            data.data.products.other_analogs = mutate(products.other_analogs);
            data.data.products.td_analogs = mutate(products.td_analogs);
            return data;
          }
        }
      });
      return { previous };
    },
    onError: (error) => {
      logger(error);
      queryClient.invalidateQueries(queryKey);
    },
  });
};
