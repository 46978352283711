import { useQueryClient } from 'react-query';
import { useEffect } from 'react';
import { io } from 'socket.io-client';
import { productKeys, articleKeys, basketKeys, notificationKeys, loyaltyProgramsKeys } from 'services/queryClient/queryKeys';
import useFocusedTabState from 'hooks/useFocusedTabState';

const useSocket = (token) => {
  const queryClient = useQueryClient();

  const { isFocused } = useFocusedTabState();

  useEffect(() => {
    if (token && isFocused) {
      const socket = io(`${process.env.REACT_APP_API_URL}/`, {
        transports: ['websocket'],
        auth: { token },
      });

      socket.on('connect', () => {
        console.log('--- connected', socket);
      });
      socket.on('reload_favorites', (data) => {
        queryClient.invalidateQueries(productKeys.favoritesTotal());
      });
      socket.on('reload_sales', (data) => {
        queryClient.invalidateQueries(articleKeys.unreadArticle('sales'));
        queryClient.invalidateQueries(loyaltyProgramsKeys.unreadLoyalty());
      });
      socket.on('reload_news', (data) => {
        queryClient.invalidateQueries(articleKeys.unreadArticle('news'));
      });
      socket.on('reload_shopping_basket', (data) => {
        queryClient.invalidateQueries(basketKeys.basketTotal());
      });
      socket.on('reload_notifications', (data) => {
        queryClient.invalidateQueries(notificationKeys.notificationsInfo());
      });
      socket.on('reload_poll', (data) => {
        queryClient.invalidateQueries(notificationKeys.surveyList());
      });

      return () => {
        console.log('--- closed');
        socket.close();
      };
    }
  }, [queryClient, isFocused, token]);
};

export default useSocket;
