import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatPrice } from 'utils/formatPrice';
import { preventLinkRedirect } from 'utils/preventLinkRedirect';

const ReportItem = memo((props) => {
  const { name, id, date, number, department, income, expense, payDate, disabled } = props;

  const { t } = useTranslation();

  return (
    <Link to={`/debt/${id}`} className="cnt-row" onClick={(event) => preventLinkRedirect(event, disabled)}>
      <div className="column date">{date}</div>

      <div className="column document">
        <span className="document__text">{name?.trim()}</span>
      </div>

      <div className="column number">
        <span>{number}</span>
      </div>

      {/* <div className="column f">{baseId}</div> */}

      <div className="column expense">{expense ? formatPrice(expense) : '-'}</div>

      <div className="column income">
        {income ? (
          <>
            <strong className="income__amount amount">{formatPrice(income)}</strong>
            <span className="currency">{t('label.currency')}</span>
          </>
        ) : (
          '-'
        )}
      </div>

      <div className="column payment-term">{payDate}</div>

      {/* <div className="column balance">{balance}</div> */}

      <div className="column department">{department?.trim()}</div>
    </Link>
  );
});

export default ReportItem;
