import React, { memo } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import Icon, { ICON_NAMES } from 'components/Icon/index';

const SortArrows = memo(({ name }) => {
  const { search } = useLocation();

  const query = queryString.parse(search, {
    arrayFormat: 'separator',
    arrayFormatSeparator: '|',
  });

  const direction = query.direction;
  const sortBy = query.sort_by;

  const isActive = sortBy === name;
  const descending = isActive && direction === 'desc';
  const ascending = isActive && direction === 'asc';

  switch (true) {
    case ascending:
      return <Icon name={ICON_NAMES.arrows_asc} />;
    case descending:
      return <Icon name={ICON_NAMES.arrows_desc} />;
    default:
      return <Icon name={ICON_NAMES.arrows} />;
  }
});

export default SortArrows;
