import classNames from 'classnames';
import { useDisclosure } from 'hooks/useDisclosure';
import { memo } from 'react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { getCatalogueNavigationUrl } from 'utils/getCatalogueNavigationUrl';

const SubMenu = memo((props) => {
  const { tool, onCatalogueChange } = props;

  const submenuState = useDisclosure(true);

  return (
    <li
      className={classNames('drop-list', { active: submenuState.isOpen })}
      onClick={(e) => {
        e.stopPropagation();
        submenuState.toggle();
      }}
    >
      <div className="drop-list__wrap">
        <p>{tool.label}</p>
      </div>

      <ul>
        {tool.list.map((catalogue) => (
          <li key={catalogue.name} onClick={onCatalogueChange}>
            <NavLink to={getCatalogueNavigationUrl(catalogue)}>{catalogue.label}</NavLink>
          </li>
        ))}
      </ul>
    </li>
  );
});

export default SubMenu;
