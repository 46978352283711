import { useQuery, useMutation } from 'react-query';
import { useApi } from 'services/api';
import { dashboardKeys } from 'services/queryClient/queryKeys';
import logger from 'services/logger';

export const useGetDashboardData = () => {
  const { dashboard } = useApi();

  return useQuery(dashboardKeys.dashboard(), () => dashboard.getDashboardData(), {
    select: (res) => res?.data,
    onError: (error) => logger(error),
  });
};

export const useGetFeedbackSubjectsList = () => {
  const { dashboard } = useApi();

  return useQuery(dashboardKeys.feedbackSubjectsList(), () => dashboard.getFeedbackSubjectsList(), {
    select: (res) => res?.data,
    onError: (error) => logger(error),
    cacheTime: 5 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });
};

export const useSendFeedback = () => {
  const { dashboard } = useApi();

  return useMutation(dashboard.sendFeedback, {
    retry: false,
    onError: (error) => logger(error),
  });
};
