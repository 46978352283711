import React, { useRef, memo, useMemo, useCallback, useEffect } from 'react';
import useOutsideClick from 'hooks/useOutsideClick';

const SelectFieldAddress = memo((props) => {
  const { header, options, input, meta, defaultLabel, onReset } = props;

  const ref = useRef();

  useOutsideClick(ref, () => input.onBlur());

  const active = useMemo(() => {
    return options?.find((option) => JSON.stringify(option?.value) === JSON.stringify(input?.value));
  }, [input, options]);

  const onChange = useCallback(
    (option, event) => {
      if (event) event.stopPropagation();
      input.onChange(option.value);
      input.onBlur();
      if (onReset) onReset();
    },
    [input, onReset]
  );

  const toggleMenuOpen = () => {
    if (meta.active) {
      input.onBlur();
      return;
    }
    input.onFocus();
  };

  // useEffect(() => {
  //   const initial = options?.length === 1 ? options[0].value : null;
  //   if (initial == null || initial === input.value) return;
  //   requestAnimationFrame(() => input.onChange(initial));
  // }, [options, input]);

  //! to do more right method
  useEffect(() => {
    const initial = options?.length === 1 ? options[0].value : null;
    if (initial == null || initial === input.value) return;
    requestAnimationFrame(() => input.onChange(initial));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="field-wrap">
      <p>{header}</p>
      <div className="select-wrap" ref={ref} onClick={toggleMenuOpen}>
        {meta.submitFailed && meta.error && !meta.active && (
          <span className="form-field_error form-field_error__offset-r">{meta.error}</span>
        )}

        <span className={`select-wrap__val ${active && 'selected'}`}>{active?.label || defaultLabel}</span>
        <ul className="select-wrap__list shadow">
          {meta.active &&
            options?.map((option, index) => {
              return (
                <li key={index} className={input.value === option?.value ? 'selected' : ''} onClick={(event) => onChange(option, event)}>
                  {option?.label}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
});

export default SelectFieldAddress;
