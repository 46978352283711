import React, { memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Icon, { ICON_NAMES } from 'components/Icon/index';
import { validateYearsOfProductionTecdoc } from 'utils/validateYearsOfProductionTecdoc';
import useOutsideClick from 'hooks/useOutsideClick';
import useBodyScrollLock from 'hooks/useBodyScrollLock';

const AutoAbout = memo(({ data, isOpen, onClose }) => {
  const { t } = useTranslation();

  const ref = useRef();
  useOutsideClick(ref, () => {
    if (isOpen) onClose();
  });

  const tecLiters = (data.ccmTech / 1000).toFixed(1);
  const motorCodes = Array.isArray(data.motorCodes) ? data.motorCodes.map((i) => i.motorCode).join(', ') : data.motorCodes;

  const overflowRef = useRef();
  useBodyScrollLock(overflowRef, true);

  return (
    <div className="vehicle-info" ref={ref}>
      <div className="container">
        <div className="vehicle-info__wrap">
          <div className="vehicle-info__title title">
            <h3>{data.manuName}</h3>
            <button type="button" className="btn-close" onClick={onClose}>
              <Icon name={ICON_NAMES.close} fill="#0A2352" />
            </button>
          </div>
          <div className="vehicle-info__cnt" ref={overflowRef}>
            <ul className="list">
              <li className="item">
                <p className="item__title">{t('label.vehicle_type')}</p>
                <p className="item__value">{data.type}</p>
              </li>
              <li className="item">
                <p className="item__title">{t('label.year_of_production')}</p>
                <p className="item__value">{validateYearsOfProductionTecdoc(data.from, data.to)}</p>
              </li>
              <li className="item">
                <p className="item__title">{t('label.engine_power')}</p>
                <p className="item__value">{`${data.kw} ${t('label.kv')} / ${data.hp} ${t('label.hp')}`}</p>
              </li>
              <li className="item">
                <p className="item__title">{t('label.working_catacity')}</p>
                <p className="item__value">
                  {`${data.cylinderCapacityCcm} ${t('label.centimeters_short')}`}
                  <sup>3</sup> {` / ${tecLiters} ${t('label.liters_short')}`}
                </p>
              </li>
              <li className="item">
                <p className="item__title">{t('label.number_of_cylinders')}</p>
                <p className="item__value">{data.cylinder}</p>
              </li>
              <li className="item">
                <p className="item__title">{t('label.valves')}</p>
                <p className="item__value">{data.valves}</p>
              </li>
              <li className="item">
                <p className="item__title">{t('label.type_of_construction')}</p>
                <p className="item__value">{data.constructionType}</p>
              </li>
            </ul>
            <ul className="list">
              <li className="item">
                <p className="item__title">{t('label.fuel')}</p>
                <p className="item__value">{data.fuelType}</p>
              </li>
              <li className="item">
                <p className="item__title">{t('label.engine_codes')}</p>
                <p className="item__value">{motorCodes}</p>
              </li>
              <li className="item">
                <p className="item__title">{t('label.engine_type')}</p>
                <p className="item__value">{data.motorType}</p>
              </li>
              <li className="item">
                <p className="item__title">{t('label.preparation')}</p>
                <p className="item__value">{data.fuelTypeProcess}</p>
              </li>
              <li className="item">
                <p className="item__title">{t('label.break_system')}</p>
                <p className="item__value">{data.brakeSystem}</p>
              </li>
              <li className="item">
                <p className="item__title">{t('label.drive_type')}</p>
                <p className="item__value">{data.impulsionType}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
});

export default AutoAbout;
