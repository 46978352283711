import { useEffect, useState, useRef } from 'react';
import useWindowDimensions from 'hooks/useWindowDimensions';

export const useRunningLine = (initialContent) => {
  const [content, setContent] = useState([initialContent]);
  const [running, setRunning] = useState(false);

  const testRef = useRef();
  const mainRef = useRef();

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (testRef.current && mainRef.current && testRef.current.clientWidth > mainRef.current.clientWidth) {
      setContent([initialContent, initialContent, initialContent]);
      setRunning(true);
    }
  }, [initialContent, width]);

  return {
    content,
    running,
    testRef,
    mainRef,
  };
};
