export const tecdocDescriptionToText = (description) => {
  if (!description || description === '') return null;

  if (typeof description === 'string') return description;
  if (Array.isArray(description) && description.length === 0) return null;

  return (
    description
      .map((item) => {
        const [criteriaDescription, formattedValue] = item;
        const criteriaUnitDescription = Boolean(item[2]) ? item[2] : '';
        return `${criteriaDescription}: ${formattedValue} ${criteriaUnitDescription}`.trim();
      })
      .join(';\n') + ';'
  );
};
