import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useApi } from 'services/api';
import { searchKeys } from 'services/queryClient/queryKeys';
import logger from 'services/logger';

export const useGetSearchBrands = (searchValue) => {
  const { search } = useApi();

  return useQuery(searchKeys.searchBrands(searchValue), () => search.getSearchBrands(searchValue), {
    select: (res) => res.data,
    keepPreviousData: false,
    cacheTime: 0,
    staleTime: 0,
    onError: (error) => logger(error),
    refetchOnWindowFocus: false,
  });
};

export const useGetSearchProductsByCode = (searchValue, manufacturerId) => {
  const { search } = useApi();

  const disabled = searchValue == null || searchValue === '' || manufacturerId == null || manufacturerId === '';

  return useQuery(
    searchKeys.searchProducts({ search: searchValue, manufacturer: manufacturerId }),
    () => search.getSearchProductsByCode(searchValue, manufacturerId),
    {
      enabled: disabled ? false : true,
      keepPreviousData: false,
      staleTime: 0,
      cacheTime: 0,
      select: (res) => res.data,
      onError: (error) => logger(error),
    }
  );
};

export const useGetSearchProductsByName = (name) => {
  const { search } = useApi();

  return useQuery(searchKeys.searchProductsName(name), () => search.getSearchProductsByName(name), {
    keepPreviousData: false,
    staleTime: 0,
    cacheTime: 0,
    select: (res) => res.data,
    onError: (error) => logger(error),
  });
};

export const useSearchByNameCheck = () => {
  const { search } = useApi();

  return useMutation(search.getSearchByNameCheck, {
    retry: false,
    onError: (error) => logger(error),
  });
};

export const useAddSearchToHistory = () => {
  const { search } = useApi();
  const queryClient = useQueryClient();
  const queryKey = searchKeys.searchHistory();

  return useMutation(search.addSearchToHistory, {
    retry: false,
    onMutate: async (data) => {
      await queryClient.cancelQueries(queryKey);

      const prevData = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (historyData) => {
        const filteredList = historyData.data.history.filter((el) => {
          return el.search != data.search || el.type != data.type;
        });
        historyData.data.history = [{ ...data }, ...filteredList];
        return historyData;
      });

      return { prevData };
    },
    onError: (error) => {
      logger(error);
      queryClient.invalidateQueries(queryKey);
    },
  });
};

export const useGetSearchHistory = () => {
  const { search } = useApi();

  return useQuery(searchKeys.searchHistory(), () => search.getSearchHistory(), {
    select: (res) => res.data.history,
    onError: (error) => logger(error),
  });
};
