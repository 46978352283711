import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import CountDown from 'components/CountDown/CountDown';

const ProductRemoval = memo(({ name, ms = 5000, onDelete, onStop }) => {
  const { t } = useTranslation();

  return (
    <div className="cnt-row-delete">
      <p>
        {t('basket.label.you_deleted')} <span className="cnt-row-delete__product">{name}</span>
      </p>

      <CountDown ms={ms} onComplete={onDelete} stopHandler={onStop} />
    </div>
  );
});

export default ProductRemoval;
