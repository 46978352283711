import React, { memo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatPrice } from 'utils/formatPrice';
import { preventLinkRedirect } from 'utils/preventLinkRedirect';

const TableItem = memo((props) => {
  const { index, docId, docNum, docName, docDate, docPayDate, cahsSum, nonCashSum, docDepartment, disabled } = props;

  const { t } = useTranslation();

  return (
    <Link to={`/debt/${docId}`} className="cnt-row" onClick={(event) => preventLinkRedirect(event, disabled)}>
      <div className="column numbering">{index}</div>
      <div className="column department">{docDepartment}</div>
      <div className="column number">
        <div>{docNum}</div>
      </div>
      <div className="column document">
        <span className="document__text">{docName}</span>
      </div>
      <div className="column date">
        <strong>{t('label.date')}</strong>
        {moment.utc(docDate).format('DD MMMM YYYY')}
      </div>
      <div className="column payment-term">
        <strong>{t('label.payment_term')}</strong>
        {docPayDate ? moment.utc(docPayDate).format('DD MMMM YYYY') : ''}
      </div>
      <div className="column cash">
        <strong>{t('label.sum_cash')}</strong>
        <div className="wrap">
          <strong className="cash__debt">{formatPrice(cahsSum)}</strong>
          <span className="currency">{t('label.currency')}</span>
        </div>
      </div>
      <div className="column cashless">
        <strong>{t('label.sum_non_cash')}</strong>
        <div className="wrap">
          <strong className="cashless__debt">{formatPrice(nonCashSum)}</strong>
          <span className="currency">{t('label.currency')}</span>
        </div>
      </div>
    </Link>
  );
});

export default TableItem;
