import moment from 'moment';

export function getDepartureTime(date) {
  if (!date) return '';

  const hasTime = date && (moment.utc(date).minute() !== 0 || moment.utc(date).hour() !== 0);
  const departureDate = hasTime ? moment.utc(date).format('DD.MM.YYYY HH:mm') : moment.utc(date).format('DD.MM.YYYY');

  return departureDate;
}
