import React from 'react';
import moment from 'moment';
import 'moment/locale/uk';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { imgUrlToResizeUrl } from 'utils/imgUrlToResizeUrl';
import Icon, { ICON_NAMES } from 'components/Icon/index';

function NewsPreview(props) {
  const { image, title, published_at, short_description, seo_slug, views, isRead } = props;

  const date = moment(published_at).format('DD MMMM YYYY');
  const { t } = useTranslation();

  return (
    <NavLink to={`/news/${seo_slug}`} exact className="news__item item">
      <div className="item__wrap">
        <div className="item__img-wrap">
          <img src={imgUrlToResizeUrl(image, 440, 200, 'resize')} width="440" height="200" alt="" />
        </div>
        <div className="item__cnt">
          {!isRead && <span className="item__status" />}

          <div className="item__title title">
            <h3>{title}</h3>
          </div>
          <div className="publication">
            <time className="publication__date">{date}</time>
            <div className="publication__views-wrap">
              <strong className="publication__views">{views}</strong>
              <Icon name={ICON_NAMES.watched} />
            </div>
          </div>
          <p className="item__desc">{short_description}</p>
        </div>
      </div>
      <span className="link link_col-blue">{t('label.article_preview_details')}</span>
    </NavLink>
  );
}

export default NewsPreview;
