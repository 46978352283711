const { default: i18n } = require('services/i18n/i18n');

export function getCorrectEndingPoints(number) {
  const lastTwoDigits = number % 100;
  const lastDigit = number % 10;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
    return i18n.t('label.points.common');
  } else if (lastDigit === 1) {
    return i18n.t('label.points.end_1');
  } else if (lastDigit >= 2 && lastDigit <= 4) {
    return i18n.t('label.points.end_2');
  } else {
    return i18n.t('label.points.common');
  }
}
