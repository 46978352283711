import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { compose } from 'redux';
import classNames from 'classnames';

import { GlobalPreloader } from 'components/Preloader/Preloader';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import TecdocStatus from 'components/TecdocStatus/TecdocStatus';
import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import withAuth from 'HOC/withAuth/withAuth';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useGetSearchBrands } from 'hooks/api/useSearch';
import { useCatalogueTypes } from 'hooks/api/useProducts';
import { normalizeSearchString } from 'utils/normalizeString';
import googleAnalytics from 'services/googleAnalytics';

function SearchBrands() {
  const { data: catalogueTypes } = useCatalogueTypes();

  const windowDimension = useWindowDimensions();
  const { t } = useTranslation();
  const { search, pathname } = useLocation();

  const { search: searchValue } = queryString.parse(search);

  const { data: brands, isFetching, isSuccess } = useGetSearchBrands(normalizeSearchString(searchValue));

  const isTecdocOnline = useMemo(() => {
    const checkOffline = (data) => data && data.online === false;

    if (checkOffline(brands)) return false;
    return true;
  }, [brands]);

  useEffect(() => {
    if (isSuccess) {
      googleAnalytics.onSearchPageView(pathname + search);
    }
  }, [isSuccess, pathname, search]);

  return (
    <>
      {isFetching && <GlobalPreloader />}

      <div className="nav-bottom">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="nav-bottom__result-wrap nav-bottom__result-wrap_wide">
              <div className="title">
                <h3>{t('page_title.search_result_by_code')}</h3>
              </div>
              <span className="nav-bottom__result">{searchValue}</span>
            </div>
          </div>

          {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>
      </div>

      <div className="wrapper">
        {brands && (
          <section className="brands">
            {brands.list.length === 0 ? (
              <div className="no-results">
                <p>{t('label.no_search_results')}</p>
              </div>
            ) : (
              <>
                <div className="title">
                  <h3>{t('page_title.search_brands')}</h3>
                </div>
                <div className="brands-list">
                  {brands?.list.map((brand, index) => {
                    const brandSearch = brand?.search;
                    const description = brand?.description;
                    const tradeNumberQuery = description?.tradeNumber ? `&tradeNumber=${description?.tradeNumber}` : '';
                    const logo = brand.logo || `${process.env.REACT_APP_API_URL}/assets/brands/${brand.value}.png`;
                    const link = brandSearch
                      ? `/search/result/code?search=${brandSearch}&manufacturer_id=${brand.value}&brand=${brand.title}${tradeNumberQuery}`
                      : `/search/result/code?search=${searchValue}&manufacturer_id=${brand.value}&brand=${brand.title}${tradeNumberQuery}`;

                    return (
                      <Link key={brand.title + index} className="brand-link" to={link}>
                        <div className="brand-link__top-wrap">
                          <div className="brand-link__img-wrap">
                            <img src={logo} alt="Brand" />
                          </div>

                          <p className="brand-link__name">{brand.title}</p>
                          <div className="list">
                            {description?.articleNumber && (
                              <div className="item">
                                <span className="item__name">{t('label.article_number')}:</span>
                                <span className={classNames('item__value', { item__value_colRed: !description.tradeNumber })}>
                                  {description.articleNumber}
                                </span>
                              </div>
                            )}
                            {description?.tradeNumber && (
                              <div className="item">
                                <span className="item__name">{t('label.trade_number')}:</span>
                                <span className="item__value item__value_colRed">{description.tradeNumber}</span>
                              </div>
                            )}
                          </div>
                        </div>

                        {description?.generic && (
                          <div className="brand-link__text-block">
                            <p className="name-product">{description.generic}</p>
                          </div>
                        )}
                      </Link>
                    );
                  })}
                </div>
              </>
            )}
          </section>
        )}
      </div>

      {!isTecdocOnline && <TecdocStatus offline />}
    </>
  );
}

export default compose(withPageLayout(), withAuth())(SearchBrands);
