import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import withAuth from 'HOC/withAuth/withAuth';

const NotFound = memo(() => {
  const { t } = useTranslation();

  return (
    <div className="wrapper">
      <section className="page-error">
        <div className="wrap">
          <div className="title page-error__title">
            <h1>404</h1>
          </div>

          <p className="page-error__text">{t('not_found.page_not_found')}</p>

          <div className="page-error__btn-wrap">
            <Link to="/" className="btn btn_bg-col-astronaut">
              {t('action.to_main')}
            </Link>
          </div>

          <div className="page-error__feedback">
            <p>
              {t('not_found.if_its_error')} <br /> {t('label.to_email')}:
            </p>
            <a href="mailto:info@al1.ua">info@al1.ua</a>
          </div>
        </div>
      </section>
    </div>
  );
});

export default compose(withPageLayout(), withAuth())(NotFound);
