import { useState } from 'react';

const useFocusedTabState = () => {
  const [tabIsFocused, setTabIsFocused] = useState(true);

  document.addEventListener('visibilitychange', () => {
    if (document.hidden) {
      setTabIsFocused(false);
    } else {
      setTabIsFocused(true);
    }
  });

  return { isFocused: tabIsFocused };
};

export default useFocusedTabState;
