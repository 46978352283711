import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import AliceCarousel from 'react-alice-carousel';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getFillValue } from 'utils/getAccumulativeSaleFillValue';
import { salesDealTypes } from 'config/constants/dealTypes';

const AccumulatedSales = memo(({ data }) => {
  const { t } = useTranslation();

  const hasData = data?.length > 0;
  const isButtonsDisabled = data?.length <= 1 ? true : false;

  return (
    <div className="list-recomm-goods__item promo-card promotions">
      {hasData ? (
        <AliceCarousel infinite={true} touchTracking={false} disableButtonsControls={isButtonsDisabled} autoPlay={false}>
          {data?.map((item, index) => {
            const isAccumulative = item.deal_type === salesDealTypes['accumulative'];
            const isAccumulativeWithReg = item.deal_type === salesDealTypes['accumulative_with_registration'];

            const fill = getFillValue(item);

            return (
              <div key={index} className="promo-card__body">
                <div className="promo-card__header">
                  <div className="promo-card__title">{t('label.accumulative_shares')}</div>
                </div>
                <div className="promo-card__content promo-card-content">
                  <div className="promo-card-content__image">
                    {(isAccumulative || (isAccumulativeWithReg && item?.deal?.registered)) && (
                      <svg viewBox="-2.5 -2.5 40 40" className="circular-chart orange">
                        <path className="circle-bg" d="M18 2.0845a 15.9155 15.9155 0 0 1 0 31.831a 15.9155 15.9155 0 0 1 0 -31.831" />
                        <path
                          className="circle"
                          strokeDasharray={`${fill},100`}
                          d="M18 2.0845a 15.9155 15.9155 0 0 1 0 31.831a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <text x="18" y="19.5" className="percentage">
                          {fill}%
                        </text>
                      </svg>
                    )}
                  </div>
                  <span className="promo-card-content__date">
                    {moment.utc(item?.starts_in).format('DD.MM.YYYY')}
                    {' - '}
                    {moment.utc(item?.expires_in).format('DD.MM.YYYY')}
                  </span>
                </div>
                <div className="promo-card__footer promo-card-footer">
                  <Link to={`/sales/${item?.seo_slug}`} className="promo-card-footer__desc">
                    {item?.title}
                  </Link>
                  <div className="promo-card-footer__btns">
                    <Link to={`/sales/${item?.seo_slug}`} className="promo-card-footer__btns--more">
                      {t('action.in_more_detail')}
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </AliceCarousel>
      ) : (
        <>
          <div className="accumulative-shares_plug title">
            <h3>{t('label.accumulative_shares')}</h3>
          </div>

          <div className="accumulative-shares_plug text">
            <h3>{t('label.no_accumulative_shares')}</h3>
          </div>
        </>
      )}
    </div>
  );
});

export default AccumulatedSales;
