import React, { memo, useRef, useCallback } from 'react';
import { debounce } from 'throttle-debounce';

const Counter = memo(({ count, onChange, onThrottleChange }) => {
  const throttled = useRef(debounce(1000, (callback) => callback()));

  const countNumber = useRef(count);

  const handleChange = useCallback(
    (value) => {
      countNumber.current = Number(value >= 0 && value <= 9999 ? value : countNumber.current);

      if (onThrottleChange) throttled.current(onThrottleChange.bind(null, countNumber.current));

      if (onChange) onChange(countNumber.current);
    },
    [onChange, throttled, onThrottleChange, countNumber]
  );

  return (
    <div className="column quantity-goods">
      <button type="button" className="btn-minus" onClick={() => handleChange(countNumber.current - 1)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="2" viewBox="0 0 10 2">
          <path fill="#0a2352" d="M0 0h10v2H0z" />
        </svg>
      </button>

      <input type="text" pattern="[0-9]{1,4}" value={countNumber.current} onChange={(event) => handleChange(event.target.value)} />

      <button type="button" className="btn-plus" onClick={() => handleChange(countNumber.current + 1)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
          <path fill="#0a2352" d="M10 4v2H6v4H4V6H0V4h4V0h2v4z" />
        </svg>
      </button>
    </div>
  );
});

export default Counter;
