import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { validateYearsOfProductionTecdoc } from 'utils/validateYearsOfProductionTecdoc';
import { useScrollTo } from 'hooks/useScrollTo';

const Modifications = memo(({ modification, modifications, setVehicle }) => {
  const { t } = useTranslation();

  useScrollTo(modification);

  return (
    <div className="table">
      <div className="table__header header">
        <h3 className="header__item modification-title">{t('label.vehicle')}</h3>
        <h3 className="header__item type-title">{t('label.engine_type_short')}</h3>
        <h3 className="header__item model-title">{t('label.engine_model_short')}</h3>
        <h3 className="header__item engine-volume-title">{t('label.engine_capacity_short')}</h3>
        <h3 className="header__item power-title">{t('label.engine_power')}</h3>
        <h3 className="header__item body-title">{t('label.body_style')}</h3>
        <h3 className="header__item release-title">{t('label.release_date')}</h3>
      </div>
      <div className="table__list list">
        {modifications.map((mod) => {
          const modificationLabel = `${mod.name || ''} ${mod.bodyStyle || ''}`;

          const capacityLiters = mod?.capacityLiters ? `${mod.capacityLiters} +  l` : '';
          const capacityCC = mod?.capacityCC ? `${mod.capacityCC} ccm` : '';
          const capacityLabel = `${capacityLiters} ${capacityLiters && capacityCC ? '/' : ''} ${capacityCC}`;

          const powerLabel = `${mod.kw} kW/${mod.hp} PS`;
          const dateLabel = validateYearsOfProductionTecdoc(mod.from, mod.to);
          const engines = mod.engines.map((engine) => engine.code).join(', ');
          const shortInfo = `${mod.name} | ${powerLabel} | ${dateLabel}`;

          return (
            <button key={mod.id} type="button" className="list__link link" onClick={() => setVehicle(mod)}>
              <p className="link__item modification">{modificationLabel}</p>
              <p className="link__item type">{mod.engineType}</p>
              <div className="link__item model">
                <p>{engines}</p>
              </div>
              <p className="link__item engine-volume">{capacityLabel}</p>
              <p className="link__item power">{powerLabel}</p>
              <p className="link__item body">{mod.bodyStyle}</p>
              <p className="link__item release">{dateLabel}</p>

              <p className="link__item general-info">{shortInfo}</p>
            </button>
          );
        })}
      </div>
    </div>
  );
});

export default Modifications;
