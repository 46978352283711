import React, { memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Icon, { ICON_NAMES } from 'components/Icon/index';
import { validateYearsOfProductionTecdoc } from 'utils/validateYearsOfProductionTecdoc';
import useBodyScrollLock from 'hooks/useBodyScrollLock';

const Modal = memo(({ data, onClose }) => {
  const { t } = useTranslation();

  const title = data.data.list.length > 0 ? data.data.list[0].manuDesc : t('label.auto');

  const overflowRef = useRef();
  useBodyScrollLock(overflowRef, true);

  return (
    <div className="components-cars">
      <div className="container">
        <div className="components-cars__wrap">
          <div className="components-cars__title title">
            <h3>{title}</h3>
            <button type="button" className="btn-close" onClick={onClose}>
              <Icon name={ICON_NAMES.close} fill="#0A2352" />
            </button>
          </div>
          <div className="components-list">
            <div className="components-list__wrap" ref={overflowRef}>
              {data.data.list.map((item) => {
                const label = `${item.carDesc} (${item.kw} ${t('label.kv')} / ${item.hp} ${t('label.hp')})`;
                return (
                  <div className="cnt-row" key={item.id}>
                    <div className="column model">
                      <p>
                        {item.name} {item.constructionType}
                      </p>
                    </div>
                    <div className="column modification">
                      <div>{label}</div>
                    </div>
                    <div className="column release">
                      <p>{validateYearsOfProductionTecdoc(item.from, item.to)}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Modal;
