import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useApi } from 'services/api';
import { tecdocKeys } from 'services/queryClient/queryKeys';
import logger from 'services/logger';

export const useGetManufacturers = () => {
  const { tecdoc } = useApi();

  return useQuery(tecdocKeys.manufacturers(), () => tecdoc.getManufacturers(), {
    select: (res) => res.data,
    onError: (error) => logger(error),
    refetchOnWindowFocus: false,
  });
};

export const useGetModels = (id) => {
  const { tecdoc } = useApi();

  return useQuery(tecdocKeys.models(id), () => tecdoc.getModels(id), {
    select: (res) => res.data,
    onError: (error) => logger(error),
    enabled: false,
  });
};

export const useGetVehicle = (manufacturerId, modelId) => {
  const { tecdoc } = useApi();

  return useQuery(tecdocKeys.vehicles({ manufacturerId, modelId }), () => tecdoc.getVehicle(manufacturerId, modelId), {
    select: (res) => res.data,
    onError: (error) => logger(error),
    enabled: false,
  });
};

export const useGetNodes = (vehicleId, targetType) => {
  const { tecdoc } = useApi();

  return useQuery(tecdocKeys.nodes(vehicleId, targetType), () => tecdoc.getNodes(vehicleId, targetType), {
    select: (res) => res.data,
    onError: (error) => logger(error),
    enabled: false,
    keepPreviousData: false,
  });
};

export const useGetProductsByNode = (nodeId, carId) => {
  const { tecdoc } = useApi();

  return useQuery(tecdocKeys.productsCatalogue(nodeId, carId), () => tecdoc.getProductsByNode(nodeId, carId), {
    select: (res) => res.data,
    onError: (error) => logger(error),
    refetchOnWindowFocus: false,
    enabled: false,
  });
};

export const useGetCarSearchHistory = () => {
  const { tecdoc } = useApi();

  return useQuery(tecdocKeys.carSearchHistory(), () => tecdoc.getCarSearchHistory(), {
    select: (res) => res.data.storage,
    onError: (error) => logger(error),
    refetchOnWindowFocus: false,
  });
};

export const useAddCarToHistory = () => {
  const { tecdoc } = useApi();
  const queryClient = useQueryClient();

  return useMutation(tecdoc.addCarToHistory, {
    retry: false,
    onError: (error) => logger(error),
    onSettled: () => queryClient.invalidateQueries(tecdocKeys.carSearchHistory()),
  });
};

export const useRemoveCarFromHistory = () => {
  const { tecdoc } = useApi();
  const queryClient = useQueryClient();

  return useMutation(tecdoc.removeCarFromHistory, {
    retry: false,
    onError: (error) => logger(error),
    onSettled: () => queryClient.invalidateQueries(tecdocKeys.carSearchHistory()),
  });
};

export const useGetCarSearchFavorites = () => {
  const { tecdoc } = useApi();

  return useQuery(tecdocKeys.carSearchFavorites(), () => tecdoc.getCarSearchFavorites(), {
    select: (res) => res.data.storage,
    onError: (error) => logger(error),
  });
};

export const useAddToFavorites = () => {
  const { tecdoc } = useApi();
  const queryClient = useQueryClient();

  return useMutation(tecdoc.addToFavorites, {
    retry: false,
    onMutate: async (data) => {
      const queryKey = tecdocKeys.carSearchFavorites();
      await queryClient.cancelQueries(queryKey);
      const prevData = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (old) => {
        const newData = { ...old };
        newData.data.storage.data.push({ id: data.id });
        return newData;
      });
      return { prevData, queryKey };
    },
    onError: (error, newData, context) => {
      logger(error);
      queryClient.invalidateQueries(context.queryKey);
    },
  });
};

export const useRemoveFromFavorites = () => {
  const { tecdoc } = useApi();
  const queryClient = useQueryClient();

  return useMutation(tecdoc.removeFromFavorites, {
    retry: false,
    onMutate: async (data) => {
      const queryKey = tecdocKeys.carSearchFavorites();
      await queryClient.cancelQueries(queryKey);
      const prevData = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (old) => {
        const newData = { ...old };
        newData.data.storage.data = newData.data.storage.data.filter((item) => item.id !== data.id);
        return newData;
      });
      return { prevData, queryKey };
    },
    onError: (error, newData, context) => {
      logger(error);
      queryClient.invalidateQueries(context.queryKey);
    },
  });
};

export const useGetLinkages = () => {
  const { tecdoc } = useApi();

  return useMutation(tecdoc.getLinkages, {
    retry: false,
    onError: (error) => logger(error),
  });
};

export const useGetAutoAbout = (vehicleId, targetType) => {
  const { tecdoc } = useApi();

  return useQuery(tecdocKeys.autoAbout(vehicleId, targetType), () => tecdoc.getAutoAbout(vehicleId, targetType), {
    select: (res) => res.data.detail,
    onError: (error) => logger(error),
    enabled: false,
  });
};
