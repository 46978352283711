import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import VendorCode from 'components/VendorCode/VendorCode';
import { imgUrlToResizeUrl } from 'utils/imgUrlToResizeUrl';

const HintManager = memo(({ lastname, firstname, phone, email, photo }) => {
  const { t } = useTranslation();
  const link = `mailto:${email}`;

  return (
    <div className="hidden-inf">
      <div className="hidden-inf__contacts">
        <p className="hidden-inf__whose-contact">{t('label.your_sales_manager')}</p>

        <a href="/" className="manager__img-wrap">
          <img src={imgUrlToResizeUrl(photo, 150, 150, 'resize')} width="150" alt="" />
        </a>

        <div className="hidden-inf__full-name">
          <p className="first-name">{`${lastname} ${firstname}`}</p>
        </div>
        <p className="hidden-inf__phone">{phone}</p>

        <VendorCode article={email} mailtoLink={link} />

        <a href={link} className="btn">
          {t('action.write_to_your_sales_manager')}
        </a>
      </div>
    </div>
  );
});

export default HintManager;
