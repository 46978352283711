import React, { Fragment, memo, useMemo } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import SelectFieldAddress from 'pages/Basket/SelectFieldAddress';

const UklonDelivery = memo((props) => {
  const { addresses } = props;

  const { t } = useTranslation();

  const transformedAddresses = useMemo(() => {
    const normalizeValue = (value) => (value ? value.trim() : '');

    return addresses?.map((item) => ({
      ...item,
      label: [normalizeValue(item?.city_name), normalizeValue(item?.full_street_name), normalizeValue(item?.build)]
        .filter((f) => f)
        .join(', '),
      value: {
        n: item.n,
        address: `${normalizeValue(item?.city_name)} ${normalizeValue(item?.full_street_name)} ${normalizeValue(item?.build)}`,
      },
    }));
  }, [addresses]);

  return (
    <Fragment>
      <Field
        name="routeListAddress"
        component={SelectFieldAddress}
        options={transformedAddresses}
        validate={(value) => (value ? null : t('errors.required_field'))}
        header={t('basket.delivery_list.address')}
        defaultLabel={t('basket.delivery_list.choose_address')}
      />

      <Field name="note">
        {({ input, meta }) => (
          <div className="field-wrap textarea">
            <p>{t('basket.delivery_list.note')}</p>
            <textarea {...input} placeholder={t('basket.label.default_placeholder')} />
          </div>
        )}
      </Field>
    </Fragment>
  );
});

export default UklonDelivery;
