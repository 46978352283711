import React, { memo, useRef, useMemo, useCallback, useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import Countdown from 'react-countdown';
import moment from 'moment';

import { useCatalogueTypes } from 'hooks/api/useProducts';
import { useGetProfile } from 'hooks/api/useProfile';
import { useGetBasketData } from 'hooks/api/useBasket';
import { useMakeOrder } from 'hooks/api/useOrder';
import { useScrollTo } from 'hooks/useScrollTo';
import useWindowDimensions from 'hooks/useWindowDimensions';
import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import withAuth from 'HOC/withAuth/withAuth';
import { carrierIdToCarrierLable, DELIVERY_METHODS } from 'utils/deliveryDataUtils';
import { paymentOptions } from 'config/constants/paymentOptions';
import googleAnalytics from 'services/googleAnalytics';

import StorageSelect from './Steps/StorageSelect';
import ProductSelect from './Steps/ProductSelect';
import DeliverySelect from './Steps/DeliverySelect';
import ContactPerson from './Steps/ContactPerson';
import OrderApproved from './Steps/OrderApproved';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import { GlobalPreloader } from 'components/Preloader/Preloader';

const Basket = memo(() => {
  const { data: catalogueTypes } = useCatalogueTypes();
  const { data: user } = useGetProfile();

  const defaultWarehouse = user?.settings?.default_warehouse;
  const warehouses = user?.warehouses;

  const windowDimension = useWindowDimensions();
  const { t } = useTranslation();

  const form = useRef();

  const savedUserName = localStorage.getItem('userName');
  const savedUserPhone = localStorage.getItem('userPhone');

  const onScrollTo = useScrollTo(null);

  const { data, isFetching, refetch: refetchBasket, isSuccess: isLoadedDataSuccess } = useGetBasketData({ page: 1, per_page: 1000 });

  const { mutate: makeOrder, isSuccess, isLoading: isMakingOrder, data: order } = useMakeOrder();

  const filtredStoragesList = warehouses?.filter((item) => item.value !== 'income');

  const [isApproved, setIsApproved] = useState(false);
  const goBackToBasket = useCallback(() => {
    refetchBasket();
    setIsApproved(false);
  }, [refetchBasket]);

  useEffect(() => {
    if (isSuccess) {
      setIsApproved(true);
      onScrollTo();
      googleAnalytics.onOrderProcessing();
      googleAnalytics.onOrderPurchase(order.data.order, order.data.products);
    }
  }, [isSuccess, order]);

  const initialValues = useMemo(
    () => ({
      storage: '',
      products: [],
      userName: '',
      userPhone: '',
      payment: null,
      note: '',
      deliveryDay: null,
      deliveryTime: null,
      deliveryMethod: null,
      dispatchOptions: [],
      city: '',
      department: '',
      routeListAddress: null,
      carrier_id: null,
    }),
    []
  );

  const onSubmit = useCallback(
    (event) => {
      const hasNotValidCountProduct = Boolean(event.products.find((item) => !item.isCountValid && item.selected));

      const orderProducts = event.products
        ?.filter((product) => product.selected && product.count > 0)
        ?.map((item) => ({
          item_id: item.item_id,
          count: item.count,
          totalPrice: Number((item.count * item.price)?.toFixed(2)),
        }));

      const isNewPost = DELIVERY_METHODS.new_post.includes(event.deliveryMethod);
      const isRouteList = Boolean(event.routeListDispatchOptions.find((item) => item?.carrier_id === event.deliveryMethod));
      const isUklonDelivery = DELIVERY_METHODS.uklon.includes(event.deliveryMethod);

      const order = {
        carrier_id: event.carrier_id ? event.carrier_id : event.deliveryMethod,
        carrier_name: carrierIdToCarrierLable(event.deliveryMethod),
        order_description: event.note,
        city: event.city,
        department: event.department,
        phone: event.userPhone,
        contact: event.userName,
        payment_type: event.payment,
        payment_name: paymentOptions().find((i) => i.value === event.payment).label,
        warehouse: event.storage,
        items: orderProducts,
        delivery_date:
          event.deliveryDay && event.deliveryTime
            ? moment(event.deliveryDay.substring(0, 10)).add(event.deliveryTime, 'h').format()
            : event.deliveryDay && !event.deliveryTime
            ? moment(event.deliveryDay.substring(0, 10)).format()
            : null,
        delivery_time: event.deliveryDay && event.deliveryTime ? event.deliveryTime : null,
        adresdelivery_n: event?.routeListAddress?.n,
        address: event?.routeListAddress?.address,
      };

      if (!isRouteList && !isUklonDelivery) {
        delete order.delivery_date;
        delete order.delivery_time;
        delete order.adresdelivery_n;
        delete order.address;
      }
      if (!isNewPost) {
        delete order.city;
        delete order.department;
      }

      if (orderProducts.length === 0 || event.isBlocked || hasNotValidCountProduct) {
        return;
      } else {
        makeOrder(order);
        localStorage.setItem('userName', event.userName);
        localStorage.setItem('userPhone', event.userPhone);
      }
    },
    [makeOrder]
  );

  useEffect(() => {
    if (isLoadedDataSuccess) {
      googleAnalytics.onBasketEnter(data.list);
    }
  }, [isLoadedDataSuccess]);

  return (
    <>
      {isFetching && <GlobalPreloader />}

      <div className="nav-bottom">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="title">
              <h3>{t('page_title.basket')}</h3>
            </div>
          </div>

          {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>
      </div>

      {isApproved ? (
        <OrderApproved order={order} defaultWarehouse={defaultWarehouse} onToBasket={goBackToBasket} />
      ) : (
        <div className="wrapper">
          <section className="basket-wrap">
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={({ handleSubmit, values, form: ref }) => {
                if (!form.current) form.current = ref;

                const hasNotValidCountProduct = Boolean(values.products.find((item) => !item.isCountValid && item.selected));
                const hasSelectedProducts = Boolean(values.products.find((item) => item?.selected === true));
                const isDisabled =
                  !values.storage || !values.deliveryMethod || !hasSelectedProducts || hasNotValidCountProduct || isMakingOrder
                    ? true
                    : false;

                const currentDispatchOption = values.dispatchOptions?.find((item) => item.carrier_id === values.deliveryMethod);
                const isClosestSendingShown = Boolean(
                  values.deliveryMethod &&
                    values.storage !== 'expr' &&
                    currentDispatchOption &&
                    !DELIVERY_METHODS.other.includes(values.deliveryMethod) &&
                    (Boolean(currentDispatchOption.day) || Boolean(currentDispatchOption.tm))
                );

                return (
                  <>
                    {data?.list?.length > 0 && (
                      <form onSubmit={handleSubmit}>
                        {filtredStoragesList && <StorageSelect storagesList={filtredStoragesList} />}

                        <ProductSelect defaultWarehouse={defaultWarehouse} warehouses={warehouses} data={data} />

                        {values.storage ? (
                          <>
                            <DeliverySelect />

                            {!values.isBlocked && (
                              <>
                                <ContactPerson user={user} savedName={savedUserName} savedPhone={savedUserPhone} />

                                <div className="submit-wrap">
                                  {isClosestSendingShown && (
                                    <p className="next-shipment">
                                      {t('basket.label.closest_sending')}{' '}
                                      <span className="next-shipment__time">
                                        <Countdown
                                          daysInHours={true}
                                          date={moment(
                                            `${moment(currentDispatchOption.day).format('YYYY-MM-DD')} ${currentDispatchOption.tm}`
                                          ).format('YYYY-MM-DDTHH:mm:ss')}
                                        />
                                      </span>
                                    </p>
                                  )}

                                  <div className={`submit-wrap__btn-wrap ${isDisabled ? 'disabled' : ''}`}>
                                    <button type="submit" disabled={isDisabled} className="btn btn_bg-col-astronaut">
                                      {t('action.submit')}
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {!values.isBlocked && (
                              <div className="select-warehouse_notification">{t('basket.label.choose_warehouse_notification')}</div>
                            )}
                          </>
                        )}
                      </form>
                    )}

                    {(!data?.list || data?.list.length === 0) && (
                      <div className="no-page-content">{t('basket.label.your_basket_is_empty')}</div>
                    )}
                  </>
                );
              }}
            />
          </section>
        </div>
      )}
    </>
  );
});

export default compose(withPageLayout(), withAuth())(Basket);
