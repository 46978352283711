import React from 'react';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import Select from 'components/Select/Select';
import Pagination from './Pagination';
import history from 'services/history';
import useWindowDimensions from 'hooks/useWindowDimensions';

const PagePagination = (props) => {
  const { pageCount, page, itemsTotal, itemsOnPage, setItemsPerPage, itemsPerPage, paginationOptions } = props;

  const windowDimension = useWindowDimensions();

  const { t } = useTranslation();

  const handlePageChange = (page) => {
    const query = {
      ...queryString.parse(history.location.search, {
        arrayFormat: 'separator',
        arrayFormatSeparator: '|',
      }),
      page: page.selected + 1,
    };
    history.pushSearch(query, history.location.pathname);
  };

  const handlePageChangeSm = (page) => {
    const query = {
      ...queryString.parse(history.location.search, {
        arrayFormat: 'separator',
        arrayFormatSeparator: '|',
      }),
      page: page,
    };
    history.pushSearch(query, history.location.pathname);
  };

  const handlePerPageChange = (option) => {
    setItemsPerPage(option);

    const query = {
      ...queryString.parse(history.location.search, {
        arrayFormat: 'separator',
        arrayFormatSeparator: '|',
      }),
      page: 1,
    };
    history.pushSearch(query, history.location.pathname);
  };

  const shownProductsLabel = () => {
    const perPageFrom = 1 + itemsPerPage.value * (props.page - 1);
    const perPageTo = props.page === pageCount ? itemsPerPage.value * (props.page - 1) + itemsOnPage : itemsPerPage.value * props.page;
    return t('pagination.items_on_page', {
      perPageFrom,
      perPageTo,
      itemsTotal,
    });
  };

  return (
    <div className="pagination-wrap">
      {windowDimension.width > 1080 && (
        <div className="pagination-wrap__left">
          <p>{shownProductsLabel()}</p>
        </div>
      )}

      {windowDimension.width <= 760 ? (
        <Pagination sm pageCount={pageCount} page={page} onChange={handlePageChangeSm} />
      ) : (
        <Pagination lg pageCount={pageCount} page={page} onChange={handlePageChange} />
      )}

      {windowDimension.width > 1080 && (
        <div className="pagination-wrap__right">
          <Select
            options={paginationOptions}
            value={paginationOptions.find((i) => i.value === itemsPerPage?.value)}
            onSelect={(data) => handlePerPageChange(data)}
            indicatorsContainer={{ width: 35 }}
            containerStyles={{ minWidth: 160 }}
          />
        </div>
      )}
    </div>
  );
};

export default PagePagination;
