import { useEffect } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const useBodyScrollLock = (ref, locked) => {
  useEffect(() => {
    if (locked && ref && ref.current) disableBodyScroll(ref.current);
    if (!locked && ref && ref.current) enableBodyScroll(ref.current);
    return () => clearAllBodyScrollLocks();
  }, [locked, ref]);
};

export default useBodyScrollLock;
