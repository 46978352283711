import { useQuery } from 'react-query';
import { useApi } from 'services/api';
import logger from 'services/logger';
import { loyaltyProgramsKeys } from 'services/queryClient/queryKeys';

export const useGetLoyaltyPrograms = (params) => {
  const { loyalty } = useApi();

  return useQuery(loyaltyProgramsKeys.loyaltyPrograms(params), () => loyalty.getLoyaltyPrograms(params), {
    select: (res) => res?.data.articles,
    onError: (error) => logger(error),
    keepPreviousData: true,
  });
};

export const useGetUnreadLoyaltyCount = () => {
  const { loyalty } = useApi();

  return useQuery(loyaltyProgramsKeys.unreadLoyalty(), loyalty.getUnreadLoyalty, {
    select: (res) => res?.data?.count,
    onError: (error) => logger(error),
  });
};
