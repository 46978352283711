function isInt(n1, n2) {
  return n1 % n2 === 0;
}

export function validateProductCountByComplNumber(productCount, complNumber, storageCount) {
  if (!storageCount) return true;

  // const validBiggestComplectNumberInStorage = Math.trunc(storageCount / complNumber) * complNumber;

  // if (productCount > validBiggestComplectNumberInStorage) {
  //   return true;
  // }

  // return isInt(productCount, complNumber) && productCount / complNumber > 0;

  // console.log({complNumber, storageCount})

  if (isInt(productCount, complNumber)) {
    return true;
  }

  return storageCount % complNumber != 0;
}

// залишки на обраному складі % кратність != 0, то є можливість замовити
