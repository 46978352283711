import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/uk';
import classNames from 'classnames';
import { imgUrlToResizeUrl } from 'utils/imgUrlToResizeUrl';
import Icon, { ICON_NAMES } from 'components/Icon/index';
import { salesDealTypes } from 'config/constants/dealTypes';

function SalesPreview(props) {
  const { image, title, starts_in, isActive, fillValue, expires_in, deal_type, short_description, seo_slug, views, isRead, isExpired } =
    props;
  const startDate = moment(starts_in).format('DD.MM.YYYY');
  const endDate = moment(expires_in).format('DD.MM.YYYY');

  const isShowProgressBar =
    (deal_type === salesDealTypes['accumulative_with_registration'] && !isExpired && isActive) ||
    deal_type === salesDealTypes['accumulative'];

  const { t } = useTranslation();

  return (
    <NavLink to={`/sales/${seo_slug}`} exact className={classNames('sales__item item', { expired: isExpired })}>
      {isExpired && <div className="discount">{t('label.the_promotion_has_ended')}</div>}
      <div className="item__wrap">
        <div className="item__img-wrap">
          <img src={imgUrlToResizeUrl(image, 440, 200, 'resize')} width="440" height="200" alt="" />
        </div>
        <div className="item__cnt">
          {!isRead && <span className="item__status" />}

          <div className="item__title title">
            <h3>{title}</h3>
          </div>
          <div className="publication">
            <time className="publication__date">
              {startDate} - {endDate}
            </time>
            <div className="publication__views-wrap">
              <strong className="publication__views">{views}</strong>
              <Icon name={ICON_NAMES.watched} />
            </div>
          </div>
          <p className="item__desc">{short_description}</p>
        </div>
      </div>
      <div className="sales__item-bottom">
        <div>
          {isShowProgressBar && (
            <div className="progress-bar" style={{ '--progress': `${fillValue}%` }}>
              <span className="bar"></span>
              {fillValue}%
            </div>
          )}
        </div>

        <span className="link link_col-blue">{t('label.article_preview_details')}</span>
      </div>
    </NavLink>
  );
}

export default SalesPreview;
