import React from 'react';
import { Redirect } from 'react-router-dom';
import { useToken } from 'hooks/api/useAuth';

const withNoAuth = () => (Component) => {
  return (props) => {
    const { data: token } = useToken();

    if (token) return <Redirect to={`/`} />;

    return <Component {...props} />;
  };
};

export default withNoAuth;
