import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import SortArrows from 'components/SortArrows/SortArrows';

const ReportHeader = memo((props) => {
  const { onSort, sortOptions } = props;

  const { t } = useTranslation();

  return (
    <div className="titles-row">
      <div className="col-title date-title">
        <div className="label-wrap" onClick={() => onSort(null, sortOptions.data)}>
          <span className="titles-row__title">{t('label.date')}</span>
          <div className="btn-square">
            <SortArrows name={sortOptions.data} />
          </div>
        </div>
      </div>

      <div className="col-title document-title">{t('label.document')}</div>

      <div className="col-title number-title">
        <div className="label-wrap" onClick={() => onSort(null, sortOptions.nomber)}>
          <span className="titles-row__title">{t('label.number')}</span>
          <div className="btn-square">
            <SortArrows name={sortOptions.nomber} />
          </div>
        </div>
      </div>

      {/* <div className="col-title f-title">{t('label.f')}</div> */}

      <div className="col-title expense-title">{t('label.expense')}</div>

      <div className="col-title income-title">
        <div className="label-wrap" onClick={() => onSort(null, sortOptions.kred)}>
          <span className="titles-row__title">{t('label.income')}</span>
          <div className="btn-square">
            <SortArrows name={sortOptions.kred} />
          </div>
        </div>
      </div>

      <div className="col-title payment-term-title">{t('label.payment_term')}</div>

      {/* <div className="col-title balance-title">{t('label.saldo')}</div> */}

      <div className="col-title department-title">{t('label.department')}</div>
    </div>
  );
});

export default ReportHeader;
