import React, { useRef, useState, memo, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import ImageSlider from 'components/ImageSlider/ImageSlider';
import ProductName from 'components/ProductName/ProductName';
import ToBasket from 'components/ToBasket/ToBasket';
import Analogues from 'components/Analogues/Analogues';
import VendorCode from 'components/VendorCode/VendorCode';
import ProductImage from 'components/ProductImage/ProductImage';
import BrandImage from 'components/BrandImage/BrandImage';
import ProductInfo from 'components/ProductInfo/ProductInfo';
import ProductInfoTecDoc from 'components/ProductInfo/ProductInfoTecDoc';
import Price from 'components/Price/Price';
import Storages from 'components/Storages/Storages';
import FavoriteButton from 'components/FavoriteButton/FavoriteButton';
import ToAnaloguesButton from 'components/ToAnaloguesButton/ToAnaloguesButton';

import { useAddToFavorites, useDeleteFromFavorites, useGetProductPrices } from 'hooks/api/useProducts';
import { useAddToBasket, useDeleteBasketCount } from 'hooks/api/useBasket';
import { useAddToWishlist, useRemoveFromWishlist } from 'hooks/api/useWishlist';
import useOutsideClick from 'hooks/useOutsideClick';
import useDebounceOpenMenu from 'hooks/useDebounceOpenMenu';
import useToastNotification from 'hooks/useToastNotification';
import useWindowDimensions from 'hooks/useWindowDimensions';

import { cutText, normalizeStringWithSpace, normalizeSearchString } from 'utils/normalizeString';
import { productTypes } from 'config/constants/productTypes';
import { pageTypes } from 'config/constants/pageTypes';

const ProductItem = memo((props) => {
  const {
    queryKey,
    images,
    isMultiImages,
    logo,
    name,
    productViewType,
    article,
    manufacturer,
    mfrId,
    displayPrice,
    description,
    isFavorite,
    isInWishlist,
    options,
    productType,
    pageType,
    autoliderID,
    shoppingCount,
    warehouses,
    deal,
    isTecDoc,
    tecdocActive,
    loyalty,
  } = props;

  const catalogueProductType = productType === productTypes['catalogue'];
  const commonProductType = productType === productTypes['common'];
  const isFavoritesPage = pageType === pageTypes['favorites'];

  const { width } = useWindowDimensions();
  const { isOpen, handleOpen, handleClose, mouseEnter, mouseLeave } = useDebounceOpenMenu();
  const togglePriceMenu = () => {
    if (isOpen && width < 1024) handleClose();
    if (!isOpen && width < 1024) handleOpen();
  };

  const { notify, notifyStatus } = useToastNotification();
  const { t } = useTranslation();

  const { mutate: addToBasket, isLoading: isAddingBasket } = useAddToBasket(queryKey);
  const { mutate: deleteBasketCount, isLoading: isDeletingBasket } = useDeleteBasketCount(queryKey);
  const { mutate: addToWishlist } = useAddToWishlist(queryKey);
  const { mutate: removeFromWishlist } = useRemoveFromWishlist(queryKey);

  const addToWishlistHandler = () => {
    if (isInWishlist) {
      removeFromWishlist({ mfrId, article });
      notify(t('info.deleted_wishlist'), notifyStatus.success);
    } else {
      addToWishlist({ item_id: autoliderID, mfrId, article, name });
      notify(t('info.added_wishlist'), notifyStatus.success);
    }
  };

  const storageTotal = warehouses
    ?.filter((i) => i.value !== 'income')
    ?.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0);
  const incomeTotal = warehouses.find((i) => i.value === 'income').count;
  const totalCountIsZero = Number(storageTotal) === 0 && Number(incomeTotal) === 0;
  const hidePrice = (Number(storageTotal) === 0 && Number(incomeTotal) >= 0) || displayPrice === null || displayPrice === undefined;

  const [isInfoOpen, setIsInfoOpen] = useState(false);

  const { mutate: addToFavorites } = useAddToFavorites(queryKey);
  const { mutate: deleteFromFavorites } = useDeleteFromFavorites(queryKey, isFavoritesPage);
  const { mutate: getProductPrices, data: productPrices } = useGetProductPrices();

  const favoritesButtonClickHandler = useCallback(() => {
    if (isFavorite) {
      deleteFromFavorites({ item_id: autoliderID, mfrId, article });
      notify(t('info.deleted_favorites'), notifyStatus.success);
    } else {
      addToFavorites({ article, item_id: autoliderID, name, manufacturer, description, logo, images, mfrId });
      notify(t('info.added_favorites'), notifyStatus.success);
    }
  }, [
    article,
    autoliderID,
    name,
    manufacturer,
    images,
    mfrId,
    isFavorite,
    logo,
    description,
    notifyStatus,
    t,
    notify,
    addToFavorites,
    deleteFromFavorites,
  ]);

  const ref = useRef();
  useOutsideClick(ref, () => {
    if (isInfoOpen) setIsInfoOpen(false);
  });

  useEffect(() => {
    if (isOpen && autoliderID) {
      getProductPrices({ item_id: autoliderID });
    }
  }, [isOpen, autoliderID, getProductPrices]);

  switch (true) {
    case productViewType === 'card' && catalogueProductType: {
      return (
        <div onMouseLeave={() => setIsInfoOpen(false)} className={classNames('cat-grid-c-fil', { 'card-shadow': isInfoOpen })}>
          {totalCountIsZero && <div className="overlay overlay_white" />}

          <FavoriteButton isFavorite={isFavorite} onClick={favoritesButtonClickHandler} isColumn />
          {options && <Analogues options={options} />}
          <ProductImage
            id={autoliderID}
            images={images}
            isMultiImages={isMultiImages}
            width={200}
            height={200}
            deal={deal}
            loyalty={loyalty}
            disabledLink={!autoliderID}
          />
          <div className="cat-grid-c-fil__wrap">
            <BrandImage isColumn manufacturer={manufacturer} logo={logo} />
            <ProductName id={autoliderID} name={name} disabledLink={!autoliderID} />
          </div>
          <VendorCode
            id={autoliderID}
            link={`/product-details/${autoliderID}`}
            productViewType={productViewType}
            article={article}
            disabledLink={!autoliderID}
            isColumn
          />
          <div className="cat-grid-c-fil__wrap">
            <ProductInfo isColumn isInfoOpen={isInfoOpen} setIsInfoOpen={setIsInfoOpen} description={description} />
            <Storages options={warehouses} isColumn />
          </div>
          <div className="cat-grid-c-fil__wrap">
            <Price
              isColumn
              displayPrice={displayPrice}
              wholesalePrice={productPrices?.data?.data?.opt}
              retailPrice={productPrices?.data?.data?.priceRetail}
              isOpen={isOpen}
              hidePrice={hidePrice}
              handleOpen={handleOpen}
              handleClose={handleClose}
              togglePriceMenu={togglePriceMenu}
              mouseEnter={mouseEnter}
              mouseLeave={mouseLeave}
            />
            <ToBasket
              article={article}
              autoliderID={autoliderID}
              name={name}
              logo={logo}
              images={images}
              price={displayPrice}
              isInWishlist={isInWishlist}
              isColumn
              description={description}
              manufacturer={manufacturer}
              shoppingCount={shoppingCount}
              storageTotal={storageTotal}
              incomeTotal={incomeTotal}
              onAdd={addToBasket}
              onDelete={deleteBasketCount}
              onAddToWishlist={addToWishlistHandler}
              isAddingBasket={isAddingBasket}
              isDeletingBasket={isDeletingBasket}
            />
          </div>
          {isInfoOpen && (
            <div className="cat-grid-c-fil__wrap description-card card-shadow" ref={ref}>
              {cutText(description, 220)}
            </div>
          )}
        </div>
      );
    }
    case productViewType === 'list' && catalogueProductType: {
      return (
        <div className="cat-grid-l-fil" onMouseLeave={() => setIsInfoOpen(false)}>
          {totalCountIsZero && <div className="overlay overlay_white" />}

          <ProductImage
            id={autoliderID}
            images={images}
            isMultiImages={isMultiImages}
            width={60}
            height={60}
            deal={deal}
            loyalty={loyalty}
            isList={true}
            isTecDoc={isTecDoc}
            disabledLink={!autoliderID}
          />
          <VendorCode id={autoliderID} link={`/product-details/${autoliderID}`} article={article} disabledLink={!autoliderID} isColumn />
          <BrandImage isColumn manufacturer={manufacturer} hasLabel logo={logo} />
          <ProductName id={autoliderID} name={name} disabledLink={!autoliderID} isColumn />
          <ProductInfo
            list
            isColumn
            isInfoOpen={isInfoOpen}
            setIsInfoOpen={setIsInfoOpen}
            description={description}
            name={name}
            options={options}
          />
          <Storages options={warehouses} isColumn />
          <Price
            isColumn
            displayPrice={displayPrice}
            wholesalePrice={productPrices?.data?.data?.opt}
            retailPrice={productPrices?.data?.data?.priceRetail}
            isOpen={isOpen}
            hidePrice={hidePrice}
            handleOpen={handleOpen}
            handleClose={handleClose}
            togglePriceMenu={togglePriceMenu}
            mouseEnter={mouseEnter}
            mouseLeave={mouseLeave}
          />
          <FavoriteButton isFavorite={isFavorite} onClick={favoritesButtonClickHandler} isColumn />
          <ToBasket
            article={article}
            autoliderID={autoliderID}
            logo={logo}
            images={images}
            name={name}
            isColumn
            price={displayPrice}
            isInWishlist={isInWishlist}
            description={description}
            manufacturer={manufacturer}
            shoppingCount={shoppingCount}
            storageTotal={storageTotal}
            incomeTotal={incomeTotal}
            onAdd={addToBasket}
            onDelete={deleteBasketCount}
            onAddToWishlist={addToWishlistHandler}
            isAddingBasket={isAddingBasket}
            isDeletingBasket={isDeletingBasket}
          />
        </div>
      );
    }
    case productType === productTypes['dashboard-bg']: {
      return (
        <div
          className={classNames('list-recomm-goods__item product-big-card', { 'card-shadow': isInfoOpen })}
          onMouseLeave={() => setIsInfoOpen(false)}
        >
          {isInfoOpen && (
            <div className="description-card card-shadow" ref={ref}>
              {cutText(description, 220)}
            </div>
          )}
          <ProductImage
            id={autoliderID}
            images={images}
            isMultiImages={isMultiImages}
            width={300}
            height={300}
            deal={deal}
            loyalty={loyalty}
            isProductDetails
          />
          <div className="right-side">
            <FavoriteButton isFavorite={isFavorite} onClick={favoritesButtonClickHandler} />
            <ProductName id={autoliderID} name={name} />
            <VendorCode
              id={autoliderID}
              link={`/product-details/${autoliderID}`}
              productViewType={productViewType}
              article={article}
              showLabel
            />
            <BrandImage hasLabel manufacturer={manufacturer} logo={logo} />
            <div className="product-big-card__wrap">
              <ProductInfo isInfoOpen={isInfoOpen} setIsInfoOpen={setIsInfoOpen} description={description} />
              <Storages options={warehouses} />
            </div>

            <div className="product-big-card__wrap">
              <Price
                displayPrice={displayPrice}
                wholesalePrice={productPrices?.data?.data?.opt}
                retailPrice={productPrices?.data?.data?.priceRetail}
                isOpen={isOpen}
                hidePrice={hidePrice}
                handleOpen={handleOpen}
                handleClose={handleClose}
                togglePriceMenu={togglePriceMenu}
                mouseEnter={mouseEnter}
                mouseLeave={mouseLeave}
              />
              <ToBasket
                article={article}
                autoliderID={autoliderID}
                logo={logo}
                images={images}
                name={name}
                price={displayPrice}
                isInWishlist={isInWishlist}
                description={description}
                manufacturer={manufacturer}
                shoppingCount={shoppingCount}
                storageTotal={storageTotal}
                incomeTotal={incomeTotal}
                onAdd={addToBasket}
                onDelete={deleteBasketCount}
                isAddingBasket={isAddingBasket}
                isDeletingBasket={isDeletingBasket}
              />
            </div>
          </div>
        </div>
      );
    }
    case productType === productTypes['dashboard-sm']: {
      return (
        <div
          className={classNames('list-recomm-goods__item product-smaller-card', { 'card-shadow': isInfoOpen })}
          onMouseLeave={() => setIsInfoOpen(false)}
        >
          {isInfoOpen && (
            <div className="description-card card-shadow" ref={ref}>
              {cutText(description, 220)}
            </div>
          )}
          <ProductImage
            id={autoliderID}
            images={images}
            isMultiImages={isMultiImages}
            width={200}
            height={200}
            deal={deal}
            loyalty={loyalty}
            isProductDetails
          />
          <FavoriteButton isFavorite={isFavorite} onClick={favoritesButtonClickHandler} />
          <div className="product-smaller-card__wrap">
            <BrandImage manufacturer={manufacturer} logo={logo} />
            <ProductName id={autoliderID} name={name} />
          </div>
          <VendorCode
            id={autoliderID}
            link={`/product-details/${autoliderID}`}
            productViewType={productViewType}
            article={article}
            showLabel
          />
          <div className="product-smaller-card__wrap">
            <ProductInfo isInfoOpen={isInfoOpen} setIsInfoOpen={setIsInfoOpen} description={description} />
            <Storages options={warehouses} />
          </div>

          <div className="product-smaller-card__wrap">
            <Price
              displayPrice={displayPrice}
              wholesalePrice={productPrices?.data?.data?.opt}
              retailPrice={productPrices?.data?.data?.priceRetail}
              isOpen={isOpen}
              hidePrice={hidePrice}
              handleOpen={handleOpen}
              handleClose={handleClose}
              togglePriceMenu={togglePriceMenu}
              mouseEnter={mouseEnter}
              mouseLeave={mouseLeave}
            />
            <ToBasket
              article={article}
              autoliderID={autoliderID}
              logo={logo}
              images={images}
              name={name}
              price={displayPrice}
              isInWishlist={isInWishlist}
              description={description}
              manufacturer={manufacturer}
              shoppingCount={shoppingCount}
              storageTotal={storageTotal}
              incomeTotal={incomeTotal}
              onAdd={addToBasket}
              onDelete={deleteBasketCount}
              isAddingBasket={isAddingBasket}
              isDeletingBasket={isDeletingBasket}
            />
          </div>
        </div>
      );
    }
    case productViewType === 'list' && commonProductType: {
      return (
        <div
          className={classNames('catalog-grid-l', {
            'card-shadow': isInfoOpen,
            active: tecdocActive,
          })}
        >
          {totalCountIsZero && <div className="overlay overlay_white" />}
          <ProductImage
            id={autoliderID}
            images={images}
            isMultiImages={isMultiImages}
            width={60}
            height={60}
            deal={deal}
            loyalty={loyalty}
            disabledLink={!autoliderID}
            isList
            isTecDoc={isTecDoc}
          />
          <VendorCode id={autoliderID} link={`/product-details/${autoliderID}`} article={article} disabledLink={!autoliderID} isColumn />
          <BrandImage isColumn hasLabel manufacturer={manufacturer} logo={logo} />
          <ProductName id={autoliderID} name={name} disabledLink={!autoliderID} />
          <ProductInfoTecDoc list isColumn isInfoOpen={isInfoOpen} setIsInfoOpen={setIsInfoOpen} description={description} />
          <Storages options={warehouses} isColumn />
          <Price
            isColumn
            displayPrice={displayPrice}
            hidePrice={hidePrice}
            wholesalePrice={productPrices?.data?.data?.opt}
            retailPrice={productPrices?.data?.data?.priceRetail}
            isOpen={isOpen}
            handleOpen={handleOpen}
            handleClose={handleClose}
            togglePriceMenu={togglePriceMenu}
            mouseEnter={mouseEnter}
            mouseLeave={mouseLeave}
          />
          <FavoriteButton isColumn isFavorite={isFavorite} onClick={favoritesButtonClickHandler} />
          <ToBasket
            article={article}
            autoliderID={autoliderID}
            logo={logo}
            images={images}
            name={name}
            isColumn
            price={displayPrice}
            isInWishlist={isInWishlist}
            description={description}
            manufacturer={manufacturer}
            shoppingCount={shoppingCount}
            storageTotal={storageTotal}
            incomeTotal={incomeTotal}
            onAdd={addToBasket}
            onDelete={deleteBasketCount}
            onAddToWishlist={addToWishlistHandler}
            isAddingBasket={isAddingBasket}
            isDeletingBasket={isDeletingBasket}
          />
        </div>
      );
    }
    case productViewType === 'card' && commonProductType: {
      return (
        <div
          onMouseLeave={() => setIsInfoOpen(false)}
          className={classNames('catalog-grid-c', {
            'card-shadow': isInfoOpen,
            active: tecdocActive,
          })}
        >
          {totalCountIsZero && <div className="overlay overlay_white" />}

          <FavoriteButton isColumn isFavorite={isFavorite} onClick={favoritesButtonClickHandler} />
          <ProductImage
            id={autoliderID}
            images={images}
            isMultiImages={isMultiImages}
            width={200}
            height={200}
            deal={deal}
            loyalty={loyalty}
            disabledLink={!autoliderID}
            isTecDoc={isTecDoc}
          />
          <div className="catalog-grid-c__wrap">
            <BrandImage isColumn manufacturer={manufacturer} logo={logo} />
            <ProductName id={autoliderID} name={name} disabledLink={!autoliderID} />
          </div>
          <VendorCode
            id={autoliderID}
            link={`/product-details/${autoliderID}`}
            productViewType={productViewType}
            article={article}
            disabledLink={!autoliderID}
            isColumn
          />
          <div className="catalog-grid-c__wrap">
            <ProductInfo isColumn isInfoOpen={isInfoOpen} description={description} setIsInfoOpen={setIsInfoOpen} />
            <Storages options={warehouses} isColumn />
          </div>
          <div className="catalog-grid-c__wrap">
            <Price
              hidePrice={hidePrice}
              isColumn
              displayPrice={displayPrice}
              wholesalePrice={productPrices?.data?.data?.opt}
              retailPrice={productPrices?.data?.data?.priceRetail}
              isOpen={isOpen}
              handleOpen={handleOpen}
              handleClose={handleClose}
              togglePriceMenu={togglePriceMenu}
              mouseEnter={mouseEnter}
              mouseLeave={mouseLeave}
            />
            <ToBasket
              article={article}
              autoliderID={autoliderID}
              logo={logo}
              images={images}
              name={name}
              isColumn
              price={displayPrice}
              isInWishlist={isInWishlist}
              description={description}
              manufacturer={manufacturer}
              shoppingCount={shoppingCount}
              storageTotal={storageTotal}
              incomeTotal={incomeTotal}
              onAdd={addToBasket}
              onDelete={deleteBasketCount}
              onAddToWishlist={addToWishlistHandler}
              isAddingBasket={isAddingBasket}
              isDeletingBasket={isDeletingBasket}
            />
            {isInfoOpen && (
              <div className="cat-grid-c-fil__wrap description-card card-shadow" ref={ref}>
                {cutText(description, 220)}
              </div>
            )}
          </div>
        </div>
      );
    }
    case productType === productTypes['article']: {
      return (
        <div
          onMouseLeave={() => setIsInfoOpen(false)}
          className={classNames('catalog-grid-c', {
            'card-shadow': isInfoOpen,
            active: tecdocActive,
          })}
        >
          {totalCountIsZero && <div className="overlay overlay_white" />}

          <FavoriteButton isColumn isFavorite={isFavorite} onClick={favoritesButtonClickHandler} />
          <ProductImage
            id={autoliderID}
            images={images}
            isMultiImages={isMultiImages}
            width={200}
            height={200}
            deal={deal}
            disabledLink={!autoliderID}
            isTecDoc={isTecDoc}
            loyalty={loyalty}
          />
          <div className="catalog-grid-c__wrap">
            <BrandImage isColumn manufacturer={manufacturer} logo={logo} />
            <ProductName id={autoliderID} name={name} disabledLink={!autoliderID} />
          </div>
          <VendorCode
            id={autoliderID}
            link={`/product-details/${autoliderID}`}
            productViewType={productViewType}
            article={article}
            disabledLink={!autoliderID}
            isColumn
          />
          <div className="catalog-grid-c__wrap">
            <ProductInfo isColumn isInfoOpen={isInfoOpen} description={description} setIsInfoOpen={setIsInfoOpen} />
            <Storages options={warehouses} isColumn />
          </div>
          <div className="catalog-grid-c__wrap">
            <Price
              hidePrice={hidePrice}
              isColumn
              displayPrice={displayPrice}
              wholesalePrice={productPrices?.data?.data?.opt}
              retailPrice={productPrices?.data?.data?.priceRetail}
              isOpen={isOpen}
              handleOpen={handleOpen}
              handleClose={handleClose}
              togglePriceMenu={togglePriceMenu}
              mouseEnter={mouseEnter}
              mouseLeave={mouseLeave}
            />
            <ToBasket
              article={article}
              autoliderID={autoliderID}
              logo={logo}
              images={images}
              name={name}
              isColumn
              price={displayPrice}
              isInWishlist={isInWishlist}
              description={description}
              manufacturer={manufacturer}
              shoppingCount={shoppingCount}
              storageTotal={storageTotal}
              incomeTotal={incomeTotal}
              onAdd={addToBasket}
              onDelete={deleteBasketCount}
              onAddToWishlist={addToWishlistHandler}
              isAddingBasket={isAddingBasket}
              isDeletingBasket={isDeletingBasket}
            />
            {isInfoOpen && (
              <div className="cat-grid-c-fil__wrap description-card card-shadow" ref={ref}>
                {cutText(description, 220)}
              </div>
            )}
          </div>
        </div>
      );
    }
    case productType === productTypes['detailsPage']: {
      return (
        <div className="goods-info">
          <ImageSlider loyalty={loyalty} deal={deal} images={images} isMultiImages={isMultiImages} />
          <div className={classNames('goods-info__right-side', { 'without-capacity': !Boolean(options) })}>
            <FavoriteButton isFavorite={isFavorite} onClick={favoritesButtonClickHandler} />
            <div className="column product-name">{normalizeStringWithSpace(name)}</div>
            <div className="column goods-info__code-wrap">
              <VendorCode
                id={autoliderID}
                productViewType="card"
                article={article}
                link={`/search?search=${normalizeSearchString(article)}`}
                isColumn
              />
              {mfrId && <ToAnaloguesButton article={article} manufacturer={manufacturer} mfrId={mfrId} />}
            </div>
            <Storages options={warehouses} isColumn />
            <BrandImage isColumn hasLabel manufacturer={manufacturer} logo={logo} />
            {options && <Analogues options={options} isColumn />}
            <div className="column goods-info__buy-wrapper">
              <Price
                displayPrice={displayPrice}
                wholesalePrice={productPrices?.data?.data?.opt}
                retailPrice={productPrices?.data?.data?.priceRetail}
                isOpen={isOpen}
                isDetailsPage
                hidePrice={hidePrice}
                handleOpen={handleOpen}
                handleClose={handleClose}
                togglePriceMenu={togglePriceMenu}
                mouseEnter={mouseEnter}
                mouseLeave={mouseLeave}
              />
              <ToBasket
                article={article}
                autoliderID={autoliderID}
                logo={logo}
                images={images}
                name={name}
                price={displayPrice}
                isInWishlist={isInWishlist}
                description={description}
                manufacturer={manufacturer}
                shoppingCount={shoppingCount}
                storageTotal={storageTotal}
                isAddingBasket={isAddingBasket}
                isDeletingBasket={isDeletingBasket}
                onAdd={addToBasket}
                onDelete={deleteBasketCount}
                onAddToWishlist={addToWishlistHandler}
              />
            </div>
          </div>
        </div>
      );
    }
    default:
      return null;
  }
});

export default ProductItem;
