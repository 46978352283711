import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { getCorrectEndingPoints } from 'utils/getCorrectEndingPoints';

const LoyaltyItem = (props) => {
  const { accumulatedPoints, startDate, endDate, name, description, slug, isUnread } = props;

  const { t } = useTranslation();

  const isExpired = endDate ? moment().unix() > moment(endDate, 'YYYY-MM-DD hh:mm:ss').unix() : false;

  return (
    <div className={classNames('cnt-row loyalty-columns', { end: isExpired })}>
      <div className="column">
        {isUnread && <span className="item-unread"></span>}
        <span className="cut-text">{name}</span>
      </div>
      <div className="column">
        <span>{accumulatedPoints}</span>{' '}
        <span className="text-lowercase">{getCorrectEndingPoints(accumulatedPoints ? Number(accumulatedPoints) : 0)}</span>
      </div>

      {isExpired && (
        <div className="column">
          <span>{t('label.finished')}</span>
        </div>
      )}
      {!isExpired && (
        <div className="column">
          {moment(startDate).format('DD.MM.YYYY')} - {moment(endDate).format('DD.MM.YYYY')}
        </div>
      )}

      <div className="column">
        <div className="cut-text">{description}</div>
      </div>
      <div className="column">
        <Link to={`/sales/${slug}`}>{t('action.in_more_detail')}</Link>
      </div>
    </div>
  );
};

export default LoyaltyItem;
