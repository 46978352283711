import { imgUrlToResizeUrl } from 'utils/imgUrlToResizeUrl';

export const handleImageUrlCatalog = (img, width, height) => {
  if (!img) return `${process.env.REACT_APP_API_URL}/assets/Nophoto.svg`;
  if (img.includes('http://') || img.includes('https://')) return img;
  if (img.includes('.gif')) return `${process.env.REACT_APP_API_URL}${img}`;
  return imgUrlToResizeUrl(img, width, height, 'resize');
};

export const handleImageUrlDetail = (img) => {
  if (!img) return `${process.env.REACT_APP_API_URL}/assets/Nophoto.svg`;
  if (img.includes('http://') || img.includes('https://')) return img;
  if (img.includes('.gif')) return `${process.env.REACT_APP_API_URL}${img}`;
  return `${process.env.REACT_APP_API_URL}${img}`;
};
