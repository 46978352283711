import { salesDealTypes } from 'config/constants/dealTypes';

export function getFillValue(dealData) {
  if (!dealData) return 0;
  if (dealData.deal_type !== salesDealTypes.accumulative_with_registration) {
    const pointsNeeded = dealData?.points_needed ? dealData?.points_needed : dealData?.data?.points_needed;
    const isRightFormat = Number(dealData.accumulated_points) >= 0 && Number(pointsNeeded) > 0;
    return isRightFormat ? Math.min(100, Math.trunc((dealData.accumulated_points * 100) / Number(pointsNeeded))) : 0;
  }

  if (!dealData.deal?.registered) {
    return 0;
  }

  return getAccumulativeWithRegPoints(dealData.deal);
}

function getAccumulativeWithRegPoints(deal) {
  if (!deal) return 0;
  const productGroup = deal.gifts.find((product) => product.item_id === deal.registered)?.group;

  if (!productGroup) return 0;
  const groupTgift = deal.groups.find((groupe) => groupe.name === productGroup).tgift;

  if (!groupTgift) return 0;
  const isRightFormat = Number(deal.accumulatedPoints) >= 0 && Number(groupTgift) > 0;

  return isRightFormat ? Math.min(100, Math.trunc((Number(deal.accumulatedPoints) * 100) / Number(groupTgift))) : 0;
}
