import { useContext } from 'react';
import { useLocation } from 'react-router';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useApi } from 'services/api';
import { notificationKeys } from 'services/queryClient/queryKeys';
import logger from 'services/logger';
import { CommonDataContext } from 'HOC/withCommonData/withCommonData';

export const useGetNotifications = (params) => {
  const { notification } = useApi();

  return useQuery(notificationKeys.notification(params), () => notification.getNotifications(params), {
    select: (res) => ({
      list: res?.data?.notifications?.data,
      pageTotal: res?.data?.notifications?.lastPage,
      total: res?.data?.notifications?.total,
    }),
    onError: (error) => logger(error),
  });
};

export const useGetSurvey = () => {
  const { notification } = useApi();

  return useQuery(notificationKeys.surveyList(), () => notification.getSurvey(), {
    select: (res) => res.data.global_notifications,
    onError: (error) => logger(error),
  });
};

export const useSendSurvey = () => {
  const { notification } = useApi();
  const queryClient = useQueryClient();

  return useMutation(notification.sendSurveyAnswer, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(notificationKeys.surveyList());
    },
    onError: (error) => {
      logger(error);
    },
  });
};

export const useReadNotification = (params) => {
  const { notification } = useApi();
  const queryClient = useQueryClient();

  const { productsPerPage } = useContext(CommonDataContext);
  const { search } = useLocation();
  const page = search.page ?? 1;

  const query = params
    ? params
    : {
        page,
        per_page: productsPerPage.value,
      };

  const queryKeyInfo = notificationKeys.notificationsInfo();
  const queryKey = notificationKeys.notification(query);

  return useMutation(notification.readNotification, {
    retry: false,
    onMutate: ({ id }) => {
      queryClient.cancelQueries(queryKey);
      queryClient.cancelQueries(queryKeyInfo);

      const prevNotifications = queryClient.getQueryData(queryKey);
      const prevNotificationsInfo = queryClient.getQueryData(queryKeyInfo);

      const mutate = (list) => {
        return list.map((item) => {
          if (item.id !== id) return item;
          return { ...item, is_viewed: true };
        });
      };

      queryClient.setQueryData(queryKey, (data) => {
        if (!data?.data?.notifications?.data) return;

        data.data.notifications.data = mutate(data.data.notifications.data);
        return data;
      });

      queryClient.setQueryData(queryKeyInfo, (data) => {
        if (!data?.data?.last) return;

        data.data.last = data.data.last?.length ? mutate(data.data.last) : null;
        data.data.count = data.data.count - 1;
        return data;
      });

      return { prevNotifications, prevNotificationsInfo };
    },
    onError: (error, newFavorites, context) => {
      logger(error);
      queryClient.setQueryData(queryKey, context.prevNotifications);
      queryClient.setQueryData(queryKeyInfo, context.prevNotificationsInfo);
      queryClient.invalidateQueries(queryKeyInfo);
      queryClient.invalidateQueries(queryKey);
    },
  });
};

export const useGetNotificationsInfo = () => {
  const { notification } = useApi();

  return useQuery(notificationKeys.notificationsInfo(), () => notification.getNotificationsInfo(), {
    select: (res) => ({
      count: res?.data?.count,
      last: res?.data?.last,
    }),
    onError: (error) => logger(error),
  });
};

export const useDeleteNotification = (query) => {
  const { notification } = useApi();
  const queryClient = useQueryClient();

  const queryKeyInfo = notificationKeys.notificationsInfo();
  const queryKey = notificationKeys.notification(query);

  return useMutation(notification.deleteNotification, {
    retry: false,
    onMutate: ({ id }) => {
      queryClient.cancelQueries(queryKey);
      queryClient.cancelQueries(queryKeyInfo);

      const prevNotifications = queryClient.getQueryData(queryKey);
      const prevNotificationsInfo = queryClient.getQueryData(queryKeyInfo);

      const mutate = (list) => list.filter((item) => item.id !== id);
      const hasUnviewedItem = (list) => {
        if (list) return list.find((item) => item.id === id)?.is_viewed === false;
      };

      queryClient.setQueryData(queryKey, (data) => {
        data.data.notifications.data = mutate(data.data.notifications.data);
        data.data.count = data.data.count - 1;
        data.data.notifications.total = data.data.notifications.total - 1;
        return data;
      });

      queryClient.setQueryData(queryKeyInfo, (data) => {
        data.data.last = data.data.last?.length ? mutate(data.data.last) : null;
        data.data.count = hasUnviewedItem(data.data.last) ? data.data.count - 1 : data.data.count;
        return data;
      });

      return { prevNotifications, prevNotificationsInfo };
    },
    onError: (error, newFavorites, context) => {
      logger(error);
      queryClient.setQueryData(queryKey, context.prevNotifications);
      queryClient.setQueryData(queryKeyInfo, context.prevNotificationsInfo);
      queryClient.invalidateQueries(queryKeyInfo);
      queryClient.invalidateQueries(queryKey);
    },
  });
};

export const useGetBanner = () => {
  const { notification } = useApi();

  return useQuery(notificationKeys.banner(), () => notification.getBanner(), {
    select: (res) => res.data,
    onError: (error) => logger(error),
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
