import i18n from 'services/i18n/i18n';
import { transformApiValidation } from 'utils/transformApiValidation';
import history from 'services/history';

function handleRequest(config) {
  return config;
}

function handleResponse(response, onLogout, setErrors) {
  const errors = transformApiValidation(response.data);

  // no error
  if (response.status === 200) {
    return response;
  }

  switch (response.status) {
    // validation error
    case 400: {
      setErrors(errors);

      return Promise.reject({
        data: response,
        status: response.status,
      });
    }

    // auth error
    case 401: {
      onLogout();

      return Promise.reject({
        data: response,
        status: response.status,
      });
    }

    case 403: {
      onLogout();
      setErrors(errors);

      return Promise.reject({
        data: response,
        status: response.status,
      });
    }

    case 404: {
      history.push('/404');
      break;
    }

    // user action error
    case 422: {
      setErrors(errors);

      return Promise.reject({
        data: response,
        status: response.status,
      });
    }

    // server error
    case 204: {
      setErrors([{ message: i18n.t('errors.server_error_try_later') }]);

      return Promise.reject({
        data: response,
        status: response.status,
      });
    }
    case 500: {
      setErrors([{ message: i18n.t('errors.server_error_try_later') }]);

      return Promise.reject({
        data: response,
        status: response.status,
      });
    }
    case 503: {
      setErrors([{ message: i18n.t('errors.servise_tecdoc_offline') }]);

      return Promise.reject({
        data: response,
        status: response.status,
      });
    }

    // common error
    default: {
      setErrors(errors);

      return Promise.reject({
        data: response,
        status: response.status,
      });
    }
  }
}

export { handleRequest, handleResponse };
