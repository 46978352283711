import React, { useState, memo, useRef, useCallback } from 'react';
import classNames from 'classnames';

import useOutsideClick from 'hooks/useOutsideClick';
import Icon, { ICON_NAMES } from 'components/Icon';
import { t } from 'i18next';

const HistorySelect = memo((props) => {
  const { options, onSelect } = props;

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const handelSelect = useCallback(
    (option) => {
      onSelect(option);
      setIsSelectOpen(false);
    },
    [onSelect]
  );

  const toggleSelectOpen = () => setIsSelectOpen(!isSelectOpen);

  const selectRef = useRef();
  useOutsideClick(selectRef, () => {
    if (isSelectOpen) setIsSelectOpen(false);
  });

  return (
    <>
      {isSelectOpen && (
        <ul ref={selectRef} className="history-search shadow">
          {options.map((option) => {
            const typeOfSearch = option.type === 'name' ? t('select.search_by_name') : t('select.search_by_code');

            return (
              <li key={option.value} className={classNames('', { disabled: option.disabled })} onClick={() => handelSelect(option)}>
                <span className="history-search__type">{typeOfSearch}</span>
                <p className="history-search__val">{option.label}</p>
              </li>
            );
          })}
        </ul>
      )}

      <div className="btn-square btn-square_bg-col-white search__btn-history-s" onClick={toggleSelectOpen}>
        <Icon name={ICON_NAMES.history} />
      </div>
    </>
  );
});

export default HistorySelect;
