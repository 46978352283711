import { useQuery, useMutation } from 'react-query';
import { useApi } from 'services/api';
import { otherKeys } from 'services/queryClient/queryKeys';
import logger from 'services/logger';
import { b64toBlob } from 'utils/b64toBlob';

export const useWriteMessage = () => {
  const { other } = useApi();
  return useMutation(other.writeMessage, {
    retry: false,
    onError: (error) => logger(error),
  });
};

export const useGetDownloads = () => {
  const { other } = useApi();

  return useQuery(otherKeys.downloads(), () => other.getDownloads(), {
    select: (res) => res?.data?.documents,
    onError: (error) => logger(error),
  });
};

export const useGetPaymentDetails = () => {
  const { other } = useApi();

  return useQuery(otherKeys.paymentDetails(), () => other.getPaymentDetails(), {
    select: (res) => res?.data?.payment_details,
    onError: (error) => logger(error),
  });
};

export const useGetUserBalances = () => {
  const { other } = useApi();

  return useQuery(otherKeys.userBalances(), () => other.getUserBalances(), {
    select: (res) => res.data,
    onError: (error) => logger(error),
  });
};

export const useGetDocuments = (data) => {
  const { other } = useApi();

  return useQuery(otherKeys.documents(data), () => other.getDocuments(data), {
    select: (res) => res.data,
    onError: (error) => logger(error),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    keepPreviousData: false,
  });
};

export const useGetDocumentById = (id) => {
  const { other } = useApi();

  return useQuery(otherKeys.document(id), () => other.getDocumentById(id), {
    select: (res) => res.data,
    onError: (error) => logger(error),
  });
};

export const useDownloadDocumentExcel = (id, documentName) => {
  const { other } = useApi();

  return useQuery(otherKeys.documentExcel(id), () => other.downloadDocumentExcel(id), {
    retry: false,
    enabled: false,
    onSuccess: (res) => {
      const FileSaver = require('file-saver');
      const blob = b64toBlob(res?.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      FileSaver.saveAs(blob, `${documentName}.xlsx`);
    },
    onError: (error) => logger(error),
  });
};

export const useDownloadDocumentPdf = (id, documentName) => {
  const { other } = useApi();

  return useQuery(otherKeys.documentPdf(id), () => other.downloadDocumentPdf(id), {
    retry: false,
    enabled: false,
    onSuccess: (res) => {
      const FileSaver = require('file-saver');
      const blob = new Blob([res?.data], { type: 'application/pdf' });
      FileSaver.saveAs(blob, `${documentName}.pdf`);
    },
    onError: (error) => logger(error),
  });
};
