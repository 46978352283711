import React, { memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { formatPrice } from 'utils/formatPrice';
import useOutsideClick from 'hooks/useOutsideClick';

const Price = memo((props) => {
  const {
    displayPrice,
    retailPrice,
    wholesalePrice,
    isColumn,
    isDetailsPage,
    hidePrice,
    isOpen,
    handleOpen,
    handleClose,
    togglePriceMenu,
    mouseEnter,
    mouseLeave,
  } = props;

  const { t } = useTranslation();

  const ref = useRef();
  useOutsideClick(ref, () => handleClose());

  return (
    <div className={classNames('product-price', { column: isColumn })}>
      {isDetailsPage ? (
        <>
          {!hidePrice && (
            <>
              <strong
                className="product-price__cost"
                ref={ref}
                onClick={togglePriceMenu}
                onMouseEnter={mouseEnter}
                onMouseLeave={mouseLeave}
              >
                {formatPrice(displayPrice)}
              </strong>
              <span className="product-price__currency">{t('label.currency')}</span>
            </>
          )}
        </>
      ) : (
        <div className="product-price__wrap">
          {!hidePrice && (
            <>
              <strong
                className="product-price__cost"
                ref={ref}
                onClick={togglePriceMenu}
                onMouseEnter={mouseEnter}
                onMouseLeave={mouseLeave}
              >
                {formatPrice(displayPrice)}
              </strong>
              <span className="product-price__currency">{t('label.currency')}</span>
            </>
          )}
        </div>
      )}
      {isOpen && (retailPrice || wholesalePrice) && (
        <div className="price-details" onMouseEnter={handleOpen} onMouseLeave={mouseLeave}>
          {retailPrice && (
            <div className="price-details_item">
              <div className="price-details_label">{t('label.retail')}</div>
              <div>
                {formatPrice(retailPrice)} {t('label.currency')}
              </div>
            </div>
          )}
          {wholesalePrice && (
            <div className="price-details_item">
              <div className="price-details_label">{t('label.wholesale')}</div>
              <div>
                {formatPrice(wholesalePrice)} {t('label.currency')}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default Price;
