import React, { useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { compose } from 'redux';

import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import { CommonDataContext } from 'HOC/withCommonData/withCommonData';
import withAuth from 'HOC/withAuth/withAuth';
import { GlobalPreloader } from 'components/Preloader/Preloader';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import ViewSwitch from 'components/Navigation/ViewSwitch/ViewSwitch';
import Products from 'components/Products/Products';
import Sort from 'components/Sort/Sort';
import TecdocStatus from 'components/TecdocStatus/TecdocStatus';
import useWindowDimensions from 'hooks/useWindowDimensions';
import useSorting from 'hooks/useSorting';
import { useGetSearchProductsByCode } from 'hooks/api/useSearch';
import { useCatalogueTypes } from 'hooks/api/useProducts';
import { searchKeys } from 'services/queryClient/queryKeys';
import googleAnalytics from 'services/googleAnalytics';
import { productTypes } from 'config/constants/productTypes';
import { sortOptions as selectSortOptions } from 'config/constants/selectOptions';
import tecdocImage from 'assets/images/other/tecdoc.png';

function SearchResultCode() {
  const { data: catalogueTypes } = useCatalogueTypes();
  const { productViewType } = useContext(CommonDataContext);

  const { width } = useWindowDimensions();

  const { t } = useTranslation();

  const { search: locationSearch, pathname } = useLocation();

  const { search, manufacturer_id, brand, tradeNumber } = queryString.parse(locationSearch);

  const { data, isFetching, isSuccess } = useGetSearchProductsByCode(search, manufacturer_id);

  const { main, td_analogs, other_analogs, td_oe } = data?.products ?? {};
  const { list: tdAnalogues, sortOptions: analoguesTDSortOptions, sort: sortTDAnalogues } = useSorting(td_analogs);
  const { list: otherAnalogues, sortOptions: otherAnaloguesSortOptions, sort: sortOtherAnalogues } = useSorting(other_analogs);

  const isTecdocOnline = useMemo(() => {
    const checkOffline = (data) => data && data.online === false;

    if (checkOffline(data)) return false;
    return true;
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      googleAnalytics.onSearchPageView(pathname + search);
      googleAnalytics.onSearchByCodeOrName(window.location.href, pathname);
    }
  }, [isSuccess, pathname, search]);

  return (
    <>
      {isFetching && <GlobalPreloader />}

      <div className="nav-bottom catalog-cart">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="nav-bottom__result-wrap nav-bottom__result-wrap_wide">
              <div className="title">
                <h3>{t('page_title.search_result_by_code')}</h3>
              </div>
              <span className="nav-bottom__result">{search}</span>
              <div className="nav-bottom__result-right">
                {tradeNumber && (
                  <div className="wrap">
                    <span>{t('label.trade_number_short')}</span>
                    <span className="trade-number">{tradeNumber}</span>
                  </div>
                )}
                {brand && <span className="brand-name">{brand}</span>}
              </div>
            </div>
            {productViewType === 'card' && <Sort largeScreen sortOptions={selectSortOptions()} onSort={sortOtherAnalogues} />}
          </div>

          {width >= 1280 && (main?.length > 0 || tdAnalogues?.length > 0 || otherAnalogues?.length > 0 || td_oe?.length > 0) && (
            <ViewSwitch />
          )}

          {width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>
      </div>

      {!data ? null : main.length === 0 && tdAnalogues.length === 0 && otherAnalogues.length === 0 && td_oe.length === 0 ? (
        <div className="no-results">
          <p>{t('label.no_search_results')}</p>
        </div>
      ) : (
        <>
          {main?.length > 0 && (
            <div className="catalog-wrap">
              <Products
                products={main}
                queryKey={searchKeys.searchProducts({ search: search, manufacturer: manufacturer_id })}
                productType={productTypes['common']}
                sortOptions={analoguesTDSortOptions}
                tecdocActive={true}
              />
            </div>
          )}

          {tdAnalogues?.length > 0 && (
            <>
              <div className="title analogues">
                <h3>{t('page_title.analogues_and_substitutes')}</h3>
                <div className="analogues__tecdoc">
                  <img src={tecdocImage} width="40" height="40" alt="Tecdoc" />
                </div>
              </div>

              <div className="catalog-wrap">
                <Products
                  products={tdAnalogues}
                  queryKey={searchKeys.searchProducts({ search: search, manufacturer: manufacturer_id })}
                  productType={productTypes['common']}
                  onSort={sortTDAnalogues}
                  sortOptions={analoguesTDSortOptions}
                  hasSortButtons
                />
              </div>
            </>
          )}

          {td_oe?.length > 0 && (
            <>
              <div className="title analogues">
                <h3>{t('page_title.originals_oem')}</h3>
                <div className="analogues__tecdoc">
                  <img src={tecdocImage} width="40" height="40" alt="Tecdoc" />
                </div>
              </div>

              <div className="catalog-wrap">
                <Products
                  products={td_oe}
                  queryKey={searchKeys.searchProducts({ search: search, manufacturer: manufacturer_id })}
                  productType={productTypes['common']}
                  onSort={sortTDAnalogues}
                  sortOptions={analoguesTDSortOptions}
                  hasSortButtons
                />
              </div>
            </>
          )}

          {otherAnalogues?.length > 0 && (
            <>
              <div className="title analogues">
                <h3>{t('page_title.other_analogues_and_substitutes')}</h3>
              </div>

              <div className="catalog-wrap">
                <Products
                  products={otherAnalogues}
                  queryKey={searchKeys.searchProducts({ search: search, manufacturer: manufacturer_id })}
                  productType={productTypes['common']}
                  onSort={sortOtherAnalogues}
                  sortOptions={otherAnaloguesSortOptions}
                  hasSortButtons
                />
              </div>
            </>
          )}
        </>
      )}

      {!isTecdocOnline && <TecdocStatus offline />}
    </>
  );
}

export default compose(withPageLayout(), withAuth())(SearchResultCode);
