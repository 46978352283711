import { useState, useRef, useCallback } from 'react';
import { debounce } from 'throttle-debounce';

const useDebounceOpenMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const debounceOpen = useRef();

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  const mouseEnter = () => {
    debounceOpen.current = debounce(1000, handleOpen);
    debounceOpen.current();
  };
  const mouseLeave = () => {
    debounceOpen.current?.cancel();
    handleClose();
  };

  return { isOpen, handleOpen, handleClose, mouseEnter, mouseLeave };
};

export default useDebounceOpenMenu;
