import React, { memo } from 'react';
import Select from 'components/Select/Select';

const DatePicker = memo((props) => {
  const { from, to, monthsStart, monthsEnd, onFrom, onTo } = props;

  return (
    <div className="date-wrap">
      <Select
        options={monthsStart}
        value={{ label: from, value: from }}
        onSelect={(data) => {
          onFrom(data.value);
        }}
        optionStyles={{ fontSize: 14 }}
        menuStyles={{ zIndex: 2 }}
      />

      <Select
        options={monthsEnd}
        value={{ label: to, value: to }}
        onSelect={(data) => {
          onTo(data.value);
        }}
        optionStyles={{ fontSize: 14 }}
        menuStyles={{ zIndex: 2 }}
      />
    </div>
  );
});

export default DatePicker;
