import React, { useState, memo, useRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Icon, { ICON_NAMES } from 'components/Icon/index';
import InputSelect from 'components/Select/InputSelect';
import { validateYearsOfProductionTecdoc } from 'utils/validateYearsOfProductionTecdoc';
import useBodyScrollLock from 'hooks/useBodyScrollLock';

const menuTypes = {
  HISTORY: 'HISTORY',
  HISTORY_SM: 'HISTORY_SM',
  CAR_SELECT: 'CAR_SELECT',
  CAR_SELECT_SM: 'CAR_SELECT_SM',
  SELECTED_CAR: 'SELECTED_CAR',
  SELECTED_CAR_SM: 'SELECTED_CAR_SM',
};

const Menu = memo((props) => {
  const { type, historyData, historyActions, carSelectData, carSelectActions, selectedCarData, selectedCarActions } = props;

  const [isSelectOpen, setIsSelectOpen] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const closeMenu = () => setIsMenuOpen(false);
  const openMenu = () => setIsMenuOpen(true);

  const { t } = useTranslation();

  const menuRef = useRef();
  useBodyScrollLock(menuRef, isMenuOpen);

  switch (type) {
    case menuTypes.HISTORY: {
      return (
        <div className="filter-list">
          <div className={classNames('btn-display', { active: isSelectOpen })}>
            {t('label.sampling_history')}
            <span className="indicator" onClick={() => setIsSelectOpen(!isSelectOpen)}>
              <Icon name={ICON_NAMES.filterArrow} />
            </span>
          </div>
          {isSelectOpen && (
            <div className="sub-list">
              {historyData.map((item) => {
                const { onHistoryClick } = historyActions;

                const { data, manufacturer_id, model_id, vehicle_id, targetType } = item;
                const { mfrShortName, vehicleModelSeriesName, name, from, to } = data?.vehicle ?? {};
                const nameLabel = `${mfrShortName} ${vehicleModelSeriesName}`;

                const dateOfProduction = validateYearsOfProductionTecdoc(from, to);
                const description = `${name} ${dateOfProduction}`;

                return (
                  <button
                    key={item.vehicle_id}
                    type="button"
                    className="sub-list__item"
                    onClick={() => onHistoryClick(manufacturer_id, model_id, vehicle_id, targetType)}
                  >
                    <strong>{nameLabel}</strong>
                    <p>{description}</p>
                  </button>
                );
              })}
            </div>
          )}
        </div>
      );
    }
    case menuTypes.HISTORY_SM: {
      return (
        <div className="filter-list">
          <div className="btn-display active" onClick={openMenu}>
            {t('label.sampling_history')}
          </div>
          {isMenuOpen && (
            <Fragment>
              <div className="overlay-backdrop" onClick={closeMenu} />

              <div className="sub-list sub-list_right" ref={menuRef}>
                <div className="sub-list__item sub-list__title">
                  {t('label.sampling_history')}
                  <button type="button" className="btn-close" onClick={closeMenu}>
                    <Icon name={ICON_NAMES.close} fill="#0a2352" />
                  </button>
                </div>

                {isSelectOpen &&
                  historyData?.map((item) => {
                    const { onHistoryClick } = historyActions;

                    const { data, manufacturer_id, model_id, vehicle_id, targetType } = item;
                    const { mfrShortName, vehicleModelSeriesName, name, from, to } = data?.vehicle ?? {};
                    const nameLabel = `${mfrShortName} ${vehicleModelSeriesName}`;

                    const dateOfProduction = validateYearsOfProductionTecdoc(from, to);
                    const description = `${name} ${dateOfProduction}`;

                    return (
                      <button
                        key={item.vehicle_id}
                        type="button"
                        className="sub-list__item"
                        onClick={() => {
                          onHistoryClick(manufacturer_id, model_id, vehicle_id, targetType);
                          closeMenu();
                        }}
                      >
                        <strong>{nameLabel}</strong>
                        <p>{description}</p>
                      </button>
                    );
                  })}
              </div>
            </Fragment>
          )}
        </div>
      );
    }
    case menuTypes.CAR_SELECT: {
      const { manufacturers, models, modifications, manufacturer, model, vehicle } = carSelectData;
      const { setManufacturer, setModel, setVehicle } = carSelectActions;

      return (
        <div className="filter-car-list">
          <InputSelect options={manufacturers} placeholder={t('label.manufacturer')} value={manufacturer} onSelect={setManufacturer} />
          <InputSelect options={models} placeholder={t('label.model')} value={model} onSelect={setModel} />
          <InputSelect options={modifications} placeholder={t('label.vehicle')} value={vehicle} onSelect={setVehicle} />
        </div>
      );
    }
    case menuTypes.CAR_SELECT_SM: {
      const { manufacturers, models, modifications, manufacturer, model, vehicle } = carSelectData;
      const { setManufacturer, setModel, setVehicle } = carSelectActions;

      return (
        <div className="filter-car-list">
          <button type="button" className="btn-display active" onClick={openMenu}>
            {t('label.selected_car')}
          </button>
          {isMenuOpen && (
            <Fragment>
              <div className="overlay-backdrop" onClick={closeMenu} />

              <div className="sub-list sub-list_left" ref={menuRef}>
                <div className="sub-list__title">
                  {t('label.selected_car')}
                  <button type="button" className="close-btn_small-screen" onClick={closeMenu}>
                    <Icon name={ICON_NAMES.close} fill="#0a2352" />
                  </button>
                </div>
                <InputSelect
                  options={manufacturers}
                  placeholder={t('label.manufacturer')}
                  value={manufacturer}
                  blockScroll={true}
                  onSelect={setManufacturer}
                />
                <InputSelect options={models} placeholder={t('label.model')} value={model} blockScroll={true} onSelect={setModel} />
                <InputSelect
                  options={modifications}
                  placeholder={t('label.vehicle')}
                  value={vehicle}
                  blockScroll={true}
                  onSelect={setVehicle}
                />
              </div>
            </Fragment>
          )}
        </div>
      );
    }
    case menuTypes.SELECTED_CAR: {
      const hasTecdocImage = Boolean(selectedCarData?.vehicleImages?.length);
      const defaultImage = `${process.env.REACT_APP_API_URL}/assets/brands/1.png`;
      const imageResultURL = hasTecdocImage ? selectedCarData?.vehicleImages[0]?.imageURL200 : defaultImage;

      const { removeFromHistory, change, openModal } = selectedCarActions;

      const modificationLabel = `${selectedCarData?.mfrName || ''} ${selectedCarData?.bodyStyle || ''} ${
        selectedCarData?.vehicleModelSeriesName || ''
      }`;
      const releaseDateLabel = validateYearsOfProductionTecdoc(selectedCarData?.from, selectedCarData?.to);

      const capacityLiters = selectedCarData?.capacityLiters ? selectedCarData?.capacityLiters + ' l' : '';
      const capacityCC = selectedCarData?.capacityCC ? `${selectedCarData.capacityCC} ccm` : '';
      const engineType = selectedCarData?.engineType ? `(${selectedCarData.engineType})` : '';
      const engineParamsLabel = `${capacityLiters} ${engineType}, ${capacityCC}`.trim();

      const engines = selectedCarData?.engines?.map((engine) => engine.code).join(', ');
      const kw = selectedCarData?.kw ? `${selectedCarData.kw} KW` : '';
      const hp = selectedCarData?.hp ? `${selectedCarData.hp} HP` : '';
      const engineModelLabel = `${engines} ${kw} / ${hp}`;

      return (
        <div className="filter-car-result">
          <button type="button" className={classNames('btn-display', { active: isSelectOpen })}>
            {t('label.selected_car')}
            <span className="indicator" onClick={() => setIsSelectOpen(!isSelectOpen)}>
              <Icon name={ICON_NAMES.filterArrow} />
            </span>
          </button>

          {isSelectOpen && (
            <Fragment>
              <div className="sub-list">
                <button
                  type="button"
                  className="btn-inf"
                  onClick={(event) => {
                    closeMenu();
                    openModal(event);
                  }}
                >
                  <Icon name={ICON_NAMES.info} onLeave />
                </button>
                <div className="sub-list__item">
                  <div className="img-car">
                    <img src={imageResultURL} alt="" />
                  </div>
                </div>
                <div className="sub-list__item">
                  <p className="modification">{modificationLabel}</p>
                </div>
                <div className="sub-list__item">
                  <p>
                    {t('label.release_date')}:<span className="release">{releaseDateLabel}</span>
                  </p>
                </div>
                <div className="sub-list__item">
                  {t('label.engine')}:<span className="type">{engineParamsLabel}</span>
                </div>
                <div className="sub-list__item">
                  {t('label.engine_model')}:<span className="model">{engineModelLabel}</span>
                </div>

                <div className="sub-list__btns-wrap">
                  <button type="button" className="btn-change" onClick={change}>
                    {t('action.change')}
                  </button>
                  <button type="button" className="btn-close" onClick={() => removeFromHistory(selectedCarData.id)}>
                    {/* <Icon name={ICON_NAMES.close} fill="red" /> */}
                    {t('action.delete')}
                  </button>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      );
    }
    case menuTypes.SELECTED_CAR_SM: {
      const hasTecdocImage = Boolean(selectedCarData?.vehicleImages?.length);
      const defaultImage = `${process.env.REACT_APP_API_URL}/assets/brands/1.png`;
      const imageResultURL = hasTecdocImage ? selectedCarData?.vehicleImages[0]?.imageURL200 : defaultImage;

      const { removeFromHistory, change, openModal } = selectedCarActions;

      const modificationLabel = `${selectedCarData?.mfrName || ''} ${selectedCarData?.bodyStyle || ''} ${
        selectedCarData?.vehicleModelSeriesName || ''
      }`;
      const releaseDateLabel = validateYearsOfProductionTecdoc(selectedCarData?.from, selectedCarData?.to);

      const capacityLiters = selectedCarData?.capacityLiters ? selectedCarData?.capacityLiters + ' l' : '';
      const capacityCC = selectedCarData?.capacityCC ? `${selectedCarData.capacityCC} ccm` : '';
      const engineType = selectedCarData?.engineType ? `(${selectedCarData.engineType})` : '';
      const engineParamsLabel = `${capacityLiters} ${engineType}, ${capacityCC}`.trim();

      const engines = selectedCarData?.engines?.map((engine) => engine.code).join(', ');
      const kw = selectedCarData?.kw ? `${selectedCarData.kw} KW` : '';
      const hp = selectedCarData?.hp ? `${selectedCarData.hp} HP` : '';
      const engineModelLabel = `${engines} ${kw} / ${hp}`;

      return (
        <div className="filter-car-result">
          <button type="button" className="btn-display active" onClick={openMenu}>
            {t('label.selected_car')}
          </button>
          {isMenuOpen && (
            <Fragment>
              <div className="overlay-backdrop" onClick={closeMenu} />

              <div className="sub-list sub-list_left" ref={menuRef}>
                <div className="sub-list__wrap">
                  <button
                    type="button"
                    className="btn-inf"
                    onClick={(event) => {
                      closeMenu();
                      openModal(event);
                    }}
                  >
                    <Icon name={ICON_NAMES.info} onLeave />
                  </button>
                  <div className="sub-list__item sub-list__title">
                    {t('label.selected_car')}
                    <button type="button" className="close-btn_small-screen" onClick={closeMenu}>
                      <Icon name={ICON_NAMES.close} fill="#0a2352" />
                    </button>
                  </div>

                  <div className="sub-list__item">
                    <div className="img-car">
                      <img src={imageResultURL} alt="" />
                    </div>
                  </div>
                  <div className="sub-list__item">
                    <p className="modification">{modificationLabel}</p>
                  </div>
                  <div className="sub-list__item">
                    <p>
                      {t('label.release_date')}:<span className="release">{releaseDateLabel}</span>
                    </p>
                  </div>
                  <div className="sub-list__item">
                    {t('label.engine')}:<span className="type">{engineParamsLabel}</span>
                  </div>
                  <div className="sub-list__item">
                    {t('label.engine_model')}:<span className="model">{engineModelLabel}</span>
                  </div>
                </div>
                <div className="sub-list__item sub-list__btns-wrap">
                  <button type="button" className="btn-change" onClick={change}>
                    {t('action.change')}
                  </button>
                  <button type="button" className="btn-close" onClick={() => removeFromHistory(selectedCarData.id)}>
                    {/* <Icon name={ICON_NAMES.close} fill="red" /> */}
                    {t('action.delete')}
                  </button>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      );
    }
    default:
      return null;
  }
});

export { menuTypes };
export default Menu;
