import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { compose } from 'redux';

import MarkNewsAsRead from 'feature/MarkNewsAsRead';
import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import { CommonDataContext } from 'HOC/withCommonData/withCommonData';
import withAuth from 'HOC/withAuth/withAuth';
import { useGetArticles } from 'hooks/api/useArticles';
import { useCatalogueTypes } from 'hooks/api/useProducts';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { paginationOptions } from 'config/constants/selectOptions';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import PagePagination from 'components/PagePagination/PagePagination';
import { GlobalPreloader } from 'components/Preloader/Preloader';
import NewsPreview from './NewsPreview/NewsPreview';

function News() {
  const { newsPerPage, setNewsPerPage } = useContext(CommonDataContext);

  const location = useLocation();
  const page = Number(queryString.parse(location.search).page) || 1;

  const { data, isFetching } = useGetArticles({ page, per_page: newsPerPage.value, type: 'news' });

  const news = data?.data;
  const newsTotal = data?.total;
  const newsOnPage = data?.data?.length;
  const pageTotal = data?.lastPage;

  const windowDimension = useWindowDimensions();
  const { t } = useTranslation();

  const { data: catalogueTypes } = useCatalogueTypes();

  return (
    <>
      {isFetching && <GlobalPreloader />}

      <div className="nav-bottom news-page">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="title">
              <h3>{t('page_title.news')}</h3>
            </div>

            <MarkNewsAsRead type="news" />
          </div>

          {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>
      </div>

      <div className="wrapper">
        {data && (
          <>
            <div className="news">
              {news.map((item) => {
                return (
                  <NewsPreview
                    key={item.id}
                    image={item.data.image}
                    title={item.data.title}
                    isRead={item.is_read}
                    published_at={item.published_at}
                    short_description={item.data.short_description}
                    seo_slug={item.seo_slug}
                    views={item.views}
                  />
                );
              })}
            </div>

            {news.length !== 0 && (
              <PagePagination
                pageCount={pageTotal}
                page={page}
                paginationOptions={paginationOptions()}
                itemsTotal={newsTotal}
                itemsOnPage={newsOnPage}
                itemsPerPage={newsPerPage}
                setItemsPerPage={setNewsPerPage}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default compose(withPageLayout(), withAuth())(News);
