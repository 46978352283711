import React, { memo, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { getCatalogueNavigationUrl } from 'utils/getCatalogueNavigationUrl';
import { getCategories } from 'utils/getCategories';
import { useDisclosure } from 'hooks/useDisclosure';
import Icon, { ICON_NAMES } from 'components/Icon';
import { version } from 'config/packageJSON';
import { checkMobileTablet } from 'utils/checkMobileTablet';

const QuickNavigation = memo(({ catalogueTypes }) => {
  const { toolsCategories, restCategories } = getCategories(catalogueTypes);

  const isMobile = checkMobileTablet();

  const toolsMenu = useDisclosure();

  return (
    <ul className={classNames('nav-bottom__right nav-bottom__item', { 'submenu-open': toolsMenu.isOpen })}>
      {restCategories.map((catalogue) => {
        return (
          <li className="item" key={catalogue.name}>
            <NavLink to={getCatalogueNavigationUrl(catalogue)} exact className="btn-square btn-square_bg-col-astr" activeClassName="active">
              <img src={process.env.REACT_APP_API_URL + catalogue.image + `?v=${version()}`} alt={catalogue.label} />
            </NavLink>
          </li>
        );
      })}
      {Boolean(toolsCategories.length) && (
        <li
          className={classNames('item item-list', { active: toolsMenu.isOpen })}
          style={{ zIndex: 9999 }}
          onMouseLeave={toolsMenu.close}
          onClick={() => {
            if (isMobile) {
              toolsMenu.toggle();
            }
          }}
        >
          <Fragment>
            <button type="button" className="btn-square btn-square_bg-col-astr svg-shrink-0" onMouseEnter={toolsMenu.open}>
              <Icon name={ICON_NAMES.extension} />
            </button>

            <ul className="submenu-list" onMouseEnter={toolsMenu.open}>
              {toolsCategories.map((catalogue, index) => (
                <li key={index}>
                  <p>{catalogue.label}</p>
                  <ul>
                    {catalogue.list.map((item) => {
                      return (
                        <li key={item.name}>
                          <NavLink to={getCatalogueNavigationUrl(item)} onClick={toolsMenu.close} activeClassName="active-submenu-link">
                            <span className="submenu-item-icon">
                              <img src={process.env.REACT_APP_API_URL + item.image + `?v=${version()}`} alt={item.label} />
                            </span>
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              ))}
            </ul>
          </Fragment>
        </li>
      )}
    </ul>
  );
});

export default QuickNavigation;
