import React from 'react';
import { useTranslation } from 'react-i18next';

const RollbarError = ({ error, resetError }) => {
  const { t } = useTranslation();

  function reloadPage() {
    const forceGetFromServer = true; // works only in FireFox

    window.location.reload(forceGetFromServer);
  }

  return (
    <div style={{ padding: 20, display: 'flex', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
      <h1>{t('errors.something_went_wrong')}:</h1>

      <p style={{ width: '100%', textAlign: 'center' }}>{error.toString()}</p>

      <div style={{ display: 'flex', gap: 20, alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
        <button type="button" className="btn btn_bg-col-astronaut" onClick={reloadPage}>
          {t('action.reload_page')}
        </button>
        <a href="/" className="link link_col-blue" style={{ width: 210, textAlign: 'center' }}>
          {t('action.to_main')}
        </a>
      </div>
    </div>
  );
};

export default RollbarError;
