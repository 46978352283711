import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

const OrderItemsFooter = memo(({ sum }) => {
  const { t } = useTranslation();

  return (
    <div className="order__result-row result-row">
      <p>
        <span className="total-sum">{sum}</span> <span className="currency">{t('label.currency')}</span>
      </p>
    </div>
  );
});

export default OrderItemsFooter;
