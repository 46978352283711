import { useState, useEffect } from 'react';
import useWindowDimensions from './useWindowDimensions';

function useProductViewType() {
  const [productViewType, setProductViewType] = useState('list');

  function viewSwitchHandler(viewType) {
    if (!['list', 'card'].includes(viewType)) return;
    setProductViewType(viewType);
  }

  const windowDimension = useWindowDimensions();
  useEffect(() => {
    if (windowDimension.width < 1280) setProductViewType('card');
  }, [windowDimension]);

  return [productViewType, viewSwitchHandler];
}

export default useProductViewType;
