import React, { useCallback, useContext, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import classNames from 'classnames';

import { CommonDataContext } from 'HOC/withCommonData/withCommonData';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Icon, { ICON_NAMES } from 'components/Icon/index';
import Select from 'components/Select/Select';
import HistorySelect from 'components/Select/HistorySelect';
import SearchUnvalidInfo from './SearchUnvalidInfo';
import { Preloader } from 'components/Preloader/Preloader';

const Search = memo((props) => {
  const { largeScreen, smallScreen, isActive, historyData, isSearchByNameValid, isSearching, onClose, onSearch } = props;
  const { searchType, setSearchType, searchOptions } = useContext(CommonDataContext);

  const { width } = useWindowDimensions();

  const { t } = useTranslation();

  const onSubmit = useCallback(
    (event) => {
      const type = searchType.value;
      const search = event.search;
      if (!type || !search) return;

      onSearch({ type, search });
      if (width <= 600) onClose();
    },
    [searchType.value, width, onClose, onSearch]
  );

  return (
    <Form
      initialValues={{ search: '' }}
      onSubmit={onSubmit}
      validate={(values) => {
        const errors = {};
        if (values.search && values.search.length < 3) errors.search = t('errors.search_length');
        return errors;
      }}
      render={({ handleSubmit, values, form }) => {
        const searchLength = values?.search?.length;

        switch (true) {
          case largeScreen:
            return (
              <form className="search" onSubmit={handleSubmit}>
                {isSearchByNameValid === false && <SearchUnvalidInfo />}
                <HistorySelect options={historyData} onSelect={onSearch} />

                <div className="search__wrap">
                  <Field name="search">
                    {({ input, meta }) => (
                      <>
                        <div className="search__input-w">
                          <input type="text" autoComplete="off" placeholder={t('label.header_search')} {...input} />
                          <span className="number-symbols">{searchLength}</span>
                        </div>
                        {meta.error && meta.touched && <span className="search__warning-message">{meta.error}</span>}
                      </>
                    )}
                  </Field>
                  <div className="search__right-wrap">
                    {width > 425 && (
                      <Select
                        options={searchOptions}
                        value={searchType}
                        indicatorsContainer={{ width: 40 }}
                        containerStyles={{
                          minWidth: 134,
                          width: '100%',
                        }}
                        onSelect={(data) => setSearchType(data)}
                      />
                    )}
                    <button type="submit" disabled={isSearching} className="btn-square btn-square_bg-col-astr">
                      {isSearching ? <Preloader /> : <Icon name={ICON_NAMES.search} />}
                    </button>
                  </div>
                </div>
              </form>
            );
          case smallScreen:
            return (
              <form className={classNames('mobile-search', { active: isActive })} onSubmit={handleSubmit}>
                <div className="mobile-search__top-wrap">{t('label.header_search')}</div>
                <div className="mobile-search__wrap">
                  <div className="mobile-search__left-side">
                    {isSearchByNameValid === false && <SearchUnvalidInfo />}
                    <HistorySelect options={historyData} onSelect={onSearch} />

                    <Field name="search">
                      {({ input, meta }) => (
                        <>
                          <div className="search__input-w">
                            <input type="text" autoComplete="off" placeholder={t('label.header_search')} {...input} />
                            <span className="number-symbols">{searchLength}</span>
                          </div>
                          {meta.error && meta.touched && <span className="search__warning-message">{meta.error}</span>}
                        </>
                      )}
                    </Field>
                    <Select
                      options={searchOptions}
                      value={searchType}
                      indicatorsContainer={{ width: 40 }}
                      containerStyles={{
                        minWidth: 134,
                        width: '200px',
                        position: width <= 600 ? 'absolute' : 'relative',
                        top: width <= 600 ? '60px' : 'unset',
                        left: width <= 600 ? '50%' : 'unset',
                        transform: width <= 600 ? 'translateX(calc(-50% + 30px))' : 'unset',
                      }}
                      onSelect={(data) => setSearchType(data)}
                    />
                  </div>
                  <button type="submit" disabled={isSearching} className="btn-square btn-square_bg-col-astr">
                    {/* <Icon name={ICON_NAMES.search} /> */}
                    {isSearching ? <Preloader /> : <Icon name={ICON_NAMES.search} />}
                  </button>
                  <button type="button" className="btn-close" onClick={onClose}>
                    <Icon name={ICON_NAMES.close} />
                  </button>
                </div>
              </form>
            );
          default:
            return null;
        }
      }}
    />
  );
});

export default Search;
