import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

const HintBasket = memo(({ total, totalPrice }) => {
  const { t } = useTranslation();

  return (
    <div className="hidden-inf">
      <div className="hidden-inf__product-inf">
        <p>
          <span className="quantity-goods">{total}</span>{' '}
          {t('basket.label.goods_for_the_amount')}
        </p>
        <p>
          <span className="total-price">{totalPrice}</span>{' '}
          {t('label.currency')}
        </p>
      </div>
    </div>
  );
});

export default HintBasket;
