import { useReducer } from 'react';

const tecdocTypes = {
  STEP_FORWARD: 'STEP_FORWARD',
  STEP_BACKWARD: 'STEP_BACKWARD',
  STEP_ON_PAGE: 'STEP_ON_PAGE',
};

const useTecdocState = () => {
  const initialState = { step: 1 };

  function reducer(state, action) {
    const { type, payload } = action;

    switch (type) {
      case tecdocTypes.STEP_FORWARD:
        return {
          step: state.step + 1,
        };
      case tecdocTypes.STEP_BACKWARD:
        return {
          step: state.step - 1,
        };
      case tecdocTypes.STEP_ON_PAGE:
        return {
          step: payload.step,
        };
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const stepForward = () => {
    if (state.step === 5) return;
    else dispatch({ type: tecdocTypes.STEP_FORWARD });
  };
  const stepBackward = () => {
    if (state.step === 1) return;
    else dispatch({ type: tecdocTypes.STEP_BACKWARD });
  };
  const stepOnPage = (step) => {
    dispatch({ type: tecdocTypes.STEP_ON_PAGE, payload: { step } });
  };

  return {
    state,
    actions: {
      stepForward,
      stepBackward,
      stepOnPage,
    },
  };
};

export default useTecdocState;
