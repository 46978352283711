import React, { Fragment, useEffect, useContext } from 'react';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import { CommonDataContext } from 'HOC/withCommonData/withCommonData';
import withAuth from 'HOC/withAuth/withAuth';
import { useGetNotifications, useReadNotification, useDeleteNotification, useGetSurvey, useSendSurvey } from 'hooks/api/useNotifications';
import { useCatalogueTypes } from 'hooks/api/useProducts';
import useWindowDimensions from 'hooks/useWindowDimensions';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import { GlobalPreloader } from 'components/Preloader/Preloader';
import PagePagination from 'components/PagePagination/PagePagination';
import Poll from './Poll';
import NotificationItem from './NotificationItem';
import { paginationOptions } from 'config/constants/selectOptions';

function Notification() {
  const { data: catalogueTypes } = useCatalogueTypes();
  const { productsPerPage, setProductsPerPage } = useContext(CommonDataContext);

  const { search } = useLocation();
  const page = Number(queryString.parse(search).page) || 1;
  const query = { page, per_page: productsPerPage.value };

  const { data: notifications, isFetching, refetch } = useGetNotifications(query);
  const { data: survey = [] } = useGetSurvey();
  const { mutate: sendSurvey } = useSendSurvey();
  const { mutate: readNotification } = useReadNotification(query);
  const { mutate: deleteNotification } = useDeleteNotification(query);

  const windowDimension = useWindowDimensions();

  const { t } = useTranslation();

  useEffect(() => {
    if (notifications?.list?.length === 0 && Number(notifications.total) !== 0) {
      setTimeout(() => refetch(), 500);
    }
  }, [notifications?.list, notifications?.total, refetch]);

  return (
    <>
      {isFetching && <GlobalPreloader />}

      <div className="nav-bottom">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="title">
              <h3>{t('page_title.notification')}</h3>
            </div>
          </div>

          {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>
      </div>

      <div className="wrapper">
        {notifications && (
          <Fragment>
            <section className="notification-list">
              {survey.length > 0 && <Poll list={survey} sendSurvey={sendSurvey} />}

              <div className="notification-list__group">
                <div className="title notification-list__group-title">
                  <h3>{t('page_title.notification')}</h3>
                </div>
                {notifications.list.map((item) => (
                  <NotificationItem
                    key={item.id}
                    type={item.type}
                    createdAt={item.created_at}
                    isViewed={item.is_viewed}
                    data={item?.data}
                    onRead={() => readNotification({ id: item.id })}
                    onDelete={() => deleteNotification({ id: item.id })}
                  />
                ))}

                {notifications.list.length === 0 && (
                  <div className="no-results">
                    <p>{t('info.no_new_notifications')}</p>
                  </div>
                )}
              </div>
            </section>

            {Number(notifications?.total) > 0 && (
              <PagePagination
                pageCount={notifications.pageTotal}
                page={page}
                paginationOptions={paginationOptions()}
                itemsTotal={notifications.total}
                itemsOnPage={notifications.list.length}
                itemsPerPage={productsPerPage}
                setItemsPerPage={setProductsPerPage}
              />
            )}
          </Fragment>
        )}
      </div>
    </>
  );
}

export default compose(withPageLayout(), withAuth())(Notification);
