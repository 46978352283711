import moment from 'moment';

export const ls = {
  keys: {
    bannerData: 'BD',
    bannerConfirm: 'BC',
    runningLineConfirm: 'RLC',
  },
  setBanner(data) {
    localStorage.setItem(this.keys.bannerData, JSON.stringify(data));
  },
  getBanner() {
    return localStorage.getItem(this.keys.bannerData);
  },
  confirmBanner() {
    localStorage.setItem(this.keys.bannerConfirm, JSON.stringify(true));
  },
  confirmRunningLine() {
    localStorage.setItem(this.keys.runningLineConfirm, JSON.stringify(true));
  },
  getConfirmBanner() {
    return JSON.parse(localStorage.getItem(this.keys.bannerConfirm));
  },
  getConfirmRunningLine() {
    return JSON.parse(localStorage.getItem(this.keys.runningLineConfirm));
  },
  clear() {
    localStorage.removeItem(this.keys.bannerData);
    localStorage.removeItem(this.keys.bannerConfirm);
    localStorage.removeItem(this.keys.runningLineConfirm);
  },
};

export function validateDate(startDate, endDate) {
  const today = moment();
  const start = moment(startDate);
  const end = moment(endDate);

  return today.isBetween(start, end, undefined, '[]');
}

export function validateNewData(newData) {
  const storedData = ls.getBanner();

  if (!storedData) return true;

  return storedData !== JSON.stringify(newData);
}
