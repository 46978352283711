import React, { useEffect, useState, memo, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import classNames from 'classnames';

import history from 'services/history';
import useFilterValuesFromQuery from 'hooks/useFilterValuesFromQuery';

const Option = memo((props) => {
  const { checked, label, value, count, labelTextInitial = false, onChange } = props;

  const disabled = useMemo(() => !checked && count === 0, [checked, count]);

  useEffect(() => {
    if (checked && count === 0) onChange?.call(null, false);
  }, [checked, count, onChange]);

  return (
    <li>
      <label
        className={classNames('sub-list__label-wrap', { disabled: disabled })}
        onChange={(event) => onChange?.call(null, event.target.checked)}
        onClick={(event) => event.stopPropagation()}
      >
        <span className="sub-list__checkbox-wrap">
          <input type="checkbox" readOnly value={value} checked={checked} disabled={disabled} />
          <span className={classNames('sub-list__name', { 'text-initial': labelTextInitial })}>{label}</span>
        </span>

        <span className="sub-list__count">{count}</span>
      </label>
    </li>
  );
});

const FilterGroup = memo((props) => {
  const { options, title, name, defaultOpen, labelTextInitial } = props;

  const [isFilterGroupOpen, setIsFilterGroupOpen] = useState(defaultOpen ? true : false);

  const filterFromQuery = useFilterValuesFromQuery();

  const location = useLocation();

  const hanleChange = useCallback(
    (option, checked) => {
      const key = name;
      const value = option.value;
      const search = filterFromQuery(key, value, history.location.search);

      history.pushSearch(search, history.location.pathname);
    },
    [name, filterFromQuery]
  );

  return (
    <li className="filter-list__item" onClick={() => setIsFilterGroupOpen(!isFilterGroupOpen)}>
      <div className={classNames('filter-list__wrap', { active: isFilterGroupOpen })}>
        {title} <span className="indicator" />
      </div>
      {isFilterGroupOpen && (
        <ul className="sub-list">
          {options.map((option) => {
            const checkboxIds = queryString.parse(location.search);
            const checked = checkboxIds[name]?.split('|')?.includes(option.value.toString());

            return (
              <Option
                key={option.value}
                value={option.value}
                label={option.value}
                count={Number(option.count)}
                onChange={hanleChange.bind(null, option)}
                checked={Boolean(checked)}
                labelTextInitial={labelTextInitial}
              />
            );
          })}
        </ul>
      )}
    </li>
  );
});

export default FilterGroup;
