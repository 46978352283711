import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ua from 'config/localization/ua';
import ru from 'config/localization/ru';

i18n.use(initReactI18next).init({
  fallbackLng: 'ua',
  debug: process.env.REACT_APP_ENV === 'production' ? false : true,
  react: {
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: true,
  },
  resources: {
    ua: { translation: ua },
    ru: { translation: ru },
  },
});

export default i18n;
