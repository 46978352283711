import React, { memo } from 'react';
import { validateYearsOfProductionTecdoc } from 'utils/validateYearsOfProductionTecdoc';
import { useScrollTo } from 'hooks/useScrollTo';

const Models = memo(({ model, models, setModel }) => {
  useScrollTo(model);

  return (
    <ul className="car-search">
      {models.map((model) => {
        const yearsOfProduction = validateYearsOfProductionTecdoc(model.from, model.to);
        return (
          <li key={model.id} className="car-search__link-wrap">
            <button type="button" className="car-brand" onClick={() => setModel(model)}>
              <span className="car-brand__name">{model.name}</span>
              <span className="car-brand__production-years">{yearsOfProduction}</span>
            </button>
          </li>
        );
      })}
    </ul>
  );
});

export default Models;
