import React from 'react';
import classNames from 'classnames';

import Icon, { ICON_NAMES } from 'components/Icon/index';
import Analogues from 'components/Analogues/Analogues';
import { cutText } from 'utils/normalizeString';

function ProductInfo(props) {
  const { isInfoOpen, setIsInfoOpen, description, name, options, list, isColumn } = props;

  return (
    <div className={classNames('product-info', { column: isColumn })}>
      {description && (
        <>
          <div className="btn-inf" onClick={() => setIsInfoOpen(true)}>
            {isInfoOpen ? <Icon name={ICON_NAMES.info} onEnter /> : <Icon name={ICON_NAMES.info} onLeave />}
          </div>

          {isInfoOpen && list && (
            <div className="cat-grid-l-fil description-list" onMouseEnter={() => setIsInfoOpen(true)}>
              <div className="description-list-name">{name}</div>
              <div className="description-list-about">{cutText(description, 250)}</div>

              <div>{options && <Analogues options={options} isList={true} />}</div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ProductInfo;
