import React, { memo } from 'react';
import { Form, Field } from 'react-final-form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useToastNotification from 'hooks/useToastNotification';

const Question = memo(({ name, checked, type, title, onChange }) => {
  return (
    <label className="label-wrap">
      <Field name={name} component="input" type={type} checked={checked} onChange={onChange} />
      <span>{title}</span>
    </label>
  );
});

const Poll = memo(({ list, sendSurvey }) => {
  const onSubmit = (values) => {
    sendSurvey(values, {
      onSuccess: () => {
        notify(t('info.your_answer_has_been_sent_successfully'), notifyStatus.success);
      },
    });
  };

  const { t } = useTranslation();

  const { notify, notifyStatus } = useToastNotification();

  return (
    <div className="notification-list__group">
      <div className="title notification-list__group-title">
        <h3>{t('label.poll')}</h3>
      </div>

      {list.map((item) => {
        const date = moment.utc(item.updated_at).format('DD.MM.YYYY');
        const time = moment.utc(item.updated_at).format('HH:mm');
        const answerElementType = item.answer_type;

        return (
          <Form
            key={item.id}
            keepDirtyOnReinitialize
            initialValues={{ notification_id: item.id, answer_ids: [] }}
            onSubmit={onSubmit}
            render={({ handleSubmit, values, form }) => {
              return (
                <form className="notification-list__item item item_poll" onSubmit={handleSubmit}>
                  <div className="item__wrap">
                    <div className="item__top-wrap">
                      <div className="item__info-status">
                        <span className="item__status" />
                        <span className="item__date">
                          {date} <span className="item__time item__time_desktop">{time}</span>
                        </span>
                      </div>
                    </div>
                    <div className="item__time item__time_adapt">{time}</div>
                    <div className="item__title title">
                      <h4>{item.question_title}</h4>
                    </div>
                  </div>
                  <div className="item__desc-wrap">
                    <span className="item__text-rule">
                      {answerElementType === 'radio' ? t('label.choose_one_of') : t('label.choose_one_or_more')}
                    </span>

                    {item.answers.map((answer) => {
                      if (answerElementType === 'radio') {
                        return (
                          <Question
                            key={answer.answer_id}
                            name="answer_ids"
                            checked={values['answer_ids'].includes(answer.answer_id)}
                            type="radio"
                            title={answer.answer_title}
                            onChange={() => form.change('answer_ids', [answer.answer_id])}
                          />
                        );
                      }

                      return (
                        <Question
                          key={answer.answer_id}
                          name="answer_ids"
                          checked={values['answer_ids'].includes(answer.answer_id)}
                          type="checkbox"
                          title={answer.answer_title}
                          onChange={() => {
                            if (values['answer_ids'].includes(answer.answer_id)) {
                              form.change(
                                'answer_ids',
                                values['answer_ids'].filter((i) => i !== answer.answer_id)
                              );
                              return;
                            }
                            form.change('answer_ids', [...values['answer_ids'], answer.answer_id]);
                          }}
                        />
                      );
                    })}
                  </div>
                  <div className="btn-wrap">
                    <button type="submit" className="btn" disabled={!values['answer_ids'] || values['answer_ids'].length === 0}>
                      {t('action.submit')}
                    </button>
                  </div>
                </form>
              );
            }}
          />
        );
      })}
    </div>
  );
});

export default Poll;
