import React, { useMemo, useState, createContext } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header/Header';
import Sidebar from 'components/Sidebar/Sidebar';
import ScrollUpButton from 'components/ScrollUpButton/ScrollUpButton';
import { sortOptions } from 'config/constants/selectOptions';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { BannerPopup, useBannerPopup } from 'feature/banner';

const ViewContext = createContext(null);

const withPageLayout = () => (Component) => {
  return function (props) {
    const { t } = useTranslation();

    const windowDimension = useWindowDimensions();

    //looks for sort option at initial load
    const location = useLocation();
    function setInitialSortOption() {
      const obj = queryString.parse(location.search, {
        arrayFormat: 'separator',
        arrayFormatSeparator: '|',
      });
      return (
        sortOptions().find((item) => item.direction === obj.direction && item.sort_by === obj.sort_by) || t('select.products_sort.sort')
      );
    }

    const [isSortMenuOpen, setIsSortMenuOpen] = useState(false);
    const [sortOption, setSortOption] = useState(setInitialSortOption());

    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const memoizedViewContext = useMemo(
      () => ({
        isSortMenuOpen,
        setIsSortMenuOpen,
        sortOption,
        setSortOption,
        isFilterOpen,
        setIsFilterOpen,
      }),
      [isSortMenuOpen, setIsSortMenuOpen, sortOption, setSortOption, isFilterOpen, setIsFilterOpen]
    );

    const bannerController = useBannerPopup();

    return (
      <div
        className={classNames('global-wrapper', {
          'notify-show': bannerController.state.runningLineState.isOpen,
        })}
      >
        <ScrollUpButton />

        <Header bannerController={bannerController} />

        <div className="main">
          <section className="alter-container content alter-container_bg-col-solitude">
            <ViewContext.Provider value={memoizedViewContext}>
              <div className="container">
                <Component {...props} />
              </div>

              {windowDimension.width > 760 && <Sidebar />}
            </ViewContext.Provider>
          </section>
        </div>

        {bannerController.state.bannerState.isOpen && (
          <BannerPopup bannerData={bannerController.data} bannerActions={bannerController.actions} />
        )}
      </div>
    );
  };
};

export { withPageLayout as default, ViewContext };
