import React, { useState, memo, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import i18n from 'services/i18n/i18n';

import { useReadNotification, useGetSurvey, useGetNotificationsInfo } from 'hooks/api/useNotifications';
import useWindowDimensions from 'hooks/useWindowDimensions';
import useOutsideClick from 'hooks/useOutsideClick';
import { formatPrice } from 'utils/formatPrice';
import { checkMobileTablet } from 'utils/checkMobileTablet';
import Icon, { ICON_NAMES } from 'components/Icon';
import HintCommon from './HintCommon';
import HintBasket from './HintBasket';
import HintManager from './HintManager';
import HintBalance from './HintBalance';
import HintNotification from './HintNotification';
import { useGetFavoritesTotal } from 'hooks/api/useProducts';
import { useGetUnreadArticlesCount } from 'hooks/api/useArticles';
import { useGetBasketInfo } from 'hooks/api/useBasket';
import { useGetProfile, useGetManagers } from 'hooks/api/useProfile';
import { useGetUnreadLoyaltyCount } from 'hooks/api/useLoyalty';

const SidebarItem = memo((props) => {
  const { to, iconName, fill, total, link, children } = props;

  const { width } = useWindowDimensions();

  const [isHintShown, setIsHintShown] = useState(false);

  const handleOpen = () => {
    const isMobile = checkMobileTablet();
    if (!link && isMobile) setIsHintShown(!isHintShown);
  };

  const ref = useRef();
  useOutsideClick(ref, () => {
    if (!link && width <= 1024) setIsHintShown(false);
  });

  return (
    <li ref={ref} onMouseEnter={() => setIsHintShown(true)} onMouseLeave={() => setIsHintShown(false)} onClick={handleOpen}>
      {link ? (
        <NavLink to={to} exact className="btn-square btn-square_bg-col-astr">
          <Icon name={iconName} fill={fill} />
          {total !== 0 && total && <span className="count">{total}</span>}
          {isHintShown && children}
        </NavLink>
      ) : (
        <div className="btn-square btn-square_bg-col-astr">
          <Icon name={iconName} fill={fill} />
          {isHintShown && children}
        </div>
      )}
    </li>
  );
});

const Sidebar = memo(() => {
  const { data: unreadNewsCount } = useGetUnreadArticlesCount('news');
  const { data: unreadSalesCount } = useGetUnreadArticlesCount('sales');
  const { data: unreadLoyaltyCount } = useGetUnreadLoyaltyCount();

  const { data: favoritesTotal } = useGetFavoritesTotal();
  const { data: notificationsData } = useGetNotificationsInfo();
  const { data: basketData } = useGetBasketInfo();
  const { data: managersData } = useGetManagers();
  const { data: userData } = useGetProfile();

  const salesManager = managersData?.salesman;

  const { data: survey = [] } = useGetSurvey();

  const { mutate: readNotification } = useReadNotification();

  const notificationsTotal = (notificationsData?.count ?? 0) + (survey.length ?? 0);

  return (
    <aside className="sidebar">
      <nav className="sidebar-wrap">
        <ul className="sidebar-list">
          <SidebarItem link to="/basket" iconName={ICON_NAMES.basket} fill="#fff" total={basketData?.total}>
            <HintBasket total={basketData?.total} totalPrice={formatPrice(basketData?.totalPrice)} />
          </SidebarItem>
          <SidebarItem iconName={ICON_NAMES.manager} fill="#fff">
            <HintManager
              lastname={salesManager?.lastname}
              firstname={salesManager?.firstname}
              phone={salesManager?.phone}
              email={salesManager?.email}
              photo={salesManager?.image}
            />
          </SidebarItem>
          <SidebarItem link to="/favorites" iconName={ICON_NAMES.favorites} fill="#fff" total={favoritesTotal}>
            <HintCommon label={i18n.t('sidebar_label.favorites')} />
          </SidebarItem>
          <SidebarItem link to="/debts" iconName={ICON_NAMES.balance} fill="#fff">
            <HintBalance userData={userData} />
          </SidebarItem>
          <SidebarItem link to="/notification" iconName={ICON_NAMES.message} fill="#fff" total={notificationsTotal}>
            <HintNotification onReadNotification={readNotification} notifications={notificationsData?.last} />
          </SidebarItem>
          <SidebarItem link to="/sales" iconName={ICON_NAMES.sales} fill="#fff" total={unreadSalesCount}>
            <HintCommon label={i18n.t('sidebar_label.sales')} />
          </SidebarItem>
          <SidebarItem link to="/loyalty-programs" iconName={ICON_NAMES.loyalty_coins} total={unreadLoyaltyCount}>
            <HintCommon label={i18n.t('label.loyalty_program')} />
          </SidebarItem>
          <SidebarItem link to="/news" iconName={ICON_NAMES.news} fill="#fff" total={unreadNewsCount}>
            <HintCommon label={i18n.t('sidebar_label.news')} />
          </SidebarItem>
          <SidebarItem link to="/order-history" iconName={ICON_NAMES.order_history} fill="#fff">
            <HintCommon label={i18n.t('sidebar_label.order_history')} />
          </SidebarItem>
        </ul>
      </nav>
    </aside>
  );
});

export default Sidebar;
