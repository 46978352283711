import React, { Fragment, memo, useRef, useState, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import useOutsideClick from 'hooks/useOutsideClick';
import useToastNotification from 'hooks/useToastNotification';
import { useGetFeedbackSubjectsList, useSendFeedback } from 'hooks/api/useDashboard';
import image from 'assets/images/dashboard/feedback.png';
import Icon, { ICON_NAMES } from 'components/Icon/index';
import Select from 'components/Select/Select';

const acceptFileConfig = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/png',
  'image/jpeg',
  'application/xml',
  'text/plain',
  'text/csv',
  'image/svg+xml',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
].join(',');

const makeFormData = (data) => {
  const formData = new FormData();

  for (let key in data) {
    if (Array.isArray(data[key])) {
      data[key].forEach((element) => formData.append(`${key}[]`, element));
    } else {
      if (key === 'profile') {
        formData.append(key, JSON.stringify(data[key]));
      } else {
        formData.append(key, data[key]);
      }
    }
  }
  return formData;
};

const preventSelectOptionFromFlipCard = (event) => event.stopPropagation();

const Feedback = memo(({ userEmail = '' }) => {
  const queryClient = useQueryClient();
  const profileData = queryClient.getQueryData('user');
  const profile = profileData?.data?.profile?.profile;

  const { data } = useGetFeedbackSubjectsList();
  const subjectsList = data?.subjects?.map((subject) => ({ label: subject.name, value: subject.id })) ?? [];

  const { mutate: sendFeedback, isLoading } = useSendFeedback();

  const { t } = useTranslation();
  const { notify, notifyStatus } = useToastNotification();

  const [isFlip, setIsFlip] = useState(false);

  const ref = useRef();
  useOutsideClick(ref, () => {
    if (isFlip) setIsFlip(false);
  });

  const initialValues = {
    profile,
    email: userEmail,
    subject: null,
    message: '',
    attachments: [],
    isAnonymously: false,
  };

  const onSubmit = useCallback(
    (values, form) => {
      if (values['message'].length > 400 || values['message'].length < 30) return;

      const isAnonymously = values.isAnonymously;
      const preparedToFormate = { ...values };
      preparedToFormate.subject_id = values.subject.value;

      if (isAnonymously) {
        preparedToFormate.email = null;
      }

      delete preparedToFormate.isAnonymously;
      delete preparedToFormate.subject;

      const formData = makeFormData(preparedToFormate);

      sendFeedback(formData, {
        onSuccess: () => {
          notify(t('info.message_sent_success'), notifyStatus.success);
          form.change('subject', null);
          form.change('message', '');
          form.change('attachments', []);
          form.change('isAnonymously', false);
          form.restart();
          setIsFlip(false);
        },
        onError: () => {
          notify(t('info.message_sent_error'), notifyStatus.error);
        },
      });
    },
    [notifyStatus.success, notifyStatus.error, sendFeedback, t, notify]
  );

  return (
    <div ref={ref} className={classNames('info-blocks__item feedback flip-block', { flip: isFlip })}>
      <div className="front">
        <div className="front__title title">
          <h3 style={{ width: 110 }}>{t('label.header_menu_callback')}</h3>
        </div>
        <img className="front__img" src={image} width="160" height="192" alt="" />
        <div className="bottom-block">
          <p>{t('info.mail_us_if_you_have_any_questions')}</p>
          <button type="button" className="btn btn_bg-col-white btn-flip" onClick={() => setIsFlip(true)}>
            {t('action.write_to_your_sales_manager')}
          </button>
        </div>
      </div>
      <Form
        keepDirtyOnReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={(values) => {
          const errors = {};

          if (!values.subject) errors.subject = t('errors.required_field');
          if (!values.message) errors.message = t('errors.required_field');
          if (values.message?.length < 30) errors.message = t('errors.min_length', { number: 30 });
          return errors;
        }}
        render={({ handleSubmit, values, form }) => {
          const setFieldValue = form.change;

          const fileName = values.attachments[0]
            ? `${values?.attachments[0]?.name} (${Math.round(values?.attachments[0]?.size / 1024)} KB)`
            : '';

          const messageLength = values['message']?.length || 0;

          return (
            <div className="message-block back">
              <form onSubmit={handleSubmit}>
                <div className="field-wrap" style={{ position: 'relative' }} onClick={preventSelectOptionFromFlipCard}>
                  <Field name="subject">
                    {({ meta }) => (
                      <Fragment>
                        <Select
                          placeholder={t('label.theme')}
                          options={subjectsList}
                          value={values['subject']}
                          onSelect={(value) => setFieldValue('subject', value)}
                          containerStyles={{ backgroundColor: '#e6ebf2' }}
                        />
                        {meta.error && meta.touched && (
                          <span className="form-field_error" style={{ bottom: -20 }}>
                            {meta.error}
                          </span>
                        )}
                      </Fragment>
                    )}
                  </Field>
                </div>

                <Field name="message">
                  {({ input, meta }) => (
                    <Fragment>
                      <div className="field-wrap" style={{ position: 'relative' }}>
                        <textarea {...input} placeholder={t('label.message_placeholder_label')} style={{ height: '100%' }} />
                        <span
                          className={classNames('form-field_char-count', { 'form-field_char-count__filled': messageLength > 400 })}
                          style={{ bottom: -20 }}
                        >
                          {messageLength}/400
                        </span>
                        {meta.error && meta.touched && (
                          <span className="form-field_error" style={{ bottom: -20 }}>
                            {meta.error}
                          </span>
                        )}
                      </div>
                    </Fragment>
                  )}
                </Field>

                <div className="message-block__bottom-block">
                  <div className="wrap">
                    {values['attachments'].length > 0 ? (
                      <div className="file-wrap">
                        <button
                          type="button"
                          className="delete-file"
                          onClick={(event) => {
                            preventSelectOptionFromFlipCard(event);
                            setFieldValue('attachments', []);
                          }}
                        >
                          <span />
                        </button>
                        <span className="file-wrap__name">{fileName}</span>
                      </div>
                    ) : (
                      <label className="uploading-file link link_col-blue">
                        <input
                          type="file"
                          accept={acceptFileConfig}
                          onChange={(event) => setFieldValue('attachments', [event.target.files[0]])}
                        />
                        <p>
                          <Icon name={ICON_NAMES.clip} />
                          {t('label.attach_file')}
                        </p>
                      </label>
                    )}

                    <label className="checkbox-block">
                      <input
                        type="checkbox"
                        checked={values['isAnonymously']}
                        onChange={() => setFieldValue('isAnonymously', !values['isAnonymously'])}
                      />
                      {t('label.anonymously')}
                    </label>
                  </div>
                  <button type="submit" className="btn btn-flip-back" disabled={isLoading}>
                    {t('action.write_to_your_sales_manager')}
                  </button>
                </div>
              </form>
            </div>
          );
        }}
      />
    </div>
  );
});

export default Feedback;
