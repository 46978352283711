import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

function Popup(props) {
  const { isVisible, onConfirm, onReject } = props;

  const { t } = useTranslation();

  return (
    <div className={classNames('confirm-content', { show: isVisible })}>
      <p className="confirm-content__text">{t('label.are_you_sure')}?</p>

      <div className="confirm-content__btns">
        <button type="button" onClick={onReject}>
          {t('label.no')}
        </button>

        <button type="button" className="btn" onClick={onConfirm}>
          {t('label.yes')}
        </button>
      </div>
    </div>
  );
}

export default Popup;
