import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useApi } from 'services/api';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { productKeys } from 'services/queryClient/queryKeys';
import logger from 'services/logger';

export const useGetProducts = (params) => {
  const { products } = useApi();
  const windowDimension = useWindowDimensions();

  return useQuery(productKeys.products(params), () => products.getProducts(params), {
    enabled: windowDimension.width <= 960 ? false : true,
    select: (res) => {
      const { products, category, category_label, label, name, catalog } = res.data;
      const { data, lastPage: pageTotal, total } = products;
      return {
        catalogueTitle: catalog,
        products: data,
        pageTotal,
        total,
        catalogue: {
          categoryName: category,
          categoryLabel: category_label,
          name,
          label,
        },
      };
    },
    onError: (error) => logger(error),
    keepPreviousData: windowDimension.width <= 960 ? true : false,
    cacheTime: 0,
    staleTime: 0,
  });
};

export const useGetProductsByIds = (params) => {
  const { products } = useApi();

  return useQuery(productKeys.products(params), () => products.getProductsByIds(params), {
    enabled: false,
    select: (res) => res.data.products,
    onError: (error) => logger(error),
    keepPreviousData: false,
    cacheTime: 0,
    staleTime: 0,
  });
};

export const useGetFilters = (params) => {
  const { products } = useApi();
  const windowDimension = useWindowDimensions();

  return useQuery(productKeys.filters(params), () => products.getFilters(params), {
    enabled: windowDimension.width <= 960 ? false : true,
    select: (res) => {
      return res.data;
    },
    onError: (error) => logger(error),
    keepPreviousData: true,
  });
};

export const useGetProductDetails = (id) => {
  const { products } = useApi();

  return useQuery(productKeys.product(id), () => products.getProductDetails(id), {
    select: (res) => res?.data?.product,
    onError: (error) => logger(error),
    keepPreviousData: true,
  });
};

export const useGetFavoritesTotal = () => {
  const { products } = useApi();
  return useQuery(productKeys.favoritesTotal(), () => products.getFavoritesInfo(), {
    select: (res) => res?.data?.count,
    onError: (error) => logger(error),
  });
};

export const useGetFavorites = (params) => {
  const { products } = useApi();

  return useQuery(productKeys.products(params), () => products.getFavorites(params), {
    select: (res) => {
      const { products } = res.data;
      const { data, lastPage: pageTotal, total } = products;

      return {
        products: data,
        pageTotal,
        total,
      };
    },
    onError: (error) => logger(error),
    keepPreviousData: false,
    cacheTime: 0,
    staleTime: 0,
  });
};

export const useAddToFavorites = (queryKey) => {
  const queryClient = useQueryClient();
  const { products } = useApi();

  return useMutation(products.addToFavorites, {
    retry: false,
    onMutate: async (product) => {
      await queryClient.cancelQueries(queryKey);
      const { item_id, mfrId, article } = product;

      const previous = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (data) => {
        const products = data.data.products;
        const productSingle = data.data.product;

        const mutate = (array) => {
          return array.map((item) => {
            if (item_id) {
              if (item.item_id !== item_id) return item;
              return { ...item, is_favorite: !item.is_favorite };
            } else {
              if (item.mfrId !== mfrId || item.article !== article) return item;
              return { ...item, is_favorite: !item.is_favorite };
            }
          });
        };

        if (Boolean(productSingle)) {
          data.data.product.is_favorite = !productSingle.is_favorite;
          return data;
        }

        if (Array.isArray(products)) {
          data.data.products = mutate(products);
          return data;
        }

        if (!Array.isArray(products)) {
          const objKeys = Object.keys(products);

          if (objKeys.includes('data')) {
            data.data.products.data = mutate(products.data);
            return data;
          }

          if (
            objKeys.includes('main') &&
            objKeys.includes('other_analogs') &&
            objKeys.includes('td_analogs') &&
            objKeys.includes('td_oe')
          ) {
            data.data.products.main = mutate(products.main);
            data.data.products.other_analogs = mutate(products.other_analogs);
            data.data.products.td_analogs = mutate(products.td_analogs);
            data.data.products.td_oe = mutate(products.td_oe);
            return data;
          }
        }
      });
      return { previous };
    },
    onError: (error, newFavorites, context) => {
      logger(error);
      queryClient.setQueryData(queryKey, context.previous);
      queryClient.invalidateQueries(queryKey);
    },
  });
};

export const useDeleteFromFavorites = (queryKey, isFavoritesPage) => {
  const queryClient = useQueryClient();
  const { products } = useApi();

  return useMutation(products.deleteFromFavorites, {
    retry: false,
    onMutate: async (product) => {
      await queryClient.cancelQueries(queryKey);
      const { item_id, mfrId, article } = product;
      const previous = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (data) => {
        const products = data.data.products;
        const productSingle = data.data.product;

        const mutate = (array) => {
          return array.map((item) => {
            if (item_id) {
              if (item.item_id !== item_id || mfrId !== item.mfrId) return item;
              return { ...item, is_favorite: !item.is_favorite };
            } else {
              if (item.mfrId !== mfrId || item.article !== article) return item;
              return { ...item, is_favorite: !item.is_favorite };
            }
          });
        };

        if (Boolean(isFavoritesPage)) {
          data.data.products.data = data.data.products.data.filter((item) => item.item_id !== item_id || mfrId !== item.mfrId);
          data.data.products.total = Number(data.data.products.total) - 1;
          return data;
        }
        if (Boolean(productSingle)) {
          data.data.product.is_favorite = !productSingle.is_favorite;
          return data;
        }
        if (Array.isArray(products)) {
          data.data.products = mutate(products);
          return data;
        }
        if (!Array.isArray(products)) {
          const objKeys = Object.keys(products);

          if (objKeys.includes('data')) {
            data.data.products.data = mutate(products.data);
            return data;
          }

          if (
            objKeys.includes('main') &&
            objKeys.includes('other_analogs') &&
            objKeys.includes('td_analogs') &&
            objKeys.includes('td_oe')
          ) {
            data.data.products.main = mutate(products.main);
            data.data.products.other_analogs = mutate(products.other_analogs);
            data.data.products.td_analogs = mutate(products.td_analogs);
            data.data.products.td_oe = mutate(products.td_oe);
            return data;
          }
        }
      });

      return { previous };
    },
    onError: (error, newFavorites, context) => {
      logger(error);
      queryClient.setQueryData(queryKey, context.previous);
      queryClient.invalidateQueries(queryKey);
    },
  });
};

export const useCatalogueTypes = () => {
  const { products } = useApi();

  return useQuery(productKeys.catalogueTypes(), () => products.getCatalogueTypes(), {
    select: (res) => res?.data?.productTypes,
    onError: (error) => logger(error),
    refetchOnWindowFocus: false,
  });
};

export const useGetProductPrices = () => {
  const { products } = useApi();

  return useMutation(products.getProductPrices, {
    retry: false,
    onError: (error) => logger(error),
  });
};
