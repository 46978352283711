import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import history from 'services/history';

const ToAnaloguesButton = memo(({ article, manufacturer, mfrId }) => {
  const { t } = useTranslation();

  const onClick = () => history.searchProductsResultCode(article, mfrId, manufacturer);

  return (
    <div className="analogues">
      <button type="button" className="btn btn_bg-col-gray" onClick={onClick}>
        {t('action.see_analogues')}
      </button>
    </div>
  );
});

export default ToAnaloguesButton;
