import React, { memo } from 'react';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast, Slide } from 'react-toastify';

import withAuth from 'HOC/withAuth/withAuth';
import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useCatalogueTypes } from 'hooks/api/useProducts';
import { useGetPaymentDetails } from 'hooks/api/useOtherRequests';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import Icon, { ICON_NAMES } from 'components/Icon/index';
import { GlobalPreloader } from 'components/Preloader/Preloader';

const PaymentDetailsCard = memo(({ name, data, notify, detailsText }) => {
  return (
    <>
      <div className="title">
        <h4>{name}</h4>
      </div>
      <div className="list-wrap">
        <ul className="list-details">
          {data.map((item) => (
            <li key={item?.value + item?.label}>{item?.label}:</li>
          ))}
        </ul>

        <ul className="list-details right">
          {data.map((item) => (
            <li key={item?.value + item?.label} className="list-details__item list-details__code">
              {item?.value}:
            </li>
          ))}
        </ul>

        <CopyToClipboard text={detailsText(data)} onCopy={() => notify(name)}>
          <button type="button" className="btn-copy">
            <Icon name={ICON_NAMES.copy} />
          </button>
        </CopyToClipboard>
      </div>
    </>
  );
});

function PaymentDetails() {
  const { data: catalogueTypes } = useCatalogueTypes();

  const { data, isFetching } = useGetPaymentDetails();

  const windowDimension = useWindowDimensions();
  const { t } = useTranslation();

  const normalizeLabel = (name) => {
    if (name === 'code') return t('label.header_user_profile_code');
    if (name === 'ipn') return t('label.tax_id');
    if (name === 'address') return t('label.address');
    if (name === 'mfo') return t('label.mfo');
    if (name === 'account') return t('label.stage_property');
  };
  const normalizedData = (data) =>
    Object.entries(data).map((item) => ({
      label: normalizeLabel(item[0]),
      value: item[1],
    }));

  const detailsText = (data) => data.map((item) => `${item?.label} ${item?.value} \n`).join('');

  const allPaymentsDetails = data
    ?.map((item) => ({
      name: item?.name,
      data: normalizedData(item?.data),
    }))
    .map((i) => `${i?.name} \n${detailsText(i?.data)}`)
    .join('\n');

  const notify = (name) =>
    toast(
      <>
        <div className="toast-article">{name}</div>
        <div className="toast-label">{t('products.copied')}</div>
      </>,
      {
        className: 'toast-copy-notification',
        position: 'bottom-center',
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: 0,
        transition: Slide,
        closeButton: false,
        pauseOnFocusLoss: false,
      }
    );

  return (
    <>
      {isFetching && <GlobalPreloader />}

      <div className="nav-bottom">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="title">
              <h3>{t('page_title.payment_details')}</h3>
            </div>
          </div>
          {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>
      </div>

      {data && (
        <div className="wrapper">
          <div className="details-company">
            <p className="details-company__top-text">
              {t('label.attention')}! <br />
              {t('info.payment_details')} <br />
              <span>({t('label.recipient_in_consignment_note')})</span>.
            </p>

            <div className="details-company__top-block top-block">
              <PaymentDetailsCard name={data[0]?.name} data={normalizedData(data[0]?.data)} notify={notify} detailsText={detailsText} />
            </div>

            <div className="bottom-blocks">
              {data[1] && (
                <div className="bottom-blocks__left">
                  <PaymentDetailsCard name={data[1]?.name} data={normalizedData(data[1]?.data)} notify={notify} detailsText={detailsText} />
                </div>
              )}

              {data[2] && (
                <div className="bottom-blocks__right">
                  <PaymentDetailsCard name={data[2]?.name} data={normalizedData(data[2]?.data)} notify={notify} detailsText={detailsText} />
                </div>
              )}
            </div>

            <div className="details-company__btn-wrap">
              <CopyToClipboard
                text={allPaymentsDetails}
                onCopy={() => {
                  notify(t('page_title.payment_details'));
                }}
              >
                <button type="button" className="btn">
                  {t('action.copy_add_data')}
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default compose(withPageLayout(), withAuth())(PaymentDetails);
