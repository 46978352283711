import React, { memo, Fragment, useEffect, createContext } from 'react';
import { toast, Slide } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { useApi } from 'services/api';

const ErrorContext = createContext(null);

const ErrorComponent = memo(({ error }) => <div className="toast-article">{error?.message}</div>);

const withHandleError = () => (Component) => {
  return (props) => {
    const { errors } = useApi();

    useEffect(() => {
      errors?.forEach((error) =>
        toast(<ErrorComponent error={error} />, {
          className: 'toast-error-notification',
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: 0,
          transition: Slide,
          toastId: error.message || Math.random(),
          closeButton: false,
          pauseOnFocusLoss: false,
        })
      );
    }, [errors]);

    return (
      <Fragment>
        <Component {...props} />

        <ToastContainer newestOnTop={true} />
      </Fragment>
    );
  };
};

export { withHandleError as default, ErrorContext };
