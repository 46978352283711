import React, { memo } from 'react';
import classNames from 'classnames';
import { imgUrlToResizeUrl } from 'utils/imgUrlToResizeUrl';

const BrandImage = memo(({ manufacturer, hasLabel, isColumn, logo }) => {
  const manufacturerImageUrl = '/brands/' + manufacturer?.replace(' ', '').toLowerCase() + '.png';
  const source = Boolean(logo) ? logo : imgUrlToResizeUrl(manufacturerImageUrl, 80, 80, 'resize');

  return (
    <div className={classNames('brand', { column: isColumn })}>
      <img src={source} className="brand__img" width="60" alt="" />
      {hasLabel && <p className="brand__text">{manufacturer}</p>}
    </div>
  );
});

export default BrandImage;
