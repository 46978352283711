function sortProducts(sort_by, direction, data) {
  function compare(a, b, direction) {
    if (!a) {
      return 1;
    }
    if (!b) {
      return -1;
    }
    if (a > b) {
      return direction === 'asc' ? 1 : -1;
    }
    if (a < b) {
      return direction === 'asc' ? -1 : 1;
    }
    return 0;
  }

  return data.sort((a, b) => {
    const current = isNaN(a[sort_by]) ? a[sort_by] : parseFloat(a[sort_by]);
    const next = isNaN(b[sort_by]) ? b[sort_by] : parseFloat(b[sort_by]);
    return compare(current, next, direction);
  });
}

export default sortProducts;
