import React, { memo } from 'react';
import classNames from 'classnames';
import useDebounceOpenMenu from 'hooks/useDebounceOpenMenu';
import { normalizeQuantity } from './utils';
import { StorageInfoMenu } from './StorageInfoMenu';

const Storage = memo((props) => {
  const { options, quantity, isHidden, isIncome, enteredElementsValues } = props;

  const { isOpen, handleOpen, mouseEnter, mouseLeave } = useDebounceOpenMenu();

  return (
    <div className="product-count-wrap" onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onClick={handleOpen}>
      <span
        className={classNames('number-product__product-count product-count', {
          'product-count_bg-col-sunglow': isIncome,
          'product-count_bg-col-solitude': (quantity === 0 || isHidden) && !isIncome,
        })}
      >
        {normalizeQuantity(quantity)}
      </span>

      {isOpen && (
        <div className="storages-details" onMouseEnter={handleOpen} onMouseLeave={mouseLeave}>
          <StorageInfoMenu options={options} enteredElementsValues={enteredElementsValues} />
        </div>
      )}
    </div>
  );
});

export { Storage };
