import React, { useCallback, useState, memo, useEffect } from 'react';
import { compose } from 'redux';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import withAuth from 'HOC/withAuth/withAuth';
import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import { useUpdatePassword } from 'hooks/api/useAuth';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useCatalogueTypes } from 'hooks/api/useProducts';
import { useGetProfile } from 'hooks/api/useProfile';
import { useUpdateSettings } from 'hooks/api/useSettings';
import useToastNotification from 'hooks/useToastNotification';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import Select from 'components/Select/Select';
import PaymentFooter from 'components/PaymentFooter/PaymentFooter';
import { GlobalPreloader } from 'components/Preloader/Preloader';
import { languageOptionsLong } from 'config/constants/selectOptions';
import { priceOptions } from 'config/constants/priceOptions';
import { getArrearsData, getBalanceData } from 'utils/getArrearsData';
import { imgUrlToResizeUrl } from 'utils/imgUrlToResizeUrl';

const InfoField = memo(({ label, value }) => (
  <div className="fields-list__item field">
    <p>{label}</p>
    <div className="field__input-wrap">
      <input type="text" defaultValue={value} disabled />
    </div>
  </div>
));

function Profile() {
  const { data: user, isFetching: isFetchingUser } = useGetProfile();
  const { data: catalogueTypes } = useCatalogueTypes();

  const profile = user?.profile;
  const paymentDetails = user?.payment_details;
  const warehouses = user?.warehouses?.filter((item) => item.is_real);

  const arrears = getArrearsData(user?.debt);

  const balanceCash = getBalanceData(user?.balanceFull.cash);
  const balanceCashless = getBalanceData(user?.balanceFull.cashless);

  const { t } = useTranslation();
  const { notify, notifyStatus } = useToastNotification();

  const { mutate: updatePassword, isSuccess } = useUpdatePassword();

  const onSubmit = useCallback(
    (event, { reset }) => {
      if (event.passwordRepeat !== event.password) return;

      updatePassword({ password: event.password });
      reset();
    },
    [updatePassword]
  );

  const windowDimension = useWindowDimensions();

  const { mutate: updateSettings } = useUpdateSettings();
  const onMutate = (value) => updateSettings(value);

  const [isExtraPrice, setIsExtraPrice] = useState(false);
  const [extraPriceValue, setExtraPriceValue] = useState(user?.settings?.extra);
  const onSelectPrice = (value) => {
    if (value.value !== 'extra') {
      setIsExtraPrice(false);
    }
    if (value.value === 'extra') {
      setIsExtraPrice(true);
      return;
    }
    if (value.value === user?.settings?.display_price) {
      return;
    }
    updateSettings({ display_price: value.value });
  };

  useEffect(() => {
    if (user?.settings?.display_price === 'extra') {
      setIsExtraPrice(true);
      setExtraPriceValue(user?.settings?.extra);
    } else {
      setIsExtraPrice(false);
      setExtraPriceValue('');
    }
  }, [user]);

  useEffect(() => {
    if (isSuccess) {
      notify(t('info.password_is_updated'), notifyStatus.success);
    }
  }, [isSuccess]);

  return (
    <>
      {isFetchingUser && <GlobalPreloader />}

      <div className="nav-bottom">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="title">
              <h3>{t('page_title.client_profile')}</h3>
            </div>
          </div>

          {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>
      </div>

      {user && (
        <div className="wrapper">
          <div className="profile container">
            <div className="profile__fields-wrap fields-wrap">
              <div className="fields-wrap__fields-list fields-list">
                <InfoField label={t('label.your_initials')} value={profile?.manager_name} />
                <InfoField label={t('label.email')} value={user?.email} />
                <InfoField
                  label={t('label.mobile_phone')}
                  value={
                    (user?.profile?.phone ? '+' + user?.profile?.phone : undefined) ?? (user?.phone ? '+' + user?.phone : undefined) ?? ''
                  }
                />
                <InfoField label={t('label.name_of_organization')} value={profile?.name} />
              </div>

              <div className="fields-list">
                <InfoField label={t('label.region')} value={profile?.region_name} />
                <InfoField label={t('label.locality')} value={profile?.city_name} />
                <InfoField label={t('label.tax_id')} value={profile?.ipn} />
                <InfoField label={t('label.register_of_enterprises')} value={profile?.zkpo} />
              </div>
            </div>

            <div className="profile__fields-wrap fields-wrap">
              <Form
                onSubmit={onSubmit}
                validate={(values) => {
                  const errors = {};
                  if (!values.password) errors.password = t('errors.required_field');
                  if (!values.passwordRepeat) errors.passwordRepete = t('errors.required_field');
                  if (values.password && values.passwordRepeat && values.password !== values.passwordRepeat)
                    errors.passwordRepeat = t('errors.passwords_must_match');
                  return errors;
                }}
                render={({ handleSubmit, values, form, submitSucceeded }) => {
                  return (
                    <form onSubmit={handleSubmit} className="fields-wrap__fields-list fields-list fields-list__bg-white">
                      <Field name="password">
                        {({ input, meta }) => (
                          <div className="fields-list__item field">
                            <p>{t('label.password')}</p>
                            <div className="field__input-wrap">
                              <input {...input} type="password" placeholder={t('label.enter_new_password_to_change_old_one')} />
                              {meta.error && meta.touched && <span className="form-field_error">{meta.error}</span>}
                            </div>
                          </div>
                        )}
                      </Field>

                      <Field name="passwordRepeat" validate={(value) => (value ? null : t('errors.required_field'))}>
                        {({ input, meta }) => (
                          <div className="fields-list__item field">
                            <p>{t('label.password_one_more_time')}</p>
                            <div className="field__input-wrap">
                              <input {...input} type="password" placeholder={t('label.repeat_new_password')} />
                              {meta.error && meta.touched && <span className="form-field_error">{meta.error}</span>}
                            </div>
                          </div>
                        )}
                      </Field>
                      <div className="fields-list__btn-wrap">
                        <button type="submit" className="btn">
                          {t('action.save')}
                        </button>
                      </div>
                    </form>
                  );
                }}
              />

              <div className="fields-list">
                <div className="fields-list__item field">
                  <p>{t('label.price_display')}</p>

                  <Select
                    options={priceOptions()}
                    value={priceOptions()?.find((i) => {
                      if (isExtraPrice) return i.value === 'extra';
                      return i.value === user?.settings?.display_price;
                    })}
                    onSelect={onSelectPrice}
                    containerStyles={{
                      maxWidth: '325px',
                      width: '100%',
                      backgroundColor: ' #fff',
                      marginLeft: '20px',
                    }}
                  />
                </div>

                {isExtraPrice && (
                  <div className="fields-list__item field">
                    <p></p>
                    <div className="field__input-wrap markup-percentage">
                      <input
                        type="number"
                        defaultValue={extraPriceValue}
                        placeholder={t('label.markup_percentage')}
                        onChange={(event) => {
                          if (!event.target.value) setExtraPriceValue(0);
                          else setExtraPriceValue(Number(event.target.value));
                        }}
                      />
                      <button
                        type="button"
                        className="btn"
                        onClick={() => {
                          updateSettings({
                            display_price: 'extra',
                            extra: extraPriceValue,
                          });
                        }}
                      >
                        {t('action.ok')}
                      </button>
                    </div>
                  </div>
                )}

                <div className="fields-list__item field">
                  <p>{t('label.default_warehouse')}</p>
                  <Select
                    options={warehouses}
                    value={warehouses?.find((i) => i.value === user?.settings?.default_warehouse)}
                    onSelect={(data) => onMutate({ default_warehouse: data.value })}
                    containerStyles={{
                      maxWidth: '325px',
                      width: '100%',
                      backgroundColor: ' #fff',
                      marginLeft: '20px',
                    }}
                  />
                </div>

                <div className="fields-list__item field">
                  <p>{t('label.system_language')}</p>
                  <Select
                    options={languageOptionsLong()}
                    value={languageOptionsLong().find((i) => i.value === user?.settings?.locale)}
                    onSelect={(data) => onMutate({ locale: data.value })}
                    containerStyles={{
                      maxWidth: '325px',
                      width: '100%',
                      backgroundColor: ' #fff',
                      marginLeft: '20px',
                    }}
                  />
                </div>
              </div>
            </div>

            <PaymentFooter
              arrears={arrears}
              url={paymentDetails?.url}
              qrImage={imgUrlToResizeUrl(paymentDetails?.qr, 120, 120, 'resize')}
              image={imgUrlToResizeUrl(paymentDetails?.image, 143, 80)}
              paymentInfoList={paymentDetails?.list}
              balanceCash={balanceCash}
              balanceCashless={balanceCashless}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default compose(withPageLayout(), withAuth())(Profile);
