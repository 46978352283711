const findChildren = (el, list) => {
  const node = { ...el };
  node.children = list.filter((i) => i.parentNodeId == node.assemblyGroupNodeId) || [];

  if (node.children.length) {
    node.children = node.children.map((el) => {
      return findChildren(el, list);
    });
  }
  return node;
};

const findActiveNodes = (array, active) => {
  const stairs = [];
  if (!array) return;

  const findActive = (el, active) => {
    if (!active) return;
    const isActiveElement = el.assemblyGroupNodeId == active;

    if (isActiveElement) {
      stairs.push({ nodeID: el.assemblyGroupNodeId, nodeName: el.assemblyGroupName });
    }
    if (isActiveElement && el.parentNodeId) {
      array.forEach((item) => findActive(item, el.parentNodeId));
    }
  };

  array.forEach((item) => findActive(item, active));

  return stairs;
};

const favoritesNodesToTop = (nodes) => {
  if (!nodes) return [];

  const favoriteNodes = nodes.filter((node) => node.isFavorite);
  const notFavoriteNodes = nodes.filter((node) => !node.isFavorite);

  return [...favoriteNodes, ...notFavoriteNodes];
};

const findParents = (list, initialList) => {
  const array = [];

  const getParent = (el) => {
    initialList.forEach((item) => {
      if (item.assemblyGroupNodeId === el.parentNodeId) {
        array.push({
          ...item,
          isOpened: true,
        });
        if (item.parentNodeId) {
          getParent(item);
        }
      }
    });
  };

  list.forEach(getParent);

  return Object.values(
    array.reduce((acc, cur) => {
      if (!acc[cur.assemblyGroupNodeId]) {
        acc[cur.assemblyGroupNodeId] = cur;
      } else {
        if (cur.isOpened) {
          acc[cur.assemblyGroupNodeId].isOpened = true;
        }
      }
      return acc;
    }, {})
  );
};

const findChild = (list, initialList) => {
  const array = [];

  const getChildren = (el) => {
    initialList.forEach((item) => {
      if (item.parentNodeId === el.assemblyGroupNodeId) {
        array.push({
          ...item,
          isOpened: true,
        });
        if (item.children) {
          getChildren(item);
        }
      }
    });
  };

  list.forEach(getChildren);

  return Object.values(
    array.reduce((acc, cur) => {
      if (!acc[cur.assemblyGroupNodeId]) {
        acc[cur.assemblyGroupNodeId] = cur;
      } else {
        if (!cur.isOpened) {
          acc[cur.assemblyGroupNodeId].isOpened = false;
        }
      }
      return acc;
    }, {})
  );
};

export { findChildren, findActiveNodes, favoritesNodesToTop, findChild, findParents };
