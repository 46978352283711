import React, { useContext, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { compose } from 'redux';

import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import { CommonDataContext } from 'HOC/withCommonData/withCommonData';
import withAuth from 'HOC/withAuth/withAuth';
import { GlobalPreloader } from 'components/Preloader/Preloader';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import ViewSwitch from 'components/Navigation/ViewSwitch/ViewSwitch';
import Products from 'components/Products/Products';
import Sort from 'components/Sort/Sort';
import TecdocStatus from 'components/TecdocStatus/TecdocStatus';

import { useCatalogueTypes } from 'hooks/api/useProducts';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useGetSearchProductsByName } from 'hooks/api/useSearch';
import useSorting from 'hooks/useSorting';
import { searchKeys } from 'services/queryClient/queryKeys';
import { productTypes } from 'config/constants/productTypes';
import { sortOptions as selectSortOptions } from 'config/constants/selectOptions';
import googleAnalytics from 'services/googleAnalytics';

function SearchResultName() {
  const { data: catalogueTypes } = useCatalogueTypes();
  const { productViewType } = useContext(CommonDataContext);

  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  const { search: locationSearch, pathname } = useLocation();
  const { search } = queryString.parse(locationSearch);

  const { data, isFetching, isSuccess } = useGetSearchProductsByName(search);
  const { list: products, sortOptions, sort } = useSorting(data?.products);

  const isTecdocOnline = useMemo(() => {
    const checkOffline = (data) => data && data.online === false;

    if (checkOffline(data)) return false;
    return true;
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      googleAnalytics.onSearchPageView(pathname + search);
      googleAnalytics.onSearchByCodeOrName(window.location.href, pathname);
    }
  }, [isSuccess, pathname, search]);

  return (
    <>
      {isFetching && <GlobalPreloader />}

      <div className="nav-bottom catalog-cart">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="nav-bottom__result-wrap nav-bottom__result-wrap_wide">
              <div className="title">
                <h3>{t('page_title.search_result_by_name')}</h3>
              </div>
              <span className="nav-bottom__result">{search}</span>
            </div>

            {productViewType === 'card' && <Sort largeScreen sortOptions={selectSortOptions()} onSort={sort} />}
          </div>

          {width >= 1280 && products?.length > 0 && <ViewSwitch />}

          {width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>
      </div>

      {!products ? null : products.length === 0 ? (
        <div className="no-results">
          <p>{t('label.no_search_results')}</p>
        </div>
      ) : (
        products?.length > 0 && (
          <div className="catalog-wrap">
            <Products
              products={products}
              queryKey={searchKeys.searchProductsName(search)}
              productType={productTypes['common']}
              sortOptions={sortOptions}
              onSort={sort}
              hasSortButtons
            />
          </div>
        )
      )}

      {!isTecdocOnline && <TecdocStatus offline />}
    </>
  );
}

export default compose(withPageLayout(), withAuth())(SearchResultName);
