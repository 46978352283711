import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import VendorCode from 'components/VendorCode/VendorCode';
import ProductImage from 'components/ProductImage/ProductImage';
import ProductName from 'components/ProductName/ProductName';
import { formatPrice } from 'utils/formatPrice';

const OrderItem = memo((props) => {
  const { id, article, name, price, sum, count, images, index } = props;

  const { t } = useTranslation();

  const isDisabledLink = !id;

  return (
    <div className="cnt-row">
      <div className="column numbering">{index}</div>

      <ProductImage id={id} images={images} width={60} isList height={60} disabledLink={isDisabledLink} />

      <VendorCode link={`/product-details/${id}`} article={article} disabledLink={isDisabledLink} isColumn />

      <ProductName id={id} name={name} isColumn disabledLink={isDisabledLink} />

      <div className="column num-prod">
        <strong>{t('label.count')}:</strong>
        <span>{count}</span>
      </div>

      <div className="column product-price">
        <strong className="amount">{formatPrice(price)}</strong>
        <span className="currency">{t('label.currency')}</span>
      </div>

      <div className="column total-amount">
        <strong className="amount">{formatPrice(sum)}</strong>
        <span className="currency">{t('label.currency')}</span>
      </div>
    </div>
  );
});

export default OrderItem;
