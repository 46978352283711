import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function SubCategories({ categories, catalogueName }) {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="filter__title">
        <span>{t('filter.category')}</span>
      </div>

      <ul className="categories-list">
        {categories.map((category) => {
          return (
            <li key={category.name}>
              <NavLink to={`/catalogue/${catalogueName}/${category.name}`} className="link link_col-blue" activeClassName="selected">
                {category.label}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </Fragment>
  );
}

export default SubCategories;
