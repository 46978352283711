import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { compose } from 'redux';

import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import { CommonDataContext } from 'HOC/withCommonData/withCommonData';
import withAuth from 'HOC/withAuth/withAuth';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useCatalogueTypes } from 'hooks/api/useProducts';
import PagePagination from 'components/PagePagination/PagePagination';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import { GlobalPreloader } from 'components/Preloader/Preloader';
import { paginationOptions } from 'config/constants/selectOptions';
import { useGetLoyaltyPrograms } from 'hooks/api/useLoyalty';
import LoyaltyItem from './LoyaltyItem';

function LoyaltyPrograms() {
  const { data: catalogueTypes } = useCatalogueTypes();

  const { productsPerPage, setProductsPerPage } = useContext(CommonDataContext);

  const location = useLocation();
  const page = Number(queryString.parse(location.search).page) || 1;

  const { data, isFetching } = useGetLoyaltyPrograms({
    page,
    per_page: productsPerPage.value,
  });

  const windowDimension = useWindowDimensions();
  const { t } = useTranslation();

  return (
    <>
      {isFetching && <GlobalPreloader />}

      <div className="nav-bottom">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="title">
              <h3>{t('label.loyalty_program')}</h3>
            </div>
          </div>

          {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>
      </div>

      <div className="wrapper">
        {data && (
          <>
            <section className="loyalty-program">
              <div className="titles-row loyalty-columns">
                <div className="col-title">{t('label.sales_name')}</div>
                <div className="col-title">{t('label.сurrent_cashback')}</div>
                <div className="col-title">{t('label.date_holding')}</div>
                <div className="col-title">{t('label.description')}</div>
                <div className="col-title"></div>
              </div>

              {data?.data?.map((item) => (
                <LoyaltyItem
                  key={item?.id}
                  accumulatedPoints={item.accumulated_points}
                  startDate={item.starts_in}
                  endDate={item.expires_in}
                  name={item.data?.title}
                  description={item.data?.short_description}
                  slug={item.seo_slug}
                  isUnread={!item.is_read}
                />
              ))}
            </section>
            {data?.data?.length !== 0 && (
              <PagePagination
                pageCount={data?.lastPage}
                page={page}
                paginationOptions={paginationOptions()}
                itemsTotal={data?.total}
                itemsOnPage={data?.data?.length}
                itemsPerPage={productsPerPage}
                setItemsPerPage={setProductsPerPage}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default compose(withPageLayout(), withAuth())(LoyaltyPrograms);
