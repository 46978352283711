import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      staleTime: 3000,
      retry: false,
      refetchOnWindowFocus: process.env.REACT_APP_ENV === 'production' ? 'always' : false,
    },
  },
});
