import React, { useState, useRef } from 'react';
import classNames from 'classnames';

import Icon, { ICON_NAMES } from 'components/Icon/index';
import useOutsideClick from 'hooks/useOutsideClick';

function ProductInfoTecDoc(props) {
  const { description, list, isColumn } = props;

  const [isInfoOpen, setIsInfoOpen] = useState(false);

  const openInfo = () => setIsInfoOpen(true);
  const closeInfo = () => setIsInfoOpen(false);

  const ref = useRef();
  useOutsideClick(ref, () => {
    if (isInfoOpen) closeInfo();
  });

  return (
    <div ref={ref} className={classNames('product-info product-info_tecdoc', { column: isColumn })} onMouseLeave={closeInfo}>
      {description && (
        <>
          <div className="btn-inf" onClick={openInfo}>
            {isInfoOpen ? <Icon name={ICON_NAMES.info} onEnter /> : <Icon name={ICON_NAMES.info} onLeave />}
          </div>

          {isInfoOpen && list && (
            <div className="cat-grid-l-fil description-list" onMouseEnter={openInfo} onMouseLeave={closeInfo}>
              <div className="description-list-about">{description}</div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ProductInfoTecDoc;
