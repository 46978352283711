import { useEffect, useCallback, useMemo } from 'react';

const useScrollTo = (deps, x = 0, y = 0, behavior = 'smooth') => {
  const options = useMemo(() => {
    return {
      top: y,
      left: x,
      behavior: behavior,
    };
  }, [x, y, behavior]);

  const onScrollTo = useCallback(() => window.scrollTo(options), [options]);

  useEffect(() => {
    if (deps) {
      setTimeout(() => onScrollTo(), 0);
    }
  }, [deps, onScrollTo]);

  return onScrollTo;
};

export { useScrollTo };
