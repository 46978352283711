import moment from 'moment';
import i18n from 'services/i18n/i18n';
import { formatPrice } from 'utils/formatPrice';
import { calcBusinessDays } from './datesUtils';

export const getArrearsData = (data) => {
  const debtDateLabel = moment(data?.date).format('DD.MM.YYYY');
  const debt = data && Number(data.debt);

  const businessDaysBeforeDebt = data?.date && calcBusinessDays(moment().format(), data.date);
  const isPastDate = data && data.date && moment().unix() > moment(data.date).unix();

  const noDebt = businessDaysBeforeDebt > 2 || debt <= 0;
  const yellowDebt = ([1, 2].includes(businessDaysBeforeDebt) || (businessDaysBeforeDebt === 0 && !isPastDate)) && debt > 0;
  const redDebt = businessDaysBeforeDebt === 0 && isPastDate && debt > 0;

  if (noDebt)
    return {
      overdueClassName: '',
      overdueTextClassName: '',
      overdueStatus:
        debt <= 0
          ? i18n.t('label.nothing_is_overdue')
          : debt > 0 && isPastDate
          ? i18n.t('label.overdue_as_of')
          : i18n.t('label.next_payment_to'),
      overdueStatusShort:
        debt <= 0
          ? i18n.t('label.nothing_is_overdue_short')
          : debt > 0 && isPastDate
          ? i18n.t('label.overdue_as_of_short')
          : i18n.t('label.next_payment_to_short'),
      debt: formatPrice(debt),
      debtDateLabel: debt > 0 ? debtDateLabel : '',
    };
  if (redDebt)
    return {
      overdueClassName: 'overdue__wrap_bg-col-red',
      overdueTextClassName: 'overdue__wrap_col-red',
      overdueStatus: isPastDate ? i18n.t('label.overdue_as_of') : i18n.t('label.next_payment_to'),
      overdueStatusShort: isPastDate ? i18n.t('label.overdue_as_of_short') : i18n.t('label.next_payment_to_short'),
      debt: formatPrice(debt),
      debtDateLabel,
    };
  if (yellowDebt)
    return {
      overdueClassName: 'overdue__wrap_bg-col-gamboge',
      overdueTextClassName: 'overdue__wrap_col-gamboge',
      overdueStatus: isPastDate ? i18n.t('label.overdue_as_of') : i18n.t('label.next_payment_to'),
      overdueStatusShort: isPastDate ? i18n.t('label.overdue_as_of_short') : i18n.t('label.next_payment_to_short'),
      debt: formatPrice(debt),
      debtDateLabel,
    };
};

export const getBalanceData = (balance) => {
  if (!balance && balance !== 0)
    return {
      balance: '',
      balanceClassName: 'user-balance-default',
      balanceStatus: '',
      balanceShow: false,
      isDebt: false,
    };

  if (balance === 0) {
    return {
      balance: formatPrice(balance),
      balanceClassName: 'user-balance-default',
      balanceStatus: i18n.t('label.no_debt'),
      balanceShow: false,
      isDebt: false,
    };
  }
  if (balance < 0) {
    return {
      balance: formatPrice(balance),
      balanceClassName: 'user-balance-default',
      balanceStatus: i18n.t('label.no_debt'),
      balanceShow: true,
      isDebt: false,
    };
  }
  if (balance > 0) {
    return {
      balance: formatPrice(balance),
      balanceClassName: 'user-balance-red',
      balanceStatus: i18n.t('label.your_debt_is'),
      balanceShow: true,
      isDebt: true,
    };
  }
};
