import React, { memo } from 'react';
import classNames from 'classnames';
import { Storage } from './Storage';

const Storages = memo((props) => {
  const { options, selectedStorage, isColumn } = props;

  if (!options) {
    return null;
  }

  return (
    <div className={classNames('number-product', { column: isColumn })}>
      <div className="number-product__wrapper">
        {options.map((option, index, array) => {
          if (index > 3) return null;

          const isHidden = !selectedStorage ? false : selectedStorage && selectedStorage !== option.value ? true : false;
          const isOthers = index === 3;
          const othersQuantity = array
            .filter((item, index) => index >= 3 && item.value !== 'income')
            .reduce((accumulator, currentValue) => accumulator + currentValue.count, 0);
          const incomeQuantity = array.find((item) => item.value === 'income').count;
          const hasProductsOnlyInIncome = incomeQuantity > 0 && othersQuantity === 0;

          if (isOthers) {
            return (
              <Storage
                key={index}
                isHidden={isHidden}
                enteredElementsValues={array.filter((i, pos) => pos >= 3).map((i) => i.value)}
                options={array}
                isIncome={hasProductsOnlyInIncome}
                quantity={hasProductsOnlyInIncome ? incomeQuantity : othersQuantity}
              />
            );
          }
          return <Storage key={index} enteredElementsValues={[option.value]} options={array} isHidden={isHidden} quantity={option.count} />;
        })}
      </div>
    </div>
  );
});

export default Storages;
