import React, { useMemo, useState, useEffect, Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import queryString from 'query-string';

import {
  useGetManufacturers,
  useGetModels,
  useGetVehicle,
  useGetNodes,
  useGetCarSearchHistory,
  useAddCarToHistory,
  useRemoveCarFromHistory,
  useAddToFavorites,
  useRemoveFromFavorites,
  useGetProductsByNode,
  useGetAutoAbout,
  useGetCarSearchFavorites,
} from 'hooks/api/useTecdoc';
import { useCatalogueTypes } from 'hooks/api/useProducts';

import useWindowDimensions from 'hooks/useWindowDimensions';
import useTecdocState from 'hooks/useTecdocState';
import useSorting from 'hooks/useSorting';
import { useConstantScroll } from 'hooks/useConstantScroll';
import history from 'services/history';
import { tecdocKeys } from 'services/queryClient/queryKeys';
import { validateYearsOfProductionTecdoc } from 'utils/validateYearsOfProductionTecdoc';
import { findChildren, findActiveNodes, favoritesNodesToTop, findChild, findParents } from 'utils/nodesUtils';
import { sortOptions as selectSortOptions } from 'config/constants/selectOptions';

import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import withAuth from 'HOC/withAuth/withAuth';
import ProductsCatalogue from './ProductsCatalogue';
import { GlobalPreloader } from 'components/Preloader/Preloader';
import Icon, { ICON_NAMES } from 'components/Icon/index';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import Manufacturers from './Manufacturers';
import Models from './Models';
import Modifications from './Modifications';
import Nodes from './Nodes';
import Menu, { menuTypes } from './Menu';
import ViewSwitch from 'components/Navigation/ViewSwitch/ViewSwitch';
import Sort from 'components/Sort/Sort';
import AutoAbout from './AutoAbout';
import TecdocStatus from 'components/TecdocStatus/TecdocStatus';

const TecdocSearch = () => {
  const { data: catalogueTypes } = useCatalogueTypes();

  const location = useLocation();
  const { manufacturer, model, vehicle, node, targetType } = queryString.parse(location.search);

  const { t } = useTranslation();
  const windowDimension = useWindowDimensions();

  const { state, actions } = useTecdocState();
  const { step } = state;
  const { stepBackward, stepForward, stepOnPage } = actions;

  const { data: manufacturersData, isFetching: isFetchingManufacturers } = useGetManufacturers();
  const { data: modelsData, refetch: fetchModels, isFetching: isFetchingModels } = useGetModels(manufacturer);
  const { data: vehiclesData, refetch: fetchVehicles, isFetching: isFetchingVehicle } = useGetVehicle(manufacturer, model);
  const { data: nodesData, refetch: fetchNodes, isFetching: isFetchingNodes } = useGetNodes(vehicle, targetType);
  const { data: carSearchHistory } = useGetCarSearchHistory();
  const { mutate: addCarToHistory } = useAddCarToHistory();
  const { mutate: removeCarFromHistory } = useRemoveCarFromHistory();
  const { mutate: removeFromFavorites } = useRemoveFromFavorites();
  const { mutate: addToFavorites } = useAddToFavorites();
  const { data: productsData, isFetching: isFetchingProducts, refetch: getProducts } = useGetProductsByNode(node, vehicle);
  const queryKey = tecdocKeys.productsCatalogue(node, vehicle);
  const {
    data: autoAbout,
    refetch: getAutoAbout,
    isLoading: isLoadingAutoAbout,
    isSuccess: autoAboutSuccess,
  } = useGetAutoAbout(vehicle, targetType);
  const { data: nodeFavorites } = useGetCarSearchFavorites();

  const {
    list: otherAnalogues,
    sortOptions: otherAnaloguesSortOptions,
    sort: sortOtherAnalogues,
  } = useSorting(productsData?.products?.other_analogs);
  const { list: tdAnalogues, sortOptions: tdSortOptions, sort: sortTDAnalogues } = useSorting(productsData?.products?.td_analogs);

  const isTecdocOnline = useMemo(() => {
    const checkOffline = (data) => data && data.online === false;

    if (
      checkOffline(manufacturersData) ||
      checkOffline(modelsData) ||
      checkOffline(vehiclesData) ||
      checkOffline(nodesData) ||
      checkOffline(productsData)
    ) {
      return false;
    }

    return true;
  }, [manufacturersData, modelsData, vehiclesData, nodesData, productsData]);

  const manufacturers = useMemo(() => {
    if (manufacturersData) {
      return manufacturersData.list.map((item) => ({
        ...item,
        value: item.id,
        label: item.name,
      }));
    }
    return [];
  }, [manufacturersData]);

  const selectedManufacturer = useMemo(() => {
    if (!manufacturers || manufacturers.length === 0 || !manufacturer) {
      return null;
    }
    return manufacturers.find((item) => item.id.toString() === manufacturer);
  }, [manufacturers, manufacturer]);

  const models = useMemo(() => {
    if (modelsData) {
      return modelsData.list.map((item) => ({
        ...item,
        value: item.id,
        label: `${item.name} ${validateYearsOfProductionTecdoc(item.from, item.to)}`,
      }));
    }
    return [];
  }, [modelsData]);

  const selectedModel = useMemo(() => {
    if (!models || models.length === 0 || !model) {
      return null;
    }
    return models.find((item) => item.id.toString() === model);
  }, [models, model]);

  const modifications = useMemo(() => {
    if (vehiclesData) {
      return vehiclesData.list.map((item) => ({
        ...item,
        value: item.id,
        label: `${item.name} ${item.kw} KW/${item.hp} HP`,
      }));
    }
    return [];
  }, [vehiclesData]);

  const selectedModification = useMemo(() => {
    if (!modifications || modifications.length === 0 || !vehicle) {
      return null;
    }
    return modifications.find((item) => item.id.toString() === vehicle);
  }, [modifications, vehicle]);

  const [nodeSearch, setNodeSearch] = useState('');
  const handleNodeSerach = (event) => {
    setNodeSearch(event.target.value);
  };

  let nodesWithFavorites = [];
  if (nodeFavorites && nodesData) {
    nodesWithFavorites = nodesData.list.map((nodeItem) => {
      const isFavorite = nodeFavorites.data.find((favItem) => favItem.id === nodeItem.assemblyGroupNodeId);
      if (isFavorite) {
        return { ...nodeItem, isFavorite: true };
      } else {
        return { ...nodeItem, isFavorite: false };
      }
    });
  }

  let searchedNodes = [];
  let parentNodes = [];

  if (nodeSearch) {
    let searched = nodesWithFavorites.filter((el) => el.assemblyGroupName.toLowerCase().includes(nodeSearch.toLowerCase()));

    const parents = findParents(searched, nodesWithFavorites);
    const children = findChild(searched, nodesWithFavorites);

    searchedNodes = [...searched, ...parents, ...children];

    searchedNodes = Object.values(
      searchedNodes.reduce((acc, cur) => {
        if (!acc[cur.assemblyGroupNodeId]) {
          acc[cur.assemblyGroupNodeId] = cur;
        } else {
          if (!cur.isOpened) {
            acc[cur.assemblyGroupNodeId].isOpened = false;
          }
        }
        return acc;
      }, {})
    );

    searchedNodes = searchedNodes.sort((a, b) => {
      if (a.assemblyGroupName > b.assemblyGroupName) return 1;
      if (a.assemblyGroupName < b.assemblyGroupName) return -1;
      return 0;
    });

    parentNodes = searchedNodes && searchedNodes.filter((i) => !i.parentNodeId).map((el) => findChildren(el, searchedNodes));
  } else {
    parentNodes = nodesWithFavorites && nodesWithFavorites.filter((i) => !i.parentNodeId).map((el) => findChildren(el, nodesWithFavorites));
  }

  const nodes = useMemo(() => {
    if (!nodeFavorites || !nodesData || !parentNodes) return [];

    const nodesWithFavoriteButton = parentNodes.map((nodeLevel1) => {
      if (!nodeLevel1.children) return { ...nodeLevel1, hasFavoriteButton: true };

      return {
        ...nodeLevel1,
        hasFavoriteButton: true,
        children: nodeLevel1.children.map((nodeLevel2) => ({ ...nodeLevel2, hasFavoriteButton: true })),
      };
    });

    return favoritesNodesToTop(
      nodesWithFavoriteButton.map((i) => {
        return {
          ...i,
          children: favoritesNodesToTop(i.children),
        };
      })
    );
  }, [nodeFavorites, parentNodes, nodesData]);

  const nodeStairs = useMemo(() => findActiveNodes(nodesData?.list, node), [nodesData, node]);
  const nodeStairsLabel = useMemo(() => {
    if (nodeStairs)
      return nodeStairs
        .reverse()
        .map((i) => i.nodeName)
        .join(' -> ');
  }, [nodeStairs]);

  const setManufacturer = (data) => {
    history.push({
      pathname: `/tecdoc-search`,
      search: `manufacturer=${data.id}`,
    });
    // stepOnPage(2);
  };
  const setModel = (data) => {
    history.push({
      pathname: `/tecdoc-search`,
      search: `manufacturer=${manufacturer}&model=${data.id}`,
    });
    // stepOnPage(3);
  };
  const setVehicle = (data) => {
    history.push({
      pathname: `/tecdoc-search`,
      search: `manufacturer=${manufacturer}&model=${model}&vehicle=${data.id}&targetType=${data.linkageTargetType}`,
    });
    // stepOnPage(4);
  };
  const onHistoryClick = (manufacturerId, modelId, vehicleId, targetType) => {
    history.push({
      pathname: `/tecdoc-search`,
      search: `manufacturer=${manufacturerId}&model=${modelId}&vehicle=${vehicleId}&targetType=${targetType}`,
    });
    stepOnPage(4);
  };
  const removeFromHistory = (id) => {
    history.push({ pathname: '/tecdoc-search' });
    stepOnPage(1);
    removeCarFromHistory({ vehicle_id: id });
  };
  const addToHistory = useCallback(() => {
    addCarToHistory({
      manufacturer_id: manufacturer,
      model_id: model,
      vehicle_id: vehicle,
      targetType: targetType,
      data: {
        // Additional data for Menu component
        vehicle: {
          ...selectedModification,
          label: selectedModification.label,
          value: selectedModification.value,
        },
      },
    });
  }, [manufacturer, model, vehicle, selectedModification, targetType, addCarToHistory]);
  const onNodeClick = (event, id) => {
    history.push({
      pathname: `/tecdoc-search`,
      search: `manufacturer=${manufacturer}&model=${model}&vehicle=${vehicle}&node=${id}&targetType=${targetType}`,
    });
    stepOnPage(5);
  };
  const change = () => stepOnPage(3);

  useEffect(() => {
    if (manufacturer) {
      fetchModels();
      stepOnPage(2);
    }
    if (!manufacturer) {
      stepOnPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manufacturer, fetchModels]);
  useEffect(() => {
    if (manufacturer && model) {
      fetchVehicles();
      stepOnPage(3);
    }
    if (manufacturer && !model) {
      stepOnPage(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manufacturer, model, fetchVehicles]);
  useEffect(() => {
    if (manufacturer && model && vehicle) {
      fetchNodes();
      stepOnPage(4);
    }
    if (manufacturer && model && !vehicle) {
      stepOnPage(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manufacturer, model, vehicle, fetchNodes]);
  useEffect(() => {
    if (manufacturer && model && vehicle && !node) stepOnPage(4);
    if (manufacturer && model && vehicle && node) stepOnPage(5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manufacturer, model, vehicle, node]);
  useEffect(() => {
    if (manufacturer && model && vehicle && selectedModification?.id == vehicle) addToHistory();
  }, [manufacturer, model, vehicle, selectedModification?.id, addToHistory]);
  useEffect(() => {
    if (location.search === '') stepOnPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);
  useEffect(() => {
    if (vehicle && node && step === 5) getProducts();
  }, [vehicle, node, step, getProducts]);

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = useCallback(
    (event) => {
      event.stopPropagation();
      getAutoAbout();
      setModalOpen(true);
    },
    [getAutoAbout]
  );
  const closeModal = useCallback(() => setModalOpen(false), []);

  useConstantScroll();

  return (
    <Fragment>
      {(isFetchingManufacturers ||
        isFetchingModels ||
        isFetchingVehicle ||
        isFetchingNodes ||
        isFetchingProducts ||
        isLoadingAutoAbout) && <GlobalPreloader />}

      <div className="nav-bottom catalog-cart">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="nav-bottom__find-title">
              <div className="nav-bottom__result-wrap">
                <div className="title">
                  <h3>{t('page_title.car_search')}</h3>
                </div>
              </div>
              <div className="btns-nav">
                <button
                  type="button"
                  className={classNames('back btn-square btn-square_bg-col-white', { disabled: step <= 1 })}
                  onClick={stepBackward}
                >
                  <Icon disabled name={ICON_NAMES.goBack} />
                </button>
                <button
                  type="button"
                  className={classNames('next btn-square btn-square_bg-col-white', {
                    disabled:
                      (step === 1 && !modelsData) ||
                      (step === 2 && !vehiclesData) ||
                      (step === 3 && !nodesData) ||
                      (step === 4 && !productsData) ||
                      step === 5,
                  })}
                  onClick={stepForward}
                >
                  <Icon disabled name={ICON_NAMES.goForward} />
                </button>
              </div>
            </div>

            {step === 5 && <Sort largeScreen sortOptions={selectSortOptions()} onSort={sortTDAnalogues} />}
          </div>

          {/* {windowDimension.width > 1365 && step === 5 && <ViewSwitch />} */}
          {windowDimension.width >= 1280 && step === 5 && <ViewSwitch />}

          {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>

        {windowDimension.width <= 960 && (
          <div className="nav-bottom__nav-buttons nav-buttons filter-car">
            {step === 4 || step === 5 ? (
              <Menu
                type={menuTypes.SELECTED_CAR_SM}
                selectedCarData={selectedModification}
                selectedCarActions={{ removeFromHistory, change, openModal }}
              />
            ) : (
              <Menu
                type={menuTypes.CAR_SELECT_SM}
                carSelectData={{
                  manufacturers,
                  models,
                  modifications,
                  manufacturer: selectedManufacturer,
                  model: selectedModel,
                  vehicle: selectedModification,
                }}
                carSelectActions={{ setManufacturer, setModel, setVehicle }}
              />
            )}
            <div className="nav-buttons__item_splitter" />
            <Menu type={menuTypes.HISTORY_SM} historyData={carSearchHistory?.data} historyActions={{ onHistoryClick }} />
          </div>
        )}
      </div>

      <div className="cat-fil-wrap">
        {windowDimension.width > 960 && (
          <div className="filter filter-car">
            {step <= 3 ? (
              <Menu
                type={menuTypes.CAR_SELECT}
                carSelectData={{
                  manufacturers,
                  models,
                  modifications,
                  manufacturer: selectedManufacturer,
                  model: selectedModel,
                  vehicle: selectedModification,
                }}
                carSelectActions={{ setManufacturer, setModel, setVehicle }}
              />
            ) : (
              <Menu
                type={menuTypes.SELECTED_CAR}
                selectedCarData={selectedModification}
                selectedCarActions={{ removeFromHistory, change, openModal }}
              />
            )}

            {carSearchHistory && <Menu type={menuTypes.HISTORY} historyData={carSearchHistory.data} historyActions={{ onHistoryClick }} />}
          </div>
        )}

        <div className="wrapper">
          {step === 1 && <Manufacturers manufacturers={manufacturers} manufacturer={manufacturer} setManufacturer={setManufacturer} />}
          {step === 2 && <Models models={models} model={model} setModel={setModel} />}
          {step === 3 && <Modifications modifications={modifications} vehicle={vehicle} setVehicle={setVehicle} />}
          {step === 4 && (
            <Nodes
              activeNode={Number(node)}
              vehicle={vehicle}
              nodeStairs={nodeStairs}
              nodes={nodes}
              search={nodeSearch}
              removeFromFavorites={removeFromFavorites}
              addToFavorites={addToFavorites}
              onNodeClick={onNodeClick}
              onChange={handleNodeSerach}
            />
          )}
          {step === 5 && (
            <ProductsCatalogue
              step={step}
              queryKey={queryKey}
              nodeStairsLabel={nodeStairsLabel}
              otherAnalogues={otherAnalogues}
              tdAnalogues={tdAnalogues}
              otherAnaloguesSortOptions={otherAnaloguesSortOptions}
              tdSortOptions={tdSortOptions}
              sortOtherAnalogues={sortOtherAnalogues}
              sortTDAnalogues={sortTDAnalogues}
              stepBackward={stepBackward}
            />
          )}
        </div>
      </div>

      {modalOpen && autoAboutSuccess && <AutoAbout data={autoAbout} isOpen={modalOpen} onClose={closeModal} />}

      {!isTecdocOnline && <TecdocStatus offline />}
    </Fragment>
  );
};

export default compose(withPageLayout(), withAuth())(TecdocSearch);
