import React, { useState, memo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import ProductName from 'components/ProductName/ProductName';
import VendorCode from 'components/VendorCode/VendorCode';
import BrandImage from 'components/BrandImage/BrandImage';
import Storages from 'components/Storages/Storages';
import Counter from 'components/Counter/Counter';
import ProductImage from 'components/ProductImage/ProductImage';
import Icon, { ICON_NAMES } from 'components/Icon/index';
import ProductRemoval from 'components/ProductRemoval/ProductRemoval';
import { useDeleteBasketCount } from 'hooks/api/useBasket';

const BasketProduct = memo((props) => {
  const {
    queryKey,
    isOrderApproved,
    article,
    autoliderID,
    nCompl,
    isNComplValid,
    images,
    logo,
    manufacturer,
    name,
    price,
    totalPrice,
    deal,
    warehouses,
    storage,
    selectedStorage,
    selected,
    onSelected,
    count,
    isCountValid,
    onChangeCount,
    onSaveCount,
    onValidCount,
  } = props;

  const [isDeleting, setIsDeleting] = useState(false);
  const [isHiding, setIsHiding] = useState(false);
  const stopDeleting = () => setIsDeleting(false);

  const { t } = useTranslation();

  const { mutate: deleteFromBasket } = useDeleteBasketCount(queryKey);

  const onDelete = useCallback(() => {
    setIsHiding(true);
    setTimeout(() => deleteFromBasket({ item_id: autoliderID }), 500);
  }, [deleteFromBasket, autoliderID]);

  useEffect(() => {
    if (isCountValid && isNComplValid && onValidCount) onValidCount(true);
    if ((!isNComplValid || !isCountValid) && onValidCount) onValidCount(false);
  }, [isCountValid, isNComplValid, onValidCount]);

  const isDisabled = !selectedStorage;

  return (
    <div className={classNames('cnt-row', { deleted: isHiding })}>
      {isDeleting && <ProductRemoval name={name} ms={2000} onDelete={onDelete} onStop={stopDeleting} />}

      {storage?.count === 0 && <div className="overlay overlay_white" />}

      {!isOrderApproved && (
        <div className={`checkbox-wrap ${!selectedStorage ? 'hidden' : ''}`}>
          {((!isCountValid && storage?.count !== 0) || !isNComplValid) && <Icon name={ICON_NAMES.warning} />}

          {storage?.count === 0 ? null : (
            <label className="checkbox-label">
              <input
                type="checkbox"
                className={classNames('checkbox-blue', { hidden: isDisabled && !Boolean(selected) })}
                disabled={isDisabled}
                onChange={onSelected}
                checked={Boolean(selected)}
              />
            </label>
          )}
        </div>
      )}

      <ProductImage id={autoliderID} images={images} width={60} height={60} deal={deal} isList={true} />

      <ProductName id={autoliderID} name={name} targetBlank />

      <VendorCode link={`/product-details/${autoliderID}`} article={article} isColumn />

      <BrandImage hasLabel isColumn manufacturer={manufacturer} logo={logo} />

      <div className="column product-price">
        <div className="product-price__wrap">
          <strong className="product-price__cost">{price}</strong>
          <span className="product-price__currency">{t('label.currency')}</span>
        </div>
      </div>

      {isOrderApproved ? (
        <div className="column quantity-goods">
          <div className="remain-count">{count}</div>
        </div>
      ) : (
        <>
          <Counter count={count} onChange={onChangeCount} onThrottleChange={onSaveCount} />

          {isCountValid && !isNComplValid ? (
            <div className="column accessible">
              <div className="warning">
                <span className="warning__quantity">{t('label.multiplicity_number', { number: nCompl })}</span>
              </div>
            </div>
          ) : isCountValid ? (
            <div className="column accessible">{t('basket.label.enough')}</div>
          ) : (
            <div className="column accessible">
              <div className="warning">
                <span className="warning__quantity">{count === 0 ? `${t('label.min')} 1` : t('label.not_enought_products')}</span>
              </div>
            </div>
          )}
        </>
      )}

      <div className="column total-price">
        <div className="total-price__wrap">
          <strong className="total-price__cost">{totalPrice}</strong>
          <span className="total-price__currency">{t('label.currency')}</span>
        </div>
      </div>

      <Storages selectedStorage={selectedStorage} options={warehouses} isColumn />

      {!isOrderApproved && (
        <div className="delete">
          <button type="button" className="btn-delete" onClick={() => setIsDeleting(true)}>
            <Icon name={ICON_NAMES.close} fill="#FF0000" />
          </button>
        </div>
      )}
    </div>
  );
});

export default BasketProduct;
