import React, { useEffect } from 'react';
import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';

import withAuth from 'HOC/withAuth/withAuth';
import { useGetArticle } from 'hooks/api/useArticles';
import { useGetProductsByIds } from 'hooks/api/useProducts';
import { useCatalogueTypes } from 'hooks/api/useProducts';
import useWindowDimensions from 'hooks/useWindowDimensions';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import { GlobalPreloader, Preloader } from 'components/Preloader/Preloader';
import { imgUrlToResizeUrl } from 'utils/imgUrlToResizeUrl';
import Icon, { ICON_NAMES } from 'components/Icon/index';
import Products from 'components/Products/Products';
import { productKeys } from 'services/queryClient/queryKeys';
import { productTypes } from 'config/constants/productTypes';

function NewsDetails() {
  const { data: catalogueTypes } = useCatalogueTypes();

  const { slug } = useParams();

  const { data, isFetching } = useGetArticle({ slug });

  const image = data?.article?.data?.image;
  const title = data?.article?.data?.title;
  const date = moment(data?.article?.published_at).format('DD MMMM YYYY');
  const description = data?.article?.data?.description;
  const views = data?.article?.views;
  const productsIds = data?.article?.products;

  const windowDimension = useWindowDimensions();
  const { t } = useTranslation();

  const params = { ids: productsIds };
  const { data: products, refetch: getProductsByIds, isLoading } = useGetProductsByIds(params);

  useEffect(() => {
    if (productsIds && productsIds?.length) getProductsByIds();
  }, [productsIds, getProductsByIds]);

  return (
    <>
      {isFetching && <GlobalPreloader />}

      <div className="nav-bottom">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="title">
              <h3>{t('page_title.news_detail')}</h3>
            </div>
          </div>

          {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>
      </div>

      <div className="news-wrap">
        {data && (
          <div className="container">
            <div className="news-wrap__header">
              <div className="wrap">
                <div className="title">
                  <h1>{title}</h1>
                </div>
                <div className="publication">
                  <time className="publication__date">{date}</time>
                  <div className="publication__views-wrap">
                    <strong className="publication__views">{views}</strong>
                    <Icon name={ICON_NAMES.watched} />
                  </div>
                </div>
              </div>
            </div>

            <section className="news-detail">
              <div className="news-detail__img-wrap">
                <img src={imgUrlToResizeUrl(image, 1920, 1047, 'resize')} alt="" />
              </div>
              <div className="news-detail__news-cnt news-cnt" dangerouslySetInnerHTML={{ __html: description }}></div>
            </section>
          </div>
        )}
      </div>

      {isLoading && (
        <div className="preloader-wrap">
          <Preloader />
        </div>
      )}

      {products && (
        <div className="catalog-wrap">
          <Products products={products} queryKey={productKeys.products(params)} productType={productTypes['article']} />
        </div>
      )}
    </>
  );
}

export default compose(withPageLayout(), withAuth())(NewsDetails);
