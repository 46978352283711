import { useEffect } from 'react';
import { useGetBanner } from 'hooks/api/useNotifications';
import { useDisclosure } from 'hooks/useDisclosure';
import { ls, validateDate, validateNewData } from './utils';
import history from 'services/history';

export const useBannerPopup = () => {
  const bannerState = useDisclosure(false);
  const runningLineState = useDisclosure(false);

  const { data } = useGetBanner();

  function handleBannerConfirmButton() {
    const btnType = data.banner.button_action;
    const link = data.banner.button_link;

    if (btnType === 'close') {
      ls.confirmBanner();
      bannerState.close();
    }
    if (btnType === 'link' && link) {
      ls.confirmBanner();
      window.open(link, '_self');
      bannerState.close();
    }
    if (btnType === 'hidden') {
      return;
    }
  }

  function handleBannerCloseButton() {
    ls.confirmBanner();
    bannerState.close();
  }

  function handleRunningLineCloseButton() {
    ls.confirmRunningLine();
    runningLineState.close();
  }

  useEffect(() => {
    if (!data) return;

    const isValidDate = validateDate(data.starts_in, data.expires_in);
    const isNewBannerData = validateNewData(data);
    const isBannerConfirmed = ls.getConfirmBanner();
    const isRunningLineConfirmed = ls.getConfirmRunningLine();

    if (!isValidDate) {
      return;
    }

    if (isNewBannerData) {
      ls.clear();
      ls.setBanner(data);
      bannerState.open();
      runningLineState.open();
      return;
    }

    if (!isBannerConfirmed) {
      bannerState.open();
    }

    if (!isRunningLineConfirmed) {
      runningLineState.open();
    }
  }, [data]);

  return {
    state: {
      bannerState,
      runningLineState,
    },
    data: {
      banner: data?.banner,
      runningLine: data?.running_line,
      color: data?.color,
    },
    actions: {
      handleBannerConfirmButton,
      handleBannerCloseButton,
      handleRunningLineCloseButton,
    },
  };
};
