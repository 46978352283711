import i18n from 'services/i18n/i18n';

export const paginationOptions = () => {
  return [
    { label: i18n.t('select.show_by_count', { count: 8 }), value: 8 },
    { label: i18n.t('select.show_by_count', { count: 16 }), value: 16 },
    { label: i18n.t('select.show_by_count', { count: 32 }), value: 32 },
    { label: i18n.t('select.show_by_count', { count: 64 }), value: 64 },
  ];
};

export const favoritePagePaginationOptions = () => {
  return [
    { label: i18n.t('select.show_by_count', { count: 10 }), value: 10 },
    { label: i18n.t('select.show_by_count', { count: 20 }), value: 20 },
    { label: i18n.t('select.show_by_count', { count: 30 }), value: 30 },
    { label: i18n.t('select.show_by_count', { count: 60 }), value: 60 },
  ];
};

export const sortOptions = () => {
  return [
    {
      label: i18n.t('select.products_sort.price_desc'),
      sort_by: 'price',
      direction: 'desc',
    },
    {
      label: i18n.t('select.products_sort.price_asc'),
      sort_by: 'price',
      direction: 'asc',
    },
    {
      label: i18n.t('select.products_sort.article_desc'),
      sort_by: 'article',
      direction: 'desc',
    },
    {
      label: i18n.t('select.products_sort.article_asc'),
      sort_by: 'article',
      direction: 'asc',
    },
    {
      label: i18n.t('select.products_sort.name_asc'),
      sort_by: 'name',
      direction: 'asc',
    },
    {
      label: i18n.t('select.products_sort.name_desc'),
      sort_by: 'name',
      direction: 'desc',
    },
    {
      label: i18n.t('select.products_sort.manufacturer_asc'),
      sort_by: 'manufacturer',
      direction: 'asc',
    },
    {
      label: i18n.t('select.products_sort.manufacturer_desc'),
      sort_by: 'manufacturer',
      direction: 'desc',
    },
  ];
};

export const sortOptionsFavorites = () => {
  return [
    ...sortOptions(),
    {
      label: i18n.t('select.products_sort.quantity_desc'),
      sort_by: 'quantity',
      direction: 'desc',
    },
  ];
};

export const languageOptions = () => {
  return [
    { label: i18n.t('language.ua'), value: 'ua' },
    { label: i18n.t('language.ru'), value: 'ru' },
  ];
};

export const languageOptionsLong = () => {
  return [
    { label: i18n.t('language.ua_long'), value: 'ua' },
    { label: i18n.t('language.ru_long'), value: 'ru' },
  ];
};
