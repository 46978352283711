import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

const GoBackModal = memo(({ stepBackward }) => {
  const { t } = useTranslation();

  return (
    <div className="report-block">
      <div className="report-block__cnt">
        <p className="report-block__text">{t('info.no_search_results')}</p>
        <button type="button" className="btn-turn-back" onClick={stepBackward}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8">
            <path fill="#1d8eff" d="M4 3h12v2H4v3L0 4l4-4z" />
          </svg>
          <span>{t('action.go_back')}</span>
        </button>
      </div>
    </div>
  );
});

export default GoBackModal;
