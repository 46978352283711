import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import { normalizeSearchString, normalizeSearchStringWithSpaceMultilang } from 'utils/normalizeString';

const history = createBrowserHistory({});

history.pushSearch = function (filters, pathname) {
  const page = Number(filters.page);
  if (page && page === 1) delete filters.page;

  history.push({
    pathname: pathname,
    search: queryString.stringify(filters, {
      arrayFormat: 'separator',
      arrayFormatSeparator: '|',
    }),
  });
};

history.removeSearchOption = function (filters, pathname, filter) {
  const page = Number(filters.page);
  if (page && page === 1) delete filters.page;

  const search = queryString.stringify(filters, {
    arrayFormat: 'separator',
    arrayFormatSeparator: '|',
  });
  search.replace(filter, '');

  history.push({
    pathname: `${pathname}`,
    search: search,
  });
};

history.searchProductsBrands = function (search) {
  history.push({
    pathname: `/search`,
    search: `search=${normalizeSearchString(search)}`,
  });
};

history.searchProductsResultCode = function (search, mfrId, brand) {
  history.push({
    pathname: `/search/result/code`,
    search: `search=${normalizeSearchString(search)}&manufacturer_id=${normalizeSearchString(mfrId)}&brand=${normalizeSearchString(brand)}`,
  });
};

history.searchProductsResultName = function (name) {
  history.push({
    pathname: `/search/result/name`,
    search: `search=${normalizeSearchStringWithSpaceMultilang(name)}`,
  });
};

export default history;
