import React, { memo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import AliceCarousel from 'react-alice-carousel';
import moment from 'moment';
import { cutText } from 'utils/normalizeString';

const NotificationItem = memo((props) => {
  const { notification, type, read } = props;

  const { t } = useTranslation();

  const handleRead = (event) => {
    event.preventDefault();
    if (notification?.is_viewed) return;
    read();
  };

  switch (type) {
    case 'debt': {
      const title = t('label.arrears');
      const description = t('label.debt_notification_message', {
        sum: notification?.data?.debt,
        date: moment.utc(notification?.data?.date).format('DD MMMM YYYY'),
      });

      return (
        <Fragment>
          <p className="hidden-inf__header-text">{title}</p>

          <div className="hidden-inf__notification-date">{moment.utc(notification?.created_at).format('DD MMMM YYYY')}</div>

          <div className="hidden-inf__message-wrap">{description}</div>

          <div className="hidden-inf__btn-wrap">
            <button type="button" className="hidden-inf__btn" onClick={handleRead}>
              {t('action.read_sm')}
            </button>
          </div>
        </Fragment>
      );
    }
    case 'message': {
      return (
        <Fragment>
          <p className="hidden-inf__header-text">{notification.data.title}</p>

          <div className="hidden-inf__notification-date">{moment.utc(notification?.created_at).format('DD MMMM YYYY')}</div>

          <div className="hidden-inf__message-wrap">{cutText(notification.data.message, 90)}</div>

          <div className="hidden-inf__btn-wrap">
            <button type="button" className="hidden-inf__btn" onClick={handleRead}>
              {t('action.read_sm')}
            </button>
          </div>
        </Fragment>
      );
    }
    case 'new_products': {
      const title = t('label.new_products');
      const description = notification.data.products.map((i) => i.name).join(', ');

      return (
        <Fragment>
          <p className="hidden-inf__header-text">{title}</p>

          <div className="hidden-inf__notification-date">{moment.utc(notification?.created_at).format('DD MMMM YYYY')}</div>

          <div className="hidden-inf__message-wrap">{cutText(description, 90)}</div>

          <div className="hidden-inf__btn-wrap">
            <button type="button" className="hidden-inf__btn" onClick={handleRead}>
              {t('action.read_sm')}
            </button>
          </div>
        </Fragment>
      );
    }
    default:
      return null;
  }
});

const HintNotification = memo((props) => {
  const { notifications, onReadNotification } = props;

  const { t } = useTranslation();
  const isButtonsDisabled = notifications?.length <= 1 ? true : false;

  return (
    <div className="hidden-inf">
      <div className="notification-wrap">
        {notifications?.length > 0 ? (
          <AliceCarousel infinite={true} disableButtonsControls={isButtonsDisabled}>
            {notifications.map((notification) => {
              return (
                <NotificationItem
                  key={notification.id}
                  type={notification.type}
                  notification={notification}
                  read={() => onReadNotification({ id: notification?.id })}
                />
              );
            })}
          </AliceCarousel>
        ) : (
          t('label.no_notifications')
        )}
      </div>
    </div>
  );
});

export default HintNotification;
