import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import withAuth from 'HOC/withAuth/withAuth';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { formatPrice } from 'utils/formatPrice';
import { useCatalogueTypes } from 'hooks/api/useProducts';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import { GlobalPreloader } from 'components/Preloader/Preloader';
import DepartmentItem from './DepartmentItem';
import { useGetUserBalances } from 'hooks/api/useOtherRequests';
import Table from './Table';

function Debts() {
  const { data: catalogueTypes } = useCatalogueTypes();

  const { data, isFetching } = useGetUserBalances();

  const departments = data?.departments;
  const documents = data?.documents;
  const debts = data?.debts;

  const [isDocumentsShown, setIsDocumentsShown] = useState(false);
  const [isDebtsShown, setIsDebtsShown] = useState(false);

  const windowDimension = useWindowDimensions();
  const { t } = useTranslation();

  function getDepartmentName(id) {
    switch (Number(id)) {
      case 23:
        return t('label.auto_parts_wholesale');
      case 8:
        return t('label.department_1');
      case 10000002:
        return t('label.sto');
      case 11066:
        return t('label.equipment');
      default:
        return t('label.others');
    }
  }

  return (
    <>
      {isFetching && <GlobalPreloader />}

      <div className="nav-bottom">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="title">
              <h3>{t('page_title.debts')}</h3>
            </div>
          </div>

          {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>
      </div>

      {data && (
        <div className="wrapper">
          <section className="debts-wrap">
            <div className="total-debt">
              <div className="total-debt__wrap">
                <div className="titles-row">
                  <div className="col-title">{t('label.department')}</div>
                  <div className="col-title cash-title">{t('label.debt_cash')}</div>
                  <div className="col-title cashless-title">{t('label.debt_non_cash')}</div>
                </div>

                {departments?.map((department, index) => (
                  <DepartmentItem
                    key={department?.place_id + index}
                    depName={getDepartmentName(department?.place_id)}
                    debtCash={department?.dept_nal}
                    debtNonCash={department?.dept_bnal}
                  />
                ))}
              </div>

              <div className="result-row">
                <div className="column result-row__left-title">{t('label.total')}</div>
                <div className="result-row__right-wrap">
                  <div className="column cash">
                    <strong>
                      {t('label.debt')} <br className="space" /> {t('label.money')}
                    </strong>
                    <div className="wrap">
                      <strong className="cash__debt">
                        {formatPrice(departments?.reduce((accumulator, currentValue) => accumulator + currentValue?.dept_nal, 0))}
                      </strong>
                      <span className="currency">{t('label.currency')}</span>
                    </div>
                  </div>
                  <div className="column cashless">
                    <strong>
                      {t('label.debt')} <br className="space" /> {t('label.no_money')}
                    </strong>
                    <div className="wrap">
                      <strong className="cashless__debt">
                        {formatPrice(departments?.reduce((accumulator, currentValue) => accumulator + currentValue?.dept_bnal, 0))}
                      </strong>
                      <span className="currency">{t('label.currency')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="debts-wrap__btns-wrap">
              <button
                className="btn btn_bg-col-white"
                type="button"
                onClick={() => {
                  setIsDocumentsShown(!isDocumentsShown);
                  setIsDebtsShown(false);
                }}
              >
                {isDocumentsShown ? t('action.hide') : t('action.all_documents')}
              </button>

              <button
                className="btn btn_bg-col-white"
                type="button"
                onClick={() => {
                  setIsDebtsShown(!isDebtsShown);
                  setIsDocumentsShown(false);
                }}
              >
                {isDebtsShown ? t('action.hide') : t('action.all_debts')}
              </button>
            </div>

            {isDocumentsShown && <Table data={documents} getDepartmentName={getDepartmentName} />}

            {isDebtsShown && <Table data={debts} getDepartmentName={getDepartmentName} />}
          </section>
        </div>
      )}
    </>
  );
}

export default compose(withPageLayout(), withAuth())(Debts);
