import React, { memo } from 'react';
import ReactSelect from 'react-select';

const Select = memo((props) => {
  const {
    options,
    onSelect,
    value,
    placeholder,
    menuPlacement = 'auto',
    inputMode = 'none',
    containerStyles = {},
    controlStyles = {},
    valueContainerStyles = {},
    singleValueStyles = {},
    placeholderStyles = {},
    inputStyles = {},
    menuStyles = {},
    menuListStyles = {},
    optionStyles = {},
    indicatorsContainer = {},
    dropdownIndicator = {},
  } = props;

  return (
    <ReactSelect
      options={options}
      components={{ IndicatorSeparator: () => null }}
      value={value}
      inputMode={inputMode}
      placeholder={placeholder}
      onChange={onSelect}
      isDisabled={options.length === 0}
      isSearchable={false}
      menuPlacement={menuPlacement}
      styles={{
        container: (provided, state) => ({
          position: 'relative',
          ...containerStyles,
        }),
        control: (provided, state) => ({
          width: state.selectProps.width,
          border: 'none',
          color: state.selectProps.menuColor,
          fontFamily: 'Manrope-Bold',
          padding: '0 0 0 20px',
          minHeight: '60px',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          fontSize: '14px',
          cursor: 'pointer',
          opacity: state.isDisabled ? 0.5 : 1,
          ...controlStyles,
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: '1px 2px',
          ...valueContainerStyles,
        }),
        singleValue: (provided, state) => {
          return {
            ...provided,
            color: '#0a2352',
            ...singleValueStyles,
          };
        },
        placeholder: (provided) => ({
          ...provided,
          color: '#8f94b3',
          ...placeholderStyles,
        }),
        // input: (provided) => ({
        //   ...provided,
        //   color: '#0a2352',
        //   ...inputStyles
        // }),
        menu: (provided) => ({
          ...provided,
          zIndex: 99999,
          marginTop: 0,
          boxShadow: '0 40px 120px rgb(0 0 0 / 10%)',
          ...menuStyles,
        }),
        menuList: (provided) => ({
          ...provided,
          paddingBottom: 0,
          paddingTop: 0,
          maxHeight: '480px',
          ...menuListStyles,
        }),
        option: (provided, state) => {
          const isSelected = state.isSelected;
          const isDisabled = state.data.isDisabled;

          return {
            ...provided,
            fontFamily: 'Manrope-Bold',
            color: isSelected || isDisabled ? '#0a2352' : '#8f94b3',
            opacity: isSelected || isDisabled ? '0.3' : '1',
            cursor: isSelected || isDisabled ? 'default' : 'pointer',
            pointerEvents: isSelected || isDisabled ? 'none' : 'auto',
            backgroundColor: '#fff',

            display: 'flex',
            alignItems: 'center',
            height: '60px',
            padding: '0 20px',
            transition: '0.3s',
            borderTop: '1px solid #e6ebf2',
            '&:hover': { backgroundColor: 'rgba(29, 142, 255, 0.5)' },
            ...optionStyles,
          };
        },
        indicatorsContainer: (provided) => ({
          ...provided,
          justifyContent: 'center',
          width: '60px',
          ...indicatorsContainer,
        }),
        dropdownIndicator: (provided, state) => ({
          '& svg': { display: 'none' },
          display: 'flex',
          alignItem: 'center',
          justifyContent: 'center',
          border: '5px solid transparent',
          borderTop: '5px solid #0a2352',
          borderBottom: 'none',
          ...dropdownIndicator,
        }),
      }}
    />
  );
});

export default Select;
