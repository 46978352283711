import { toast, Slide } from 'react-toastify';

const notifyStatus = {
  success: 'success',
  error: 'error',
  info: 'info',
  focus: 'focus',
};

const useToastNotification = () => {
  const notify = (label, status, config = {}) =>
    toast(<div className="toast-label">{label}</div>, {
      className: `custom-toast-notification custom-toast-notification_${status}`,
      position: 'bottom-center',
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: 0,
      transition: Slide,
      closeButton: false,
      pauseOnFocusLoss: false,
      ...config,
    });

  return { notify, notifyStatus };
};

export default useToastNotification;
