import React, { memo, useCallback, useEffect, useState, useMemo } from 'react';
import { useForm, useFormState, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { useDeleteBasket, useUpdateBasketCount } from 'hooks/api/useBasket';
import useSorting from 'hooks/useSorting';
import { formatPrice } from 'utils/formatPrice';
import { basketKeys } from 'services/queryClient/queryKeys';

import BasketProduct from '../BasketProduct';
import ProductsHeader from '../ProductsHeader';
import CountDown from 'components/CountDown/CountDown';
import { validateProductCountByComplNumber } from '../utils';

const ProductSelect = memo(({ data, defaultWarehouse, warehouses }) => {
  const { t } = useTranslation();

  const queryKey = basketKeys.basket();
  const { mutate: onUpdateProductCount } = useUpdateBasketCount();

  const [isDeleting, setIsDeleting] = useState(false);

  const { mutate: deleteBasket } = useDeleteBasket();
  const clearBasket = useCallback(() => {
    deleteBasket();
    setIsDeleting(false);
  }, [deleteBasket, setIsDeleting]);

  const form = useForm();
  const formState = useFormState();
  const selectedStorage = formState.values['storage'];

  const { list, sortOptions, sort } = useSorting(
    formState.values['products'].map((item) => ({
      ...item,
      sum: Number((item.price * item.count).toFixed(2)),
    }))
  );

  const { numberOfItems, numberOfUnits, totalPrice } = useMemo(() => {
    return list.reduce(
      (data, item) => {
        if (item.selected) {
          data.numberOfItems = data.numberOfItems + 1;
          data.numberOfUnits = data.numberOfUnits + item.count;
          data.totalPrice = Number((data.totalPrice + item.price * item.count).toFixed(2));
        }
        return data;
      },
      {
        numberOfItems: 0,
        numberOfUnits: 0,
        totalPrice: 0,
      }
    );
  }, [list]);

  const { onSelectAll, onUnselectAll } = useMemo(() => {
    const onSelectAll = () => {
      list.forEach((item, index) => {
        const warehouse = item?.warehouses.find((i) => i.value === selectedStorage);

        if (item?.count > warehouse?.count) form.change(`products[${index}]`, item);
        else form.change(`products[${index}]`, { ...item, selected: true });
      });
    };
    const onUnselectAll = () => {
      list.forEach((item, index) => {
        form.change(`products[${index}]`, { ...item, selected: false });
      });
    };

    return { onSelectAll, onUnselectAll };
  }, [list, selectedStorage, form]);

  const isSelectedAll = useMemo(() => {
    const validProducts = list.filter((item) => {
      const warehouse = item?.warehouses?.find((i) => i.value === selectedStorage);
      return item?.count <= warehouse?.count;
    });

    if (validProducts.length !== 0) return validProducts.every((item) => item.selected === true);
  }, [list, selectedStorage]);

  const handleChangeCount = useCallback((count, product, index) => form.change(`products[${index}].count`, count), [form]);

  const onSelected = useCallback((product, index) => form.change(`products[${index}].selected`, !product.selected), [form]);

  const handleSaveCount = useCallback(
    (count, product, maximum, index) => {
      if (!selectedStorage && count <= maximum && count !== 0) {
        const { article, item_id, name, manufacturer, logo, images, description, price } = product;
        onUpdateProductCount({ article, item_id, name, manufacturer, logo, images, description, count });
        form.change(`products[${index}].sum`, Number(count) * Number(price));
      }
    },
    [selectedStorage, form, onUpdateProductCount]
  );

  const onValidCount = useCallback((isValid, index) => form.change(`products[${index}].isCountValid`, isValid), [form]);

  useEffect(() => {
    if (data.list && formState.values.products.length === 0) {
      form.change('products', data.list);
    }
  }, [form, formState.values.products, data.list]);

  useEffect(() => {
    if (selectedStorage && list) {
      form.change(
        'products',
        list.map((item) => ({
          ...item,
          selected: item?.count <= item?.warehouses?.find((i) => i?.value === selectedStorage)?.count ? true : false,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, selectedStorage]);

  useEffect(() => {
    if (data.list?.length !== formState.values.products.length) {
      const oldProductsState = formState.values.products.map((i) => ({
        item_id: i.item_id,
        selected: i.selected,
        count: i.count,
      }));

      const newProducts = data.list.map((item) => {
        const currentState = oldProductsState.find((old) => old.item_id === item.item_id);
        if (currentState)
          return {
            ...item,
            ...currentState,
            sum: Number((item.price * currentState.count).toFixed(2)),
          };
        return {
          ...item,
          selected: false,
          sum: Number((item.price * item.count).toFixed(2)),
        };
      });

      form.change('products', newProducts);
    }
  }, [form, formState.values.products, data.list]);

  return !list.length ? null : (
    <div className="step-two">
      <div className="step-two__step-wrap">
        <p className="basket-wrap__step-desc">
          <span className="basket-wrap__step-number">2.</span> {t('basket.label.adjust_your_order')}
        </p>

        <div className="step-two__btns-wrap">
          {isSelectedAll ? (
            <button type="button" className="btn btn_bg-col-white" onClick={() => onUnselectAll()}>
              {t('action.unselect_all')}
            </button>
          ) : (
            <button
              type="button"
              disabled={!selectedStorage ? true : false}
              className={`btn btn_bg-col-white ${!selectedStorage ? 'disabled' : ''}`}
              onClick={() => onSelectAll()}
            >
              {t('action.select_all')}
            </button>
          )}

          <button type="button" className="btn btn_bg-col-white clear-basket" onClick={() => setIsDeleting(!isDeleting)}>
            {isDeleting ? (
              <CountDown onComplete={clearBasket} stopHandler={() => setIsDeleting(false)} ms={5000} />
            ) : (
              t('action.clean_the_basket')
            )}
          </button>
        </div>
      </div>

      <div className="basket">
        <ProductsHeader warehouses={warehouses} sortOptions={sortOptions} onSort={sort} />

        <Field>
          {({ input, meta }) =>
            list.map((product) => {
              const selectedStorageData = product?.warehouses?.find((item) => item.value === selectedStorage);
              const storageTotal = product?.warehouses?.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0);

              const isNComplValid = validateProductCountByComplNumber(product.count, product.nCompl, selectedStorageData?.count);

              const isCountValid = selectedStorage
                ? selectedStorageData?.count >= product.count && product.count !== 0
                : storageTotal >= product.count && product.count !== 0;

              const position = formState.values['products'].findIndex((element) => element.item_id === product.item_id);

              return (
                <BasketProduct
                  key={product.item_id}
                  queryKey={queryKey}
                  article={product.article}
                  nCompl={product.nCompl}
                  autoliderID={product.item_id}
                  images={product.images}
                  logo={product.logo}
                  manufacturer={product.manufacturer}
                  name={product.name}
                  price={formatPrice(product.price)}
                  totalPrice={formatPrice(product.sum)}
                  deal={product.deal}
                  warehouses={product.warehouses}
                  selectedStorage={selectedStorage}
                  storage={selectedStorageData}
                  selected={product?.selected}
                  count={product.count}
                  isNComplValid={isNComplValid}
                  isCountValid={isCountValid}
                  onSelected={() => onSelected(product, position)}
                  onChangeCount={(count) => handleChangeCount(count, product, position)}
                  onSaveCount={(count) => handleSaveCount(count, product, storageTotal, position)}
                  onValidCount={(isValid) => onValidCount(isValid, position)}
                />
              );
            })
          }
        </Field>
      </div>

      <div className="basket-wrap__result-row result-row">
        <div className="result-row__left">
          <p className="result-row__all">{t('basket.label.total')}</p>
          <p>
            {t('basket.label.number_of_names')} <span className="number-items">{numberOfItems}</span>
          </p>
          <p>
            {t('basket.label.number_of_units')} <span className="number-units">{numberOfUnits}</span>
          </p>
        </div>
        <div className="result-row__right">
          <p>
            {t('basket.label.total_price')} <span className="total-sum">{formatPrice(totalPrice)}</span>
          </p>
        </div>
      </div>
    </div>
  );
});

export default ProductSelect;
