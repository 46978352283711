import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon, { ICON_NAMES } from 'components/Icon/index';

export function BannerPopup(props) {
  const { bannerData, bannerActions } = props;

  const { t } = useTranslation();

  if (!bannerData.banner) {
    return null;
  }

  if (!bannerData.banner.content && !bannerData.banner.title) {
    return null;
  }

  return (
    <div className="popup" style={{ backgroundColor: bannerData.color }}>
      <div className="popup__header">
        <div className="popup__status">{t('label.attention')}</div>

        <button type="button" className="popup__close" onClick={bannerActions.handleBannerCloseButton}>
          <Icon name={ICON_NAMES.close} fill="currentColor" />
        </button>
      </div>

      <div className="popup__body">
        <div className="popup__title">{bannerData.banner.title}</div>
        <div className="popup__content" dangerouslySetInnerHTML={{ __html: bannerData.banner.content }} />
      </div>

      {bannerData.banner.button_action !== 'hidden' && (
        <div className="popup__footer">
          <button type="button" onClick={bannerActions.handleBannerConfirmButton}>
            {bannerData.banner.button_text}
          </button>
        </div>
      )}
    </div>
  );
}
