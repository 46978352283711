import React, { memo, useState, useEffect } from 'react';
import { useScrollTo } from 'hooks/useScrollTo';
import classNames from 'classnames';

const ScrollUpButton = memo(() => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY >= 100) setShow(true);
      else setShow(false);
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const onClick = useScrollTo(null);

  return <div className={classNames('btn-up', { show: show })} onClick={onClick} />;
});

export default ScrollUpButton;
