import React from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import withAuth from 'HOC/withAuth/withAuth';
import { getArrearsData, getBalanceData } from 'utils/getArrearsData';
import { useGetDashboardData } from 'hooks/api/useDashboard';
import { useCatalogueTypes } from 'hooks/api/useProducts';
import { useGetProfile, useGetManagers } from 'hooks/api/useProfile';
import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { dashboardKeys } from 'services/queryClient/queryKeys';
import { productTypes } from 'config/constants/productTypes';
import { tecdocDescriptionToText } from 'utils/tecdocDescriptionToText';

import ManagerCard from './ManagerCard';
import ArticleSlider from './ArticleSlider';
import ProductItem from 'components/Products/ProductItem';
import backgroundImage from '../../assets/images/dashboard/money.svg';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import { GlobalPreloader } from 'components/Preloader/Preloader';
import AccumulatedSales from './AccumulatedSales';
import Feedback from './Feedback';
import LoyaltySales from './LoyaltySales';
import Icon, { ICON_NAMES } from 'components/Icon';
import { formatPrice } from 'utils/formatPrice';

function Main() {
  const { data: user } = useGetProfile();
  const { data: managers } = useGetManagers();
  const { data: catalogueTypes } = useCatalogueTypes();

  const { t } = useTranslation();

  const { data, isFetching } = useGetDashboardData();

  const arrears = getArrearsData(user?.debt);

  const balanceCash = getBalanceData(user?.balanceFull.cash);
  const balanceCashless = getBalanceData(user?.balanceFull.cashless);

  const windowDimension = useWindowDimensions();

  return (
    <>
      {isFetching && <GlobalPreloader />}

      <div className="nav-bottom">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="title">
              <h3>{t('page_title.main')}</h3>
            </div>
          </div>
          {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>
      </div>

      {data && (
        <section className="dashboard">
          <div className="slider-wrap">
            <ArticleSlider name="news" disableDotsControls={true} plug={data?.news?.default_image} articles={data?.news?.list} />

            <ArticleSlider name="sales" disableDotsControls={true} plug={data?.sales?.default_image} articles={data?.sales?.list} />
          </div>

          <div className="info-blocks">
            <ManagerCard managerData={managers?.salesman} isSalesman />

            <ManagerCard managerData={managers?.manager} isManager />

            <Feedback userEmail={user?.email} />

            {arrears && (
              <Link to="/debts" className="info-blocks__item arrears-block">
                <div className="arrears-block__img-wrap">
                  <img src={backgroundImage} width="180" height="240" alt="" />
                </div>
                <div className="arrears-block__title title">
                  <h3>
                    {t('label.yours')} <br /> {t('label.arrears_sl')}
                  </h3>
                </div>

                <div className="arrears-block__top">
                  <div className="arrears-block__overdue overdue">
                    <p className="overdue__text">
                      {arrears.overdueStatus} <br />
                      <time dateTime="">{arrears.debtDateLabel}</time>
                    </p>
                    <div className={`arrears__wrap ${arrears.overdueClassName}`}>
                      <span className="arrears__sum">{arrears.debt}</span> <span className="currency">{t('label.currency')}</span>
                    </div>
                  </div>
                </div>

                <div className="arrears">
                  <div className="arrears__block">
                    <p>
                      {(balanceCash.isDebt || balanceCashless.isDebt) && t('label.your_debt_is')}
                      {!balanceCash.isDebt && !balanceCashless.isDebt && t('label.no_debt')}
                    </p>
                    <div className="overdue__wrap">
                      {balanceCash.balanceShow && (
                        <span className="balance-info__row">
                          <span className="balance-info__title">
                            <span className="balance-info__title-icon">
                              <Icon name={ICON_NAMES.cash} fill="#0A2352" />
                            </span>
                          </span>
                          <span className={`balance-info__value ${balanceCash.balanceClassName}`}>
                            <span>{balanceCash.balance}</span> <span className="currency">{t('label.currency')}</span>
                          </span>
                        </span>
                      )}
                      {balanceCashless.balanceShow && (
                        <span className="balance-info__row">
                          <span className="balance-info__title">
                            <span className="balance-info__title-icon">
                              <Icon name={ICON_NAMES.cashless} fill="#0A2352" />
                            </span>
                          </span>
                          <span className={`balance-info__value ${balanceCashless.balanceClassName}`}>
                            <span>{balanceCashless.balance}</span> <span className="currency">{t('label.currency')}</span>
                          </span>
                        </span>
                      )}
                      {!balanceCash.balanceShow && !balanceCashless.balanceShow && (
                        <span className="balance-info__row">
                          <span className="balance-info__value">
                            <span>{formatPrice(0)}</span> <span className="currency">{t('label.currency')}</span>
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="arrears__link-wrap">
                    <span>{t('label.article_preview_details')}</span>
                  </div>
                </div>
              </Link>
            )}
          </div>

          <div className="list-recomm-goods">
            <AccumulatedSales data={data?.accumulative_sales} />
            {data?.products?.slice(0, 1).map((product, i) => {
              const key = product.item_id ? product.item_id : product.mfrId;
              const normalizedDescription = Array.isArray(product.description)
                ? tecdocDescriptionToText(product.description)
                : product.description;

              let productType;

              if (windowDimension.width <= 700) {
                productType = productTypes['dashboard-sm'];
              } else {
                productType = productTypes['dashboard-bg'];
              }

              return (
                <ProductItem
                  key={key}
                  queryKey={dashboardKeys.dashboard()}
                  autoliderID={product.item_id}
                  options={product.options}
                  name={product.name}
                  displayPrice={product.display_price}
                  article={product.article}
                  mfrId={product.mfrId}
                  manufacturer={product.manufacturer}
                  description={normalizedDescription}
                  isFavorite={product.is_favorite}
                  isInWishlist={product.is_in_wishlist}
                  productType={productType}
                  shoppingCount={product.shopping_count}
                  isMultiImages={product.isMultiImages}
                  deal={product.deal}
                  images={product.images}
                  loyalty={product.loyalty_program}
                  logo={product.logo}
                  warehouses={product.warehouses}
                />
              );
            })}
            <LoyaltySales data={data?.loyalty_programs} />
            {data?.products?.slice(1).map((product, i) => {
              const key = product.item_id ? product.item_id : product.mfrId;
              const normalizedDescription = Array.isArray(product.description)
                ? tecdocDescriptionToText(product.description)
                : product.description;

              let productType;

              if (windowDimension.width <= 700) {
                productType = productTypes['dashboard-sm'];
              }
              if (windowDimension.width > 700 && windowDimension.width < 1366) {
                productType = i % 5 === 2 ? productTypes['dashboard-bg'] : productTypes['dashboard-sm'];
              }
              if (windowDimension.width >= 1366) {
                productType = i % 6 === 2 || i % 6 === 3 ? productTypes['dashboard-bg'] : productTypes['dashboard-sm'];
              }

              return (
                <ProductItem
                  key={key}
                  queryKey={dashboardKeys.dashboard()}
                  autoliderID={product.item_id}
                  options={product.options}
                  name={product.name}
                  displayPrice={product.display_price}
                  article={product.article}
                  mfrId={product.mfrId}
                  manufacturer={product.manufacturer}
                  description={normalizedDescription}
                  isFavorite={product.is_favorite}
                  isInWishlist={product.is_in_wishlist}
                  productType={productType}
                  shoppingCount={product.shopping_count}
                  isMultiImages={product.isMultiImages}
                  deal={product.deal}
                  images={product.images}
                  logo={product.logo}
                  warehouses={product.warehouses}
                  loyalty={product.loyalty_program}
                />
              );
            })}
          </div>
        </section>
      )}
    </>
  );
}

export default compose(withPageLayout(), withAuth())(Main);
