import Icon, { ICON_NAMES } from 'components/Icon';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'utils/formatPrice';

import { getArrearsData, getBalanceData } from 'utils/getArrearsData';

const HintBalance = memo(({ userData }) => {
  const { t } = useTranslation();

  const balanceCash = getBalanceData(userData?.balanceFull.cash);
  const balanceCashless = getBalanceData(userData?.balanceFull.cashless);
  const arrears = getArrearsData(userData?.debt);

  return (
    <div className="hidden-inf">
      <div className="hidden-inf__arrears">
        <div className="overdue-wrap">
          <p className="overdue__text">
            {arrears?.overdueStatus} <span className="overdue__date">{arrears?.debtDateLabel}</span>
          </p>
          <div className={`arrears__wrap ${arrears?.overdueClassName}`}>
            <span className="arrears__sum">{arrears?.debt}</span>
            <span className="currency"> {t('label.currency')}</span>
          </div>
        </div>

        <p className="hidden-inf__arrears-text">
          {(balanceCash.isDebt || balanceCashless.isDebt) && t('label.your_debt_is')}
          {!balanceCash.isDebt && !balanceCashless.isDebt && t('label.no_debt')}
        </p>
        <div className="overdue__wrap-wrap">
          {balanceCash.balanceShow && (
            <span className="balance-info__row">
              <span className="balance-info__title">
                <span className="balance-info__title-icon">
                  <Icon name={ICON_NAMES.cash} fill="#fff" />
                </span>
              </span>
              <span className={`balance-info__value ${balanceCash.balanceClassName}`}>
                <span>{balanceCash.balance}</span> <span className="currency">{t('label.currency')}</span>
              </span>
            </span>
          )}
          {balanceCashless.balanceShow && (
            <span className="balance-info__row">
              <span className="balance-info__title">
                <span className="balance-info__title-icon">
                  <Icon name={ICON_NAMES.cashless} fill="#fff" />
                </span>
              </span>
              <span className={`balance-info__value ${balanceCashless.balanceClassName}`}>
                <span>{balanceCashless.balance}</span> <span className="currency">{t('label.currency')}</span>
              </span>
            </span>
          )}
          {!balanceCash.balanceShow && !balanceCashless.balanceShow && (
            <span className="balance-info__row">
              <span className="balance-info__value">
                <span>{formatPrice(0)}</span> <span className="currency">{t('label.currency')}</span>
              </span>
            </span>
          )}
        </div>
      </div>
    </div>
  );
});

export default HintBalance;
