import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useApi } from 'services/api';
import { basketKeys } from 'services/queryClient/queryKeys';
import logger from 'services/logger';

export const useGetBasketData = (params) => {
  const { basket } = useApi();

  return useQuery(basketKeys.basket(), () => basket.getBasketData(params), {
    select: (res) => {
      const { products } = res.data;
      const { data, total } = products;

      return { list: data, total };
    },
    onError: (error) => logger(error),
    keepPreviousData: true,
    cacheTime: 0,
    staleTime: 0,
  });
};

export const useAddToBasket = (queryKey) => {
  const queryClient = useQueryClient();
  const { basket } = useApi();

  return useMutation(basket.addToBasket, {
    retry: false,
    onMutate: async (product) => {
      await queryClient.cancelQueries(queryKey);

      const { item_id, count, mfrId, article } = product;
      const previous = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (data) => {
        const products = data.data.products?.data;
        const dashboardProducts = data.data?.products;
        const productSingle = data.data.product;
        const isProductSearch =
          data.data?.products?.main || data.data?.products?.td_analogs || data.data?.products?.other_analogs || data.data?.products?.td_oe;

        const mutate = (array) => {
          return array.map((item) => {
            if (item_id) {
              if (item.item_id !== item_id) return item;
              return { ...item, count, shopping_count: count };
            } else {
              if (item.mfrId !== mfrId || item.article !== article) return item;
              return { ...item, count, shopping_count: count };
            }
          });
        };

        if (Boolean(isProductSearch)) {
          if (data.data.products.main) data.data.products.main = mutate(data.data.products.main);
          if (data.data.products.td_analogs) data.data.products.td_analogs = mutate(data.data.products.td_analogs);
          if (data.data.products.other_analogs) data.data.products.other_analogs = mutate(data.data.products.other_analogs);
          if (data.data.products.td_oe) data.data.products.td_oe = mutate(data.data.products.td_oe);
          return data;
        }

        if (Boolean(productSingle)) {
          data.data.product.shopping_count = count;
          return data;
        }
        if (Boolean(products)) {
          data.data.products.data = mutate(products);
          return data;
        }
        if (Boolean(dashboardProducts)) {
          data.data.products = mutate(dashboardProducts);
          return data;
        }
      });

      return { previous };
    },
    onError: (error, newData, context) => {
      logger(error);
      queryClient.setQueryData(queryKey, context.previous);
      queryClient.invalidateQueries(queryKey);
    },
  });
};

export const useDeleteBasketCount = (queryKey) => {
  const queryClient = useQueryClient();
  const { basket } = useApi();

  return useMutation(basket.deleteBasketItem, {
    retry: false,
    onMutate: async (product) => {
      await queryClient.cancelQueries(queryKey);

      const { item_id } = product;
      const previous = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (data) => {
        const products = data.data.products?.data;
        const dashboardProducts = data.data?.products;
        const productSingle = data.data.product;
        const isProductSearch =
          data.data?.products?.main || data.data?.products?.td_analogs || data.data?.products?.other_analogs || data.data?.products?.td_oe;
        const isBasket = queryKey.includes(basketKeys.basket()[0]);

        const mutate = (array) => {
          return array.map((item) => {
            if (item.item_id !== item_id) return item;
            delete item.count;
            delete item.shopping_count;
            return item;
          });
        };

        if (Boolean(isBasket)) {
          data.data.products.data = data.data.products.data.filter((item) => item.item_id !== item_id);
          return data;
        }
        if (Boolean(isProductSearch)) {
          if (data.data.products.main) data.data.products.main = mutate(data.data.products.main);
          if (data.data.products.td_analogs) data.data.products.td_analogs = mutate(data.data.products.td_analogs);
          if (data.data.products.other_analogs) data.data.products.other_analogs = mutate(data.data.products.other_analogs);
          if (data.data.products.td_oe) data.data.products.td_oe = mutate(data.data.products.td_oe);
          return data;
        }
        if (Boolean(productSingle)) {
          delete data.data.product.shopping_count;
          return data;
        }
        if (Boolean(products)) {
          data.data.products.data = mutate(products);
          return data;
        }
        if (Boolean(dashboardProducts)) {
          data.data.products = mutate(dashboardProducts);
          return data;
        }
      });

      return { previous };
    },
    onError: (error, newData, context) => {
      logger(error);
      queryClient.setQueryData(queryKey, context.previous);
      queryClient.invalidateQueries(queryKey);
    },
  });
};

export const useUpdateBasketCount = () => {
  const { basket } = useApi();

  return useMutation(basket.addToBasket, {
    retry: false,
    onError: (error) => logger(error),
  });
};

export const useDeleteBasket = () => {
  const queryClient = useQueryClient();
  const { basket } = useApi();

  return useMutation(basket.deleteBasket, {
    retry: false,
    onError: (error) => logger(error),
    onSettled: (newData, error, variables, context) => {
      queryClient.invalidateQueries(basketKeys.basket());
      queryClient.invalidateQueries(basketKeys.basketTotal());
    },
  });
};

export const useGetBasketInfo = () => {
  const { basket } = useApi();

  return useQuery(basketKeys.basketTotal(), () => basket.getBasketInfo(), {
    select: (res) => ({
      totalPrice: res?.data?.price,
      total: res?.data?.count,
    }),
    onError: (error) => logger(error),
    keepPreviousData: false,
  });
};

export const useGetDeliveryInfo = (warehouse) => {
  const { basket } = useApi();

  return useQuery(basketKeys.warehouseInfo(warehouse), () => basket.getDeliveryInfo(warehouse), {
    enabled: false,
    select: (res) => res?.data?.deals,
    onError: (error) => logger(error),
  });
};
