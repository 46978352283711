import queryString from 'query-string';

function useFilterValuesFromQuery() {
  function filterFromQuery(key, value, query) {
    const normalizedValue = value.toString();

    // full search query object
    const searchValueFromQuery = queryString.parse(query, {
      arrayFormat: 'separator',
      arrayFormatSeparator: '|',
    });

    // current groupe array
    const normalizedCurrentCategory = Array.isArray(searchValueFromQuery[key])
      ? searchValueFromQuery[key]
      : [searchValueFromQuery[key]];

    const previousSearchValue = searchValueFromQuery[key]
      ? normalizedCurrentCategory
      : [];

    let search;
    if (previousSearchValue.includes(normalizedValue)) {
      search = {
        ...searchValueFromQuery,
        [key]: [...previousSearchValue.filter((i) => i !== normalizedValue)],
        page: 1,
      };
    } else {
      search = {
        ...searchValueFromQuery,
        [key]: [...previousSearchValue, normalizedValue],
        page: 1,
      };
    }
    return search;
  }

  return filterFromQuery;
}

export default useFilterValuesFromQuery;
