import React, { memo, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { handleImageUrlCatalog } from 'utils/handleImageUrl';
import { preventLinkRedirect } from 'utils/preventLinkRedirect';
import DiscountLabel from 'components/DiscountLabel/DiscountLabel';
import tecdocImage from 'assets/images/other/tecdoc.png';
import LoyaltyLabel from 'components/LoyaltyLabel';

const Img = memo(({ image, width, height, defaultDimensions, hasMargin }) => {
  const url = handleImageUrlCatalog(image, width, height);

  if (image) return <img className="product-img__img" src={url} width={width} height={height} alt="" />;
  if (!image)
    return (
      <img
        className={classNames('product-img__img', { 'margin-50': hasMargin })}
        src={url}
        width={defaultDimensions}
        height={defaultDimensions}
        alt=""
      />
    );
});

const ProductImage = memo((props) => {
  const { id, images, width, height, deal, loyalty, isList, isProductDetails, isTecDoc, isMultiImages, disabledLink } = props;

  const link = `/product-details/${id}`;

  const [showHiddenImg, setShowHiddenImg] = useState(false);

  const defaultImageDimensions = (!isList || isProductDetails) && !images[0] ? 100 : 60;

  return !images ? null : (
    <div
      className={classNames('product-img', { column: !isProductDetails })}
      onMouseEnter={() => setShowHiddenImg(true)}
      onMouseLeave={() => setShowHiddenImg(false)}
    >
      {isTecDoc && !isProductDetails && (
        <div className="tecdoc">
          <img src={tecdocImage} width="30" height="30" alt="tecdoc" />
        </div>
      )}
      {isList && showHiddenImg && images?.length !== 0 && (
        <div className="hidden-img hidden-img_top" onMouseEnter={() => setShowHiddenImg(false)}>
          <Img width={200} height={200} image={images[0]} />
        </div>
      )}

      <div className="relative">
        <Link to={link} className="product-img__link" onClick={(event) => preventLinkRedirect(event, disabledLink)}>
          <Img
            width={width}
            height={height}
            image={images[0]}
            hasMargin={!isList && !images[0]}
            defaultDimensions={defaultImageDimensions}
          />
          {isMultiImages && (
            <div className="small-squares">
              <span className="small-squares__item" />
              <span className="small-squares__item" />
              <span className="small-squares__item" />
            </div>
          )}
        </Link>
        {loyalty && <LoyaltyLabel loyalty={loyalty} />}
      </div>
      {deal && <DiscountLabel deal={deal} />}
    </div>
  );
});

export default ProductImage;
