import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useReadAllArticles } from 'hooks/api/useArticles';
import Popup from './components/Popup';

function MarkNewsAsRead(props) {
  const { type } = props;

  const [alertPopup, setAlertPopup] = useState(false);

  const { t } = useTranslation();

  const { mutate: readAll, isLoading: isMutating } = useReadAllArticles();

  const handleConfirm = () => {
    readAll({ type }, { onSuccess: () => setAlertPopup(false) });
  };

  return (
    <Fragment>
      {alertPopup ? (
        <Popup isVisible={alertPopup} onConfirm={handleConfirm} onReject={() => setAlertPopup(false)} />
      ) : (
        <button type="button" disabled={isMutating} className="btn-text-blue" onClick={() => setAlertPopup(true)}>
          {t('label.mark_all_as_read')}
        </button>
      )}
    </Fragment>
  );
}

export default MarkNewsAsRead;
