import React, { useEffect, useMemo, useCallback, useContext, memo } from 'react';
import queryString from 'query-string';
import { useLocation, useParams } from 'react-router-dom';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';

import Filter from './Filter/Filter';
import Products from 'components/Products/Products';
import PagePagination from 'components/PagePagination/PagePagination';
import Sort from 'components/Sort/Sort';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import ViewSwitch from 'components/Navigation/ViewSwitch/ViewSwitch';
import { GlobalPreloader } from 'components/Preloader/Preloader';

import withAuth from 'HOC/withAuth/withAuth';
import withPageLayout, { ViewContext } from 'HOC/withPageLayout/withPageLayout';
import { CommonDataContext } from 'HOC/withCommonData/withCommonData';
import { useGetProducts, useGetFilters } from 'hooks/api/useProducts';
import { useCatalogueTypes } from 'hooks/api/useProducts';
import useWindowDimensions from 'hooks/useWindowDimensions';
import useSorting from 'hooks/useSorting';
import { useScrollTo } from 'hooks/useScrollTo';
import normalizeQueryParams from 'utils/normalizeQueryParams';
import { productKeys } from 'services/queryClient/queryKeys';
import { productTypes } from 'config/constants/productTypes';
import { sortOptions as selectSortOptions } from 'config/constants/selectOptions';
import { paginationOptions } from 'config/constants/selectOptions';
import { useConstantScroll } from 'hooks/useConstantScroll';

const Catalogue = memo(() => {
  const { productsPerPage, setProductsPerPage } = useContext(CommonDataContext);

  const { data: catalogueTypes } = useCatalogueTypes();

  const windowDimension = useWindowDimensions();
  const { catalogueType, category } = useParams();

  const scrollTop = useScrollTo(null);

  const location = useLocation();

  const page = Number(queryString.parse(location.search).page) || 1;

  const params = useMemo(() => {
    const query = normalizeQueryParams(
      queryString.parse(location.search, {
        arrayFormat: 'separator',
        arrayFormatSeparator: '|',
      })
    );

    return {
      ...query,
      category,
      product_type: catalogueType,
      per_page: productsPerPage.value,
      page,
    };
  }, [location, catalogueType, category, page, productsPerPage]);

  const filterParams = useMemo(() => {
    const query = { ...params };
    delete query.page;
    delete query.per_page;

    return query;
  }, [params]);

  const { data: productsData, refetch: refetchProducts, isFetching, isFetched } = useGetProducts(params);
  const { data: filtersData, refetch: refetchFilters } = useGetFilters(filterParams);

  useEffect(() => {
    // fetch on first load on sm screens
    if (windowDimension.width <= 960) refetchProducts();
  }, [windowDimension.width, location.pathname, params?.page, refetchProducts]);

  useEffect(() => {
    // fetch on first load on sm screens
    if (windowDimension.width <= 960) refetchFilters();
  }, [windowDimension.width, location.pathname, filterParams, refetchFilters]);

  useEffect(() => {
    // fetch on sort change on sm screens
    if (windowDimension.width <= 960 && params.sort_by && params.direction) {
      refetchFilters();
      refetchProducts();
    }
  }, [windowDimension.width, params.sort_by, params.direction, refetchProducts, refetchFilters]);

  useEffect(() => {
    if (isFetched) scrollTop();
  }, [isFetched, scrollTop]);

  const handleApplyButton = useCallback(() => {
    refetchProducts();
    refetchFilters();
  }, [refetchFilters, refetchProducts]);

  const { t } = useTranslation();

  const { isSortMenuOpen, isFilterOpen, setIsSortMenuOpen, setIsFilterOpen } = useContext(ViewContext);

  const { sortOptions, sort } = useSorting(null);

  useConstantScroll();

  return (
    <>
      {isFetching && <GlobalPreloader />}

      <div className="nav-bottom">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="title">
              <h3>{productsData?.catalogueTitle}</h3>
            </div>

            {windowDimension.width > 960 && <Sort largeScreen sortOptions={selectSortOptions()} onSort={sort} />}
          </div>

          {windowDimension.width >= 1280 && <ViewSwitch />}

          {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>

        {windowDimension.width < 961 && (
          <div className="nav-bottom__nav-buttons nav-buttons">
            <button className="nav-buttons__item" type="button" onClick={() => setIsFilterOpen(true)}>
              {t('filter.label')}
            </button>

            <div className="nav-buttons__item_splitter" />

            <button
              type="button"
              className="nav-buttons__item nav-buttons__select-wrap select-wrap"
              onClick={() => setIsSortMenuOpen(true)}
            >
              {t('select.products_sort.sort')}
            </button>
          </div>
        )}
      </div>

      <div className="cat-fil-wrap">
        {windowDimension.width > 960 && filtersData && (
          <Filter largeScreen filterGroups={filtersData.filters} productsTotal={productsData?.total} catalogueTypes={catalogueTypes} />
        )}

        {productsData && (
          <div className="wrapper">
            <div className="catalog-wrap">
              <Products
                products={productsData.products}
                queryKey={productKeys.products(params)}
                productType={productTypes['catalogue']}
                sortOptions={sortOptions}
                onSort={sort}
                hasSortButtons
              />
            </div>
            <PagePagination
              pageCount={productsData.pageTotal}
              page={page}
              paginationOptions={paginationOptions()}
              itemsTotal={productsData.total}
              itemsOnPage={productsData.products.length}
              itemsPerPage={productsPerPage}
              setItemsPerPage={setProductsPerPage}
            />
          </div>
        )}
      </div>

      {isFilterOpen && windowDimension.width < 961 && (
        <Filter
          onApply={handleApplyButton}
          smallScreen
          filterGroups={filtersData.filters}
          productsTotal={productsData?.total}
          isFilterOpen={isFilterOpen}
          catalogueTypes={catalogueTypes}
        />
      )}
      {isSortMenuOpen && windowDimension.width < 961 && <Sort sortOptions={selectSortOptions()} smallScreen onSort={sort} />}
    </>
  );
});

export default compose(withPageLayout(), withAuth())(Catalogue);
