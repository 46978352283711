import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast, Slide } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Icon, { ICON_NAMES } from 'components/Icon/index';
import { preventLinkRedirect } from 'utils/preventLinkRedirect';

function VendorCode(props) {
  const { article, productViewType, isColumn, showLabel, disabledLink, link, mailtoLink } = props;

  const { t } = useTranslation();

  const notify = () =>
    toast(
      <>
        <div className="toast-article">{article}</div>
        <div className="toast-label">{t('products.copied')}</div>
      </>,
      {
        className: 'toast-copy-notification',
        position: 'bottom-center',
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: 0,
        transition: Slide,
        closeButton: false,
        pauseOnFocusLoss: false,
      }
    );

  return (
    <div className={classNames('vendor-code', { column: isColumn })}>
      {(productViewType === 'card' || showLabel) && <span>{t('products.article_short')}:</span>}
      <CopyToClipboard text={article} onCopy={() => notify()}>
        <button type="button" className="btn-copy">
          <Icon name={ICON_NAMES.copy} />
        </button>
      </CopyToClipboard>

      {mailtoLink ? (
        <a href={mailtoLink} className="vendor-code__v-code link link_col-blue">
          {article}
        </a>
      ) : (
        <Link
          to={link || ''}
          className="vendor-code__v-code link link_col-blue"
          onClick={(event) => preventLinkRedirect(event, disabledLink)}
        >
          {article}
        </Link>
      )}
    </div>
  );
}

export default VendorCode;
