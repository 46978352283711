import i18n from 'services/i18n/i18n';

const DELIVERY_METHODS = {
  new_post: [1, 110, 310], // Нова пошта з Хм , Нова пошта з Києва , Нова пошта зі Львова
  self_pickup: [27, 127, 327], // Самовивіз з Хм , Самовивіз з Києва , Самовивіз зі Львова
  other: [20, 320], // Автобуси та інше
  uklon: [1010],
};

const warehouseToLable = (warehouse) => {
  if (warehouse === 'km') return i18n.t('label.khmelnytskyi_long');
  if (warehouse === 'kv') return i18n.t('label.kyiv_long');
  if (warehouse === 'lv') return i18n.t('label.lviv_long');
  if (warehouse === 'expr') return i18n.t('label.express_long');
  return '';
};

const carrierIdToCarrierLable = (carrierId) => {
  if (carrierId === 27) return i18n.t('label.self_pickup_from_km'); // Самовивіз з Хм
  if (carrierId === 127) return i18n.t('label.self_pickup_from_kv'); // Самовивіз з Києва
  if (carrierId === 327) return i18n.t('label.self_pickup_from_lv'); // Самовивіз зі Львова

  if (carrierId === 1) return i18n.t('label.new_post_from_km'); // Нова пошта з Хм
  if (carrierId === 110) return i18n.t('label.new_post_from_kv'); // Нова пошта з Києва
  if (carrierId === 310) return i18n.t('label.new_post_from_lv'); // Нова пошта зі Львова

  if (carrierId === 20) return i18n.t('label.bus_from_km'); // Автобус з Хм. /Інше
  if (carrierId === 1010) return i18n.t('basket.delivery_list.uklon_delivery'); // Автобус з Хм. /Інше
  return i18n.t('basket.delivery_list.route_list'); // Доставка Автолідера
};

const normalizeDeliveryData = (data) => {
  if (DELIVERY_METHODS.new_post.includes(data.carrier_id)) return i18n.t('basket.delivery_list.new_post');
  if (DELIVERY_METHODS.self_pickup.includes(data.carrier_id)) return i18n.t('basket.delivery_list.self_pickup');
  if (DELIVERY_METHODS.other.includes(data.carrier_id)) return i18n.t('basket.delivery_list.bus_and_other');
  if (DELIVERY_METHODS.uklon.includes(data.carrier_id)) return i18n.t('basket.delivery_list.uklon_delivery');

  return i18n.t('basket.delivery_list.route_list'); // Доставка Автолідера
};

export { DELIVERY_METHODS, warehouseToLable, carrierIdToCarrierLable, normalizeDeliveryData };
