import { useLocation } from 'react-router-dom';
import { useScrollTo } from 'hooks/useScrollTo';

const ScrollTop = () => {
  const { pathname } = useLocation();
  useScrollTo(pathname, 0, 0, 'instant');

  return null;
};

export default ScrollTop;
