import React, { memo, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import tecdocImage from 'assets/images/other/tecdoc.png';
import GoBackModal from './GoBackModal';
import Products from 'components/Products/Products';
import { productTypes } from 'config/constants/productTypes';
import { useScrollTo } from 'hooks/useScrollTo';

const ProductsCatalogue = memo((props) => {
  const {
    step,
    queryKey,
    nodeStairsLabel = '',
    otherAnalogues,
    tdAnalogues,
    otherAnaloguesSortOptions,
    tdSortOptions,
    sortOtherAnalogues,
    sortTDAnalogues,
    stepBackward,
  } = props;

  const { t } = useTranslation();

  const onScrollTo = useScrollTo(null);

  useEffect(() => {
    if (step === 5) onScrollTo();
  }, [step, onScrollTo]);

  return !tdAnalogues || !otherAnalogues ? null : (
    <Fragment>
      {tdAnalogues.length === 0 && otherAnalogues.length === 0 ? (
        <GoBackModal stepBackward={stepBackward} />
      ) : (
        <Fragment>
          <div className="node-title">{nodeStairsLabel}</div>

          {tdAnalogues?.length > 0 && (
            <>
              <div className="title analogues">
                <h3>{t('page_title.according_to_tecdoc')}</h3>
                <div className="analogues__tecdoc">
                  <img src={tecdocImage} width="40" height="40" alt="Tecdoc" />
                </div>
              </div>
              <div className="catalog-wrap">
                <Products
                  products={tdAnalogues}
                  queryKey={queryKey}
                  productType={productTypes['catalogue']}
                  onSort={sortTDAnalogues}
                  sortOptions={tdSortOptions}
                  hasSortButtons
                />
              </div>
            </>
          )}

          {otherAnalogues?.length > 0 && (
            <>
              <div className="title analogues">
                <h3>{t('page_title.analogues')}</h3>
              </div>
              <div className="catalog-wrap">
                <Products
                  products={otherAnalogues}
                  queryKey={queryKey}
                  productType={productTypes['catalogue']}
                  onSort={sortOtherAnalogues}
                  sortOptions={otherAnaloguesSortOptions}
                  hasSortButtons
                />
              </div>
            </>
          )}
        </Fragment>
      )}
    </Fragment>
  );
});

export default ProductsCatalogue;
