import React, { memo } from 'react';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import { useCatalogueTypes } from 'hooks/api/useProducts';
import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import withAuth from 'HOC/withAuth/withAuth';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useGetDocumentById, useDownloadDocumentExcel, useDownloadDocumentPdf } from 'hooks/api/useOtherRequests';
import { formatPrice } from 'utils/formatPrice';
import { GlobalPreloader } from 'components/Preloader/Preloader';
import VendorCode from 'components/VendorCode/VendorCode';

const DebtProduct = memo((props) => {
  const { index, id, price, name, quantity, quantityReturned, code } = props;

  const { t } = useTranslation();

  return (
    <div className="cnt-row">
      <div className="column numbering">{index}</div>

      <VendorCode article={code} isColumn disabledLink />

      <div className="column product-name">
        <Link to={`/product-details/${id}`} className="product-name__text link link_col-sapphire">
          {name}
        </Link>
      </div>

      <div className="column num-prod">
        <strong>{t('label.count')}</strong>
        {quantity}
      </div>

      <div className="column return-prod">
        <strong>{t('label.return')}</strong>
        {quantityReturned}
      </div>

      <div className="column product-price">
        <strong className="amount">{formatPrice(price)}</strong>
        <span className="currency">{t('label.currency')}</span>
      </div>

      <div className="column total-price">
        <strong className="amount">{formatPrice(price * quantity)}</strong>
        <span className="currency">{t('label.currency')}</span>
      </div>
    </div>
  );
});

function Debts() {
  const { data: catalogueTypes } = useCatalogueTypes();

  const windowDimension = useWindowDimensions();
  const { t } = useTranslation();

  const { id } = useParams();

  const { data, isFetching } = useGetDocumentById(id);

  const document = data?.document;
  const list = data?.list;

  const sum = formatPrice(document?.sum);
  const paid = document?.osum ? formatPrice(document.osum) : '-';
  const debt = document?.osum ? formatPrice(sum - paid) : '-';

  const downloadName = `${document?.kinddoc_name}-${document?.doc_num}-${document?.day}`;

  const { refetch: downloadExcelFile } = useDownloadDocumentExcel(id, downloadName);
  const { refetch: downloadPdfFile } = useDownloadDocumentPdf(id, downloadName);

  return (
    <>
      {isFetching && <GlobalPreloader />}

      <div className="nav-bottom bkn">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="wrapper">
              <div className="title">
                <h3>{document?.kinddoc_name}</h3>
                <span>{document?.doc_num}</span>
              </div>
            </div>
            <p className="payment-term">
              {t('label.from')} {document?.day} <br className="space" /> {t('label.pay_to')} <br className="space" /> {document?.sday}
            </p>
            <p className="order">
              {t('label.order')} <br className="space" /> {document?.order_id} / {document?.req_num}
            </p>
          </div>

          {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>
      </div>

      <div className="wrapper">
        <section className="cash-order bkn-order">
          <div className="cash-order__inf-row inf-row">
            <p>
              <span>{t('label.wholesum')}</span> <strong className="total-amount">{sum}</strong>
            </p>
            <p>
              <span>{t('label.debt')}</span>
              {document?.osum ? <strong className="debt">{debt}</strong> : <strong>-</strong>}
            </p>
            <p>
              <span>
                {t('label.paid')}/<br className="space" /> {t('label.credited')}
              </span>
              {document?.osum ? <strong className="calculated-amount">{paid}</strong> : <strong>-</strong>}
            </p>
          </div>

          <div className="order-inf">
            <div className="order-inf__title title">
              <h4>{t('label.list_of_goods')}</h4>
            </div>
            <div className="order-inf__wrap">
              <div className="titles-row">
                <div className="col-title numbering-title">#</div>
                <div className="col-title vendor-code-title">{t('label.article')}</div>
                <div className="col-title product-name-title">{t('label.name')}</div>
                <div className="col-title num-prod-title">{t('label.count')}</div>
                <div className="col-title return-prod-title">{t('label.return')}</div>
                <div className="col-title product-price-title">{t('label.price')}</div>
                <div className="col-title total-price-title">{t('label.sum')}</div>
              </div>

              {list?.map((item, index) => (
                <DebtProduct
                  key={item?.item_id}
                  id={item?.item_id}
                  index={index + 1}
                  price={Number(item?.price)}
                  name={item?.name}
                  quantity={Number(item?.quant)}
                  quantityReturned={item?.quant_ret}
                  code={item?.code}
                />
              ))}
            </div>

            {list && list.length === 0 && <div className="no-cnt-row">{t('info.not_carried_advance')}</div>}

            <div className="order-inf__result-row result-row">
              <div className="column">{t('label.total_with_return')}</div>
              <div className="column num-prod">
                {list?.reduce((accumulator, currentValue) => accumulator + Number(currentValue.quant), 0)}
              </div>
              <div className="column return-prod">
                {list?.reduce((accumulator, currentValue) => accumulator + Number(currentValue.quant_ret), 0)}
              </div>
              <div className="column product-price">
                <div className="wrap">
                  <strong className="amount">
                    {formatPrice(list?.reduce((accumulator, currentValue) => accumulator + Number(currentValue.price), 0))}
                  </strong>
                  <span className="currency">{t('label.currency')}</span>
                </div>
              </div>
              <div className="column total-price">
                <div className="wrap">
                  <strong className="amount">
                    {formatPrice(
                      list?.reduce((accumulator, currentValue) => accumulator + Number(currentValue.price) * Number(currentValue.quant), 0)
                    )}
                  </strong>
                  <span className="currency">{t('label.currency')}</span>
                </div>
              </div>
            </div>
            <div className="btns-wrap">
              {/* <button type="button" className="btn btn_bg-col-astronaut" onClick={() => downloadPdfFile()}>
                {t('action.print')}
              </button> */}

              <button type="button" className="btn btn_bg-col-astronaut" onClick={() => downloadExcelFile()} style={{ marginRight: 0 }}>
                {t('action.export_to_excel')}
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default compose(withPageLayout(), withAuth())(Debts);
