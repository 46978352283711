import React, { memo, useState } from 'react';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';

import withAuth from 'HOC/withAuth/withAuth';
import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useCatalogueTypes } from 'hooks/api/useProducts';
import { useGetDownloads } from 'hooks/api/useOtherRequests';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import { GlobalPreloader } from 'components/Preloader/Preloader';

const DownloadDocument = memo(({ link, name, extension }) => {
  const [isOnHover, setIsOnHover] = useState(false);

  const { t } = useTranslation();

  const isLinkToDownload = !link.includes('http');
  const linkToDownload = `${process.env.REACT_APP_API_URL}${link}`;

  return (
    <li>
      {isLinkToDownload ? (
        <a href={linkToDownload} download onMouseEnter={() => setIsOnHover(true)} onMouseLeave={() => setIsOnHover(false)}>
          {name}
          {isOnHover ? <strong>{t('action.download')}</strong> : <strong>{extension}</strong>}
        </a>
      ) : (
        <a href={link} target="_blank" rel="noreferrer" onMouseEnter={() => setIsOnHover(true)} onMouseLeave={() => setIsOnHover(false)}>
          {name}
          {isOnHover ? <strong>{t('action.to_go')}</strong> : <strong>{link.includes('google.com') ? 'google' : t('label.link')}</strong>}
        </a>
      )}
    </li>
  );
});

function Downloads() {
  const { data: catalogueTypes } = useCatalogueTypes();

  const { data: documenst, isFetching } = useGetDownloads();

  const windowDimension = useWindowDimensions();

  const { t } = useTranslation();

  return (
    <>
      {isFetching && <GlobalPreloader />}

      <div className="nav-bottom">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="title">
              <h3>{t('page_title.downloads')}</h3>
            </div>
          </div>
          {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>
      </div>

      <div className="wrapper">
        {documenst && (
          <ul className="downloads-list">
            {documenst?.map((document) => (
              <DownloadDocument
                key={document?.link + document?.name}
                link={document?.link}
                name={document?.name}
                extension={document?.extension}
              />
            ))}
          </ul>
        )}
      </div>
    </>
  );
}

export default compose(withPageLayout(), withAuth())(Downloads);
