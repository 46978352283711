const tecdocArray = [
  {
    id: '100341',
    class: 'ta-icon ta-icon-interior-equipment',
    name: ' Внутренняя отделка ',
  },
  {
    id: '100400',
    class: 'ta-icon ta-icon-axle-drive',
    name: ' Главная передача ',
  },
  {
    id: '100002',
    class: 'ta-icon ta-icon-engine',
    name: ' Двигатель ',
  },
  {
    id: '100019',
    class: 'ta-icon ta-icon-maintenance-service-parts',
    name: ' Детали для сервиса / ТО / ухода ',
  },
  {
    id: '100339',
    class: 'ta-icon ta-icon-communication-systems',
    name: ' Информационная / коммуникационная система ',
  },
  {
    id: '103099',
    class: 'ta-icon ta-icon-wheels-tyres',
    name: ' Колёса / шины ',
  },
  {
    id: '100733',
    class: 'ta-icon ta-icon-accessories',
    name: ' Комплектующие ',
  },
  {
    id: '100243',
    class: 'ta-icon ta-icon-air-conditioning',
    name: ' Кондиционер ',
  },
  {
    id: '100238',
    class: 'ta-icon ta-icon-transmission',
    name: ' Коробка передач ',
  },
  {
    id: '100001',
    class: 'ta-icon ta-icon-body-car',
    name: ' Кузов ',
  },
  {
    id: '100343',
    class: 'ta-icon ta-icon-carrier-equipment',
    name: ' Оборудование для перевозки ',
  },
  {
    id: '100241',
    class: 'ta-icon ta-icon-heater',
    name: ' Отопление / вентиляция ',
  },
  {
    id: '103168',
    class: 'ta-icon ta-icon-compressed-air-system',
    name: ' Пневматическая система ',
  },
  {
    id: '100011',
    class: 'ta-icon ta-icon-suspension',
    name: ' Подвеска / амортизация ',
  },
  {
    id: '100013',
    class: 'ta-icon ta-icon-axle',
    name: ' Подвеска оси / система подвески / колеса ',
  },
  {
    id: '100254',
    class: 'ta-icon ta-icon-fuel-mixture-formation',
    name: ' Подготовка топлива ',
  },
  {
    id: '100014',
    class: 'ta-icon ta-icon-wheel-drive',
    name: ' Привод колеса ',
  },
  {
    id: '100015',
    class: 'ta-icon ta-icon-towbar-parts',
    name: ' Прицепное оборудование / комплектующие ',
  },
  {
    id: '100016',
    class: 'ta-icon ta-icon-belt-drive',
    name: ' Ременный привод ',
  },
  {
    id: '100012',
    class: 'ta-icon ta-icon-steering',
    name: ' Рулевое управление ',
  },
  {
    id: '100417',
    class: 'ta-icon ta-icon-security-systems',
    name: ' Система безопасности ',
  },
  {
    id: '100004',
    class: 'ta-icon ta-icon-exhaust',
    name: ' Система выпуска ОГ ',
  },
  {
    id: '100008',
    class: 'ta-icon ta-icon-spark-glow-ignition',
    name: ' Система зажигания/накаливания ',
  },
  {
    id: '100007',
    class: 'ta-icon ta-icon-cooling-ac',
    name: ' Система охлаждения ',
  },
  {
    id: '100018',
    class: 'ta-icon ta-icon-window-cleaning',
    name: ' Система очистки окон ',
  },
  {
    id: '100342',
    class: 'ta-icon ta-icon-headlight-washer-system',
    name: ' Система очистки фар ',
  },
  {
    id: '100214',
    class: 'ta-icon ta-icon-fuel-supply-system',
    name: ' Система подачи топлива ',
  },
  {
    id: '100050',
    class: 'ta-icon ta-icon-clutch-parts',
    name: ' Система сцепления / навесные части ',
  },
  {
    id: '100335',
    class: 'ta-icon ta-icon-comfort-systems',
    name: ' системы комфорта ',
  },
  {
    id: '706365',
    class: 'ta-icon ta-icon-special-tools',
    name: ' Специальные инструменты ',
  },
  {
    id: '100006',
    class: 'ta-icon ta-icon-brake',
    name: ' Тормозная система ',
  },
  {
    id: '100685',
    class: 'ta-icon ta-icon-locking-system',
    name: ' Устройство запирания ',
  },
  {
    id: '100005',
    class: 'ta-icon ta-icon-filter',
    name: ' Фильтр ',
  },
  {
    id: '100010',
    class: 'ta-icon ta-icon-electrics',
    name: ' Электрооборудование ',
  },
];

export const tecdocIconsByID = tecdocArray.reduce((obj, cur) => ({ ...obj, [cur.id]: cur }), {});
