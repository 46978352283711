import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

const Analogue = React.memo((props) => {
  const { to, capacity, isList, active } = props;

  return (
    <NavLink
      to={to}
      exact
      className={classNames('link', {
        'volume-list__item': isList,
        'volume__item link_col-manatee': !isList,
        active: active,
      })}
    >
      <span>{capacity?.value}</span>
    </NavLink>
  );
});

const Analogues = React.memo(({ options, isList, isColumn }) => (
  <div
    className={classNames('', {
      'volume-list': isList,
      volume: !isList,
      column: isColumn,
    })}
  >
    {(options ?? []).map((option) => (
      <Analogue key={option.item_id} to={`/product-details/${option?.item_id}`} capacity={option} isList={isList} active={option?.active} />
    ))}
  </div>
));

export default Analogues;
