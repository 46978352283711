import Icon, { ICON_NAMES } from 'components/Icon';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'utils/formatPrice';

const PaymentFooter = memo((props) => {
  const { arrears, balanceCash, balanceCashless, qrImage, image, url, paymentInfoList, className = '' } = props;

  const { t } = useTranslation();

  return (
    <div className={`payment-section ${className}`}>
      <div className="payment-section__left-side">
        <div className="arrears-wrap">
          <div className="overdue">
            <p>
              {arrears.overdueStatus} <span className="overdue__date">{arrears.debtDateLabel}</span>
            </p>
            <div className={`arrears__wrap ${arrears.overdueClassName}`}>
              {arrears.debt} <span className="currency">{t('label.currency')}</span>
            </div>
          </div>

          <div className="arrears">
            <p>
              {(balanceCash.isDebt || balanceCashless.isDebt) && t('label.your_debt_is')}
              {!balanceCash.isDebt && !balanceCashless.isDebt && t('label.no_debt')}
            </p>
            <div className="overdue__wrap">
              {balanceCash.balanceShow && (
                <span className="balance-info__row">
                  <span className="balance-info__title">
                    <span className="balance-info__title-icon">
                      <Icon name={ICON_NAMES.cash} fill="#0A2352" />
                    </span>
                    <span className="balance-info__title-label">{t('label.cash')}</span>
                  </span>
                  <span className={`balance-info__value ${balanceCash.balanceClassName}`}>
                    <span>{balanceCash.balance}</span> <span className="currency">{t('label.currency')}</span>
                  </span>
                </span>
              )}
              {balanceCashless.balanceShow && (
                <span className="balance-info__row">
                  <span className="balance-info__title">
                    <span className="balance-info__title-icon">
                      <Icon name={ICON_NAMES.cashless} fill="#0A2352" />
                    </span>
                    <span className="balance-info__title-label">{t('label.non_cash')}</span>
                  </span>
                  <span className={`balance-info__value ${balanceCashless.balanceClassName}`}>
                    <span>{balanceCashless.balance}</span> <span className="currency">{t('label.currency')}</span>
                  </span>
                </span>
              )}
              {!balanceCash.balanceShow && !balanceCashless.balanceShow && (
                <span className="balance-info__row">
                  <span className="balance-info__value">
                    <span>{formatPrice(0)}</span> <span className="currency">{t('label.currency')}</span>
                  </span>
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="qr-code">
          <p>{t('label.pay_with_privat')}</p>
          <div className="qr-code__img-wrap">
            <img src={qrImage} width="100" height="100" alt="" />
          </div>
          <p>{t('label.use_privat')}</p>
        </div>
      </div>

      <div className="payment-section__right-side">
        <div className="payment">
          <div className="payment__img-wrap">
            <img src={image} width="143" height="80" alt="" />
          </div>
          <div className="payment__desc">
            {paymentInfoList?.map((item, index) => (
              <p key={item?.text + index}>{item?.text}</p>
            ))}
          </div>
        </div>
        <div className="payment__link-wrap">
          <a href={url} target="_blank" rel="noreferrer" className="btn">
            {t('action.pay')}
          </a>
        </div>
      </div>
    </div>
  );
});

export default PaymentFooter;
