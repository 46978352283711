export const normalizeSearchString = (string) => {
  if (!string) return;
  return string.toString().replace(/[^\p{L}\p{N}0-9]+/giu, '');
};

export const normalizeSearchStringWithSpaceMultilang = (string) => {
  if (!string) return;
  return string
    .toString()
    .replace(/[^\p{L}\p{N}0-9\s]+/giu, ' ')
    .replace(/\s\s+/g, ' ')
    .trim();
};

export const normalizeStringWithSpace = (string) => {
  if (!string) return;

  return string
    .replace(/\s\s+/g, ' ') //replaced multi spaces & tabs & new line -> ' '
    .replace(/\s*([,.!?:;]+)(?!\s*$)\s*/g, '$1 ') // add space after dot coma
    .trim();
};

export const cutText = (text, maxTextSize) => {
  if (!text) return '';
  if (!maxTextSize) return text;

  if (text.length <= maxTextSize) return text;
  if (text.length > maxTextSize) return text.substring(0, maxTextSize) + ' ...';
};
