import React, { useState, useMemo, useContext, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsFetching } from 'react-query';

import { CommonDataContext } from 'HOC/withCommonData/withCommonData';
import Search from './Search/Search';
import ClientInfo from './ClientInfo/ClientInfo';
import Menu from './Menu/Menu';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useAddSearchToHistory, useSearchByNameCheck } from 'hooks/api/useSearch';
import history from 'services/history';
import { searchKeys } from 'services/queryClient/queryKeys';
import { useGetBasketInfo } from 'hooks/api/useBasket';
import { useGetProfile } from 'hooks/api/useProfile';
import { useCatalogueTypes } from 'hooks/api/useProducts';
import { useGetSearchHistory } from 'hooks/api/useSearch';
import { RunningLine } from 'feature/banner';

function Header(props) {
  const { bannerController } = props;

  const { data: user } = useGetProfile();
  const { data: basketData } = useGetBasketInfo();
  const { data: catalogueTypes } = useCatalogueTypes();
  const { data: searchHistoryData } = useGetSearchHistory();

  const { setSearchType, searchOptions } = useContext(CommonDataContext);

  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  const [searchByNameState, setSearchByNameState] = useState(null);

  const { mutate: addSearchToHistory } = useAddSearchToHistory();
  const { mutate: checkSearch, data: searchByNameCheckCount, isLoading } = useSearchByNameCheck();

  const fetchingProducts = useIsFetching(searchKeys.searchProducts()[0]);
  const fetchingBrands = useIsFetching(searchKeys.searchBrands()[0]);
  const fetchingProductsName = useIsFetching(searchKeys.searchProductsName()[0]);
  const isSearching = Boolean(fetchingProducts) || Boolean(fetchingBrands) || Boolean(fetchingProductsName) || isLoading;

  const isSearchByNameValid = searchByNameCheckCount && searchByNameCheckCount.data.count <= 50;

  const normalizedHistoryData = useMemo(() => {
    if (!searchHistoryData || searchHistoryData.length === 0)
      return [
        {
          label: t('label.no_search_history'),
          value: 0,
          disabled: true,
        },
      ];
    return searchHistoryData.map((item) => ({
      ...item,
      label: item.search,
      value: JSON.stringify(item),
      disabled: false,
    }));
  }, [searchHistoryData, t]);

  const onSearch = useCallback(
    ({ type, search }) => {
      if (type === 'code') {
        history.searchProductsBrands(search);
        addSearchToHistory({ search, type });
        setSearchByNameState(null);
      }
      if (type === 'name') {
        checkSearch(search);
        setSearchByNameState({ search, type });
      }
    },
    [addSearchToHistory, checkSearch]
  );

  useEffect(() => {
    if (isSearchByNameValid) {
      history.searchProductsResultName(searchByNameState.search);
      addSearchToHistory({
        search: searchByNameState.search,
        type: searchByNameState.type,
      });
    }
    if (isSearchByNameValid) {
      setSearchType(searchOptions.find((i) => i.value === 'code'));
    }
  }, [isSearchByNameValid, searchByNameState, searchByNameCheckCount?.data?.count, searchOptions, setSearchType, addSearchToHistory]);

  return (
    <header className="main-header">
      {bannerController.state.runningLineState.isOpen && <RunningLine bannerController={bannerController} />}

      <div className="alter-container">
        <Menu
          user={user}
          catalogueTypes={catalogueTypes}
          historyData={normalizedHistoryData}
          isSearchByNameValid={isSearchByNameValid}
          isSearching={isSearching}
          onSearch={onSearch}
        />

        {width > 1250 && (
          <Search
            largeScreen
            historyData={normalizedHistoryData}
            isSearchByNameValid={isSearchByNameValid}
            isSearching={isSearching}
            onSearch={onSearch}
          />
        )}

        {user && (
          <ClientInfo
            basketTotal={basketData?.total}
            userDebt={user?.debt}
            userBalance={user?.balance}
            userBalanceFullInfo={user?.balanceFull}
            userName={user?.profile?.name}
            userEmail={user?.email}
            userCode={user?.profile?.code}
            managerID={user?.manager_id}
            runningLineVisible={bannerController.state.runningLineState.isOpen}
          />
        )}
      </div>
    </header>
  );
}

export default Header;
