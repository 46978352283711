const normalizeQueryParams = (query, isSortQuery) => {
  if (isSortQuery) {
    delete query.page;
    delete query.sort_by;
    delete query.direction;
    return query;
  }

  delete query.page;
  return query;
};

export default normalizeQueryParams;
