import React, { memo, useContext, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import Icon, { ICON_NAMES } from 'components/Icon';
import { CommonDataContext } from 'HOC/withCommonData/withCommonData';

const ViewSwitch = memo(() => {
  const { viewSwitchHandler, productViewType } = useContext(CommonDataContext);

  const isCard = useMemo(() => productViewType === 'card', [productViewType]);
  const isList = useMemo(() => productViewType === 'list', [productViewType]);

  const onListSwitch = useCallback(() => viewSwitchHandler('list'), [viewSwitchHandler]);
  const onCardSwitch = useCallback(() => viewSwitchHandler('card'), [viewSwitchHandler]);

  return (
    <ul className="nav-bottom__center nav-bottom__item">
      <li
        className={classNames('grid-table btn-square', {
          'btn-square_bg-col-sapphire': isList,
          'btn-square_bg-col-white': isCard,
        })}
        onClick={onListSwitch}
      >
        <Icon name={ICON_NAMES.list} fill={isList ? '#FFF' : '#0a2352'} />
      </li>
      <li
        className={classNames('grid-table btn-square', {
          'btn-square_bg-col-sapphire': isCard,
          'btn-square_bg-col-white': isList,
        })}
        onClick={onCardSwitch}
      >
        <Icon fill={isCard ? '#FFF' : '#0a2352'} name={ICON_NAMES.card} />
      </li>
    </ul>
  );
});

export default ViewSwitch;
