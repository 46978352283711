import React from 'react';
import { Redirect } from 'react-router-dom';
import { useToken } from 'hooks/api/useAuth';

const withAuth = () => (Component) => {
  return (props) => {
    const { data: token } = useToken();
    const localStorageToken = localStorage.getItem('token');

    if (!token && !localStorageToken) return <Redirect to="/login" />;

    return <Component {...props} />;
  };
};

export default withAuth;
