import React, { memo, Fragment } from 'react';
import classNames from 'classnames';
import { normalizeQuantity } from './utils';
import { Link } from 'react-router-dom';
import { preventLinkRedirect } from 'utils/preventLinkRedirect';

const StorageInfoMenu = memo((props) => {
  const { options, enteredElementsValues } = props;

  if (!options) {
    return null;
  }

  return (
    <Fragment>
      {options.map((option) => {
        const isIncome = option.value === 'income';

        if (isIncome && Number(option.count) === 0) return null;

        const storageLink = option.is_real ? `/about-us/${option.value}` : '';

        return (
          <Link
            to={storageLink}
            key={option.value}
            className={classNames('storage-item', {
              'col-text-sunglow': isIncome,
              active: enteredElementsValues.includes(option.value),
              'storage-item__link': Boolean(storageLink),
            })}
            target="_blank"
            rel="noreferrer"
            onClick={(event) => preventLinkRedirect(event, !Boolean(storageLink))}
          >
            <span>{option.label}</span>
            <span>{normalizeQuantity(option.count)}</span>
          </Link>
        );
      })}
    </Fragment>
  );
});

export { StorageInfoMenu };
