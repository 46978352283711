import React, { useState, useCallback, useRef, useEffect, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { compose } from 'redux';
import { debounce } from 'throttle-debounce';
import moment from 'moment';
import { useLocation } from 'react-router';

import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import { CommonDataContext } from 'HOC/withCommonData/withCommonData';
import withAuth from 'HOC/withAuth/withAuth';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useGetDocuments } from 'hooks/api/useOtherRequests';
import useSorting from 'hooks/useSorting';
import { paginationOptions } from 'config/constants/selectOptions';
import history from 'services/history';
import normalizeQueryParams from 'utils/normalizeQueryParams';
import { useCatalogueTypes } from 'hooks/api/useProducts';
import { useGetProfile } from 'hooks/api/useProfile';
import PagePagination from 'components/PagePagination/PagePagination';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import { GlobalPreloader } from 'components/Preloader/Preloader';
import ReportItem from './ReportItem';
import ReportHeader from './ReportHeader';
import DatePicker from './DatePicker';

function Report() {
  const { productsPerPage, setProductsPerPage } = useContext(CommonDataContext);

  const { data: user } = useGetProfile();
  const { data: catalogueTypes } = useCatalogueTypes();

  const windowDimension = useWindowDimensions();
  const { t } = useTranslation();
  const { search, pathname } = useLocation();

  const page = Number(queryString.parse(search).page) || 1;
  const direction = queryString.parse(search).direction;
  const sortBy = queryString.parse(search).sort_by;

  const from = queryString.parse(search).from || moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
  const to = queryString.parse(search).to || moment().endOf('month').format('YYYY-MM-DD');

  const clickFrom = (date) => {
    const query = normalizeQueryParams(queryString.parse(search));

    history.pushSearch({ ...query, from: date }, pathname);
  };

  const clickTo = (date) => {
    const query = normalizeQueryParams(queryString.parse(search));

    history.pushSearch({ ...query, to: date }, pathname);
  };

  const { monthsStart, monthsEnd } = useMemo(() => {
    const numberOfMonths = Math.ceil(moment().diff(moment().subtract(1, 'years').month(0), 'months', true)) + 1;
    const monthsStart = [];
    const monthsEnd = [];

    for (let i = 0; i < numberOfMonths; i++) {
      monthsStart.push({
        label: moment().subtract(i, 'M').startOf('month').format('YYYY-MM-DD'),
        value: moment().subtract(i, 'M').startOf('month').format('YYYY-MM-DD'),
        isDisabled: moment().subtract(i, 'M').endOf('month').unix() > moment(to).unix() ? true : false,
      });
    }

    for (let i = 0; i < numberOfMonths; i++) {
      monthsEnd.push({
        label: moment().subtract(i, 'M').endOf('month').format('YYYY-MM-DD'),
        value: moment().subtract(i, 'M').endOf('month').format('YYYY-MM-DD'),
        isDisabled: moment().subtract(i, 'M').endOf('month').unix() < moment(from).unix() ? true : false,
      });
    }
    return { monthsStart, monthsEnd };
  }, [from, to]);

  const params = useMemo(() => {
    return queryString.stringify({
      dateStart: from,
      dateEnd: to,
      per_page: 10000,
      page: 1,
    });
  }, [from, to]);

  const { data, isFetching, isSuccess } = useGetDocuments(params);

  const [code, setCode] = useState('');
  const [docs, setDocs] = useState(null);

  const { list, sortOptions, sort } = useSorting(docs);

  const pageTotal = Math.ceil(docs?.length / productsPerPage?.value);

  const paginatedData = useMemo(() => {
    return list?.filter((item, index) => {
      return index < page * productsPerPage.value && index >= (page - 1) * productsPerPage.value;
    });
  }, [page, productsPerPage, list]);

  const throttled = useRef(debounce(500, (callback) => callback()));

  const setFirstPage = useCallback(() => {
    const query = {
      direction: direction,
      sort_by: sortBy,
      page: 1,
    };
    if (!direction) delete query.direction;
    if (!sortBy) delete query.sort_by;

    history.push({
      pathname: pathname,
      search: queryString.stringify(query),
    });
  }, [pathname, direction, sortBy]);

  const findDocuments = useCallback(
    (value) => {
      const filtredDocs = data?.data?.filter((item) => item?.nomber?.toString().includes(value) || item?.deb?.toString().includes(value));
      setDocs(filtredDocs);
      setFirstPage();
    },
    [data, setFirstPage]
  );

  const handleChange = useCallback(
    (value) => {
      setCode(value);
      throttled.current(findDocuments.bind(null, value));
    },
    [throttled, findDocuments]
  );

  const handlePerPageChange = useCallback(
    (data) => {
      setProductsPerPage(data);
      setFirstPage();
    },
    [setFirstPage, setProductsPerPage]
  );

  useEffect(() => {
    if (isSuccess) setDocs(data.data || []);
  }, [isSuccess]);

  return (
    <>
      {isFetching && <GlobalPreloader />}

      <div className="nav-bottom report">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="wrapper">
              <div className="title">
                <h3>{t('page_title.report')}</h3>
              </div>

              <DatePicker from={from} to={to} monthsStart={monthsStart} monthsEnd={monthsEnd} onFrom={clickFrom} onTo={clickTo} />
            </div>

            <p className="debt">
              {t('label.current_debt')}: <span className="debt__amount">{user?.balance}</span> {t('label.currency')}
            </p>

            <div className="input-wrap">
              <input
                type="text"
                value={code}
                placeholder={t('label.enter_document_number')}
                onChange={(event) => handleChange(event.target.value)}
              />
            </div>
          </div>

          {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>
      </div>

      <div className="wrapper">
        {docs && paginatedData && (
          <>
            <section className="report-table">
              <ReportHeader onSort={sort} sortOptions={sortOptions} />

              {paginatedData?.map((item) => {
                const hasNoDetailsPage = [43, 60, 61].includes(Number(item.kinddoc_id));

                return (
                  <ReportItem
                    key={item?.doc_id}
                    payDate={item?.sday}
                    income={item?.kred}
                    expense={item?.deb}
                    name={item?.docname}
                    id={item?.doc_id}
                    number={item?.nomber}
                    date={item?.data}
                    department={item?.otdel}
                    disabled={hasNoDetailsPage}
                  />
                );
              })}
            </section>
            {paginatedData?.length !== 0 && (
              <PagePagination
                pageCount={pageTotal}
                page={page}
                paginationOptions={paginationOptions()}
                itemsTotal={docs.length}
                itemsOnPage={paginatedData?.length}
                itemsPerPage={productsPerPage}
                setItemsPerPage={handlePerPageChange}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default compose(withPageLayout(), withAuth())(Report);
