import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { preventLinkRedirect } from 'utils/preventLinkRedirect';
import { normalizeStringWithSpace } from 'utils/normalizeString';

const ProductName = memo((props) => {
  const { isColumn, id, name, targetBlank, disabledLink } = props;
  const target = targetBlank ? '_blank' : '_self';
  const link = `/product-details/${id}`;

  return (
    <div className={classNames('product-name', { column: isColumn })}>
      <Link
        to={link}
        target={target}
        className="product-name__text link link_col-sapphire"
        onClick={(event) => preventLinkRedirect(event, disabledLink)}
      >
        {normalizeStringWithSpace(name)}
      </Link>
    </div>
  );
});

export default ProductName;
