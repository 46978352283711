import { useEffect, useState, useCallback, memo } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import history from 'services/history';
import useFilterValuesFromQuery from 'hooks/useFilterValuesFromQuery';
import normalizeQueryParams from 'utils/normalizeQueryParams';
import Icon, { ICON_NAMES } from 'components/Icon/index';

const DuplicatedOption = memo((props) => {
  const { data, removeSearchOptionHandler, clearAll, removeAllSearchOptionHandler } = props;
  const option = data ? data.split('=')[1] : null;

  const { t } = useTranslation();

  return clearAll ? (
    <li className="tag-list__item">
      <span className="tag-name">{t('filter.remove_all_filters')}</span>
      <span className="btn-close" onClick={() => removeAllSearchOptionHandler()}>
        <Icon name={ICON_NAMES.close} fill={'#ff0000'} />
      </span>
    </li>
  ) : (
    <li className="tag-list__item">
      <span className="tag-name">{option}</span>
      <span className="btn-close" onClick={() => removeSearchOptionHandler(data)}>
        <Icon name={ICON_NAMES.close} fill={'#ff0000'} />
      </span>
    </li>
  );
});

const DuplicateFilterGroup = memo(() => {
  const [duplicatedFilters, setDuplicatedFilters] = useState([]);

  const location = useLocation();
  const filterFromQuery = useFilterValuesFromQuery();

  useEffect(() => {
    const query = normalizeQueryParams(
      queryString.parse(location.search, {
        arrayFormat: 'separator',
        arrayFormatSeparator: '|',
      }),
      true
    );

    function paramsSerializer(params) {
      const selectedFilterOptions = [];

      Object.entries({ ...params }).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            selectedFilterOptions.push(`${key}=${item}`);
          });
        } else {
          selectedFilterOptions.push(`${key}=${value}`);
        }
      });

      return selectedFilterOptions;
    }
    setDuplicatedFilters(paramsSerializer(query));
  }, [location, setDuplicatedFilters]);

  const removeSearchOptionHandler = useCallback(
    (item) => {
      const [key, value] = item.split('=');
      const search = filterFromQuery(key, value, history.location.search);
      history.removeSearchOption(search, history.location.pathname, item);
      setDuplicatedFilters(duplicatedFilters.filter((i) => i !== item));
    },
    [filterFromQuery, duplicatedFilters]
  );

  const removeAllSearchOptionHandler = useCallback(() => {
    history.push({
      pathname: history.location.pathname,
    });
    setDuplicatedFilters([]);
  }, []);

  return (
    duplicatedFilters.length !== 0 && (
      <div className="tags-wrap">
        <ul className="filter__tag-list tag-list">
          <DuplicatedOption clearAll removeAllSearchOptionHandler={removeAllSearchOptionHandler} />

          {duplicatedFilters.map((filter) => (
            <DuplicatedOption key={filter} data={filter} removeSearchOptionHandler={removeSearchOptionHandler} />
          ))}
        </ul>
      </div>
    )
  );
});

export default DuplicateFilterGroup;
