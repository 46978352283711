import React from 'react';
import classNames from 'classnames';
import Icon, { ICON_NAMES } from 'components/Icon/index';
import { useRunningLine } from '../useRunningLine';

export function RunningLine(props) {
  const { bannerController } = props;

  const { testRef, mainRef, content, running } = useRunningLine(bannerController.data.runningLine.text);

  if (!bannerController.data || !bannerController.data.runningLine) {
    return null;
  }

  const onClick = () => {
    if (bannerController.data.runningLine.link) {
      window.open(bannerController.data.runningLine.link, '_self');
      bannerController.actions.handleRunningLineCloseButton();
    }
  };

  return (
    <div className="main-header__notify header-notify">
      <div className="header-notify__wrapper">
        <div className="header-notify__body" style={{ backgroundColor: bannerController.data.color }}>
          <div
            ref={mainRef}
            style={{ cursor: bannerController.data.runningLine.link ? 'pointer' : 'auto' }}
            className={classNames('header-notify__text', { running: running })}
            onClick={onClick}
          >
            {content.map((text, index) => (
              <p key={index} style={{ animationDuration: bannerController.data.runningLine.time + 's' }}>
                {text}
              </p>
            ))}
          </div>

          <div ref={testRef} className="header-notify__text-tester">
            <p>{bannerController.data.runningLine.text}</p>
          </div>

          <button type="button" className="header-notify__btn" onClick={bannerController.actions.handleRunningLineCloseButton}>
            <Icon name={ICON_NAMES.close} fill="currentColor" />
          </button>
        </div>
      </div>
    </div>
  );
}
