import React, { memo } from 'react';

export const Preloader = memo(() => (
  <div className="loading-wrap">
    <span className="loading-anim" />
  </div>
));

export const GlobalPreloader = memo(() => (
  <div className="overlay-backdrop">
    <div className="loading-wrap">
      <span className="preloader" />
    </div>
  </div>
));
