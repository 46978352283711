import { useMutation, useQueryClient } from 'react-query';
import { useApi } from 'services/api';
import i18n from 'services/i18n/i18n';
import logger from 'services/logger';

export const useUpdateSettings = () => {
  const { settings, setContentLanguage } = useApi();
  const queryClient = useQueryClient();

  return useMutation(settings.updateSettings, {
    retry: false,
    onSuccess: ({ data }) => {
      const locale = data?.settings?.locale;
      setContentLanguage(locale);
      i18n.changeLanguage(locale);
    },
    onError: (error) => logger(error),
    onSettled: (newFavorites, error, variables, context) => {
      queryClient.invalidateQueries();
    },
  });
};
