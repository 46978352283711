import i18n from 'services/i18n/i18n';

export const paymentOptions = () => {
  return [
    {
      label: i18n.t('label.cash'),
      value: 1,
    },
    {
      label: i18n.t('label.non_cash'),
      value: 2,
    },
  ];
};
