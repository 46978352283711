import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import SortArrows from 'components/SortArrows/SortArrows';

const ProductsHeader = memo(({ sortOptions, onSort, warehouses }) => {
  const { t } = useTranslation();

  return (
    <div className="titles-row">
      <div className="col-title checkbox-title"></div>
      <div className="col-title prod-img-title"></div>
      <div className="col-title product-name-title">
        <div className="label-wrap" onClick={() => onSort(null, sortOptions.name)}>
          <span className="titles-row__title">{t('products.nomination')}</span>
          <div className="btn-square">
            <SortArrows name={sortOptions.name} />
          </div>
        </div>
      </div>
      <div className="col-title vendor-code-title">
        <div className="label-wrap" onClick={() => onSort(null, sortOptions.article)}>
          <span className="titles-row__title">{t('products.article')}</span>
          <div className="btn-square">
            <SortArrows name={sortOptions.article} />
          </div>
        </div>
      </div>
      <div className="col-title brand-title">
        <div className="label-wrap" onClick={() => onSort(null, sortOptions.manufacturer)}>
          <span className="titles-row__title">{t('products.brand')}</span>
          <div className="btn-square">
            <SortArrows name={sortOptions.manufacturer} />
          </div>
        </div>
      </div>
      <div className="col-title prod-price-title">
        <div className="label-wrap" onClick={() => onSort(null, sortOptions.price)}>
          <span className="titles-row__title">{t('products.price')}</span>
          <div className="btn-square">
            <SortArrows name={sortOptions.price} />
          </div>
        </div>
      </div>
      <div className="col-title quantity-goods-title">
        <span></span>
        <span className="titles-row__title">{t('label.quantity')}</span>
        <span></span>
      </div>
      <div className="col-title accessible-title">
        <span className="titles-row__title">{t('label.available')}</span>
      </div>
      <div className="col-title total-price-title">
        <div className="label-wrap" onClick={() => onSort(null, sortOptions.sum)}>
          <span className="titles-row__title">{t('select.products_sort.price_total')}</span>
          <div className="btn-square">
            <SortArrows name={sortOptions.sum} />
          </div>
        </div>
      </div>

      <div className="col-title num-prod-title">
        <div className="num-prod-title__wrapper">
          {warehouses?.map((warehouse) => (
            <span className="num-prod-title__item" key={warehouse.label}>
              {warehouse.short_label}
            </span>
          ))}
        </div>
      </div>
      <div className="delete-title" />
    </div>
  );
});

export default ProductsHeader;
