export const articleKeys = {
  articles: (params) => ['articles', params],
  article: (slug) => ['article', slug],
  unreadArticle: (type) => [`unread_${type}`],
  articleProducts: (id) => ['articleProducts', id],
};

export const authKeys = {
  token: () => ['token'],
};

export const aboutUsKeys = {
  aboutUs: (storage) => ['aboutUs', storage],
};

export const tecdocKeys = {
  manufacturers: () => ['manufacturers'],
  models: (id) => ['models', id],
  vehicles: (ids) => ['vehicles', ids],
  nodes: (id, targetType) => ['nodes', [id, targetType]],
  productsCatalogue: (nodeId, carId) => ['productsCatalogue', nodeId, carId],
  carSearchHistory: () => ['carSearchHistory'],
  carSearchFavorites: () => ['carSearchFavorites'],
  autoAbout: (id, targetType) => ['autoAbout', [id, targetType]],
};

export const basketKeys = {
  basket: () => ['basket'],
  basketTotal: () => ['basketTotal'],
  warehouseInfo: (warehouse) => ['warehouseInfo', { warehouse }],
};

export const productKeys = {
  product: (id) => ['product', id],
  catalogueTypes: () => ['catalogueTypes'],
  products: (params) => ['products', params],
  filters: (params) => ['filters', params],
  favoritesTotal: () => ['favoritesTotal'],
};

export const dashboardKeys = {
  dashboard: () => ['dashboard'],
  feedbackSubjectsList: () => ['feedbackSubjectsList'],
};

export const notificationKeys = {
  notification: (params) => ['notifications', params],
  banner: () => ['banner'],
  notificationsInfo: () => ['notificationsInfo'],
  surveyList: () => ['surveyList'],
};

export const orderKeys = {
  orders: (params) => ['orders', params],
  order: (id) => ['order', { id }],
};

export const otherKeys = {
  downloads: () => ['downloads'],
  paymentDetails: () => ['paymentDetails'],
  userBalances: () => ['userBalances'],
  documents: (data) => ['documents', { data }],
  document: (id) => ['document', { id }],
  documentExcel: (id) => ['documentExcel', { id }],
  documentPdf: (id) => ['documentPdf', { id }],
};

export const profileKeys = {
  user: () => ['user'],
  managers: () => ['managers'],
};

export const searchKeys = {
  searchBrands: (data) => ['searchBrands', data],
  searchProducts: (data) => ['searchProducts', data],
  searchProductsName: (data) => ['searchProductsName', data],
  searchHistory: () => ['searchHistory'],
};

export const loyaltyProgramsKeys = {
  loyaltyPrograms: (params) => ['loyaltyPrograms', params],
  unreadLoyalty: () => [`unread_loyaltyPrograms`],
};
