import React, { memo } from 'react';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import Icon, { ICON_NAMES } from 'components/Icon/index';

const Pagination = memo(({ pageCount, onChange, sm, lg, page }) => {
  const { t } = useTranslation();

  switch (true) {
    case lg: {
      return pageCount <= 1 ? null : (
        <ReactPaginate
          pageCount={pageCount}
          forcePage={page - 1}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          containerClassName="pagination-wrap__center"
          pageClassName="m-hide"
          breakClassName="break"
          pageLinkClassName=""
          activeClassName="active"
          previousClassName="previous-page"
          previousLinkClassName=""
          nextClassName="next-page"
          nextLinkClassName=""
          previousLabel={<Icon name={ICON_NAMES.paginationArrowLeft} />}
          nextLabel={<Icon name={ICON_NAMES.paginationArrowRight} />}
          onPageChange={onChange}
        />
      );
    }
    case sm: {
      return (
        <div className="pagination-wrap__center">
          <button type="button" onClick={() => onChange(1)} className="mob-show">
            <Icon name={ICON_NAMES.paginationArrowStart} />
          </button>
          <button type="button" onClick={() => onChange(page - 1)} disabled={page === 1}>
            <Icon name={ICON_NAMES.paginationArrowLeft} />
          </button>
          <ul className="pagination-wrap__list">
            <li className="current-page mob-show">{t('pagination.page_label_sm', { currentPage: page, totalPage: pageCount })}</li>
          </ul>
          <button type="button" onClick={() => onChange(page + 1)} disabled={page === pageCount}>
            <Icon name={ICON_NAMES.paginationArrowRight} />
          </button>
          <button type="button" onClick={() => onChange(pageCount)} className="mob-show">
            <Icon name={ICON_NAMES.paginationArrowEnd} />
          </button>
        </div>
      );
    }
    default:
      return null;
  }
});

export default Pagination;
