import React, { memo } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { Link } from 'react-router-dom';

import { imgUrlToResizeUrl } from 'utils/imgUrlToResizeUrl';

const SliderItem = memo(({ image, path }) => (
  <Link to={path}>
    <img src={imgUrlToResizeUrl(image, 1540, 744, 'crop')} width="870" height="420" alt="Article preview" />
  </Link>
));

const ArticleSlider = memo((props) => {
  const { articles, plug, name } = props;

  const autoPlay = articles?.length > 1 ? true : false;
  const isButtonsDisabled = articles?.length <= 1 ? true : false;

  return (
    <div className="slider">
      <AliceCarousel
        infinite={true}
        disableButtonsControls={isButtonsDisabled}
        autoPlayStrategy={'all'}
        autoPlay={autoPlay}
        touchTracking={false}
        autoPlayInterval="3000"
      >
        {articles?.length !== 0 ? (
          articles?.map((item, i) => <SliderItem path={`${name}/${item?.seo_slug}`} image={item?.image} key={i} />)
        ) : (
          <img src={imgUrlToResizeUrl(plug, 1540, 744, 'crop')} width="870" height="420" alt="Article preview" />
        )}
      </AliceCarousel>
    </div>
  );
});

export default ArticleSlider;
