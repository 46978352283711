import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';
import MaskInput from 'react-maskinput';

import { paymentOptions } from 'config/constants/paymentOptions';
import SelectField from '../SelectField';

const ContactPerson = memo((props) => {
  const { user, savedName, savedPhone } = props;

  const normalizedPhone = (() => {
    if (!user && !savedPhone) return null;

    let number = savedPhone?.replace('+', '') || user?.profile?.phone?.replace('+', '') || user?.phone?.replace('+', '');

    if (number.charAt(0) === '3' && number.charAt(1) === '8') number = number.slice(2);
    if (number.charAt(0) === '8') number = number.slice(1);
    return `38${number}`;
  })();

  const { t } = useTranslation();

  const formState = useFormState();
  const selectedStorage = formState.values['storage'];

  return (
    <div className="step-four">
      <div className="fields">
        {!selectedStorage && <div className="overlay overlay_solitude"></div>}

        <Field
          name="userName"
          initialValue={savedName || user?.profile?.name}
          validate={(value) => (value ? null : t('errors.required_field'))}
        >
          {({ input, meta }) => (
            <div className="field-wrap">
              <p>{t('basket.label.full_name')}</p>

              <div className="input-wrap input-wrap_small-field">
                <input {...input} type="text" placeholder={t('basket.label.default_placeholder')} />
                {meta.error && meta.touched && <span className="form-field_error">{meta.error}</span>}
              </div>
            </div>
          )}
        </Field>

        <Field
          name="userPhone"
          initialValue={normalizedPhone || ''}
          validate={(value) => {
            if (value?.length <= 2) return t('errors.required_field');
            if (value?.length < 12) return t('errors.enter_right_phone_number');
          }}
          parse={(value) => value && value.replace(/\D/g, '')}
        >
          {({ input, meta }) => (
            <div className="field-wrap">
              <p>{t('basket.label.phone')}</p>

              <div className="input-wrap input-wrap_small-field">
                <MaskInput {...input} type="text" inputMode="numeric" mask={'+38 (000) 000 00 00'} showMask />
                {meta.error && meta.touched && <span className="form-field_error-bottom">{meta.error}</span>}
              </div>
            </div>
          )}
        </Field>

        <Field
          size="lg"
          name="payment"
          component={SelectField}
          options={paymentOptions()}
          header={t('select.basket.payment_method')}
          defaultLabel={t('select.basket.choose')}
          validate={(value) => (value ? null : t('errors.required_field'))}
        />
      </div>
    </div>
  );
});

export default ContactPerson;
