import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useApi } from 'services/api';
import { orderKeys, basketKeys } from 'services/queryClient/queryKeys';
import logger from 'services/logger';

export const useMakeOrder = () => {
  const { order } = useApi();
  const queryClient = useQueryClient();

  return useMutation(order.makeOrder, {
    retry: false,
    onError: (error) => logger(error),
    onSettled: () => queryClient.invalidateQueries(basketKeys.basket()),
  });
};

export const useGetOrderHistory = (params) => {
  const { order } = useApi();

  return useQuery(orderKeys.orders(params), () => order.getOrderHistory(params), {
    select: (res) => res?.data,
    onError: (error) => logger(error),
    keepPreviousData: true,
  });
};

export const useGetOrder = (id) => {
  const { order } = useApi();

  return useQuery(orderKeys.order(id), () => order.getOrder(id), {
    select: (res) => ({
      order: res?.data?.order,
      products: res?.data?.products,
    }),
    onError: (error) => logger(error),
  });
};
