import { useQuery } from 'react-query';
import { useApi } from 'services/api';
import { aboutUsKeys } from 'services/queryClient/queryKeys';
import logger from 'services/logger';

export const useGetAboutUs = (storage) => {
  const { aboutUs } = useApi();

  return useQuery(aboutUsKeys.aboutUs(storage), () => aboutUs.getAboutUsData(storage), {
    select: (res) => res.data,
    onError: (error) => logger(error),
    keepPreviousData: false,
  });
};
