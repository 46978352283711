import React from 'react';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import logo from '../../assets/images/login/logo.svg';
import background from '../../assets/images/login/bg.png';
import withNoAuth from 'HOC/withNoAuth/withNoAuth';
import { useRequestPasswordChange } from 'hooks/api/useAuth';

const initialValues = { login: '' };

function PasswordRecovery() {
  const { t } = useTranslation();

  const onSubmit = (event) => requestPasswordChange({ email: event.login });

  const { mutate: requestPasswordChange } = useRequestPasswordChange();
  // values.email.length > 0 && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  return (
    <section className="login" style={{ backgroundImage: `url(${background})` }}>
      <div className="login__container-m container-m">
        <div className="login__img-wrap">
          <img src={logo} width="366" height="100" alt="logo" />
          <span>{t('label.give_peoples_the_best')}</span>
        </div>

        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={(values) => {
            const errors = {};
            if (!values.login) errors.login = t('errors.required_field');
            if (values.login && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.login)) {
              errors.login = t('errors.wrong_email');
            }
            return errors;
          }}
          render={({ handleSubmit }) => {
            return (
              <form className="form" onSubmit={handleSubmit}>
                <div className="form__title title">
                  <h3>
                    {t('title.password_recovery')}
                    <br />
                    {t('label.b2b_avtolider')}
                  </h3>
                </div>
                <div className="list-fields">
                  <Field name="login">
                    {({ input, meta }) => (
                      <div className="item">
                        <span className="item__title">{t('label.login')}</span>
                        <div className="item__input-wrap">
                          <input {...input} type="text" placeholder={t('label.enter_login')} />

                          {meta.error && meta.touched && <span className="form-field_error">{meta.error}</span>}
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
                <div className="form__btn-wrap">
                  <NavLink to="/login" className="link link_col-blue">
                    {t('action.remember')}?
                  </NavLink>
                  <button className="btn">{t('action.send')}</button>
                </div>
              </form>
            );
          }}
        />
      </div>
    </section>
  );
}

export default withNoAuth()(PasswordRecovery);
