import React, { memo } from 'react';
import { useScrollTo } from 'hooks/useScrollTo';

const Manufacturers = memo(({ manufacturer, manufacturers, setManufacturer }) => {
  useScrollTo(manufacturer);

  return (
    <ul className="car-search">
      {manufacturers?.map((manufacturer) => {
        return (
          <li key={manufacturer.id} className="car-search__link-wrap">
            <button type="button" className="car-brand" onClick={() => setManufacturer(manufacturer)}>
              <div className="car-brand__img-wrap default">
                <img src={`${process.env.REACT_APP_API_URL}/assets/brands/${manufacturer.id}.png`} alt="" />
              </div>
              <span className="car-brand__name">{manufacturer.name}</span>
            </button>
          </li>
        );
      })}
    </ul>
  );
});

export default Manufacturers;
