export function getCategories(categories = []) {
  const { toolsCategories, restCategories } = categories.reduce(
    (acc, c) => {
      if (!c.subcategories) {
        acc.restCategories.push(c);
      }
      if (c.subcategories) {
        if (c.subcategories.key in acc.toolsCategories) {
          acc.toolsCategories[c.subcategories.key].list.push(c);
        } else {
          acc.toolsCategories[c.subcategories.key] = {
            label: c.subcategories.label,
            list: [c],
          };
        }
      }

      return acc;
    },
    { toolsCategories: {}, restCategories: [] }
  );

  return { toolsCategories: Object.values(toolsCategories), restCategories };
}
