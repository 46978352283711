export function imgUrlToResizeUrl(url, width, height, type = 'crop', isSite) {
  if (type !== 'crop' && type !== 'resize') return url;

  if (!url) return null;

  if (!Number.isInteger(width) || !Number.isInteger(height)) return url;

  if (type === 'resize' && isSite)
    return `${process.env.REACT_APP_SITE_URL}/images/r/${width}x${height}${url}`;
  if (type === 'crop' && isSite)
    return `${process.env.REACT_APP_SITE_URL}/images/${width}x${height}${url}`;

  if (type === 'resize')
    return `${process.env.REACT_APP_API_URL}/images/r/${width}x${height}${url}`;
  if (type === 'crop')
    return `${process.env.REACT_APP_API_URL}/images/${width}x${height}${url}`;
}
