import React, { memo, useEffect, useMemo } from 'react';
import { Field, useFormState, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Countdown from 'react-countdown';
import moment from 'moment';

import { normalizeDeliveryData, DELIVERY_METHODS } from 'utils/deliveryDataUtils';
import { useGetDeliveryInfo } from 'hooks/api/useBasket';
import SelectField from '../SelectField';
import { GlobalPreloader } from 'components/Preloader/Preloader';

const StorageSelect = memo(({ storagesList }) => {
  const { t } = useTranslation();

  const form = useForm();
  const formState = useFormState();
  const selectedStorage = formState.values['storage'];
  const deliveryMethod = formState.values['deliveryMethod'];

  const { data, refetch: getDeliveryInfo, isFetching } = useGetDeliveryInfo(selectedStorage);

  const dispatchOptions = useMemo(() => {
    if (!data) return [];

    const routeList = data?.routeList.length !== 0 ? [data?.routeList[0]] : [];
    return data ? [...data?.deliveryList, ...routeList] : [];
  }, [data]);

  useEffect(() => {
    if (selectedStorage) getDeliveryInfo();
  }, [getDeliveryInfo, selectedStorage]);

  useEffect(() => {
    if (data) {
      form.change('dispatchOptions', dispatchOptions ?? []);
      form.change('routeListDispatchOptions', data?.routeList ?? []);
    }
  }, [form, data, dispatchOptions]);

  const onResetField = () => {
    if (deliveryMethod) {
      form.change('deliveryMethod', null);
      form.change('deliveryDay', null);
      form.change('deliveryTime', null);
      form.change('routeListAddress', null);
      form.change('department', '');
      form.change('city', '');
    }
  };

  const storageOptions = storagesList.map((storage) => ({
    ...storage,
    detailsLink: storage.value === 'expr' ? null : `/about-us/${storage.value}`,
  }));

  return (
    <>
      {isFetching && <GlobalPreloader />}

      <div className="step-first">
        <div className="warehouse-wrap">
          <Field
            name="storage"
            component={SelectField}
            options={storageOptions}
            header={t('basket.label.choose_warehouse')}
            defaultLabel={t('basket.label.choose_warehouse_input')}
            onReset={onResetField}
          />
        </div>

        {dispatchOptions && selectedStorage !== 'expr' && (
          <div className="step-first__dispatch dispatch">
            {dispatchOptions?.map((item, index) => {
              const dateTarget = moment(`${moment(item.day).format('YYYY-MM-DD')} ${item.tm}`).format('YYYY-MM-DDTHH:mm:ss');

              if (DELIVERY_METHODS.other.includes(item.carrier_id)) return null;

              return (
                <p key={item.carrier_id}>
                  <span className="dispatch__text">{normalizeDeliveryData(item)} </span>
                  {item.day && (
                    <span className="dispatch__time">
                      <Countdown key={item.day + item.tm} onComplete={getDeliveryInfo} daysInHours={true} date={dateTarget} />
                    </span>
                  )}
                  {!item.day && (
                    <span className="dispatch__time">
                      <span>-- : -- : --</span>
                    </span>
                  )}
                </p>
              );
            })}
          </div>
        )}
        {dispatchOptions && selectedStorage === 'expr' && (
          <div className="step-first__dispatch dispatch dispatch__express-message">
            <div>
              <p className="">{t('info.check_departure_date_and_time_with_manager')}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
});

export default StorageSelect;
