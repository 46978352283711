import React, { memo } from 'react';
import Select from 'react-select';

const InputSelect = memo((props) => {
  const { options, value, onSelect, placeholder, blockScroll = false } = props;

  return (
    <Select
      options={options}
      placeholder={placeholder}
      components={{ IndicatorSeparator: () => null }}
      value={value}
      menuShouldBlockScroll={blockScroll}
      onChange={onSelect}
      isDisabled={options.length === 0}
      styles={{
        control: (provided, state) => ({
          width: state.selectProps.width,
          border: 'none',
          borderBottom: '1px solid #e6ebf2',
          color: state.selectProps.menuColor,
          fontFamily: 'Manrope-Bold',
          padding: '0 0 0 20px',
          minHeight: '60px',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          fontSize: '14px',
          cursor: 'pointer',
          opacity: state.isDisabled ? 0.5 : 1,
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: '1px 2px',
        }),
        singleValue: (provided, state) => {
          return {
            ...provided,
            color: '#0a2352',
            display: state.selectProps.menuIsOpen ? 'none' : 'block',
          };
        },
        placeholder: (provided) => ({
          ...provided,
          color: '#8f94b3',
        }),
        input: (provided) => ({
          ...provided,
          color: '#0a2352',
        }),
        menu: (provided) => ({
          ...provided,
          marginTop: 0,
          boxShadow: '0 40px 120px rgb(0 0 0 / 10%)',
        }),
        menuList: (provided) => ({
          ...provided,
          paddingTop: 0,
          maxHeight: '480px',
        }),
        option: (provided, state) => {
          const isSelected = state.isSelected;

          return {
            ...provided,
            fontFamily: 'Manrope-Bold',
            color: isSelected ? '#0a2352' : '#8f94b3',
            opacity: isSelected ? '0.3' : '1',
            cursor: isSelected ? 'default' : 'pointer',
            pointerEvents: isSelected ? 'none' : 'auto',

            display: 'flex',
            alignItems: 'center',
            height: '60px',
            padding: '0 20px',
            transition: '0.3s',
            borderTop: '1px solid #e6ebf2',
          };
        },
        indicatorsContainer: (provided) => ({
          ...provided,
          justifyContent: 'center',
          width: '60px',
        }),
        dropdownIndicator: (provided, state) => ({
          '& svg': { display: 'none' },
          display: 'flex',
          alignItem: 'center',
          justifyContent: 'center',
          border: '5px solid transparent',
          borderTop: '5px solid #0a2352',
          borderBottom: 'none',
        }),
      }}
    />
  );
});

export default InputSelect;
