import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Icon, { ICON_NAMES } from 'components/Icon/index';

const Modal = memo(({ onClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="overlay-backdrop" onClick={onClose} />

      <div className="modal">
        <div className="modal__wrap">
          <div className="modal__title">
            <h3>{t('label.attention')}</h3>
            <button type="button" className="btn-close" onClick={onClose}>
              <Icon name={ICON_NAMES.close} fill="#0A2352" />
            </button>
          </div>
          <div className="modal__content">{t('info.password_recovery_instruction')}</div>
        </div>
      </div>
    </>
  );
});

export default Modal;
