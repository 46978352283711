export function getCatalogueNavigationUrl(catalogue) {
  const hasSubcategories = Array.isArray(catalogue.categories) && catalogue.categories.length > 0;

  if (catalogue.name === 'auto_search') {
    return '/tecdoc-search';
  }

  if (hasSubcategories) {
    return `/catalogue/${catalogue.name}/${catalogue.categories[0].name}`;
  }

  return `/catalogue/${catalogue.name}`;
}
