import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Form, Field } from 'react-final-form';

import logo from '../../assets/images/login/logo.svg';
import background from '../../assets/images/login/bg.png';
import { useLogin } from 'hooks/api/useAuth';
import useToastNotification from 'hooks/useToastNotification';
import withNoAuth from 'HOC/withNoAuth/withNoAuth';
import history from 'services/history';
import Modal from './Modal';

function Login() {
  const { mutate: login, data } = useLogin();
  const accountBlocked = data?.data?.blocked;

  const { state } = useLocation();
  const { t } = useTranslation();
  const { notify, notifyStatus } = useToastNotification();

  const onSubmit = (event) => {
    login({
      email: event.email,
      password: event.password,
    });
  };

  useEffect(() => {
    if (accountBlocked) {
      notify(t('info.your_profile_is_blocked'), notifyStatus.error);
    }
  }, [accountBlocked]);

  const close = () => history.replace('/login', null);

  return (
    <section className="login" style={{ backgroundImage: `url(${background})` }}>
      <div className="login__container-m container-m">
        <div className="login__img-wrap">
          <img src={logo} width="366" height="100" alt="logo" />
          <span>{t('label.give_peoples_the_best')}</span>
        </div>

        {state && <Modal onClose={close} />}

        <Form
          onSubmit={onSubmit}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = t('errors.required_field');
            }
            if (!values.password) {
              errors.password = t('errors.required_field');
            }
            return errors;
          }}
          render={({ handleSubmit }) => {
            return (
              <form className="form" onSubmit={handleSubmit}>
                <div className="form__title title">
                  <h3>
                    {t('title.auth')}
                    <br />
                    {t('label.b2b_avtolider')}
                  </h3>
                </div>
                <div className="list-fields">
                  <Field name="email">
                    {({ input, meta }) => (
                      <div className="item">
                        <span className="item__title">{t('label.login')}</span>
                        <div className="item__input-wrap">
                          <input {...input} autoComplete="off" type="text" placeholder={t('label.enter_login')} />

                          {meta.error && meta.touched && <span className="form-field_error">{meta.error}</span>}
                        </div>
                      </div>
                    )}
                  </Field>

                  <Field name="password">
                    {({ input, meta }) => (
                      <div className="item">
                        <span className="item__title">{t('label.password')}</span>
                        <div className="item__input-wrap">
                          <input {...input} autoComplete="off" type="password" placeholder={t('label.enter_password')} />

                          {meta.error && meta.touched && <span className="form-field_error">{meta.error}</span>}
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
                <div className="form__btn-wrap">
                  <Link to="/password-recovery" className="link link_col-blue">
                    {t('action.forgot_password')}
                  </Link>
                  <button className="btn" type="submit">
                    {t('action.authorization')}
                  </button>
                </div>
              </form>
            );
          }}
        />

        <a href="https://al1.ua/clients-form" target="_blank" rel="noreferrer" className="login__link">
          {t('action.become_member')}
        </a>
      </div>
    </section>
  );
}

export default withNoAuth()(Login);
