import React, { useRef, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import useWindowDimensions from 'hooks/useWindowDimensions';
import Icon, { ICON_NAMES } from 'components/Icon/index';
import useOutsideClick from 'hooks/useOutsideClick';
import useBodyScrollLock from 'hooks/useBodyScrollLock';
import { formatPrice } from 'utils/formatPrice';

const UserProfile = memo((props) => {
  const {
    setIsUserProfileInfoOpen,
    balanceCash,
    balanceCashless,
    isUserProfileInfoOpen,
    logoutHandler,
    userName,
    userEmail,
    userCode,
    arrears,
    managerID,
  } = props;

  const { width } = useWindowDimensions();

  const { t } = useTranslation();

  const ref = useRef();
  useOutsideClick(ref, () => {
    setIsUserProfileInfoOpen(false);
  });

  const menuRef = useRef();
  useBodyScrollLock(menuRef, isUserProfileInfoOpen && width <= 1024);

  useEffect(() => {
    const height = window.innerHeight - 40;
    if (width < 1024 && menuRef && menuRef.current) {
      menuRef.current.style.height = height + 'px';
    }
  }, [width]);

  switch (true) {
    case width > 1024:
      return (
        <div className="user-profile-info card-shadow" ref={ref}>
          <div className="user-profile-info__name">{userName}</div>
          <div className="user-profile-info__code">
            {t('label.manager_cl')}:<span>{managerID}</span>
          </div>
          <div className="user-profile-info__code">
            {t('label.header_user_profile_code')}:<span>{userCode}</span>
          </div>
          <div className="user-profile-info__email">{userEmail}</div>

          <div className="profile-footer__wrap">
            <Link to="/profile">{t('action.client_profile')}</Link>
          </div>
        </div>
      );
    case width > 760 && width <= 1024:
      return (
        <>
          <div className="overlay-backdrop" onClick={() => setIsUserProfileInfoOpen(false)} />
          <div className="user-profile-info">
            <div className="user-profile-info__wrap" ref={menuRef}>
              <div>
                <div className="user-profile-info__name">{userName}</div>
                <button type="button" className="close-button" onClick={() => setIsUserProfileInfoOpen(false)}>
                  <Icon name={ICON_NAMES.close} fill="#fff" />
                </button>

                <div className="user-profile-info__code">
                  {t('label.manager_cl')}:<span>{managerID}</span>
                </div>
                <div className="user-profile-info__code">
                  {t('label.header_user_profile_code')}:<span>{userCode}</span>
                </div>
                <div className="user-profile-info__email">{userEmail}</div>

                <div className="user-profile-info-arrears">
                  <div className="user-profile-info-arrears__text">
                    {(balanceCash.isDebt || balanceCashless.isDebt) && t('label.your_debt_is')}
                    {!balanceCash.isDebt && !balanceCashless.isDebt && t('label.no_debt')}
                  </div>

                  <div className="user-profile-info-arrears__sum">
                    <div>
                      {balanceCash.balanceShow && (
                        <span className="balance-info__row">
                          <span className="balance-info__title">
                            <span className="balance-info__title-icon">
                              <Icon name={ICON_NAMES.cash} fill="#fff" />
                            </span>
                          </span>
                          <span className={`balance-info__value ${balanceCash.balanceClassName}`}>
                            <span>{balanceCash.balance}</span> <span className="currency">{t('label.currency')}</span>
                          </span>
                        </span>
                      )}

                      {balanceCashless.balanceShow && (
                        <span className="balance-info__row">
                          <span className="balance-info__title">
                            <span className="balance-info__title-icon">
                              <Icon name={ICON_NAMES.cashless} fill="#fff" />
                            </span>
                          </span>
                          <span className={`balance-info__value ${balanceCashless.balanceClassName}`}>
                            <span>{balanceCashless.balance}</span> <span className="currency">{t('label.currency')}</span>
                          </span>
                        </span>
                      )}
                      {!balanceCash.balanceShow && !balanceCashless.balanceShow && (
                        <span className="balance-info__row">
                          <span className="balance-info__value">
                            <span>{formatPrice(0)}</span> <span className="currency">{t('label.currency')}</span>
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="user-profile-info-overdue">
                  <div className="user-profile-info-overdue__text">
                    {arrears?.overdueStatus}
                    <span>{arrears?.debtDateLabel}</span>
                  </div>
                  <div className={`user-profile-info-overdue__sum ${arrears?.overdueClassName}`}>
                    {arrears?.debt} {t('label.currency')}
                  </div>
                </div>
              </div>

              <div className="profile-footer__wrap">
                <Link to="/profile">{t('action.client_profile')}</Link>
              </div>
            </div>
          </div>
        </>
      );

    case width <= 760:
      return (
        <div className="user-profile-info">
          <div className="user-profile-info__wrap" ref={menuRef}>
            <div>
              <div className="user-profile-info-action-btn__wrap">
                <button type="button" className="btn-square log-out" onClick={() => logoutHandler()}>
                  <Icon name={ICON_NAMES.logOut} />
                </button>

                <button type="button" className="close-button" onClick={() => setIsUserProfileInfoOpen(false)}>
                  <Icon name={ICON_NAMES.close} fill="#fff" />
                </button>
              </div>

              <div className="user-profile-info__name">{userName}</div>
              <div className="user-profile-info__code">
                {t('label.manager_cl')}:<span>{managerID}</span>
              </div>
              <div className="user-profile-info__code">
                {t('label.header_user_profile_code')}:<span>{userCode}</span>
              </div>

              <div className="user-profile-info__email">{userEmail}</div>

              <div className="user-profile-info-arrears">
                <div className="user-profile-info-arrears__text">
                  {(balanceCash.isDebt || balanceCashless.isDebt) && t('label.your_debt_is')}
                  {!balanceCash.isDebt && !balanceCashless.isDebt && t('label.no_debt')}
                </div>
                <div className="user-profile-info-arrears__sum">
                  <div>
                    {balanceCash.balanceShow && (
                      <span className="balance-info__row">
                        <span className="balance-info__title">
                          <span className="balance-info__title-icon">
                            <Icon name={ICON_NAMES.cash} fill="#fff" />
                          </span>
                        </span>
                        <span className={`balance-info__value ${balanceCash.balanceClassName}`}>
                          <span>{balanceCash.balance}</span> <span className="currency">{t('label.currency')}</span>
                        </span>
                      </span>
                    )}

                    {balanceCashless.balanceShow && (
                      <span className="balance-info__row">
                        <span className="balance-info__title">
                          <span className="balance-info__title-icon">
                            <Icon name={ICON_NAMES.cashless} fill="#fff" />
                          </span>
                        </span>
                        <span className={`balance-info__value ${balanceCashless.balanceClassName}`}>
                          <span>{balanceCashless.balance}</span> <span className="currency">{t('label.currency')}</span>
                        </span>
                      </span>
                    )}
                    {!balanceCash.balanceShow && !balanceCashless.balanceShow && (
                      <span className="balance-info__row">
                        <span className="balance-info__value">
                          <span>{formatPrice(0)}</span> <span className="currency">{t('label.currency')}</span>
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="user-profile-info-overdue">
                <div className="user-profile-info-overdue__text">
                  {arrears?.overdueStatus}
                  <span>{arrears?.debtDateLabel}</span>
                </div>
                <div className={`user-profile-info-overdue__sum ${arrears?.overdueClassName}`}>
                  {arrears?.debt} {t('label.currency')}
                </div>
              </div>
            </div>

            <div className="profile-footer__wrap">
              <Link to="/profile">{t('action.client_profile')}</Link>
            </div>
          </div>
        </div>
      );
    default:
      return null;
  }
});

export default UserProfile;
