import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { compose } from 'redux';
import moment from 'moment';

import MarkNewsAsRead from 'feature/MarkNewsAsRead';
import { paginationOptions } from 'config/constants/selectOptions';
import { useGetArticles } from 'hooks/api/useArticles';
import { useCatalogueTypes } from 'hooks/api/useProducts';
import useWindowDimensions from 'hooks/useWindowDimensions';
import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import withAuth from 'HOC/withAuth/withAuth';
import { CommonDataContext } from 'HOC/withCommonData/withCommonData';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import { GlobalPreloader } from 'components/Preloader/Preloader';
import PagePagination from 'components/PagePagination/PagePagination';
import SalesPreview from './SalesPreview/SalesPreview';
import { getFillValue } from 'utils/getAccumulativeSaleFillValue';

function Sales() {
  const { data: catalogueTypes } = useCatalogueTypes();
  const { newsPerPage, setNewsPerPage } = useContext(CommonDataContext);

  const location = useLocation();
  const page = Number(queryString.parse(location.search).page) || 1;

  const { data, isFetching } = useGetArticles({
    page,
    per_page: newsPerPage.value,
    type: 'sales',
  });

  const sales = data?.data;
  const salesTotal = data?.total;
  const salesOnPage = data?.data?.length;
  const pageTotal = data?.lastPage;

  const windowDimension = useWindowDimensions();

  const { t } = useTranslation();

  return (
    <>
      {isFetching && <GlobalPreloader />}

      <div className="nav-bottom sales-page">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="title">
              <h3>{t('page_title.sales')}</h3>
            </div>

            <MarkNewsAsRead type="sales" />
          </div>

          {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>
      </div>

      <div className="wrapper">
        {data && (
          <>
            <div className="sales">
              {sales.length > 0 &&
                sales.map((item) => {
                  const isExpired = item?.expires_in ? moment().unix() > moment(item.expires_in, 'YYYY-MM-DD hh:mm:ss').unix() : false;
                  const fillValue = getFillValue(item);
                  return (
                    <SalesPreview
                      key={item.id}
                      image={item.data.image}
                      title={item.data.title}
                      fillValue={fillValue}
                      isActive={Boolean(item?.deal?.registered)}
                      isRead={item.is_read}
                      isExpired={isExpired}
                      deal_type={item.deal_type}
                      starts_in={item.starts_in}
                      expires_in={item.expires_in}
                      short_description={item.data.short_description}
                      seo_slug={item.seo_slug}
                      views={item.views}
                    />
                  );
                })}

              {sales.length === 0 && (
                <div className="no-results">
                  <p>{t('info.no_active_sales')}</p>
                </div>
              )}
            </div>
            {sales.length !== 0 && (
              <PagePagination
                pageCount={pageTotal}
                page={page}
                paginationOptions={paginationOptions()}
                itemsTotal={salesTotal}
                itemsOnPage={salesOnPage}
                itemsPerPage={newsPerPage}
                setItemsPerPage={setNewsPerPage}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default compose(withPageLayout(), withAuth())(Sales);
