import { createContext, useMemo, useState, useContext, useCallback } from 'react';
import Axios from 'axios';
import { useQueryClient } from 'react-query';
import { handleRequest, handleResponse } from './config';

export const ApiContext = createContext(undefined);

export const ApiProvider = ({ children }) => {
  const getToken = useCallback(() => {
    const token = localStorage.getItem('token');
    if (!token) return null;
    return `Bearer ${token}`;
  }, []);

  const queryClient = useQueryClient();

  const [errors, setErrors] = useState([]);

  const api = useMemo(() => {
    const axios = Axios.create({
      timeout: 30000,
      baseURL: `${process.env.REACT_APP_API_URL}/api`,
      headers: {
        'Content-Language': localStorage.getItem('locale'),
        Authorization: getToken('token'),
      },
      validateStatus: (status) => status,
    });

    return axios;
  }, [getToken]);

  api.interceptors.request.use(handleRequest);
  api.interceptors.response.use((response) => handleResponse(response, logout, setErrors));

  const setToken = useCallback(
    (token) => {
      if (!token) {
        localStorage.removeItem('token');
        delete api.defaults.headers.Authorization;
        return;
      }
      localStorage.setItem('token', token);
      api.defaults.headers.Authorization = `Bearer ${token}`;
    },
    [api.defaults.headers]
  );

  const logout = useCallback(() => {
    localStorage.clear();
    queryClient.resetQueries('token', { exact: true });
    queryClient.invalidateQueries();
    delete api.defaults.headers.Authorization;
    return;
  }, [api.defaults.headers, queryClient]);

  const setContentLanguage = useCallback(
    (locale) => {
      if (!locale) {
        delete api.defaults.headers['Content-Language'];
        localStorage.setItem('locale', null);
        return;
      }
      api.defaults.headers['Content-Language'] = locale;
      localStorage.setItem('locale', locale);
    },
    [api.defaults.headers]
  );

  const settingsRequests = {
    updateSettings: (data) => api.post('/settings/update', data),
  };

  const authRequests = {
    login: (data) => api.post('/signin', data),
    resetPassword: (data) => api.post('/reset_password', data),
    requestPasswordChange: (data) => api.post('/forgot_password', data),
    updatePassword: (data) => api.post('/user/change_password', data),
  };

  const userRequests = {
    getUser: () => api.get('/user/profile'),
    getManagers: () => api.get('/user/managers'),
  };

  const aboutUsRequests = {
    getAboutUsData: (warehouse) => api.get(`/about_us/${warehouse}`),
  };

  const productsRequests = {
    getProducts: (data) => api.post('/catalog', data),
    getProductsByIds: (data) => api.post('/products', data),
    getFilters: (data) => api.post('/filters', data),
    getProductDetails: (data) => api.post('/product', data),
    getCatalogueTypes: () => api.post('/product_types'),
    getFavorites: (data) => api.post('/favorites/list', data),
    addToFavorites: (data) => api.post('/favorites/add', data),
    deleteFromFavorites: (data) => api.post('/favorites/delete', data),
    getFavoritesInfo: () => api.get('/favorites/info'),
    getProductPrices: (data) => api.post('/product/prices', data),
  };

  const articleRequests = {
    getArticles: (data) => api.post('/articles/b2b/list', data),
    getArticle: (data) => api.post('/articles/b2b', data),
    getUnreadArticles: (type) => api.get(`/articles/b2b/info?type=${type}`),
    getArticlesProducts: (id) => api.get(`/articles/${id}/products`),
    readAll: (data) => api.post('/articles/b2b/read', data),
    selectProductGift: (data) => api.post('/articles/register_gift', data),
  };

  const basketRequests = {
    getBasketData: (data) => api.post('/basket/list', data),
    addToBasket: (data) => api.post('/basket/add', data),
    updateBasket: (data) => api.post('/basket/update', data),
    deleteBasketItem: (data) => api.post('/basket/delete', data),
    deleteBasket: () => api.post('/basket/delete_all'),
    getBasketInfo: () => api.get('/basket/info'),
    getStorages: () => api.get('/warehouses'),
    getDeliveryInfo: (warehouse) => api.get(`/basket/delivery/${warehouse}`),
  };

  const orderRequests = {
    makeOrder: (data) => api.post('/orders/make', data),
    getOrderHistory: (data) => api.post('/orders/list', data),
    getOrder: (id) => api.get(`/orders/${id}`),
  };

  const notificationRequests = {
    getNotificationsInfo: () => api.get('/notifications/info'),
    getNotifications: (data) => api.post('/notifications/list', data),
    getSurvey: () => api.post('/global_notifications/list', {}),
    sendSurveyAnswer: (data) => api.post('/global_notifications/answer ', data),
    readNotification: (data) => api.post('/notifications/read', data),
    deleteNotification: (id) => api.post('/notifications/delete', id),
    getBanner: () => api.get('/banner'),
  };

  const dashboardRequests = {
    getDashboardData: () => api.get('/dashboard'),
    getFeedbackSubjectsList: () => api.post('/subjects/list', {}),
    sendFeedback: (data) => api.post('/feedbacks/create', data, { headers: { 'Content-Type': 'multipart/form-data' } }),
  };

  const wishlistRequests = {
    addToWishlist: (data) => api.post('/wishlist/add', data),
    removeFromWishlist: (data) => api.post('/wishlist/remove', data),
  };

  const otherRequests = {
    writeMessage: (data) => api.post('/user/message', data),
    getDownloads: () => api.get('/downloads'),
    getPaymentDetails: () => api.get('/payment_details'),
    getUserBalances: () => api.get('/user/balance'), // debts
    getDocumentById: (id) => api.get(`/documents/${id}`), // debt
    getDocuments: (query) => api.get(`/documents?${query}`), // documents
    downloadDocumentExcel: (id) => api.get(`/documents/csv/${id}`),
    downloadDocumentPdf: (id) => api.get(`/documents/pdf/${id}`, { responseType: 'arraybuffer' }),
  };

  const tecdocRequests = {
    getManufacturers: () => api.get('/manufacturers'),
    getModels: (manufacturerId) => api.get(`/models/${manufacturerId}`),
    getVehicle: (manufacturerId, modelId) => api.get(`/vehicles/${manufacturerId}/${modelId}`),
    getNodes: (vehicleId, targetType) => api.get(`/nodes/${vehicleId}/${targetType}`),
    getProductsByNode: (nodeId, carId) => api.get(`/search/node/${nodeId}/${carId}`),

    getCarSearchHistory: () => api.get('/storage/car_history'),
    addCarToHistory: (data) => api.post('/storage/car_history/add', data),
    removeCarFromHistory: (data) => api.post('/storage/car_history/remove', data),

    getCarSearchFavorites: () => api.get(`/storage/favorites`),
    addToFavorites: (data) => api.post('/storage/favorites/add', data),
    removeFromFavorites: (data) => api.post('/storage/favorites/remove', data),

    getLinkages: (params) => api.get(`/products/linkages/${params.articleId}/${params.mfrId}`),
    getAutoAbout: (id, targetType) => api.get(`/vehicle/${id}/${targetType}`),
  };

  const searchRequests = {
    getSearchBrands: (search) => api.get(`/search/${search}`),
    getSearchProductsByCode: (search, manufacturerId) => api.get(`/search/${search}/${manufacturerId}`),
    getSearchByNameCheck: (name) => api.get(`/search/name/check?search=${name}`),
    getSearchProductsByName: (name) => api.get(`/search/name?search=${name}`),
    addSearchToHistory: (data) => api.post('/storage/search_history/add', data),
    getSearchHistory: () => api.get(`/storage/search_history`),
  };

  const loyaltyRequests = {
    getLoyaltyPrograms: (params) => api.get('/articles/b2b/loyalty_programs', { params }),
    getUnreadLoyalty: () => api.get(`/articles/b2b/loyalty_programs/info`),
  };

  const value = {
    setToken,
    setContentLanguage,
    logout,
    errors,
    aboutUs: aboutUsRequests,
    auth: authRequests,
    tecdoc: tecdocRequests,
    products: productsRequests,
    articles: articleRequests,
    user: userRequests,
    settings: settingsRequests,
    basket: basketRequests,
    dashboard: dashboardRequests,
    order: orderRequests,
    loyalty: loyaltyRequests,
    notification: notificationRequests,
    wishlist: wishlistRequests,
    other: otherRequests,
    search: searchRequests,
  };

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  return useContext(ApiContext);
};
