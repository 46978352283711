import React, { useContext, memo } from 'react';
import ProductItem from './ProductItem';
import ListHeader from './ListHeader';
import { CommonDataContext } from 'HOC/withCommonData/withCommonData';
import { tecdocDescriptionToText } from 'utils/tecdocDescriptionToText';

const Products = memo((props) => {
  const { products, queryKey, productType, pageType, tecdocActive, sortOptions, hasSortButtons, onSort } = props;

  const { productViewType } = useContext(CommonDataContext);

  return (
    <>
      {productViewType === 'list' && (
        <ListHeader productType={productType} sortOptions={sortOptions} onSort={onSort} hasSortButtons={hasSortButtons} />
      )}

      {products &&
        products.map(({ description, ...product }) => {
          const normalizedDescription = Array.isArray(description) ? tecdocDescriptionToText(description) : description;
          const key = product.item_id ? product.item_id + productViewType : product.mfrId + product.article + productViewType;

          return (
            <ProductItem
              key={key}
              queryKey={queryKey}
              autoliderID={product.item_id}
              productViewType={productViewType}
              options={product.options}
              name={product.name}
              displayPrice={product.display_price}
              article={product.article}
              mfrId={product.mfrId}
              manufacturer={product.manufacturer}
              description={normalizedDescription}
              isFavorite={product.is_favorite}
              isInWishlist={product.is_in_wishlist}
              productType={productType}
              pageType={pageType}
              shoppingCount={product.shopping_count}
              deal={product.deal}
              loyalty={product.loyalty_program}
              images={product.images}
              isMultiImages={product.isMultiImages}
              logo={product.logo}
              warehouses={product.warehouses}
              isTecDoc={product.td}
              tecdocActive={tecdocActive}
            />
          );
        })}
    </>
  );
});

export default Products;
