import React, { useState, memo, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useLocation } from 'react-router';

import useOutsideClick from 'hooks/useOutsideClick';
import Icon, { ICON_NAMES } from 'components/Icon/index';
import { Preloader } from 'components/Preloader/Preloader';
import googleAnalytics from 'services/googleAnalytics';

const ToBasket = memo((props) => {
  const {
    name,
    autoliderID,
    article,
    description,
    logo,
    price,
    images,
    isColumn,
    isInWishlist,
    manufacturer,
    onAdd,
    storageTotal,
    incomeTotal,
    onDelete,
    shoppingCount,
    onAddToWishlist,
    isAddingBasket,
    isDeletingBasket,
  } = props;

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [count, setCount] = useState(1);

  const ref = useRef();
  useOutsideClick(ref, () => setCount(1));

  const isValid = useMemo(() => {
    const itemsInBasket = shoppingCount ? shoppingCount : 0;
    const sum = Number(count) + Number(itemsInBasket);

    if (sum === 0) return false;
    if (sum <= storageTotal) return true;
    else return false;
  }, [storageTotal, shoppingCount, count]);

  const isAddedAll = shoppingCount === storageTotal;

  const isMutating = isAddingBasket || isDeletingBasket;

  function countHandler(e) {
    const value = e.target.value.replace(/\./g, '');
    setCount(value >= 0 && value <= 9999 ? value : count);
  }

  function addToBasketHandler() {
    if (!isValid) return;
    setCount(1);
    onAdd({
      article,
      item_id: autoliderID,
      name,
      manufacturer,
      logo,
      images,
      description,
      count: shoppingCount ? Number(shoppingCount) + Number(count) : Number(count),
    });

    const category = googleAnalytics.extractCategoryFromUrl(pathname);
    googleAnalytics.onAddToBasket(name, autoliderID, price, manufacturer, count);
    if (category) {
      googleAnalytics.onAddToBasketFromPage(name, autoliderID, price, manufacturer, count, category);
    }
  }

  function deleteFromBasketHandler() {
    onDelete({ item_id: autoliderID });
    googleAnalytics.onRemoveFromBasket(name, autoliderID, price, manufacturer, 0);
  }

  switch (true) {
    case storageTotal > 0: {
      return (
        <div className={classNames('buy', { column: isColumn })}>
          <div className="buy__wrap" ref={ref}>
            <div className="buy-count">
              <input type="number" disabled={isAddedAll} value={count} onChange={(e) => countHandler(e)} />
              {(!isValid || isAddedAll) && <Icon name={ICON_NAMES.warning} />}
            </div>

            <button
              type="button"
              className={classNames('btn-square btn-square_bg-col-astr', { loading: isMutating })}
              disabled={!isValid || isMutating}
              onClick={addToBasketHandler}
            >
              {isMutating ? <Preloader /> : <Icon name={ICON_NAMES.basket} fill={'#fff'} />}
            </button>
            {shoppingCount && shoppingCount >= 1 && (
              <span className={classNames('count', { disabled: isMutating })} onClick={deleteFromBasketHandler}>
                {shoppingCount}
                <Icon name={ICON_NAMES.deleteFromBasket} className="count__close" />
              </span>
            )}
          </div>
        </div>
      );
    }
    case storageTotal === 0 && incomeTotal >= 0:
    default: {
      return (
        <div className={classNames('buy', { column: isColumn })}>
          <button
            type="button"
            className={classNames('link link_col-blue link_col-blue__visible', { 'opacity-02': isInWishlist })}
            onClick={onAddToWishlist}
          >
            {t('action.let_me_know')}
          </button>
        </div>
      );
    }
  }
});

export default ToBasket;
