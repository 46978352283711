import React, { memo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import AliceCarousel from 'react-alice-carousel';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getCorrectEndingPoints } from 'utils/getCorrectEndingPoints';

const LoyaltySales = memo(({ data }) => {
  const { t } = useTranslation();

  const hasData = data?.length > 0;
  const isButtonsDisabled = data?.length <= 1 ? true : false;

  return (
    <div className="list-recomm-goods__item promo-card loyalty">
      {hasData ? (
        <AliceCarousel infinite={true} touchTracking={false} disableButtonsControls={isButtonsDisabled} autoPlay={false}>
          {data?.map((item, index) => {
            return (
              <div key={index} className="promo-card__body">
                <div className="promo-card__header">
                  <div className="promo-card__title">{t('label.loyalty_program')}</div>
                  <div className="promo-card__date">
                    {moment.utc(item?.starts_in).format('DD.MM.YYYY')}
                    {' - '}
                    {moment.utc(item?.expires_in).format('DD.MM.YYYY')}
                  </div>
                </div>
                <div className="promo-card__content promo-card-content promo-card-content--loyalty">
                  <p>{t('label.сurrent_cashback')}</p>
                  <p className="focus">
                    {item?.accumulated_points}{' '}
                    <span className="text-lowercase">
                      {getCorrectEndingPoints(item?.accumulated_points ? Number(item?.accumulated_points) : 0)}
                    </span>
                  </p>
                </div>
                <div className="promo-card__footer promo-card-footer">
                  <Link to={`/sales/${item?.seo_slug}`} className="promo-card-footer__desc">
                    {item?.title}
                  </Link>
                  <div className="promo-card-footer__btns">
                    <Link to={`/sales/${item?.seo_slug}`} className="promo-card-footer__btns--more">
                      {t('action.in_more_detail')}
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </AliceCarousel>
      ) : (
        <>
          <div className="accumulative-shares_plug title">
            <h3>{t('label.loyalty_program')}</h3>
          </div>

          <div className="accumulative-shares_plug text">
            <h3>{t('label.no_loyalty_programs')}</h3>
          </div>
        </>
      )}
    </div>
  );
});

export default LoyaltySales;
