import React, { Fragment, memo, useMemo, useEffect } from 'react';
import { Field, useFormState, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';
import SelectFieldAddress from 'pages/Basket/SelectFieldAddress';

const Calendar = memo((props) => {
  const { options, header, name, isDay, onChange } = props;

  const form = useForm();
  const formState = useFormState();
  const state = formState.values[name];

  useEffect(() => {
    if ((!state || state === '') && options && options.length > 0) {
      form.change(name, options[0].value);
      form.change('carrier_id', options[0].carrier_id);
    }
  }, [name, options, state, form]);

  return !isDay && options?.length === 0 ? null : (
    <div className="field-wrap">
      <p>{header}</p>

      <div className={classNames('time-select', { 'day-dispatch': isDay })}>
        {options?.map((option, index) => (
          <label key={`${option.value} ${option.label} ${index}`}>
            <Field name={name} component="input" type="radio" value={option.value} onChange={() => onChange(option)} />
            <p>{option.label}</p>
          </label>
        ))}
      </div>
    </div>
  );
});

const RouteList = memo((props) => {
  const { addresses, routeList } = props;

  const { t } = useTranslation();

  const form = useForm();
  const formState = useFormState();
  const selectedAddress = formState.values['routeListAddress'];
  const selectedDeliveryDay = formState.values['deliveryDay'];
  const storage = formState.values['storage'];

  const transformedAddresses = useMemo(() => {
    const normalizeValue = (value) => (value ? value.trim() : '');

    return addresses?.map((item) => ({
      ...item,
      label: [normalizeValue(item?.city_name), normalizeValue(item?.full_street_name), normalizeValue(item?.build)]
        .filter((f) => f)
        .join(', '),
      value: {
        n: item.n,
        address: `${normalizeValue(item?.city_name)} ${normalizeValue(item?.full_street_name)} ${normalizeValue(item?.build)}`,
      },
    }));
  }, [addresses]);

  const dayOptions =
    routeList &&
    selectedAddress &&
    Object.values(
      routeList
        .filter((item) => item.adresdeliv_n === selectedAddress.n)
        .map((item) => ({
          ...item,
          value: item.deliv_day,
          label: moment(item.deliv_day.substring(0, 10)).format('ddd (D MMMM)'),
        }))
        .reduce((acc, item) => ({ ...acc, [item.deliv_day]: { ...item } }), {})
    );

  const timeOptions =
    routeList &&
    selectedAddress &&
    selectedDeliveryDay &&
    Object.values(
      routeList
        .filter((item) => item.adresdeliv_n === selectedAddress.n)
        .filter((item) => item.deliv_tm && item.deliv_tm !== '00:00:00')
        .filter((item) => item.deliv_day.includes(selectedDeliveryDay))
        .map((item) => ({
          ...item,
          value: item.deliv_tm,
          label: item.deliv_tm,
        }))
        .reduce((acc, item) => ({ ...acc, [item.deliv_tm]: { ...item } }), {})
    );

  const resetField = () => {
    form.change('deliveryDay', null);
    form.change('deliveryTime', null);
  };

  const onChangeDay = (option) => {
    form.change('deliveryDay', option.value);
    form.change('carrier_id', option.carrier_id);
    form.change('deliveryTime', null);
  };

  const onChangeTime = (option) => {
    form.change('deliveryTime', option.value);
    form.change('carrier_id', option.carrier_id);
  };

  return (
    <Fragment>
      <Field
        name="routeListAddress"
        component={SelectFieldAddress}
        options={transformedAddresses}
        validate={(value) => (value ? null : t('errors.required_field'))}
        header={t('basket.delivery_list.address')}
        defaultLabel={t('basket.delivery_list.choose_address')}
        onReset={resetField}
      />

      {storage === 'expr' ? (
        <div className="free-shipping">
          <div className="free-shipping__desc-wrap-transparent">
            <p>{t('basket.label.check_the_date_and_time_of_departure_with_the_manager')}</p>
          </div>
        </div>
      ) : (
        <Fragment>
          {selectedAddress != null && (
            <Calendar
              options={dayOptions}
              header={t('basket.delivery_list.delivery_date')}
              name="deliveryDay"
              isDay
              onChange={onChangeDay}
            />
          )}

          {selectedDeliveryDay && <Calendar options={timeOptions} header={t('label.time')} name="deliveryTime" onChange={onChangeTime} />}
        </Fragment>
      )}

      <Field name="note">
        {({ input, meta }) => (
          <div className="field-wrap textarea">
            <p>{t('basket.delivery_list.note')}</p>
            <textarea {...input} placeholder={t('basket.label.default_placeholder')} />
          </div>
        )}
      </Field>
    </Fragment>
  );
});

export default RouteList;
