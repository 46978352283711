import React, { memo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useOutsideClick from 'hooks/useOutsideClick';

const SearchUnvalidInfo = memo(() => {
  const { t } = useTranslation();

  const [mounted, setMounted] = useState(true);

  const ref = useRef();
  useOutsideClick(ref, () => {
    if (mounted) setMounted(false);
  });

  return !mounted ? null : (
    <div className="search__message shadow" ref={ref}>
      {t('info.too_much_results')}
    </div>
  );
});

export default SearchUnvalidInfo;
