import React, { useState, useMemo, createContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useProductsPerPageOptions from 'hooks/useProductsPerPageOption';
import useProductViewType from 'hooks/useProductViewType';

const CommonDataContext = createContext(null);

const withCommonData = () => (Component) => {
  return function (props) {
    const { t, i18n } = useTranslation();

    const searchOptions = useMemo(() => {
      if (i18n.language)
        return [
          { label: t('select.search_by_code'), value: 'code' },
          { label: t('select.search_by_name'), value: 'name' },
        ];
    }, [t, i18n.language]);

    const [productsPerPage, setProductsPerPage, favoritesPerPage, setFavoritesPerPage, newsPerPage, setNewsPerPage] =
      useProductsPerPageOptions();
    const [productViewType, viewSwitchHandler] = useProductViewType();
    const [searchType, setSearchType] = useState(searchOptions[0]);

    useEffect(() => {
      if (i18n.language && searchType) {
        setSearchType(searchOptions.find((item) => item.value === searchType.value));
      }
    }, [i18n.language, searchType, searchOptions]);

    const memoizedCommonDataContext = useMemo(() => {
      return {
        productViewType,
        viewSwitchHandler,
        productsPerPage,
        setProductsPerPage,
        favoritesPerPage,
        setFavoritesPerPage,
        newsPerPage,
        setNewsPerPage,
        searchType,
        setSearchType,
        searchOptions,
      };
    }, [
      productViewType,
      viewSwitchHandler,
      productsPerPage,
      setProductsPerPage,
      favoritesPerPage,
      setFavoritesPerPage,
      searchType,
      setSearchType,
      searchOptions,
      newsPerPage,
      setNewsPerPage,
    ]);

    return (
      <CommonDataContext.Provider value={memoizedCommonDataContext}>
        <Component {...props} />
      </CommonDataContext.Provider>
    );
  };
};

export { withCommonData as default, CommonDataContext };
