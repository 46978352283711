import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'utils/formatPrice';

const DepartmentItem = memo(({ depName, debtCash, debtNonCash }) => {
  const { t } = useTranslation();

  return (
    <div className="cnt-row">
      <div className="column">{depName}</div>
      <div className="column cash">
        <strong>{t('label.debt_cash')}</strong>
        <div className="wrap">
          <strong className="cash__debt">{formatPrice(debtCash)}</strong>
          <span className="currency">{t('label.currency')}</span>
        </div>
      </div>
      <div className="column cashless">
        <strong>{t('label.debt_non_cash')}</strong>
        <div className="wrap">
          <strong className="cashless__debt">{formatPrice(debtNonCash)}</strong>
          <span className="currency">{t('label.currency')}</span>
        </div>
      </div>
    </div>
  );
});

export default DepartmentItem;
