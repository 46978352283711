import { useState } from 'react';
import { paginationOptions, favoritePagePaginationOptions } from 'config/constants/selectOptions';

function useProductsPerPageOptions() {
  const [productsPerPage, setProductsPerPage] = useState(paginationOptions()[0]);
  const [favoritesPerPage, setFavoritesPerPage] = useState(favoritePagePaginationOptions()[2]);
  const [newsPerPage, setNewsPerPage] = useState(paginationOptions()[1]);

  return [productsPerPage, setProductsPerPage, favoritesPerPage, setFavoritesPerPage, newsPerPage, setNewsPerPage];
}

export default useProductsPerPageOptions;
