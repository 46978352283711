import React, { useMemo, useContext, useEffect } from 'react';
import { compose } from 'redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import withAuth from 'HOC/withAuth/withAuth';
import withPageLayout, { ViewContext } from 'HOC/withPageLayout/withPageLayout';
import { CommonDataContext } from 'HOC/withCommonData/withCommonData';
import { useGetFavorites } from 'hooks/api/useProducts';
import useWindowDimensions from 'hooks/useWindowDimensions';
import useSorting from 'hooks/useSorting';
import { useCatalogueTypes } from 'hooks/api/useProducts';
import Products from 'components/Products/Products';
import PagePagination from 'components/PagePagination/PagePagination';
import ViewSwitch from 'components/Navigation/ViewSwitch/ViewSwitch';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import Sort from 'components/Sort/Sort';
import { GlobalPreloader } from 'components/Preloader/Preloader';
import normalizeQueryParams from 'utils/normalizeQueryParams';
import { productKeys } from 'services/queryClient/queryKeys';
import { productTypes } from 'config/constants/productTypes';
import { pageTypes } from 'config/constants/pageTypes';
import { sortOptionsFavorites } from 'config/constants/selectOptions';
import { favoritePagePaginationOptions } from 'config/constants/selectOptions';

function Favorites() {
  const { data: catalogueTypes } = useCatalogueTypes();
  const { setIsSortMenuOpen, isSortMenuOpen } = useContext(ViewContext);
  const { favoritesPerPage, setFavoritesPerPage } = useContext(CommonDataContext);

  const location = useLocation();
  const page = Number(queryString.parse(location.search).page) || 1;

  const params = useMemo(() => {
    const query = normalizeQueryParams(
      queryString.parse(location.search, {
        arrayFormat: 'separator',
        arrayFormatSeparator: '|',
      })
    );
    return {
      ...query,
      per_page: favoritesPerPage.value,
      page,
    };
  }, [location, page, favoritesPerPage]);

  const { data, isFetching, refetch } = useGetFavorites(params);

  const windowDimension = useWindowDimensions();

  const { t } = useTranslation();

  const { sortOptions, sort } = useSorting(null);

  useEffect(() => {
    if (data?.products?.length === 0 && Number(data.total) !== 0) {
      setTimeout(() => refetch(), 500);
    }
  }, [data?.products, data?.total, refetch]);

  return (
    <>
      {isFetching && <GlobalPreloader />}

      <div className="nav-bottom">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="title">
              <h3>{t('page_title.favorites')}</h3>
            </div>

            {windowDimension.width > 960 && data?.products?.length > 0 && (
              <Sort largeScreen sortOptions={sortOptionsFavorites()} onSort={sort} />
            )}
          </div>

          {/* {windowDimension.width > 1365 && data?.products?.length > 0 && <ViewSwitch />} */}
          {windowDimension.width >= 1280 && data?.products?.length > 0 && <ViewSwitch />}
          {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>

        {windowDimension.width < 961 && data?.products?.length > 0 && (
          <div className="nav-bottom__nav-buttons nav-buttons">
            <button
              onClick={() => setIsSortMenuOpen(true)}
              className="nav-buttons__item nav-buttons__select-wrap select-wrap nav-buttons__item_full-screen"
              type="button"
            >
              {t('select.products_sort.sort')}
            </button>
          </div>
        )}
      </div>

      {data && (
        <div className="wrapper">
          {data.products.length > 0 || Number(data.total) > 0 ? (
            <>
              <div className="catalog-wrap">
                <Products
                  products={data.products}
                  queryKey={productKeys.products(params)}
                  productType={productTypes['common']}
                  pageType={pageTypes['favorites']}
                  sortOptions={sortOptions}
                  onSort={sort}
                  hasSortButtons
                />
              </div>
              {data.products.length !== 0 && (
                <PagePagination
                  pageCount={data.pageTotal}
                  page={page}
                  itemsTotal={data.total}
                  itemsOnPage={data.products.length}
                  itemsPerPage={favoritesPerPage}
                  paginationOptions={favoritePagePaginationOptions()}
                  setItemsPerPage={setFavoritesPerPage}
                />
              )}

              {isSortMenuOpen && windowDimension.width < 961 && <Sort smallScreen sortOptions={sortOptionsFavorites()} onSort={sort} />}
            </>
          ) : (
            <div className="no-page-content">{t('label.favorites_no_content')}</div>
          )}
        </div>
      )}
    </>
  );
}

export default compose(withPageLayout(), withAuth())(Favorites);
