import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import TableItem from './TableItem';
import { formatPrice } from 'utils/formatPrice';

const Table = memo(({ data, getDepartmentName }) => {
  const { t } = useTranslation();

  return (
    <div className="details-debt">
      <div className="details-debt__wrap">
        <div className="titles-row">
          <div className="col-title numbering-title">#</div>
          <div className="col-title department-title">{t('label.department')}</div>
          <div className="col-title number-title">{t('label.number')}</div>
          <div className="col-title document-title">{t('label.document')}</div>
          <div className="col-title date-title">{t('label.date')}</div>
          <div className="col-title payment-term-title">{t('label.payment_term')}</div>
          <div className="col-title cash-title">{t('label.sum_cash')}</div>
          <div className="col-title cashless-title">{t('label.sum_non_cash')}</div>
        </div>

        {data?.map((document, index) => {
          const hasNoDetailsPage = [43, 60, 61].includes(Number(document.kinddoc_id));

          return (
            <TableItem
              key={document?.doc_id}
              index={index + 1}
              docId={document?.doc_id}
              docNum={document?.num}
              docName={document?.doc_name}
              docDate={document?.day}
              docPayDate={document?.sday}
              cahsSum={document?.s_nal}
              nonCashSum={document?.s_bnal}
              disabled={hasNoDetailsPage}
              docDepartment={getDepartmentName(document?.place_id)}
            />
          );
        })}
      </div>

      <div className="result-row">
        <div className="column result-row__left-title">{t('label.total')}</div>

        <div className="result-row__right-wrap">
          <div className="column cash">
            <strong>
              {t('label.debt')} <br className="space" /> {t('label.money')}
            </strong>
            <div className="wrap">
              <strong className="cash__debt">
                {formatPrice(data?.reduce((accumulator, currentValue) => accumulator + currentValue.s_nal, 0))}
              </strong>
              <span className="currency">{t('label.currency')}</span>
            </div>
          </div>
          <div className="column cashless">
            <strong>
              {t('label.debt')} <br className="space" /> {t('label.no_money')}
            </strong>
            <div className="wrap">
              <strong className="cashless__debt">
                {formatPrice(data?.reduce((accumulator, currentValue) => accumulator + currentValue.s_bnal, 0))}
              </strong>
              <span className="currency">{t('label.currency')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Table;
