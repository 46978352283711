import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { compose } from 'redux';

import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import { CommonDataContext } from 'HOC/withCommonData/withCommonData';
import withAuth from 'HOC/withAuth/withAuth';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useCatalogueTypes } from 'hooks/api/useProducts';
import { useGetProfile } from 'hooks/api/useProfile';
import { useGetOrderHistory } from 'hooks/api/useOrder';
import PagePagination from 'components/PagePagination/PagePagination';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import PaymentFooter from 'components/PaymentFooter/PaymentFooter';
import { GlobalPreloader } from 'components/Preloader/Preloader';
import { getArrearsData, getBalanceData } from 'utils/getArrearsData';
import { imgUrlToResizeUrl } from 'utils/imgUrlToResizeUrl';
import { paginationOptions } from 'config/constants/selectOptions';
import OrderItem from './OrderItem';

function OrderHistory() {
  const { data: userData } = useGetProfile(true);
  const { data: catalogueTypes } = useCatalogueTypes();

  const { productsPerPage, setProductsPerPage } = useContext(CommonDataContext);

  const arrears = getArrearsData(userData?.debt);
  const balanceCash = getBalanceData(userData?.balanceFull.cash);
  const balanceCashless = getBalanceData(userData?.balanceFull.cashless);
  const paymentDetails = userData?.payment_details;

  const location = useLocation();
  const page = Number(queryString.parse(location.search).page) || 1;

  const { data, isFetching } = useGetOrderHistory({
    page,
    per_page: productsPerPage.value,
  });

  const windowDimension = useWindowDimensions();
  const { t } = useTranslation();

  return (
    <>
      {isFetching && <GlobalPreloader />}

      <div className="nav-bottom">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="title">
              <h3>{t('page_title.list_of_orders')}</h3>
            </div>
          </div>

          {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>
      </div>

      <div className="wrapper">
        {data && (
          <>
            <section className="orders-history">
              <div className="titles-row">
                <div className="col-title status-title">{t('label.status')}</div>
                <div className="col-title warehouse-title">{t('label.storage')}</div>
                <div className="col-title number-title">{t('label.order')}</div>
                <div className="col-title date-title">{t('label.date')}</div>
                <div className="col-title total-amount-title">{t('label.sum')}</div>
                <div className="col-title delivery-information-title">{t('label.carrier_and_time_of_departure')}</div>
              </div>

              {data?.data?.map((item) => (
                <OrderItem
                  key={item?.id}
                  id={item?.id}
                  carrierId={item?.carrier_id}
                  date={item?.data}
                  sum={item?.summ}
                  warehouse={item?.warehouse}
                  deliveryDate={item?.delivery_date}
                  isProcessed={Boolean(item?.doc_id)}
                />
              ))}
            </section>
            {data?.data?.length !== 0 && (
              <PagePagination
                pageCount={data?.lastPage}
                page={page}
                paginationOptions={paginationOptions()}
                itemsTotal={data?.total}
                itemsOnPage={data?.data?.length}
                itemsPerPage={productsPerPage}
                setItemsPerPage={setProductsPerPage}
              />
            )}
          </>
        )}
      </div>

      {data && userData && (
        <PaymentFooter
          arrears={arrears}
          url={paymentDetails?.url}
          qrImage={imgUrlToResizeUrl(paymentDetails?.qr, 120, 120, 'resize')}
          image={imgUrlToResizeUrl(paymentDetails?.image, 143, 80)}
          paymentInfoList={paymentDetails?.list}
          className="mt-60"
          balanceCash={balanceCash}
          balanceCashless={balanceCashless}
        />
      )}
    </>
  );
}

export default compose(withPageLayout(), withAuth())(OrderHistory);
