import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClientProvider } from 'react-query';
import { Provider as RollbarProvider, ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react';
import App from './App';
import { ApiProvider } from './services/api';
import i18n from './services/i18n/i18n';
import { queryClient } from 'services/queryClient/queryClient';
import { rollbarConfig } from 'config/rollbarConfig';
import RollbarError from './components/RollbarError';

ReactDOM.render(
  <React.StrictMode>
    <RollbarProvider config={rollbarConfig}>
      <RollbarErrorBoundary fallbackUI={RollbarError}>
        <QueryClientProvider client={queryClient}>
          <ApiProvider>
            <I18nextProvider i18n={i18n}>
              <App />
            </I18nextProvider>
          </ApiProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </RollbarErrorBoundary>
    </RollbarProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
