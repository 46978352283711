import React, { memo, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const BrandInfo = memo(({ data, logo }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedButtonShown, setIsExpandedButtonShown] = useState(false);

  const { t } = useTranslation();
  const ref = useRef();

  const toggleExpand = () => setIsExpanded(!isExpanded);

  useEffect(() => {
    if (ref?.current?.scrollHeight > 200) setIsExpandedButtonShown(true);
    if (ref?.current?.scrollHeight <= 200) setIsExpandedButtonShown(false);
  }, []);

  const brandImage = data.image ? `${process.env.REACT_APP_API_URL}${data.image}` : logo;

  return (
    <div className="inf-brand">
      <div className="inf-brand__title title">
        <h4>{t('label.about_brand_info')}</h4>
      </div>
      <div className="inf-brand__wrap">
        <div className="inf-brand__left-wrap">
          <div className="inf-brand__img-wrap">
            <img src={brandImage} width="100" alt="" />
          </div>
          <div className="brand-details">
            <strong className="brand-details__name">{data?.name}</strong>
            <div className="lists-wrap">
              <ul className="list">
                <li className="list__item">
                  <p className="list__title">{t('label.country')}</p>
                  <p className="list__value">{data?.data?.country}</p>
                </li>

                <li className="list__item">
                  <p className="list__title">{t('label.site')}</p>
                  <a href={data?.data?.site} target="_blank" rel="noreferrer" className="list__value">
                    {data?.data?.site}
                  </a>
                </li>

                <li className="list__item">
                  <p className="list__title">{t('label.product_groups')}</p>
                  <p className="list__value">{data?.data?.production}</p>
                </li>
              </ul>
              <ul className="list-links">
                {data?.data?.links?.map((link, index) => (
                  <li className="list-links__item" key={index}>
                    <p className="list-links__title">{t('label.online_catalogue')}</p>
                    <a href={link?.value} target="_blank" rel="noreferrer" className="list-links__value">
                      {t('label.online_selection_catalogue')}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="inf-brand__desc">
          <div
            ref={ref}
            className={classNames('text-wrap', { active: isExpanded })}
            dangerouslySetInnerHTML={{ __html: data?.data?.description }}
          >
            {/* <p>
              Компанія FAI Automotive є провідним незалежним дистриб'ютором автомобільних запчастин у
              Великій Британії. Асортимент продукції задовольняє потреби існуючого парку європейських,
              японських, корейських легкових автомобілів і легкого комерційного транспорту.
              Приміщення підприємства займає територію в 10 000 кв. м. Асортимент продукції нараховує
              більше 40 000 артикулів для понад 100 автовиробників. Стандартна завантаженість складу
              готової продукції – понад 3 000 000 одиниць.
            </p>
            <p>
              В асортиментну лінійку входять комплектуючі ДВЗ, елементи поршневої групи, розподільчі
              вали, деталі ГРМ, ремкомплекти, болти та ущільнювачі, а також елементи торсіонної
              підвіски і склопідйомники.
            </p>
            <p>
              Компанія FAI Automotive є провідним незалежним дистриб'ютором автомобільних запчастин у
              Великій Британії. Асортимент продукції задовольняє потреби існуючого парку європейських,
              японських, корейських легкових автомобілів і легкого комерційного транспорту.
              Приміщення підприємства займає територію в 10 000 кв. м. Асортимент продукції нараховує
              більше 40 000 артикулів для понад 100 автовиробників. Стандартна завантаженість складу
              готової продукції – понад 3 000 000 одиниць.
            </p>
            <p>
              В асортиментну лінійку входять комплектуючі ДВЗ, елементи поршневої групи, розподільчі
              вали, деталі ГРМ, ремкомплекти, болти та ущільнювачі, а також елементи торсіонної
              підвіски і склопідйомники.
            </p> */}
          </div>

          {isExpandedButtonShown && (
            <button type="button" className="btn-review" onClick={toggleExpand}>
              {isExpanded ? t('action.hide') : t('action.in_more_detail')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
});

export default BrandInfo;
