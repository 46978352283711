import React, { useEffect, useRef, memo, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useOutsideClick from 'hooks/useOutsideClick';

const SelectField = memo((props) => {
  const { size, header, options, input, meta, defaultLabel, onReset } = props;

  const ref = useRef();
  const { t } = useTranslation();

  useOutsideClick(ref, () => input.onBlur());

  const active = useMemo(() => {
    return options?.find((option) => option?.value === input?.value);
  }, [input, options]);

  const onChange = useCallback(
    (option, event) => {
      if (event) event.stopPropagation();
      input.onChange(option.value);
      input.onBlur();
      if (onReset) onReset();
    },
    [input, onReset]
  );

  const toggleMenuOpen = () => {
    if (meta.active) {
      input.onBlur();
      return;
    }
    input.onFocus();
  };

  useEffect(() => {
    const initial = options?.length === 1 ? options[0].value : null;
    if (initial == null || initial === input.value) return;
    requestAnimationFrame(() => input.onChange(initial));
  }, [options, input]);

  switch (size) {
    case 'lg':
      return (
        <div className="field-wrap">
          <p>{header}</p>
          <div className="select-wrap" ref={ref} onClick={toggleMenuOpen}>
            {meta.submitFailed && meta.error && !meta.active && (
              <span className="form-field_error form-field_error__offset-r">{meta.error}</span>
            )}

            <span className={`select-wrap__val ${active && 'selected'}`}>{active?.label || defaultLabel}</span>
            <ul className="select-wrap__list shadow">
              {meta.active &&
                options?.map((option, index) => {
                  return (
                    <li
                      key={index}
                      className={input.value === option?.value ? 'selected' : ''}
                      onClick={(event) => onChange(option, event)}
                    >
                      {option?.label}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      );

    default:
      return (
        <>
          <p className="basket-wrap__step-desc">
            <span className="basket-wrap__step-number">1.</span> {header}
          </p>

          <div className={`select-wrap ${!active?.label && 'forced'}`} ref={ref} onClick={toggleMenuOpen}>
            {meta.submitFailed && meta.error && !meta.active && (
              <span className="form-field_error form-field_error__offset-r">{meta.error}</span>
            )}

            <span className={`select-wrap__val ${active && 'selected'}`}>{active?.label || defaultLabel}</span>
            <ul className="select-wrap__list shadow">
              {meta.active &&
                options?.map((option, index) => (
                  <li key={index} className={input.value === option?.value ? 'selected' : ''} onClick={(event) => onChange(option, event)}>
                    {option.label}

                    {option.detailsLink ? (
                      <Link
                        to={option.detailsLink}
                        target="_blank"
                        rel="noreferrer"
                        className="link link_col-blue"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {t('action.in_more_detail')}
                      </Link>
                    ) : null}
                  </li>
                ))}
            </ul>
          </div>
        </>
      );
  }
});

export default SelectField;
