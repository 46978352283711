import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';

const LoyaltyLabel = memo(({ loyalty }) => {
  const { slug } = loyalty;
  return (
    <NavLink className="promo-points" to={`/sales/${slug}`} exact>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.8 6.6C12.8 7.48366 10.8301 8.2 8.4 8.2C5.96994 8.2 4 7.48366 4 6.6M12.8 6.6C12.8 5.71634 10.8301 5 8.4 5C5.96994 5 4 5.71634 4 6.6M12.8 6.6V10.1657C11.8228 10.4592 11.2 10.9031 11.2 11.4M4 6.6V16.2C4 17.0837 5.96994 17.8 8.4 17.8C9.4637 17.8 10.4393 17.6627 11.2 17.4343V11.4M11.2 11.4C11.2 12.2837 13.1699 13 15.6 13C18.0301 13 20 12.2837 20 11.4M11.2 11.4C11.2 10.5163 13.1699 9.8 15.6 9.8C18.0301 9.8 20 10.5163 20 11.4M11.2 11.4V17.8C11.2 18.6837 13.1699 19.4 15.6 19.4C18.0301 19.4 20 18.6837 20 17.8V11.4M4 9.8C4 10.6837 5.96994 11.4 8.4 11.4C9.4637 11.4 10.4393 11.2627 11.2 11.0343M4 13C4 13.8837 5.96994 14.6 8.4 14.6C9.4637 14.6 10.4393 14.4627 11.2 14.2343M20 14.6C20 15.4837 18.0301 16.2 15.6 16.2C13.1699 16.2 11.2 15.4837 11.2 14.6"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    </NavLink>
  );
});

export default LoyaltyLabel;
