import React, { memo, useRef } from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';

const Timer = memo(({ onComplete, ms }) => {
  const msRef = useRef(Date.now() + ms);

  return (
    <Countdown
      date={msRef.current}
      intervalDelay={1000}
      precision={1}
      onComplete={() => onComplete()}
      renderer={({ seconds }) => <>{seconds}</>}
    />
  );
});

const CountDown = memo(({ onComplete, ms, stopHandler }) => {
  const { t } = useTranslation();

  return (
    <div className="return">
      <span className="return__countdown">
        <Timer ms={ms} onComplete={onComplete} />
      </span>
      <div onClick={stopHandler}>{t('action.return')}</div>
    </div>
  );
});

export default CountDown;
