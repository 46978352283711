import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';

import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import withAuth from 'HOC/withAuth/withAuth';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useGetOrder } from 'hooks/api/useOrder';
import { warehouseToLable, carrierIdToCarrierLable } from 'utils/deliveryDataUtils';
import { formatPrice } from 'utils/formatPrice';
import { useCatalogueTypes } from 'hooks/api/useProducts';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import { GlobalPreloader } from 'components/Preloader/Preloader';
import OrderItemsHeader from './OrderItemsHeader';
import OrderItemsFooter from './OrderItemsFooter';
import OrderItem from './OrderItem';
import { getDepartureTime } from './utils';
import { paymentOptions } from 'config/constants/paymentOptions';

function OrderDetails() {
  const { data: catalogueTypes } = useCatalogueTypes();

  const { id } = useParams();

  const windowDimension = useWindowDimensions();
  const { t } = useTranslation();

  const { data, isFetching } = useGetOrder(id);
  const order = data?.order;
  const products = data?.products ?? [];

  const orderSum = formatPrice(
    order?.JSON_order_copy?.items?.reduce((acc, curr) => acc + Number(curr?.price) * Number(curr?.shopping_count), 0)
  );

  const [isOrderShown, toggleShowOrder] = useState(false);

  return (
    <>
      {isFetching && <GlobalPreloader />}

      {data && (
        <>
          <div className="nav-bottom order">
            <div className="nav-bottom__top-wrap">
              <div className="nav-bottom__left nav-bottom__item">
                <div className="wrapper">
                  <div className="title">
                    <h3>{t('page_title.order')}</h3>
                  </div>
                  <span className="order-number">{order?.id}</span>
                </div>
                <div className="wrap">
                  <div className="order">
                    {t('label.date')} <span className="order__date">{moment(order?.data).format('DD.MM.YY')}</span>
                  </div>
                  <div className="about-document">
                    {t('label.document_sum')}. <span className="about-document__amount">{formatPrice(order?.summ)}</span>
                  </div>
                </div>
              </div>

              {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
            </div>
          </div>

          <div className="wrapper">
            <section className="orders-wrap">
              <div className="orders-info">
                <ul className="orders-info__left-list left-list">
                  <li>
                    <span className="left-list__title">{t('label.carrier')}</span>
                    <strong className="left-list__value">{carrierIdToCarrierLable(order?.carrier_id)}</strong>
                  </li>
                  <li>
                    <span className="left-list__title">{t('label.contact_person')}</span>
                    <strong className="left-list__value">{order?.contact_name}</strong>
                  </li>
                  <li>
                    <span className="left-list__title">{t('select.basket.payment_method')}</span>
                    <strong className="left-list__value">
                      {
                        paymentOptions().find((el) => {
                          return el.value === order?.typepay_id;
                        })?.label
                      }
                    </strong>
                  </li>
                  <li>
                    <span className="left-list__title">{t('label.note')}</span>
                    <strong className="left-list__value">{order?.order_description}</strong>
                  </li>
                </ul>

                <ul className="orders-info__right-list right-list">
                  <li>
                    <span className="right-list__title">{t('label.date_and_time_of_order')}</span>
                    <strong className="right-list__value">{moment(order?.data).format('DD.MM.YYYY HH:mm')}</strong>
                  </li>
                  <li>
                    <span className="right-list__title">{t('label.phone')}</span>
                    <strong className="right-list__value">{order?.phone}</strong>
                  </li>
                  <li>
                    <span className="right-list__title">{t('label.dispatch_place')}</span>
                    <strong className="right-list__value">{warehouseToLable(order?.warehouse)}</strong>
                  </li>
                  <li>
                    <span className="right-list__title">{t('label.date_and_time_of_departure')}</span>
                    <strong className="right-list__value">{getDepartureTime(order?.delivery_date)}</strong>
                  </li>
                </ul>
              </div>

              <div className="order">
                <div className="order__wrap">
                  <div className="title">
                    <h4>{t('label.order_list')}</h4>
                  </div>
                </div>
                <div className="order__list">
                  <OrderItemsHeader />

                  {products.map((product, index) => (
                    <OrderItem
                      key={product?.item_id}
                      index={index + 1}
                      id={product?.item_id}
                      article={product?.article}
                      name={product?.name}
                      price={product?.price}
                      sum={product?.sum_str}
                      count={product?.quant}
                      images={product?.images}
                    />
                  ))}
                </div>

                <OrderItemsFooter sum={formatPrice(order?.summ)} />

                {order && (
                  <>
                    <div className="order__btn-wrap">
                      {t('label.original_order')}
                      <button type="button" className="btn btn_bg-col-white" onClick={() => toggleShowOrder(!isOrderShown)}>
                        {isOrderShown ? t('action.hide') : t('action.show')}
                      </button>
                    </div>

                    {isOrderShown && (
                      <div className="order-details">
                        <div className="order-details__table">
                          <OrderItemsHeader />

                          {order?.JSON_order_copy?.items?.map((item, index) => (
                            <OrderItem
                              key={item?.item_id}
                              id={item?.item_id}
                              article={item?.article}
                              name={item?.name}
                              price={item?.price}
                              sum={item?.price * item?.shopping_count}
                              count={item?.shopping_count}
                              images={item?.images}
                              index={index + 1}
                            />
                          ))}
                        </div>

                        <OrderItemsFooter sum={orderSum} />
                      </div>
                    )}
                  </>
                )}
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default compose(withPageLayout(), withAuth())(OrderDetails);
