import React, { useCallback, useMemo, useState } from 'react';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import withAuth from 'HOC/withAuth/withAuth';
import { useGetProductDetails } from 'hooks/api/useProducts';
import { useGetLinkages } from 'hooks/api/useTecdoc';
import { useCatalogueTypes } from 'hooks/api/useProducts';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { productKeys } from 'services/queryClient/queryKeys';
import history from 'services/history';
import { tecdocDescriptionToText } from 'utils/tecdocDescriptionToText';
import { productTypes } from 'config/constants/productTypes';

import ProductItem from 'components/Products/ProductItem';
import CharacteristicsDropdown, { dropdownTypes } from './CharacteristicsDropdown';
import BrandInfo from './BrandInfo';
import { GlobalPreloader } from 'components/Preloader/Preloader';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import Icon, { ICON_NAMES } from 'components/Icon/index';
import Modal from './Modal';

function ProductDetails() {
  const { data: catalogueTypes } = useCatalogueTypes();

  let { id } = useParams();

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = useCallback(() => setModalOpen(true), []);
  const closeModal = useCallback(() => setModalOpen(false), []);

  const { data, isFetching } = useGetProductDetails({ item_id: id });
  const { data: linkages, mutate: getLinkages, isLoading: isLoadingLinkages, isSuccess: linkagesSuccess } = useGetLinkages();

  const isTecDoc = data?.isTD;

  const queryKey = productKeys.product({ item_id: id });

  const windowDimension = useWindowDimensions();

  const { originalNumsList, appliedList } = useMemo(() => {
    if (!data) return {};
    if (!isTecDoc) return {};

    const originalNumsList = Object.entries(data.oemNumbers).map((item) => ({
      label: item[0],
      value: item[1],
    }));
    const appliedList = data.linkages.map((item) => ({
      ...item,
      label: item.manufacturer,
      value: item.mfrId,
      articleId: data.articleId,
    }));

    return { originalNumsList, appliedList };
  }, [data, isTecDoc]);

  const handleGetLinkages = useCallback(
    (articleId, mfrId) => {
      getLinkages({ articleId, mfrId });
      openModal();
    },
    [getLinkages, openModal]
  );

  return (
    <>
      {(isFetching || isLoadingLinkages) && <GlobalPreloader />}

      <div className="nav-bottom">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <button type="button" className="back btn-square btn-square_bg-col-white" onClick={history.goBack}>
              <Icon name={ICON_NAMES.goBack} />
            </button>
          </div>

          {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>
      </div>

      <div className="wrapper">
        {data && (
          <section className={classNames('details-goods-wrap', { 'tec-doc': isTecDoc })}>
            {data && isTecDoc && (
              <>
                <div className="details-goods">
                  <ProductItem
                    queryKey={queryKey}
                    autoliderID={data.item_id}
                    tecdocManufacturerId={data.mfrId || data.manufacturer_id}
                    options={data.options}
                    description={tecdocDescriptionToText(data.description)}
                    name={data.name}
                    displayPrice={data.display_price}
                    article={data.article}
                    manufacturer={data.manufacturer}
                    mfrId={data.manufacturer_id}
                    isFavorite={data.is_favorite}
                    isInWishlist={data.is_in_wishlist}
                    isMultiImages={data.isMultiImages}
                    productType={productTypes['detailsPage']}
                    shoppingCount={data.shopping_count}
                    deal={data.deal}
                    images={data.images}
                    logo={data.logo}
                    warehouses={data.warehouses}
                    isTecDoc={data.isTD}
                    loyalty={data.loyalty_program}
                  />

                  <CharacteristicsDropdown type={dropdownTypes.originalNums} data={originalNumsList} />
                  <CharacteristicsDropdown type={dropdownTypes.characteristics} data={data.properties} />
                  <CharacteristicsDropdown type={dropdownTypes.appliedTo} data={appliedList} onClick={handleGetLinkages} />
                </div>

                {data && data.description && data.description.length > 0 && (
                  <CharacteristicsDropdown type={dropdownTypes.description} data={data.description} />
                )}
              </>
            )}

            {data && !isTecDoc && (
              <div className="details-goods">
                <ProductItem
                  queryKey={queryKey}
                  autoliderID={data.item_id}
                  tecdocManufacturerId={data.mfrId || data.manufacturer_id}
                  options={data.options}
                  name={data.name}
                  description={data.description}
                  displayPrice={data.display_price}
                  article={data.article}
                  manufacturer={data.manufacturer}
                  mfrId={data.manufacturer_id}
                  isFavorite={data.is_favorite}
                  isInWishlist={data.is_in_wishlist}
                  isMultiImages={data.isMultiImages}
                  productType={productTypes['detailsPage']}
                  shoppingCount={data.shopping_count}
                  deal={data.deal}
                  images={data.images}
                  logo={data.logo}
                  warehouses={data.warehouses}
                  isTecDoc={data.isTD}
                  loyalty={data.loyalty_program}
                />
                <CharacteristicsDropdown type={dropdownTypes.characteristics} data={data.properties} />
                <CharacteristicsDropdown type={dropdownTypes.description} data={data.description} />
              </div>
            )}
            {data.brand && <BrandInfo data={data.brand} logo={data.logo} />}
          </section>
        )}
      </div>

      {modalOpen && linkagesSuccess && linkages && <Modal data={linkages} onClose={closeModal} />}
    </>
  );
}

export default compose(withPageLayout(), withAuth())(ProductDetails);
