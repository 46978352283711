import React, { memo } from 'react';
import classNames from 'classnames';
import Icon, { ICON_NAMES } from 'components/Icon/index';

const FavoriteButton = memo(({ isFavorite, isColumn, onClick }) => (
  <div className={classNames('favorites', { column: isColumn })} onClick={onClick}>
    <div className="btn-square">
      {isFavorite ? (
        <Icon name={ICON_NAMES.favoritesChecked} fill="#0a2352" />
      ) : (
        <Icon name={ICON_NAMES.favoritesUnchecked} fill="#0a2352" />
      )}
    </div>
  </div>
));

export default FavoriteButton;
