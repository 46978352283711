import React, { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useFormState, useForm } from 'react-final-form';

import { useGetDeliveryInfo } from 'hooks/api/useBasket';
import { normalizeDeliveryData, DELIVERY_METHODS } from 'utils/deliveryDataUtils';

import SelectField from '../SelectField';
import NewPost from './DeliverySelectOptions/NewPost';
import SelfPickup from './DeliverySelectOptions/SeltPickup';
import RouteList from './DeliverySelectOptions/RouteList';
import Other from './DeliverySelectOptions/Other';
import UklonDelivery from './DeliverySelectOptions/UklonDelivery';

const DeliverySelect = memo(() => {
  const { t } = useTranslation();

  const form = useForm();
  const formState = useFormState();
  const selectedStorage = formState.values['storage'];
  const selectedDeliveryMethod = formState.values['deliveryMethod'];

  const selectedProductsSum = formState.values['products']
    .filter((product) => product.selected && product.count > 0)
    .reduce((accumulator, currentValue) => accumulator + currentValue.count * currentValue.price, 0);

  const { data: deliveryInfoData, refetch: getDeliveryInfo } = useGetDeliveryInfo(selectedStorage);

  useEffect(() => {
    if (selectedStorage) getDeliveryInfo();
  }, [getDeliveryInfo, selectedStorage]);

  const transformedList = useMemo(() => {
    const routeList =
      deliveryInfoData?.routeList?.length > 0
        ? {
            ...deliveryInfoData?.routeList[0],
            value: deliveryInfoData?.routeList[0]?.carrier_id,
            label: t('basket.delivery_list.route_list'),
          }
        : undefined;

    const normalizedData = deliveryInfoData?.deliveryList.map((item) => ({
      ...item,
      label: normalizeDeliveryData(item),
      value: item.carrier_id,
    }));

    if (routeList) return [routeList, ...normalizedData];
    return normalizedData;
  }, [deliveryInfoData, t]);

  const getAddresses = () => {
    if (deliveryInfoData?.addressDelivery && selectedDeliveryMethod in deliveryInfoData?.addressDelivery) {
      return deliveryInfoData?.addressDelivery?.[selectedDeliveryMethod].reduce((acc, item) => {
        const option = deliveryInfoData?.addresses.find((el) => el.n === item);
        if (option) {
          acc.push(option);
        }
        return acc;
      }, []);
    }
    if (
      selectedDeliveryMethod &&
      !DELIVERY_METHODS.new_post.includes(selectedDeliveryMethod) &&
      !DELIVERY_METHODS.self_pickup.includes(selectedDeliveryMethod) &&
      !DELIVERY_METHODS.other.includes(selectedDeliveryMethod) &&
      !DELIVERY_METHODS.uklon.includes(selectedDeliveryMethod)
    ) {
      return deliveryInfoData?.addressLider.reduce((acc, item) => {
        const option = deliveryInfoData?.addresses.find((el) => el.n === item);
        if (option) {
          acc.push(option);
        }
        return acc;
      }, []);
    }

    return deliveryInfoData?.addresses;
  };

  const routeListAddresses = getAddresses();
  const routeList = deliveryInfoData?.routeList;
  const message = deliveryInfoData?.messages?.find((item) => item.carrier_id === selectedDeliveryMethod);

  const isRouteListSelected = Boolean(routeList?.find((item) => item.carrier_id === selectedDeliveryMethod));
  const isNewPostSelected = DELIVERY_METHODS.new_post.includes(selectedDeliveryMethod);
  const isSelfPickupSelected = DELIVERY_METHODS.self_pickup.includes(selectedDeliveryMethod);
  const isOtherMethodSelected = DELIVERY_METHODS.other.includes(selectedDeliveryMethod);
  const isUklonDeliverySelected = DELIVERY_METHODS.uklon.includes(selectedDeliveryMethod);

  const minOrderSum = deliveryInfoData?.routeList?.find((item) => item?.carrier_id === selectedDeliveryMethod)?.minordersum;
  const deliverySum = deliveryInfoData?.routeList?.find((item) => item?.carrier_id === selectedDeliveryMethod)?.delivsum;

  const resetField = () => {
    form.change('deliveryDay', null);
    form.change('deliveryTime', null);
    form.change('routeListAddress', null);
    form.change('note', '');
    form.change('city', '');
    form.change('department', '');
    form.change('carrier_id', null);
  };

  useEffect(() => {
    if (message) form.change('isBlocked', message?.is_blocking);
    if (!message) form.change('isBlocked', false);
  }, [form, message]);

  return (
    <div className="step-three">
      <div className="step-three__top">
        <p className="basket-wrap__step-desc">
          <span className="basket-wrap__step-number">3.</span> {t('basket.label.enter_additional_information')}
        </p>
      </div>

      <div className="fields">
        {!selectedStorage && <div className="overlay overlay_solitude"></div>}
        <Field
          size="lg"
          name="deliveryMethod"
          component={SelectField}
          options={transformedList}
          header={t('select.basket.delivery_method')}
          defaultLabel={t('select.basket.choose')}
          onReset={resetField}
        />

        {message && (
          <div className="free-shipping">
            <div className="free-shipping__desc-wrap">
              <p>{message.message}</p>
            </div>
          </div>
        )}

        {!message?.is_blocking && (
          <>
            {isNewPostSelected && <NewPost />}

            {isSelfPickupSelected && <SelfPickup />}

            {isRouteListSelected && <RouteList addresses={routeListAddresses} routeList={routeList} />}

            {isOtherMethodSelected && <Other />}

            {isUklonDeliverySelected && <UklonDelivery addresses={routeListAddresses} />}

            {minOrderSum && selectedProductsSum > 0 && Number(minOrderSum) > selectedProductsSum && (
              <div className="free-shipping">
                <div className="free-shipping__desc-wrap">{minOrderSum && <p>{t('label.min_order_sum', { sum: minOrderSum })}</p>}</div>
              </div>
            )}
            {Number(deliverySum) > 0 && Number(minOrderSum) > selectedProductsSum && (
              <div className="free-shipping">
                <div className="free-shipping__desc-wrap">{deliverySum && <p>{t('label.delivery_sum', { sum: deliverySum })}</p>}</div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
});

export default DeliverySelect;
